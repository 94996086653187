import { BoardList } from './views';
import { BoardCreate } from './views/board-create';
import { BoardEdit } from './views/board-edit';

export default {
  edit: BoardEdit,
  create: BoardCreate,
  list: BoardList,
  name: 'board-type',
  label: 'Boards',
  to: '/board-type',
};
