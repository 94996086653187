import { RegionsList, RegionsCreate, RegionsEdit } from './views';

export default {
  create: RegionsCreate,
  edit: RegionsEdit,
  list: RegionsList,
  name: 'region',
  label: 'Regions',
  to: '/region',
};
