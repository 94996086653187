import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  none: {
    display: 'none',
  },
  black: {
    color: 'black !important',
  },
  tabBoard: {
    backgroundColor: 'white',
    color: 'blue',
  },
  inputGrid: {
    margin: '8px 0px',
    width: '90%',
    textAlign: 'center',
  },
  inputDate: {
    margin: '8px 0px 15px',
  },
  inputAddress: {
    margin: '15px 0px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  inputMarginRight: {
    margin: '15px 15px 15px 0px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    marginTop: '25px',
    width: '100%',
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  leftPanel: {
    width: '70%',
  },
  rightPanel: {
    marginLeft: '20px',
    width: '30%',
    border: '1.5px solid lightgray',
    padding: '15px',
    borderRadius: '10px',
  },
  grayBox: {
    border: '1.5px solid lightgray',
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '5px',
  },
  flex: {
    display: 'flex',
    maxWidth: '1500px',
    width: '1500px',
  },
  button: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonRed: {
    marginTop: '10px',
    marginBottom: '10px',
    borderColor: 'red !important',
    color: 'red !important',
  },
  buttonTimeline: {
    marginLeft: '10px',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '13px',
      marginLeft: 0,
    },
  },
  buttonRepairs: {
    backgroundColor: '#21a1f9',
    color: '#fff',
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '13px',
    },
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100% !important',
    margin: '10px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  orderDescription: {
    fontSize: '12px',
  },
  orderDescriptionSeparator: {
    borderTop: '1px solid lightgray',
  },
  whiteBox: {
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid lightgray',
    padding: '10px',
    width: '100%',
  },
  mobileBorderlessWhiteBox: {
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid lightgray',
    padding: '10px',
    width: '100%',
    '@media (max-width: 850px)': {
      borderRadius: '0px',
      padding: '0px',
    },
  },
  borderBox: {
    borderRadius: '10px',
    border: '1px solid lightgray',
    overflow: 'hidden',
    margin: '0px 10px',
  },
  titleBorderBox: {
    borderRadius: '10px',
    backgroundColor: '#F5F5F5',
    border: '1px solid #EBEBEB',
    overflow: 'hidden',
    padding: '20px',
  },
  whiteBoxMobile: {
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid lightgray',
    width: '100%',
    overflow: 'hidden',
  },
  pointer: {
    cursor: 'pointer',
    marginLeft: '10px',
  },
  circle: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: '#21a1f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleSmall: {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    borderRadius: '10px',
    backgroundColor: '#21a1f9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleRed: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: '#e6005a',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleGray: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: '#adadad',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleGreen: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  contedWrong: {
    backgroundColor: '#ff000085',
  },
  circleYellow: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: '#ff9900',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleInWarranty: {
    width: '30px',
    height: '30px',
    minWidth: '30px',
    borderRadius: '25px',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconCircle: {
    color: 'white',
    width: '20px',
  },
  iconCircleBlack: {
    color: 'black',
    width: '20px',
  },
  statusItem: {
    display: 'flex',
    alignItems: 'center',
  },
  timeline: {
    display: 'flex',
    overflow: 'auto',
    justifyContent: 'space-between',
    padding: '15px 30px 15px 15px',
    alignItems: 'center',
  },
  separator: {
    display: 'flex',
    flex: 1,
    height: '1px',
    backgroundColor: 'lightgray',
    margin: '0px 20px',
  },
  transparentSeparator: {
    width: '100%',
    height: '1px',
    margin: '0px 20px',
  },
  statusText: {
    marginLeft: '10px',
  },
  statusTextInWarranty: {
    marginLeft: '10px',
    color: 'lightgray',
  },
  dateText: {
    marginLeft: '10px',
    color: 'gray',
  },
  dateTextInWarranty: {
    marginLeft: '10px',
    color: 'lightgray',
  },
  tabs: {
    backgroundColor: '#21a1f9',
  },
  tabsText: {
    fontSize: 14,
    '@media (max-width: 800px)': {
      fontSize: '12px',
    },
  },
  tabsBoards: {
    backgroundColor: 'white',
  },
  indicatorBoards: {
    backgroundColor: '#21a1f9',
  },
  indicator: {
    backgroundColor: 'white',
  },
  titleFont: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  titleFontBlack: {
    fontSize: '16px',
    color: 'black',
  },
  bigText: {
    fontSize: '16px',
  },
  titleFontBigger: {
    fontWeight: 'bold',
    fontSize: '18px',
  },
  error: {
    color: '#e6005a',
  },
  totalBoards: {
    color: '#e6005a',
    fontSize: '16px',
    marginLeft: '20px',
    marginRight: '10px',
  },
  totalBoardsGreen: {
    fontSize: '16px',
    marginRight: '10px',
    marginLeft: '20px',
  },
  totalBoardsMobile: {
    color: '#e6005a',
    fontSize: '12px',
  },
  totalBoardsGreenMobile: {
    fontSize: '12px',
  },
  titleTab: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
    borderBottom: '1px solid black',
  },
  titleTabNoBorder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },
  optionsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid black',
  },
  titleTabTimeline: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
    borderBottom: '1px solid black',
    '@media (max-width: 800px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
      paddingBottom: '10px',
    },
  },
  statusNumber: {
    color: 'white',
    fontSize: '16px',
  },
  geoLocationError: {
    color: '#e6005a',
    fontSize: '16px',
    marginRight: '10px',
    marginBottom: '10px',
  },
  floorRow: {
    margin: '10px 0px',
  },
  floorGroup: {
    borderTop: '1.3px solid black',
    margin: '10px 0px',
  },
  floorGroupMobile: {
    borderTop: '1.3px solid lightgray',
    padding: '10px',
  },
  floorHeader: {
    display: 'flex',
    backgroundColor: 'rgba(211,211,211,0.3)',
    padding: '10px',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  loadingText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '10px',
  },
  backgroundButton: {
    backgroundColor: 'lightgray',
    borderRadius: '10px',
    padding: '5px 10px',
  },
  borderGrid: {
    borderBottom: '1.3px solid black',
  },
  alignRight: {
    textAlign: 'right',
  },
  value: {
    fontSize: '14px',
  },
  spinner: {
    color: 'white',
  },
  editor: {
    border: '1px solid gray',
    padding: '3px',
  },
  editIcon: {
    marginLeft: '4px',
    cursor: 'pointer',
  },
  striked: {
    textDecoration: 'line-through',
    cursor: 'pointer',
  },
  normal: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  info: {
    cursor: 'pointer',
  },
  filterDate: {
    width: '100%',
    minWidth: '200px',
  },
  iconsUpload: {
    height: '30px',
    width: '30px',
    cursor: 'pointer',
  },
  mobileFieldText: {
    flex: 1,
    fontSize: 14,
  },
  mobileFieldTextCentered: {
    flex: 1,
    textAlign: 'center',
    fontSize: 14,
  },
  mobilePOWrapper: {
    marginBottom: 8,
  },
  buttonRepairsCancel: {
    textTransform: 'capitalize',
    '@media (max-width: 800px)': {
      fontSize: '13px',
    },
  },
  table: {
    borderRadius: 12,
    overflow: 'hidden',
    border: '1px solid lightgray',
  },
  tableHeader: {
    display: 'flex',
    backgroundColor: 'rgba(211,211,211,0.3)',
    padding: '10px 15px',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  tableLine: {
    borderTop: '1px solid lightgray',
    padding: '10px 15px',
  },
  iconAdd: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    display: 'flex',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
  },
  iconAddDisabled: {
    height: '25px',
    width: '25px',
    borderRadius: '50%',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.24)',
  },
  iconDelete: {
    height: '25px',
    width: '25px',
    cursor: 'pointer',
  },
  label: {
    color: '#666',
    fontSize: '12px',
  },
  signedStatusBadge: {
    backgroundColor: '#27AE60',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '30px',
    '& > svg': {
      width: '30px',
      height: '30px',
    },
  },
  builderSupervisorFormControl: {
    marginTop: 16,
  },
  builderSupervisorFormGroup: {
    marginTop: 8,
  },
});
