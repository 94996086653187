export const BumperPaymentType = [
  {
    name: 'NA',
    id: 'NA',
  },
  {
    name: 'PerBoard',
    id: 'PerBoard',
  },
  {
    name: 'PerHour',
    id: 'PerHour',
  },
];

export const RepairPaymentType = [
  {
    name: 'PerDay',
    id: 'PerDay',
  },
  {
    name: 'PerHour',
    id: 'PerHour',
  },
  {
    name: 'SetPrice',
    id: 'SetPrice',
  },
];

export const RepairResourceType = [
  {
    name: 'Bumper',
    id: 'Bumper',
  },
  {
    name: 'Hanger',
    id: 'Hanger',
  },
  {
    name: 'Finisher',
    id: 'Finisher',
  },
  {
    name: 'Sprayer',
    id: 'Sprayer',
  },
  {
    name: 'Specialist',
    id: 'Specialist',
  },
];
