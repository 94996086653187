import { ContractorsShow, ContractorsList, ContractorsCreate, ContractorsEdit } from './views';

export default {
  create: ContractorsCreate,
  edit: ContractorsEdit,
  list: ContractorsList,
  show: ContractorsShow,
  name: 'contractor',
  label: 'Contractors',
  to: '/contractor',
};
