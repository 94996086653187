import axios, { AxiosRequestConfig } from 'axios';

import { API_URL, ACCESS_TOKEN } from './constants';

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// const verifyAuth = async (): Promise<void> => {
//   try {
//     await renewAuth({
//       accessToken: localStorage.getItem(ANAGO_USER_ACCESS_TOKEN) || '',
//       savedRefreshToken: localStorage.getItem(ANAGO_USER_REFRESH_TOKEN) || '',
//       expireDate: localStorage.getItem(ANAGO_USER_EXPIRE_DATE) || '',
//     });
//   } catch (err) {
//     if (err && err.status != 401 && err.status != 403) {
//       //   console.log('err', err);
//       throw err;
//     }
//   }
// };

axiosInstance.interceptors.request.use(
  async (config): Promise<AxiosRequestConfig> => {
    if (
      config.url &&
      !config.url.includes('signin') &&
      !config.url.includes('refresh') &&
      !config.url.includes('signup')
    ) {
      //   await verifyAuth();

      config.headers.Authorization = `Bearer ${localStorage.getItem(
        ACCESS_TOKEN,
      )}`;
    }
    return config;
  },
  async (error): Promise<AxiosRequestConfig> => {
    if (error.status !== 401 && error.status !== 403) {
      return Promise.reject(error);
    }

    // await verifyAuth();

    error.response.config.headers.Authorization = `Bearer ${localStorage.getItem(
      ACCESS_TOKEN,
    )}`;

    return axios(error.response.config);
  },
);

export { axiosInstance };
