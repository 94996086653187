import React, { useEffect, useRef, useState } from 'react';

import { Identifier, useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import Email from '@material-ui/icons/Email';
import { SubdivisionBuilderSupervisorDto } from '@vatos-pas/common';
import InfoIcon from '@material-ui/icons/Info';

import { useStyles } from './master-sheet-styles';
import RepairDialog from '../components/repair-modal';
import AddRepairDialog from './master-sheet-add-repair';
import MasterSheetRepairsCheckPhotos from './master-sheet-repairs-check-photos';
import {
  CAN_ADD_REPAIR_CONTRACTOR,
  CAN_CREATE_REPAIR,
  CAN_SEND_EMAIL_CLIENT,
} from 'providers/permissions';
import { Show } from 'components/Show';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { Job } from './master-sheet-edit';
import { Repair } from 'modules/job-repair-sheet/views';
import { EmailManagementDialog } from '../components/email-management-dialog';
import { sendMail } from '../utils/emailManagement';
import { maskDate } from '../utils/maskDate';
import InfoDialog from 'components/InfoDialog';
import { getAddContractorsBlocked } from '../utils/getAddContractorsBlocked';

interface MasterSheetRepairsProps {
  theJob: Job;
  refreshRepairs: () => Promise<Repair[]>;
  initialValue?: string;
}

export const MasterSheetRepairs: React.FC<MasterSheetRepairsProps> = ({
  theJob,
  refreshRepairs,
  initialValue,
}) => {
  const notify = useNotify();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [poReleasedInfoOpen, setPoReleasedInfoOpen] = useState(false);
  const [emailManagementOpen, setEmailManagementOpen] = useState(false);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const [showAddRepairModal, setShowAddRepairModal] = useState(false);
  const [repair, setRepair] = useState<Repair>();
  const [openCheckPhotosModal, setOpenCheckPhotosModal] = useState(false);
  const [title, setTitle] = useState('');
  const [photos, setPhotos] = useState([]);
  const [loadingBuilderSupervisorEmail, setLoadingBuilderSupervisorEmail] =
    useState(false);
  const [hasEmailScreenshot, setHasEmailscreenshot] = useState(false);
  const [emailRepair, setEmailRepair] = useState<Repair | null>(null);
  const scroll = useRef<HTMLDivElement>(null);
  const [subdivisionBuilderSupervisors, setSubdivisionBuilderSupervisors] =
    useState<SubdivisionBuilderSupervisorDto[] | null>(null);

  const canSendEmailClient = hasPermission(CAN_SEND_EMAIL_CLIENT);

  const handleSendMail = async (repairId: Identifier | undefined) => {
    if (!repairId) return;

    try {
      setLoadingBuilderSupervisorEmail(true);
      await sendMail(repairId);
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setLoadingBuilderSupervisorEmail(false);
    }
  };

  const getSubdivisionBuilderSupervisors = async (subdivisionId: string) => {
    const builderSupervisors =
      await dataProvider.getList<SubdivisionBuilderSupervisorDto>(
        'subdivision-builder-supervisor',
        {
          filter: { subdivisionId },
          pagination: { page: 1, perPage: 999 },
          sort: { field: 'user.firstName', order: 'ASC' },
        },
      );

    if (builderSupervisors?.data.length) {
      setSubdivisionBuilderSupervisors(builderSupervisors.data);
    }
  };

  const onAddRepair = async (repair: Repair, hasEmailScreenshot: boolean) => {
    // Get refresh repairs because when creating a repair
    // the builder supervisors information doesn't get joined in the response.
    const refreshedRepairs = await refreshRepairs();

    const createdRepair = refreshedRepairs?.find(
      record => record.id === repair?.id,
    );

    if (!createdRepair) return;

    setRepair(createdRepair);
    setShowRepairModal(true);

    if (canSendEmailClient) {
      setEmailRepair(createdRepair);
      await handleSendMail(createdRepair.id);
      setHasEmailscreenshot(hasEmailScreenshot);
      setEmailManagementOpen(true);
    }
  };

  const onPhotoClick = (repair: any) => {
    setTitle('View Repair Photos');
    const filteredPhotos = repair.jobRepairPhotos.filter(
      photo => photo.repairPhotoType === 'Photo',
    );
    setPhotos(filteredPhotos);
    setOpenCheckPhotosModal(true);
  };

  const onEmailClick = (repair: any) => {
    setTitle('View Email Screenshots');
    const filteredScreenshots = repair?.jobRepairPhotos.filter(
      photo => photo.repairPhotoType === 'Screenshot',
    );
    setPhotos(filteredScreenshots);
    setOpenCheckPhotosModal(true);
  };

  const repairs = theJob?.jobRepairs;
  const hasRepairs = !!repairs && repairs.length > 0;

  const canCreateRepair = hasPermission(CAN_CREATE_REPAIR);
  const canAddContractor = hasPermission(CAN_ADD_REPAIR_CONTRACTOR);

  useEffect(() => {
    const openInitialRepair = () => {
      if (!initialValue) return;
      scroll.current?.scrollIntoView();
    };
    getSubdivisionBuilderSupervisors(theJob.subdivision.id);
    openInitialRepair();
  }, [initialValue]);

  return (
    <Box px={3}>
      <Box display="flex" justifyContent="space-between" my={4}>
        <Typography className={classes.titleFont}>Repairs</Typography>
        {canCreateRepair && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonRepairs}
            onClick={() => setShowAddRepairModal(true)}
          >
            Add Repair
          </Button>
        )}
      </Box>
      <div ref={scroll} />
      <Box width="100%">
        {hasRepairs && (
          <Box width="100%" display="flex">
            <Box width="100%">
              <Typography className={classes.titleFont}>
                Service Type
              </Typography>
            </Box>
            <Box width="100%">
              <Typography className={classes.titleFont}>Description</Typography>
            </Box>
            <Box width="100%">
              <Typography className={classes.titleFont}>Date</Typography>
            </Box>
            <Box width="100%">
              <Typography className={classes.titleFont}>PO Total $</Typography>
            </Box>
            <Box width="100%">
              <Typography className={classes.titleFont}>
                Email {canSendEmailClient ? 'Management' : ''}
              </Typography>
            </Box>
            <Box width="100%">
              <Typography className={classes.titleFont}>Photos</Typography>
            </Box>
          </Box>
        )}
        {repairs.map((repair, i) => {
          const {
            jobRepairPhotos,
            repair: repairType,
            description,
            repairDate,
            poTotal,
          } = repair;

          const hasPhotos = jobRepairPhotos?.some(
            photo => photo.repairPhotoType === 'Photo',
          );

          const hasScreenshots = jobRepairPhotos?.some(
            photo => photo.repairPhotoType === 'Screenshot',
          );

          const canEdit = canAddContractor && !repair.complete;
          const canView = canAddContractor ? true : repair.complete;
          const isAddContractorsBlocked = getAddContractorsBlocked(repair);

          const buttonText = canEdit
            ? isAddContractorsBlocked
              ? 'Review Details'
              : 'Add Contractor'
            : 'Show Contractors';

          const onActionClick = () => {
            setRepair(repair);
            setShowRepairModal(true);
          };

          return (
            <Box key={i} borderTop="1px solid black" py={3} mt={3}>
              <Box width="100%" display="flex" my={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  gridColumnGap={2}
                  width="100%"
                >
                  <Typography className={classes.bigText}>
                    {repairType.description}
                  </Typography>
                  <Show condition={isAddContractorsBlocked}>
                    <IconButton onClick={() => setPoReleasedInfoOpen(true)}>
                      <InfoIcon color="error" />
                    </IconButton>
                  </Show>
                </Box>
                <Box width="100%">
                  <Typography className={classes.bigText}>
                    {description}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography className={classes.bigText}>
                    {maskDate(repairDate)}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography className={classes.bigText}>{poTotal}</Typography>
                </Box>
                <Box width="100%">
                  <Typography className={classes.bigText}>
                    <Show
                      condition={canSendEmailClient}
                      fallback={
                        <>
                          {hasScreenshots && (
                            <Email
                              onClick={() => onEmailClick(repair)}
                              className={classes.iconsUpload}
                            />
                          )}
                        </>
                      }
                    >
                      <Email
                        onClick={() => {
                          setEmailManagementOpen(true);
                          setHasEmailscreenshot(hasScreenshots);
                          setEmailRepair(repair);
                          setRepair(repair);
                        }}
                        className={classes.iconsUpload}
                      />
                    </Show>
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography className={classes.titleFont}>
                    {hasPhotos && (
                      <CameraAltIcon
                        onClick={() => onPhotoClick(repair)}
                        className={classes.iconsUpload}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={!canView}
                  className={classes.buttonRepairs}
                  onClick={onActionClick}
                >
                  {buttonText}
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      <AddRepairDialog
        open={showAddRepairModal}
        subdivisionBuilderSupervisors={subdivisionBuilderSupervisors}
        theJob={theJob}
        onClose={() => setShowAddRepairModal(false)}
        onAdd={onAddRepair}
      />
      {showRepairModal && (
        <RepairDialog
          open
          subdivisionBuilderSupervisors={subdivisionBuilderSupervisors}
          repair={repair}
          theJob={theJob}
          handleClose={() => setShowRepairModal(false)}
          handleConfirm={refreshRepairs}
        />
      )}
      <MasterSheetRepairsCheckPhotos
        photos={photos}
        title={title}
        handleClose={() => setOpenCheckPhotosModal(false)}
        open={openCheckPhotosModal}
      />
      <EmailManagementDialog
        hasEmailScreenshot={hasEmailScreenshot}
        emailManagementOpen={emailManagementOpen}
        loadingBuilderSupervisorEmail={loadingBuilderSupervisorEmail}
        emailRepair={emailRepair}
        handleSendMail={handleSendMail}
        handleEmailClick={() => onEmailClick(repair)}
        handleClose={() => {
          setEmailManagementOpen(false);
          setHasEmailscreenshot(false);
          setEmailRepair(null);
        }}
      />
      <InfoDialog
        handleClose={() => setPoReleasedInfoOpen(false)}
        open={poReleasedInfoOpen}
        content="Contractor is not able to be assigned until the PO has been released."
      />
    </Box>
  );
};

export default MasterSheetRepairs;
