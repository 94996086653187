import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  pendingStatusBadge: {
    backgroundColor: '#F2C94C',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  signedStatusBadge: {
    backgroundColor: '#27AE60',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
});
