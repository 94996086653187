import Box from '@material-ui/core/Box';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import {
  SpConnectOrderTypeEnum,
  SpConnectPurposeEnum,
} from '@vatos-pas/common';

const spOrderPurposeOptions = Object.keys(SpConnectPurposeEnum).map(key => {
  const val = SpConnectPurposeEnum[key];
  return { val, text: key };
});

const spOrderOrderTypeOptions = Object.keys(SpConnectOrderTypeEnum).map(key => {
  const val = SpConnectOrderTypeEnum[key];
  return { val, text: key };
});

export const PendingJobsSheetFilter = (props: any) => (
  <Box>
    <Filter {...props}>
      <ReferenceInput
        label="Builder"
        source="builderId"
        reference="builder"
        resettable
        allowEmpty={false}
        alwaysOn
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        alwaysOn
        label="Subdivision"
        source="subdivisionId"
        reference="subdivision"
        resettable
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        alwaysOn
        label="Model"
        source="modelId"
        reference="model"
        resettable
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <TextInput
        label="Order ID"
        source="spOrderOrderId||$eq"
        resettable
        alwaysOn
      />
      <TextInput
        label="Builder Order #"
        source="spOrderBuilderOrderNumber||$eq"
        resettable
        alwaysOn
      />
      <TextInput label="Lot" source="spOrderJobLot" resettable alwaysOn />
      <TextInput label="Block" source="spOrderJobBlock" resettable alwaysOn />
      <SelectInput
        alwaysOn
        choices={spOrderPurposeOptions}
        label="Purpose"
        source="spOrderPurpose||$eq"
        emptyText="All"
        optionValue="val"
        optionText="text"
      />
      <SelectInput
        alwaysOn
        choices={spOrderOrderTypeOptions}
        label="Order Type"
        source="spOrderOrderType||$eq"
        emptyText="All"
        optionValue="val"
        optionText="text"
      />
      <TextInput
        label="Sibling Count"
        source="orderSiblingCount||$eq"
        resettable
        alwaysOn
      />
      <SelectInput
        alwaysOn
        choices={[
          { val: true, text: 'New' },
          { val: false, text: 'Not New' },
        ]}
        defaultValue={false}
        label="Is New"
        source="isNew"
        emptyText="All"
        optionValue="val"
        optionText="text"
      />
      <SelectInput
        alwaysOn
        choices={[
          { val: true, text: 'Duplicate' },
          { val: false, text: 'Not Duplicate' },
        ]}
        defaultValue={false}
        label="Is Duplicate"
        source="isDuplicate"
        emptyText="All"
        optionValue="val"
        optionText="text"
      />
      <SelectInput
        alwaysOn
        choices={[
          { val: false, text: 'Pending' },
          { val: true, text: 'Imported' },
        ]}
        defaultValue={false}
        label="Status"
        source="imported"
        emptyText="All"
        optionValue="val"
        optionText="text"
      />
    </Filter>
  </Box>
);
