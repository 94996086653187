import { ChangeEvent, FC, useMemo, useRef, useState } from 'react';
import {
  List,
  TextField,
  DateField,
  FunctionField,
  useNotify,
  useRefresh,
  Record,
  DateFieldProps,
  useDataProvider,
  useListContext,
  FunctionFieldProps,
  useUnselectAll,
} from 'react-admin';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InfoIcon from '@material-ui/icons/Info';

import { axiosInstance } from 'services/http';
import Datagrid from 'components/Datagrid';
import ConfirmDialog from 'components/ConfirmDialog';
import PartialDeliveryModal, {
  FormValues,
} from '../components/partial-delivery-modal';
import { MaterialDeliveryFilter } from '../filters/materials-delivery-filters';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';
import { AppRole } from 'providers/roles';
import {
  CAN_ADD_MATERIALS_DELIVERY_DATE,
  CAN_ADD_PARTIAL_DELIVERY,
  CAN_CANCEL_DELIVERY_PURCHASE_ORDER,
  CAN_STOP_FULFILLMENT,
  CAN_PRIORITIZE_MATERIALS_DELIVERY,
  CAN_CHANGE_EXPECTED_DATE,
} from 'providers/permissions';
import { StickyNote2 } from 'components/Icons';
import { makeStyles } from '@material-ui/core';
import PartialDeliveryHistoryModal from '../components/partial-delivery-history-modal';
import { FulfillmentModal } from '../components/fulfillment-modal';
import { CancelOrderModal } from '../components/cancel-order-modal';
import PurchaseOrderSummary from './purchase-order-summary';
import { getSummary } from 'services/summary';
import OriginalExpectedModal from '../components/original-expected-modal';

interface Field {
  datePromised: MaterialUiPickersDate;
  dateDelivery: MaterialUiPickersDate;
  dateExpected: MaterialUiPickersDate;
}

interface Fields {
  [id: string]: Field;
}

interface DateProps extends Omit<DateFieldProps, 'onChange'> {
  onChange: (id: string) => (event: any) => void;
  fields: Fields;
  record?: Record;
  openOriginalExpected?: any;
}

const useStyles = makeStyles({
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  deliveryHistoryIcon: {
    cursor: 'pointer',
    padding: 4,
  },
  poAnchor: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
  },
  info: {
    cursor: 'pointer',
  },
});

const applyRowStyle = (record: Record) => {
  const { isHighPriority } = record;
  return { backgroundColor: !isHighPriority ? 'white' : '#ff8282' };
};

const ExpectedDateField = ({
  record,
  onChange,
  fields,
  openOriginalExpected,
  ...props
}: DateProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();
  const classes = useStyles();

  const id = record?.id ? String(record?.id) : '';
  const date = record?.dateExpected !== undefined ? record.dateExpected : '';
  const fieldExists = fields?.[id]?.dateExpected !== undefined;
  const value = fieldExists ? fields[id].dateExpected : date;
  const canChangeExpectedDate = hasPermission(CAN_CHANGE_EXPECTED_DATE);

  const onDateChange = (value: MaterialUiPickersDate) =>
    onChange(id)({ target: { value } });

  if (!canChangeExpectedDate || record?.dateDelivery) {
    return (
      <DateField record={record} emptyText="-" locales="en-US" {...props} />
    );
  }

  return (
    <Box display="flex" minWidth={165} alignItems="center" fontSize="12px">
      <KeyboardDatePicker
        // disabled={!!record?.dateExpected}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        minDate={new Date()}
        margin="normal"
        id="date-picker-inline"
        helperText=""
        error={false}
        fullWidth
        KeyboardButtonProps={{ 'aria-label': 'Change Date' }}
        onChange={onDateChange}
        value={value}
      />
      {record && record.dateExpected !== record.originalDateExpected && (
        <InfoIcon
          className={classes.info}
          onClick={() => openOriginalExpected(record)}
        />
      )}
    </Box>
  );
};

const PromisedDateField = ({
  record,
  onChange,
  fields,
  ...props
}: DateProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  const id = record?.id ? String(record?.id) : '';
  const date = record?.datePromised !== undefined ? record.datePromised : '';
  const fieldExists = fields?.[id]?.datePromised !== undefined;
  const value = fieldExists ? fields[id].datePromised : date;
  const canAddDates = hasPermission(CAN_ADD_MATERIALS_DELIVERY_DATE);

  const onDateChange = (value: MaterialUiPickersDate) =>
    onChange(id)({ target: { value } });

  if (!canAddDates) {
    return (
      <DateField record={record} emptyText="-" locales="en-US" {...props} />
    );
  }

  if (record?.datePromised || record?.dateDelivery) {
    return (
      <DateField record={record} emptyText="-" locales="en-US" {...props} />
    );
  }

  return (
    <Box display="flex" minWidth={140}>
      <KeyboardDatePicker
        disabled={!!record?.datePromised}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        fullWidth
        KeyboardButtonProps={{ 'aria-label': 'Change Date' }}
        onChange={onDateChange}
        value={value}
      />
    </Box>
  );
};

const DeliveryDateField = ({
  record,
  onChange,
  fields,
  ...props
}: DateProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  const id = record?.id ? String(record?.id) : '';
  const date = record?.dateDelivery !== undefined ? record.dateDelivery : '';
  const fieldExists = fields?.[id]?.dateDelivery !== undefined;
  const value = fieldExists ? fields[id].dateDelivery : date;
  const canAddDates = hasPermission(CAN_ADD_MATERIALS_DELIVERY_DATE);

  const onDateChange = (value: MaterialUiPickersDate) =>
    onChange(id)({ target: { value } });

  if (!canAddDates) {
    return (
      <DateField record={record} emptyText="-" locales="en-US" {...props} />
    );
  }

  if (record?.dateDelivery) {
    return (
      <DateField record={record} emptyText="-" locales="en-US" {...props} />
    );
  }

  return (
    <Box display="flex" minWidth={140}>
      <KeyboardDatePicker
        disabled={!!record?.dateDelivery}
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        maxDate={new Date()}
        fullWidth
        KeyboardButtonProps={{ 'aria-label': 'Change Date' }}
        onChange={onDateChange}
        value={value}
      />
    </Box>
  );
};

interface SubmitButtonProps extends Omit<ButtonProps, 'onSubmit'> {
  onSubmit: (id: string) => void;
  record?: Record;
  fields: Fields;
}

const SubmitButton = ({
  onSubmit,
  record = { id: '' },
  fields,
  ...props
}: SubmitButtonProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();
  const { dateDelivery, id } = record;

  const canAddDates = hasPermission(CAN_ADD_MATERIALS_DELIVERY_DATE);
  const canChangeExpectedDate = hasPermission(CAN_CHANGE_EXPECTED_DATE);

  let disabled = fields[id]
    ? !fields[id].dateDelivery && !fields[id].datePromised
    : true;

  if (fields[id] && Object.keys(fields[id]).indexOf('dateExpected') !== -1) {
    disabled = !fields[id].dateExpected;
  }

  if (!canAddDates && !canChangeExpectedDate) return null;

  if (dateDelivery) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      onClick={() => onSubmit(String(id))}
      style={{ whiteSpace: 'nowrap', fontSize: '12px' }}
      disabled={disabled}
    >
      Submit
    </Button>
  );
};

const PartialDeliveryButton: FC<any> = ({
  record,
  onClick,
  fields,
  ...props
}) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  const delivery = fields[record.id];
  const disabled = delivery ? !!delivery.dateDelivery : false;

  if (!hasPermission(CAN_ADD_PARTIAL_DELIVERY)) return null;

  if (record.dateDelivery) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      {...props}
      onClick={() => onClick(record.id)}
      style={{ whiteSpace: 'nowrap', fontSize: '12px' }}
      disabled={disabled}
    >
      Partial Delivery
    </Button>
  );
};

type DeliveryHistoryFieldProps = {
  onHistoryClick: (record: Record) => void;
} & Omit<FunctionFieldProps, 'render'>;

const DeliveryHistoryField: FC<DeliveryHistoryFieldProps> = ({
  onHistoryClick,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        const { boardPurchasePartialDeliveries: deliveries } = record;
        const isPartialDeliveryArray = Array.isArray(deliveries);
        const hasPartialDeliveries =
          isPartialDeliveryArray && deliveries.length !== 0;
        return hasPartialDeliveries ? (
          <Box
            onClick={() => onHistoryClick(record)}
            className={classes.deliveryHistoryIcon}
          >
            <StickyNote2 />
          </Box>
        ) : null;
      }}
    />
  );
};

const BullkActions: FC<any> = props => {
  const notify = useNotify();
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(props.resource);
  const dataProvider = useDataProvider();
  const { data, selectedIds } = useListContext(props);
  const { hasPermission } = PermissionsProvider.useContainer();
  const [loading, setLoading] = useState(false);
  const [showFullfilmentModal, setShowFullfilmentModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const openFulfillmentConfirmationModal = () => setShowFullfilmentModal(true);

  const closeFulfillmentConfirmationModal = () =>
    setShowFullfilmentModal(false);

  const openCancelConfirmationModal = () => setShowCancelModal(true);

  const closeCancelConfirmationModal = () => setShowCancelModal(false);

  const handleStopFulfillment = async (
    stoppedFulfillmentNotification: boolean,
  ) => {
    setLoading(true);
    try {
      const [id] = selectedIds;
      const { data: previousData } = await dataProvider.getOne(
        'board-purchase',
        { id },
      );
      const data = { stoppedFulfillment: true, stoppedFulfillmentNotification };
      await dataProvider.update('board-purchase', { id, data, previousData });
      unselectAll();
      refresh();
      setShowFullfilmentModal(false);
      notify('Changes were made sucessfully!');
    } catch (error) {
      notify('Failed to stop fulfillment', 'error');
    }
    setLoading(false);
  };

  const handleChangeToHighPriority = async () => {
    setLoading(true);
    try {
      const params = { ids: selectedIds, isHighPriority: true };
      await axiosInstance.post('/board-purchase/high-priority-batch', params);
      unselectAll();
      refresh();
      notify('Selected item(s) set as High Priority!');
    } catch (error) {
      const message = error?.response?.data?.message;
      const defaultMessage = 'Failed to update priority for selected item(s)';
      const text = message ? message : defaultMessage;
      notify(text, 'error');
    }
    setLoading(false);
  };

  const handleChangeToNormalPriority = async () => {
    setLoading(true);
    try {
      const params = { ids: selectedIds, isHighPriority: false };
      await axiosInstance.post('/board-purchase/high-priority-batch', params);
      unselectAll();
      refresh();
      notify('Selected item(s) High Priority Removed!');
    } catch (error) {
      const message = error?.response?.data?.message;
      const defaultMessage = 'Failed to update priority for selected item(s)';
      const text = message ? message : defaultMessage;
      notify(text, 'error');
    }
    setLoading(false);
  };

  const handleCancelPurchaseOrder = async (cancelNotification: boolean) => {
    setLoading(true);
    try {
      const [id] = selectedIds;
      const { data: previousData } = await dataProvider.getOne(
        'board-purchase',
        { id },
      );

      const params = {
        id,
        previousData,
        data: { canceled: true, cancelNotification },
      };
      await dataProvider.update('board-purchase', params);

      unselectAll();
      refresh();
      setShowFullfilmentModal(false);
      notify('Changes were made sucessfully!');
    } catch (error) {
      notify(error?.message || 'Failed to cancel purchase order', 'error');
    }
    setLoading(false);
  };

  const hasSelectedNormalPriority = useMemo(() => {
    if (selectedIds.length === 0) return false;
    return selectedIds.some(id => data[id] && !data[id].isHighPriority);
  }, [data, selectedIds]);

  const hasSelectedHighPriority = useMemo(() => {
    if (selectedIds.length === 0) return false;
    return selectedIds.some(id => data[id] && !!data[id].isHighPriority);
  }, [data, selectedIds]);

  const hasSinglePartialDeliverySelected = useMemo(() => {
    if (selectedIds.length !== 1) return false;

    const [id] = selectedIds;
    const deliveries = data?.[id]?.boardPurchasePartialDeliveries;

    const isPartialDeliveryArray = Array.isArray(deliveries);
    const hasPartialDeliveries =
      isPartialDeliveryArray && deliveries.length !== 0;
    const isDeliveryFinished = !!data?.[id]?.dateDelivery;

    return hasPartialDeliveries && !isDeliveryFinished;
  }, [selectedIds, data]);

  const hasSingleEmptyDelivery = useMemo(() => {
    if (selectedIds.length !== 1) return false;

    const [id] = selectedIds;
    const deliveries = data?.[id]?.boardPurchasePartialDeliveries;

    const isPartialDeliveryArray = Array.isArray(deliveries);
    const hasPartialDeliveries =
      isPartialDeliveryArray && deliveries.length !== 0;
    const isDeliveryFinished = !!data?.[id]?.dateDelivery;

    return !hasPartialDeliveries && !isDeliveryFinished;
  }, [selectedIds, data]);

  return (
    <>
      {hasPermission(CAN_PRIORITIZE_MATERIALS_DELIVERY) && (
        <>
          {hasSelectedNormalPriority && (
            <Button onClick={handleChangeToHighPriority} disabled={loading}>
              Set as High Priority
            </Button>
          )}
          {hasSelectedHighPriority && (
            <Button onClick={handleChangeToNormalPriority} disabled={loading}>
              Remove High Priority
            </Button>
          )}
        </>
      )}
      {hasPermission(CAN_STOP_FULFILLMENT) && (
        <>
          {hasSinglePartialDeliverySelected && (
            <>
              <Button
                onClick={openFulfillmentConfirmationModal}
                disabled={loading}
              >
                Stop fulfillment
              </Button>
              <FulfillmentModal
                open={showFullfilmentModal}
                onClose={closeFulfillmentConfirmationModal}
                onConfirm={handleStopFulfillment}
                disabled={loading}
              />
            </>
          )}
        </>
      )}
      {hasPermission(CAN_CANCEL_DELIVERY_PURCHASE_ORDER) && (
        <>
          {hasSingleEmptyDelivery && (
            <>
              <Button onClick={openCancelConfirmationModal} disabled={loading}>
                Cancel Purchase Order
              </Button>
              <CancelOrderModal
                open={showCancelModal}
                onClose={closeCancelConfirmationModal}
                onConfirm={handleCancelPurchaseOrder}
                disabled={loading}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

const PurchaseOrder: React.FC<any> = ({ record, selectPurchaseOrder }) => {
  const classes = useStyles();

  return (
    <div onClick={() => selectPurchaseOrder(record.id)}>
      <Typography className={classes.poAnchor}>{record.orderNumber}</Typography>
    </div>
  );
};

export const MaterialsDeliveryList: FC = props => {
  const { hasRole, hasPermission } = PermissionsProvider.useContainer();
  const [fields, setFields] = useState<Fields>({});
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [showOriginalExpected, setShowOriginalExpected] = useState(false);
  const [selectedOriginalExpected, setSelectedOriginalExpected] = useState();
  const [showPartialDeliveryModal, setShowPartialDeliveryModal] =
    useState(false);
  const [showPartialDeliveryHistoryModal, setShowPartialDeliveryHistoryModal] =
    useState(false);
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
  const [selectedDeliveryHistory, setSelectedDeliveryHistory] = useState<any[]>(
    [],
  );
  const selectedId = useRef('');
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const { opened } = UIProvider.useContainer();
  const [summaryData, setSummaryData] = useState<any>();

  const isSupplierRepresentative = hasRole(AppRole.SupplierRepresentative);

  const onFieldChange =
    (field: keyof Field) =>
    (id: string) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      setFields(oldFields => ({
        ...oldFields,
        [id]: { ...oldFields[id], [field]: event.target.value },
      }));

  const onRowSubmit = (id: string) => {
    selectedId.current = id;
    const row = fields[id];
    if (!row) return;

    const { dateDelivery } = row;

    if (dateDelivery) {
      setSelectedDeliveryDate(dateDelivery.toLocaleString({ locale: 'en-US' }));
      setShowConfirmModal(true);
      return;
    }

    onConfirmSubmit();
  };

  const onConfirmClose = () => setShowConfirmModal(false);

  const onPartialDeliveryModalClose = () => setShowPartialDeliveryModal(false);

  const onPartialDeliveryClick = (id: string) => {
    selectedId.current = id;
    setShowPartialDeliveryModal(true);
  };

  const handlePartialDelivery = async (values: FormValues) => {
    setLoading(true);
    try {
      const { date, description } = values;
      const partialDeliveryDate = date.toISO();
      const params = {
        data: {
          partialDeliveryDate,
          notes: description,
          boardPurchaseId: selectedId.current,
        },
      };
      await dataProvider.create('/board-purchase-partial-delivery', params);
      setShowPartialDeliveryModal(false);
      refresh();
    } catch (error) {
      notify('Failed to add partial delivery.', 'error');
    }
    setLoading(false);
  };

  const onPartialDeliveryHistoryModalClose = () =>
    setShowPartialDeliveryHistoryModal(false);

  const onDeliveryHistoryClick = (record: any) => {
    setSelectedDeliveryHistory(record.boardPurchasePartialDeliveries);
    setShowPartialDeliveryHistoryModal(true);
  };

  const onConfirmSubmit = async () => {
    setLoading(true);
    try {
      const id = selectedId.current;
      const row = fields[id];
      if (!row) return;

      const { data: boardPurchase } = await dataProvider.getOne(
        'board-purchase',
        { id },
      );

      const data: any = {};
      if (row.datePromised) {
        data.datePromised = row.datePromised.toISO();
      }
      if (row.dateDelivery) {
        data.dateDelivery = row.dateDelivery.toISO();
      }
      if (row.dateExpected) {
        data.dateExpected = row.dateExpected.toISO();
      }

      // const data = { datePromised, dateDelivery, dateExpected };

      const params = { id, data, previousData: boardPurchase };
      await dataProvider.update('board-purchase', params);

      notify('Changes were made successfully!');
      refresh();
    } catch (error: any) {
      notify(error.message, 'warning');
    }
    setShowConfirmModal(false);
    setLoading(false);
  };

  const canAccessBulkActions =
    hasPermission(CAN_PRIORITIZE_MATERIALS_DELIVERY) ||
    hasPermission(CAN_STOP_FULFILLMENT) ||
    hasPermission(CAN_CANCEL_DELIVERY_PURCHASE_ORDER);

  console.log(showSummary, setShowSummary);

  const selectPurchaseOrder = async purchaseOrderNumber => {
    try {
      const summary = await getSummary({
        boardPurchaseId: purchaseOrderNumber,
      });
      setSummaryData(summary.data);
      setShowSummary(true);
    } catch (error) {
      console.log(error);
    }
  };

  const openOriginalExpected = (record: any) => {
    setSelectedOriginalExpected(record);
    setShowOriginalExpected(true);
  };

  return (
    <>
      <List
        {...props}
        filters={
          <MaterialDeliveryFilter showSupplier={!isSupplierRepresentative} />
        }
        sort={{ field: 'isHighPriority', order: 'DESC' }}
        bulkActionButtons={canAccessBulkActions ? <BullkActions /> : false}
        empty={false}
        classes={{
          content: opened ? classes.content : classes.contentClosed,
          root: opened ? classes.root : classes.rootClosed,
        }}
      >
        <Datagrid rowStyle={applyRowStyle}>
          <TextField
            label="Supplier"
            source="supplierName"
            textAlign="center"
            emptyText="-"
          />
          <PurchaseOrder
            selectPurchaseOrder={selectPurchaseOrder}
            label="Purchase Order #"
          />
          <TextField
            label="Builder"
            source="builderName"
            textAlign="center"
            emptyText="-"
          />
          <TextField
            label="Subdivision"
            source="subdivisionName"
            textAlign="center"
            emptyText="-"
          />
          <TextField label="Lot #" source="jobLot" textAlign="center" />
          <TextField label="Block" source="jobBlock" textAlign="center" />
          <TextField
            label="Address (1+2 City, State, ZIP)"
            source="fullAddress"
            textAlign="center"
          />
          <DeliveryHistoryField
            label="Delivery History"
            textAlign="center"
            onHistoryClick={onDeliveryHistoryClick}
          />
          <PartialDeliveryButton
            onClick={onPartialDeliveryClick}
            fields={fields}
          />
          <DateField
            label="Order Date"
            source="orderDate"
            textAlign="center"
            locales="en-US"
          />
          <ExpectedDateField
            label="Expected Date"
            source="dateExpected"
            fields={fields}
            onChange={onFieldChange('dateExpected')}
            textAlign="center"
            openOriginalExpected={openOriginalExpected}
          />
          <PromisedDateField
            label="Promised Date"
            source="datePromised"
            fields={fields}
            onChange={onFieldChange('datePromised')}
            textAlign="center"
          />
          <DeliveryDateField
            label="Delivery Date"
            source="dateDelivery"
            fields={fields}
            onChange={onFieldChange('dateDelivery')}
            textAlign="center"
          />
          <TextField
            label="Delivery Date Source"
            source="deliveryDateSource"
            textAlign="center"
          />
          <SubmitButton onSubmit={onRowSubmit} fields={fields} />
        </Datagrid>
      </List>
      <ConfirmDialog
        open={showConfirmModal}
        title={`Are you sure that the order has been delivered at ${selectedDeliveryDate}?`}
        content="You cannot change the date after you confirm"
        align="center"
        handleClose={onConfirmClose}
        onConfirm={onConfirmSubmit}
        disabled={loading}
      />
      <PartialDeliveryModal
        open={showPartialDeliveryModal}
        onConfirm={handlePartialDelivery}
        handleClose={onPartialDeliveryModalClose}
        disabled={loading}
      />
      <PartialDeliveryHistoryModal
        open={showPartialDeliveryHistoryModal}
        handleClose={onPartialDeliveryHistoryModalClose}
        deliveries={selectedDeliveryHistory}
      />
      <PurchaseOrderSummary
        summaryData={summaryData}
        open={showSummary}
        onClose={() => setShowSummary(false)}
      />
      <OriginalExpectedModal
        open={showOriginalExpected}
        record={selectedOriginalExpected}
        handleClose={() => setShowOriginalExpected(false)}
      />
    </>
  );
};
