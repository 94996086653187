import React, { FC, ReactElement } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';

type ConfirmDialogProps = {
  open: boolean;
  handleClose(): void;
  title?: string;
  content?: string | ReactElement;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
};

export const InfoDialog: FC<ConfirmDialogProps> = ({
  open,
  handleClose,
  title,
  content,
  align,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {content && (
        <DialogContent style={{ maxWidth: '450px' }}>
          <DialogContentText
            style={{
              whiteSpace: 'pre-line',
            }}
            id="alert-dialog-description"
            align={align}
          >
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          paddingX="20px"
          paddingY="20px"
        >
          <Button onClick={handleClose}>Ok</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDialog;
