import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const FactCheck: React.FC = () => {
  return (
    <SvgIcon viewBox="0 0 48 48">
      <path d="M10 18H20V14H10ZM10 26H20V22H10ZM10 34H20V30H10ZM29.1 30 39 20.1 36.15 17.25 29.1 24.35 26.25 21.5 23.45 24.35ZM6.6 42Q5.4 42 4.5 41.1Q3.6 40.2 3.6 39V9Q3.6 7.8 4.5 6.9Q5.4 6 6.6 6H41.4Q42.6 6 43.5 6.9Q44.4 7.8 44.4 9V39Q44.4 40.2 43.5 41.1Q42.6 42 41.4 42ZM6.6 39H41.4Q41.4 39 41.4 39Q41.4 39 41.4 39V9Q41.4 9 41.4 9Q41.4 9 41.4 9H6.6Q6.6 9 6.6 9Q6.6 9 6.6 9V39Q6.6 39 6.6 39Q6.6 39 6.6 39ZM6.6 39Q6.6 39 6.6 39Q6.6 39 6.6 39V9Q6.6 9 6.6 9Q6.6 9 6.6 9Q6.6 9 6.6 9Q6.6 9 6.6 9V39Q6.6 39 6.6 39Q6.6 39 6.6 39Z" />
    </SvgIcon>
  );
};

export default FactCheck;
