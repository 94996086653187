import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  CreateProps,
  useDataProvider,
  LinearProgress,
  useInput,
  InputProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { SubdivisionDto } from '@vatos-pas/common';

import { validateModel } from './modelValidation';
import SimpleForm from 'components/SimpleForm';
import CustomSelect, { ICustomItem } from 'components/CustomSelect';
import { findManyBuilders, findManySubdivisions } from 'services/jobs';
import { Show } from 'components/Show';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldsWithBoolean: {
    width: '100%',
    display: 'flex',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  expand: {
    width: '100%',
  },
});

type SubdivisionInputProps = {
  builderId: string | undefined;
} & InputProps;

const SubdivisionInput = ({ builderId, ...props }: SubdivisionInputProps) => {
  const {
    input,
    meta: { touched, error },
  } = useInput(props);
  const dataProvider = useDataProvider();
  const currentRequestId = useRef(0);

  const [loadingSubdivisions, setLoadingSubdivisions] = useState(false);
  const [selectedSubdivision, setSelectedSubdivision] = useState(null);
  const [subdivisions, setSubdivisions] = useState<SubdivisionDto[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = async (_event, value, _reason, _details) => {
    input.onChange(value?.id ?? null);
    setSelectedSubdivision(value);
  };

  const getSubdivisions = async (
    builderId: string | undefined,
    requestId: number,
    name: string,
  ) => {
    setLoadingSubdivisions(true);

    const subdivisions = await findManySubdivisions(
      dataProvider,
      {
        builderId,
        active: true,
        ...(name && { name }),
      },
      50,
    );

    if (subdivisions && requestId === currentRequestId.current) {
      setSubdivisions(subdivisions);
    }

    setLoadingSubdivisions(false);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(name => {
        currentRequestId.current += 1;
        const requestId = currentRequestId.current;
        getSubdivisions(builderId, requestId, name);
      }, 300),
    [builderId, dataProvider],
  );

  // Reset subdivision state whenever builder changes
  useEffect(() => {
    input.onChange(null);
    setSelectedSubdivision(null);
  }, [builderId]);

  useEffect(() => {
    debouncedSearch(inputValue);

    // Clean up the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [builderId, inputValue, debouncedSearch]);

  return (
    <Autocomplete
      fullWidth
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => (option ? option?.name : '')}
      renderInput={params => (
        <TextField
          {...params}
          name={props.resource}
          label="Subdivision"
          margin="dense"
          variant="filled"
          error={!!(touched && error)}
          helperText={touched && error}
        />
      )}
      loading={loadingSubdivisions}
      disabled={!builderId && !loadingSubdivisions}
      options={subdivisions?.map(item => ({
        name: item.name,
        id: item.id,
      }))}
      {...input}
      value={selectedSubdivision}
      onChange={handleChange}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
    />
  );
};

export const ModelsCreate: FC<CreateProps> = props => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const theme = useTheme();

  const [loadingBuilders, setLoadingBuilders] = useState(false);
  const [builders, setBuilders] = useState<ICustomItem[]>([]);
  const [builderId, setBuilderId] = useState<string | undefined>(undefined);

  const getBuilders = async () => {
    const builders = await findManyBuilders(dataProvider);

    const mappedOptions = builders?.map(builder => ({
      id: builder.id,
      name: builder.name,
    }));

    if (mappedOptions?.length) {
      setBuilders(mappedOptions);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setLoadingBuilders(true);
      await getBuilders();
      setLoadingBuilders(false);
    };

    initialize();
  }, []);

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm validate={validateModel}>
        <Box display="flex" alignItems="center" width="100% !important">
          <Box
            display="flex"
            width="100%"
            mb={2}
            ml={2}
            style={{ gap: theme.spacing(4) }}
          >
            <Show
              condition={!loadingBuilders}
              fallback={
                <LinearProgress className={classes.expand} timeout={0} />
              }
            >
              <CustomSelect
                className={classes.expand}
                label="Builder"
                resource="builder"
                variant="filled"
                options={builders}
                value={builderId}
                margin="dense"
                onChange={event => {
                  setBuilderId(event.target.value as string);
                }}
              />
            </Show>

            <SubdivisionInput source="subdivisionId" builderId={builderId} />

            <Box display="flex" alignItems="center">
              <BooleanInput
                defaultValue={true}
                label="Active"
                source="active"
              />
            </Box>
          </Box>
        </Box>

        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="name" />
          <TextInput
            fullWidth
            className={classes.input}
            source="externalId"
            label="External ID"
          />
        </Box>
        <Box className={classes.fieldsWithBoolean}>
          <TextInput
            className={classes.halfWidth}
            source="description"
            multiline
            rows={3}
          />
          <TextInput
            className={classes.halfWidth}
            source="stockingSpec"
            multiline
            rows={3}
          />
        </Box>
        <Box display="flex" className={classes.fieldsWithBoolean}>
          <BooleanInput
            label="Garage First"
            className={classes.input}
            source="garageFirst"
          />
          <BooleanInput
            label="In Building"
            className={classes.input}
            source="inBuilding"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
