import { RouteList } from './views';
import { RouteCreate } from './views/route-create';
import { RouteEdit } from './views/route-edit';

export default {
  edit: RouteEdit,
  create: RouteCreate,
  list: RouteList,
  name: 'route',
  label: 'Routes',
  to: '/route',
};
