import { useState, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Editor } from 'react-draft-wysiwyg';
import {
  CAN_CHANGE_JOB,
  CAN_EDIT_OPTIONS_JOB_VIEW,
} from 'providers/permissions';
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useDataProvider, useNotify } from 'react-admin';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { JobContainer } from '../providers/job';
import { useContainer } from 'unstated-next';
import { Show } from 'components/Show';
import { IconButton } from '@material-ui/core';
import InfoDialog from 'components/InfoDialog';

type Props = {
  isVoidLoading: boolean;
};

export const ProjectDetails: FC<Props> = ({ isVoidLoading }) => {
  const dataProvider = useDataProvider();
  const { job, subdivision } = useContainer(JobContainer);
  const notify = useNotify();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [options, setOptions] = useState(() => {
    return job?.options?.trim()?.length
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(job?.options).contentBlocks,
          ),
        )
      : EditorState.createEmpty();
  });
  const [stockingSpecInfo, setStockingSpecInfo] = useState(false);

  const classes = useStyles();

  const handleEditOptions = async () => {
    if (!job?.id) return;

    try {
      const plainText = convertToHTML(options.getCurrentContent());

      const data = {
        options: plainText.trim().length
          ? convertToHTML(options.getCurrentContent())
          : null,
      };

      const params = {
        id: job.id,
        data,
        previousData: job,
      };
      await dataProvider.update('job', params);
      notify('Options Updated!');
    } catch (error) {
      notify(`Error on Update Options`, 'warning');
    }
  };

  return (
    <Box width="100%">
      <Box className={classes.grayBox}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Project Details
        </Typography>
        <Box width="100%" display="flex" mt={2}>
          <Box width="50%" paddingRight={3}>
            <Box>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Builder"
                fullWidth
                disabled
                className={classes.input}
                value={subdivision?.builder?.name}
              />
              <TextField
                label="Subdivision"
                InputLabelProps={{ shrink: true }}
                fullWidth
                disabled
                className={classes.input}
                value={subdivision?.name}
              />
              <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
                <TextField
                  label="Model"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  disabled
                  className={classes.input}
                  value={job?.model?.name}
                />
                <Show condition={!!job?.model?.stockingSpec}>
                  <IconButton
                    onClick={() =>
                      job?.model?.stockingSpec
                        ? setStockingSpecInfo(true)
                        : undefined
                    }
                  >
                    <InfoOutlinedIcon className={classes.infoWarning} />
                  </IconButton>
                </Show>
              </Box>

              <Box display="flex" alignItems="center">
                <TextField
                  label="Building #"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled
                  className={classes.input}
                  value={job?.building?.number}
                />
                <TextField
                  label="Lot"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  disabled
                  value={job?.lot}
                  className={classes.inputNoMarginRight}
                />
              </Box>
              <TextField
                label="Block"
                fullWidth
                InputLabelProps={{ shrink: true }}
                className={classes.input}
                disabled
                value={job?.block}
              />
              <TextField
                label="Community Phase"
                fullWidth
                InputLabelProps={{ shrink: true }}
                className={classes.input}
                disabled
                value={job?.communityPhase}
              />
              <TextField
                value={`${subdivision?.supervisorUser?.firstName} ${subdivision?.supervisorUser?.lastName}`}
                label="Supervisor"
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                className={classes.input}
              />
            </Box>
          </Box>
          <Box width="50%">
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              onChange={(date: any) => date}
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Project Approval Date"
              fullWidth
              disabled
              className={classes.inputDate}
              value={null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              className={classes.inputDate}
              variant="inline"
              format="MM/dd/yyyy"
              disabled
              onChange={(date: any) => date}
              margin="normal"
              id="date-picker-inline"
              label="Project Due Date"
              fullWidth
              value={null}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <TextField
              className={classes.input}
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled
              value={job?.idNumber}
              label="Job ID #"
            />
            <TextField
              className={classes.inputAddress}
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={job?.address1}
              label="Address"
              disabled
            />
            <Box display="flex">
              <TextField
                className={classes.inputMarginRight}
                fullWidth
                label="Address2"
                InputLabelProps={{ shrink: true }}
                disabled
                value={job?.address2}
              />
              <TextField
                className={classes.inputAddress}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Zip"
                disabled
                value={job?.zip}
              />
            </Box>
            <Box display="flex">
              <TextField
                InputLabelProps={{ shrink: true }}
                className={classes.inputMarginRight}
                fullWidth
                label="City"
                value={job?.city}
                disabled
              />
              <TextField
                className={classes.inputMarginRight}
                fullWidth
                label="State"
                InputLabelProps={{ shrink: true }}
                value={job?.state}
                disabled
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.grayBox}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Options
        </Typography>
        <Box>
          <Show
            condition={
              !!job?.options && !hasPermission(CAN_EDIT_OPTIONS_JOB_VIEW)
            }
          >
            {job?.options && (
              <div
                className="preview"
                dangerouslySetInnerHTML={{ __html: job.options }}
              />
            )}
          </Show>

          <Show condition={hasPermission(CAN_EDIT_OPTIONS_JOB_VIEW)}>
            <Editor
              editorState={options}
              editorClassName={classes.editor}
              toolbar={{
                options: ['inline', 'blockType', 'list'],
                fontSize: {
                  options: [12, 14, 16, 18, 24, 30],
                },
                inline: {
                  options: ['bold', 'italic', 'underline'],
                },
                blockType: {
                  inDropdown: false,
                  options: ['Normal', 'H1', 'H2', 'H3'],
                },
                list: {
                  options: ['unordered', 'ordered'],
                },
              }}
              onEditorStateChange={value => setOptions(value)}
            />
          </Show>
        </Box>

        <Show condition={hasPermission(CAN_CHANGE_JOB)}>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              disabled={isVoidLoading}
              onClick={handleEditOptions}
            >
              Submit
            </Button>
          </Box>
        </Show>

        <InfoDialog
          title="Stock Specs"
          content={job?.model?.stockingSpec}
          open={stockingSpecInfo}
          handleClose={() => setStockingSpecInfo(false)}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  inputDate: {
    margin: '8px 0px 15px',
  },
  inputAddress: {
    margin: '15px 0px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  inputMarginRight: {
    margin: '15px 15px 15px 0px',
  },
  grayBox: {
    border: '1.5px solid lightgray',
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '5px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 20,
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  editor: {
    border: '1px solid gray',
    padding: '3px',
  },
  infoWarning: {
    color: '#ff9800',
  },
});
