import { axiosInstance } from 'services/http';
import { AxiosResponse } from 'axios';

interface ISendVerificationCode {
  email: string;
}

export const sendVerificationCode = ({
  email,
}: ISendVerificationCode): Promise<void | AxiosResponse<any>> => {
  return axiosInstance
    .post('/auth/resetPassword', { email, isAdmin: false })
    .then(response => response);
};

export const batchBuilderSupervisor = (
  body: any,
): Promise<void | AxiosResponse<any>> => {
  return axiosInstance
    .post('/subdivision-builder-supervisor/batch-process', body)
    .then(response => response);
};

interface IChangePassword {
  password: string;
  token: string;
}

export const changePassword = ({
  password,
  token,
}: IChangePassword): Promise<void> =>
  axiosInstance.patch(`/auth/resetPassword/${token}`, { password });

export const verifyToken = ({ token }: { token: string }): Promise<void> =>
  axiosInstance.get(`auth/resetPassword/${token}`);
