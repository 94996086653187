import React from 'react';
import {
  Create,
  TextInput,
  NumberInput,
  CreateProps,
  useDataProvider,
  useRedirect,
  useNotify,
} from 'react-admin';
import { validateRegion } from './regionValidation';
import SimpleForm from 'components/SimpleForm';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { RegionTimeType, RegionTimeWindowInterface } from '@vatos-pas/common';

const DEFAULT_TIME_WINDOWS = [
  {
    windowStart: '08:00:00',
    windowEnd: '11:00:00',
  },
  {
    windowStart: '11:00:00',
    windowEnd: '14:00:00',
  },
  {
    windowStart: '14:00:00',
    windowEnd: '17:00:00',
  },
];

export const RegionsCreate = (props: CreateProps) => {
  const notify = useNotify();
  const classes = useStyles();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleSuccess = async ({ data }) => {
    if (!data?.id) return;

    try {
      const regionTimeWindowsPromises = DEFAULT_TIME_WINDOWS.map(
        async timeWindow =>
          dataProvider.create<RegionTimeWindowInterface>('region-time-window', {
            data: {
              regionId: data.id,
              regionTimeType: RegionTimeType.HO_SERVICE,
              windowStartTime: timeWindow.windowStart,
              windowEndTime: timeWindow.windowEnd,
            },
          }),
      );

      if (regionTimeWindowsPromises?.length) {
        await Promise.all(regionTimeWindowsPromises);
      }

      redirect(`/region/${data.id}`);
      notify('Element created');
    } catch (error) {
      notify(
        error?.message ?? 'Error while creating the region time windows',
        'warning',
      );
    }
  };

  return (
    <Create {...props} onSuccess={handleSuccess} className={classes.createBox}>
      <SimpleForm validate={validateRegion}>
        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="name" />
          <TextInput fullWidth className={classes.input} source="shortName" />
        </Box>
        <Box>
          <NumberInput
            className={classes.inputNumber}
            min="1"
            max="100"
            step="0.01"
            label="Repair Rate (Per Hour)"
            source="repairsRate"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  inputNumber: {
    margin: '0px 15px',
    minWidth: '200px',
  },
});
