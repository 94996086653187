import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';

import { SpreadsheetCopyExampleDialog } from './spreadsheet-copy-example-dialog';
import { useDataProvider } from 'react-admin';
import { BoardExcelFloorBaseDto } from 'modules/jobs/types';
import { BoardExcelFloorDto } from '@vatos-pas/common';

type BoardExcelFloorDtoWithId = {
  id: string;
} & BoardExcelFloorDto;

const useStyles = makeStyles({
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    cursor: 'pointer',
    marginLeft: 8,
  },
  progress: {
    marginRight: 8,
  },
  errorMessage: {
    color: 'red',
  },
});

type ErrorType = {
  message: string;
};

type TakeoffDatabaseCopyDialogProps = {
  open: boolean;
  handleClose: () => void;
  updateFloors: (BoardExcelFloors: BoardExcelFloorBaseDto) => void;
};

export const TakeoffDatabaseCopyDialog: FC<TakeoffDatabaseCopyDialogProps> = ({
  open,
  handleClose,
  updateFloors,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [modelInformationText, setModelInformationText] = useState<
    string | null
  >(null);
  const [error, setError] = useState<ErrorType | null>(null);
  const [parsedPurchaseOrder, setParsedPurchaseOrder] =
    useState<BoardExcelFloorDto | null>(null);
  const [spreadsheetCopyExampleModal, setSpreadsheetCopyExampleModal] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    handleClose();
    setParsedPurchaseOrder(null);
    setModelInformationText(null);
    setError(null);
  };

  const getFormattedPurchaseOrder = async (rawText: string) => {
    const formattedPurchaseOrder =
      await dataProvider.create<BoardExcelFloorDtoWithId>('board-excel-floor', {
        data: {
          data: rawText,
        },
      });

    return formattedPurchaseOrder.data;
  };

  const handleImport = async () => {
    setError(null);

    if (!modelInformationText) return;

    if (parsedPurchaseOrder) {
      updateFloors(parsedPurchaseOrder.boardExcelFloors);
      setError(null);
      setParsedPurchaseOrder(null);
      onClose();
      return;
    }

    try {
      setLoading(true);

      const parsedData = await getFormattedPurchaseOrder(modelInformationText);

      const hasParsingErrors = !!parsedData?.errors?.length;
      const hasParsingSuccess = !!parsedData?.boardExcelFloors?.length;

      if (hasParsingErrors) {
        if (hasParsingSuccess) {
          setError({
            message: `Attention: Parsing was not 100% successful. To continue anyway, please select "Import" again.`,
          });
          setParsedPurchaseOrder(parsedData);
          return;
        }

        throw new Error('Parsing failed.');
      }

      updateFloors(parsedData.boardExcelFloors);
      onClose();
    } catch (error) {
      setError({
        message:
          'Attention: We apologize, but the information you provided could not be recognized. Please fill out the Purchase Order details manually.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleModelInformationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setModelInformationText(event.target.value);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText
          className={classes.content}
          id="alert-dialog-description"
        >
          Copy and paste the floor, board and quantity information from the
          Vatos SharePoint site below:
          <InfoIcon
            role="button"
            className={classes.infoIcon}
            onClick={() => setSpreadsheetCopyExampleModal(true)}
          />
        </DialogContentText>
        <TextField
          fullWidth
          value={modelInformationText}
          onChange={handleModelInformationChange}
          label="Model Information"
          variant="outlined"
          multiline
          rows={7}
        />
        {error?.message && (
          <Box mt={2}>
            <Typography className={classes.errorMessage}>
              {error.message}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
        >
          <Button color="primary" variant="contained" onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={handleImport}
            color="primary"
            autoFocus
            disabled={!modelInformationText || loading}
            variant="contained"
          >
            {loading && (
              <CircularProgress className={classes.progress} size={16} />
            )}
            Import
          </Button>
        </Box>
      </DialogActions>
      <SpreadsheetCopyExampleDialog
        open={spreadsheetCopyExampleModal}
        handleClose={() => setSpreadsheetCopyExampleModal(false)}
      />
    </Dialog>
  );
};
