import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useNotify } from 'react-admin';
import { BoardFloorCreatableInterface } from '@vatos-pas/common';
import { useStyles } from './master-sheet-styles';
import MasterSheetBoardTable from './master-sheet-board-table';
import MobileMasterSheetBoardTable from './mobile/master-sheet-board-table';
import { FloorListItem, FloorListRow, TabEnum } from './master-sheet-edit';
import dataProvider from 'providers/dataProvider';
import isMobileHook from 'hooks/isMobile';

interface MasterSheetBoardsProps {
  theJob?: any;
  geoLocationError?: string;
  history?: any;
  updateJob?: any;
  loading?: boolean;
  changeTab?: (tab: TabEnum) => void;
}

const signReport =
  (floors, boardPurchase) =>
  async (
    notify: any,
    dataProvider: any,
    updateJob: MasterSheetBoardsProps['updateJob'],
    changeTab: MasterSheetBoardsProps['changeTab'],
  ) => {
    const boardReceiptFloors: BoardFloorCreatableInterface[] = [];

    floors.map((floor: FloorListItem) => {
      return floor.rows.map((row: FloorListRow) => {
        const keys = Object.keys(row.boards);
        return keys.map(key => {
          if (row.boards[key].quantity && row.boards[key].quantity > 0) {
            boardReceiptFloors.push({
              floor: floor.floor,
              quantity: row.boards[key].quantity ? row.boards[key].quantity : 0,
              boardTypeId: key,
              boardPurchaseId: boardPurchase.id,
            });
          }
        });
      });
    });

    try {
      const payload = {
        data: {
          boardPurchaseId: boardPurchase.id,
          boardReceiptFloors,
        },
      };

      await dataProvider.create('/job-signoff/signoff', payload);

      notify('Job Signed with Success!');
      updateJob();
      changeTab?.(TabEnum.Timeline);
    } catch (error) {
      notify(error.message, 'warning');
    }
  };

export const MasterSheetBoards: React.FC<MasterSheetBoardsProps> = ({
  theJob,
  geoLocationError,
  history,
  updateJob,
  changeTab,
  loading,
}) => {
  const isMobile = isMobileHook();
  const classes = useStyles();
  const notify = useNotify();
  const [tabSelectedPO, setTabSelectedPO] = useState<number>(0);
  const [accordionsExpanded, setAccordionsExpanded] = useState<
    Record<string, boolean>
  >(
    theJob.boardPurchases.reduce((acc, value) => {
      acc[value.id] = false;
      return acc;
    }, {}),
  );

  const onSignReport = (floors, boardPurchase) =>
    signReport(floors, boardPurchase)(
      notify,
      dataProvider,
      updateJob,
      changeTab,
    );

  const onCollapseAllClick = () =>
    setAccordionsExpanded(accordions => {
      const newValues = {};
      for (const key in accordions) {
        newValues[key] = false;
      }
      return newValues;
    });

  const onExpandToggle = (id: string, value: boolean) =>
    setAccordionsExpanded(accordions => ({ ...accordions, [id]: value }));

  const hasExpandedAccordions = Object.values(accordionsExpanded).some(
    value => value,
  );

  useEffect(() => {
    if (!loading) {
      const selected = theJob.boardPurchases.findIndex(
        ({ isPrimary, canceled }: any) => !!isPrimary && !canceled,
      );
      setTabSelectedPO(selected || 0);
    }
  }, [loading, theJob]);

  return (
    <Box>
      <Box className={classes.titleTab}>
        <Typography className={classes.titleFont}>Total Boards</Typography>
      </Box>
      <Box display="flex" alignItems="center" flexDirection="column" mt={1}>
        {geoLocationError && (
          <Typography className={classes.geoLocationError}>
            {geoLocationError}
          </Typography>
        )}
        {isMobile ? (
          <Box display="flex" flexDirection="column" width="100%" my={2}>
            {theJob?.boardPurchases?.map((boardPurchase, index) => {
              if (boardPurchase.canceled) return null;

              return (
                <Box
                  key={boardPurchase.id}
                  className={`${classes.borderBox} ${classes.mobilePOWrapper}`}
                >
                  <MobileMasterSheetBoardTable
                    title={`PO #${index + 1}`}
                    expanded={accordionsExpanded[boardPurchase.id]}
                    onExpandToggle={onExpandToggle}
                    signReport={onSignReport}
                    boardPurchase={boardPurchase}
                    boardReceiptFloors={theJob.boardReceiptFloors}
                    job={theJob.job}
                    theJob={theJob}
                    history={history}
                  />
                </Box>
              );
            })}
            {hasExpandedAccordions && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={2}
              >
                <Button
                  variant="text"
                  endIcon={<ExpandLessIcon />}
                  onClick={onCollapseAllClick}
                >
                  Collapse all
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" width="100%">
            <AppBar position="static">
              <Tabs
                value={tabSelectedPO}
                onChange={(_event, newTab) => setTabSelectedPO(newTab)}
                aria-label="Vatos Tabs on House Report"
                centered
                indicatorColor="primary"
                variant="fullWidth"
                classes={{
                  root: classes.tabsBoards,
                  indicator: classes.indicatorBoards,
                }}
                textColor="secondary"
              >
                {theJob.boardPurchases.map((boardPurchase, index) => {
                  if (boardPurchase.canceled) return null;
                  return (
                    <Tab
                      value={index}
                      label={`PO ${index + 1} Total Boards`}
                      key={boardPurchase.id}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
            <Box display="flex" flexDirection="column" width="100%" mt={2}>
              {theJob.boardPurchases.map((boardPurchase, index) => {
                if (boardPurchase.canceled) return null;

                return (
                  <Box
                    key={boardPurchase.id}
                    flexDirection="column"
                    display="flex"
                    width="100%"
                    className={tabSelectedPO === index ? '' : classes.none}
                  >
                    <MasterSheetBoardTable
                      signReport={onSignReport}
                      boardPurchase={boardPurchase}
                      boardReceiptFloors={theJob.boardReceiptFloors}
                      job={theJob.job}
                      theJob={theJob}
                      history={history}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MasterSheetBoards;
