import {
  BoardPurchaseDto,
  BoardPurchaseUpdateDto,
  BoardTypeCreateDto,
  BoardTypeDto,
  BuilderDto,
  JobDto,
  JobOrderDto,
  ModelDto,
  SubdivisionDto,
} from '@vatos-pas/common';
import {
  CreateParams,
  DataProvider,
  GetListParams,
  UpdateParams,
} from 'react-admin';
import { EnhancedJobCreateDto } from 'modules/jobs/types';

const createJob = async (
  dataProvider: DataProvider,
  jobPayload: EnhancedJobCreateDto,
) => dataProvider.create<JobDto>('/job', { data: jobPayload });

const findOneJob = async (dataProvider: DataProvider, id?: string) => {
  if (!id) return null;

  try {
    const job = await dataProvider.getOne<JobDto>('job', {
      id,
      // @ts-expect-error join is not typed
      join: [
        {
          field: 'building.subdivision',
        },
      ],
    });

    return job.data;
  } catch (err) {
    console.log('Error trying to fetch job', err);
  }

  return null;
};

const findOneSubdivision = async (dataProvider: DataProvider, id?: string) => {
  if (!id) return null;

  try {
    const subdivision = await dataProvider.getOne<SubdivisionDto>(
      'subdivision',
      {
        id,
        // @ts-expect-error join is not typed
        join: [
          {
            field: 'builder',
          },
          {
            field: 'supervisorUser',
          },
        ],
      },
    );

    return subdivision.data;
  } catch (err) {
    console.log('Error trying to fetch subdivision', err);
  }

  return null;
};

const findOneBuilder = async (dataProvider: DataProvider, id?: string) => {
  if (!id) return null;

  try {
    const builder = await dataProvider.getOne<BuilderDto>('builder', {
      id,
    });

    return builder.data;
  } catch (err) {
    console.log('Error trying to fetch builder', err);
  }

  return null;
};

const findManyBuilders = async (dataProvider: DataProvider) => {
  try {
    const builders = await dataProvider.getList<BuilderDto>('builder', {
      filter: {},
      pagination: { page: 1, perPage: 9999 },
      sort: { field: 'name', order: 'ASC' },
    });

    return builders.data;
  } catch (err) {
    console.log('Error trying to fetch builders', err);
  }

  return null;
};

const findManySubdivisions = async (
  dataProvider: DataProvider,
  filter?: GetListParams['filter'],
  perPage = 9999,
) => {
  try {
    const subdivisions = await dataProvider.getList<SubdivisionDto>(
      'subdivision',
      {
        filter: filter ?? {},
        pagination: { page: 1, perPage },
        sort: { field: 'name', order: 'ASC' },
      },
    );

    return subdivisions.data;
  } catch (err) {
    console.log('Error trying to fetch subdivisions', err);
  }

  return null;
};

const findManyModels = async (
  dataProvider: DataProvider,
  filter?: GetListParams['filter'],
) => {
  try {
    const models = await dataProvider.getList<ModelDto>('model', {
      filter: filter ?? {},
      pagination: { page: 1, perPage: 9999 },
      sort: { field: 'name', order: 'ASC' },
    });

    return models.data;
  } catch (err) {
    console.log('Error trying to fetch models', err);
  }

  return null;
};

const findManyPurchaseOrders = async (
  dataProvider: DataProvider,
  jobId?: string,
) => {
  if (!jobId) return null;

  try {
    const purchaseOrders = await dataProvider.getList<BoardPurchaseDto>(
      'board-purchase',
      {
        filter: {
          jobId,
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'createdAt', order: 'ASC' },
      },
    );

    return purchaseOrders.data;
  } catch (err) {
    console.log('Error trying to fetch purchase orders', err);
  }

  return null;
};

const findManyBoardTypes = async (dataProvider: DataProvider) => {
  try {
    const boardTypes = await dataProvider.getList<BoardTypeDto>('board-type', {
      filter: {},
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
    });

    return boardTypes.data;
  } catch (err) {
    console.log('Error trying to fetch board types', err);
  }

  return null;
};

const findManyJobOrder = async (dataProvider: DataProvider, id: string) => {
  try {
    const jobOrder = await dataProvider.getList<JobOrderDto>('job-order', {
      filter: { 'spOrderId||$eq||': id },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'spOrderId', order: 'ASC' },
    });

    return jobOrder.data;
  } catch (err) {
    console.log('Error trying to fetch job order', err);
  }

  return null;
};

const updatePurchaseOrder = async (
  dataProvider: DataProvider,
  params: UpdateParams<BoardPurchaseUpdateDto>,
) => {
  try {
    return await dataProvider.update('board-purchase', params);
  } catch (err) {
    throw new Error(err);
  }
};

const createPurchaseOrder = async (
  dataProvider: DataProvider,
  params: CreateParams<Partial<BoardTypeCreateDto>>,
) => {
  try {
    return await dataProvider.create('board-purchase', params);
  } catch (err) {
    throw new Error(err);
  }
};

export {
  createJob,
  findOneJob,
  findManyBoardTypes,
  findManyJobOrder,
  findOneSubdivision,
  findOneBuilder,
  findManyBuilders,
  findManySubdivisions,
  findManyModels,
  findManyPurchaseOrders,
  updatePurchaseOrder,
  createPurchaseOrder,
};
