import { useState } from 'react';
import { createContainer, useContainer } from 'unstated-next';
import _ from 'lodash';
import { JobOrderBoardDto, PhaseDto } from '../../../../../common/dist';
import { useDataProvider } from 'react-admin';
import { JobContainer } from './job';
import {
  EMPTY_MATERIALS,
  INITIAL_DRAFT_PURCHASE_ORDER,
  INITIAL_FLOORS,
  INITIAL_MATERIALS,
} from '../constants';
import { BoardDto } from '../types';

const usePurchaseOrder = () => {
  const { selectedPurchaseOrder } = useContainer(JobContainer);

  const dataProvider = useDataProvider();

  const [draftPurchaseOrder, setDraftPurchaseOrder] = useState({
    ...INITIAL_DRAFT_PURCHASE_ORDER,
  });
  const [hasPOBoardExcelFloors, setPOHasBoardExcelFloors] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [jobOrderBoard, setJobOrderBoard] = useState<JobOrderBoardDto[] | null>(
    null,
  );
  const [selectedPhase, setSelectedPhase] = useState('');
  const [isDraft, setIsDraft] = useState(false);
  const [sameSupplier, setSameSupplier] = useState(true);

  const isEdit = selectedPurchaseOrder !== null;
  const isNew = selectedPurchaseOrder === null;
  const isCanceled = isEdit && !isDraft && !!selectedPurchaseOrder?.canceled;

  const handleUpdateDraftPOFloors = (boardPurchaseFloors: BoardDto[]) => {
    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors,
    }));
  };

  const resetPurchaseOrder = (hasPurchaseOrder: boolean) => {
    handleUpdateDraftPOFloors([...INITIAL_FLOORS]);
    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      materials: hasPurchaseOrder
        ? [...EMPTY_MATERIALS]
        : [...INITIAL_MATERIALS],
    }));
  };

  const getJobOrderBoard = async (jobId: string) => {
    const jobOrderBoard = await dataProvider.getList<JobOrderBoardDto>(
      'job-order-board',
      {
        filter: { jobId: jobId },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'boardTypeShortname', order: 'ASC' },
      },
    );

    if (jobOrderBoard?.data.length) {
      setJobOrderBoard(jobOrderBoard.data);
    }
  };

  const getMaterialPhase = (phases: PhaseDto[]) => {
    if (!phases.length) return phases;

    return phases.filter(item => {
      if (item.name === 'Materials') {
        setSelectedPhase(item.id);
      }

      return item.name === 'Materials';
    });
  };

  return {
    draftPurchaseOrder,
    hasPOBoardExcelFloors,
    isLoading,
    errors,
    jobOrderBoard,
    selectedPhase,
    selectedPurchaseOrder,
    isDraft,
    isEdit,
    isNew,
    isCanceled,
    sameSupplier,
    handleUpdateDraftPOFloors,
    resetPurchaseOrder,
    setErrors,
    getJobOrderBoard,
    getMaterialPhase,
    setSelectedPhase,
    setDraftPurchaseOrder,
    setIsLoading,
    setIsDraft,
    setPOHasBoardExcelFloors,
    setSameSupplier,
  };
};

export const PurchaseOrderContainer = createContainer(usePurchaseOrder);
