import React, { FC } from 'react';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
  FilterProps,
  SelectInput,
} from 'react-admin';
import { UserDto } from '@vatos-pas/common';
import { AppRole } from 'providers/roles';

export const PreRockSheetFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Supervisor"
      source="supervisorUserId"
      reference="user"
      resettable
      data-cy="ProdPayMasterSheetSheetFilterSupervisor"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: {
              $and: [
                {
                  $or: [
                    { firstName: { $cont: value } },
                    { lastName: { $cont: value } },
                  ],
                },
                {
                  'userRoles.role.name': { $eq: AppRole.Supervisor },
                },
              ],
            },
          };
        } else {
          return {
            s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
          };
        }
      }}
      sort={{ field: 'firstName', order: 'ASC' }}
      alwaysOn
    >
      <AutocompleteInput
        optionText={(user: UserDto) => {
          return `${user.firstName} ${user.lastName}`;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <TextInput
      label="Lot"
      source="jobLot||$eq"
      alwaysOn
      resettable
      data-cy="ProdPayMasterSheetSheetFilterLot"
    />
    <ReferenceInput
      label="Builder"
      source="builderId"
      reference="builder"
      resettable
      data-cy="masterSheetFilterBuilder"
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        allowEmpty={false}
        optionText="name"
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Subdivision"
      source="subdivisionId"
      reference="subdivision"
      resettable
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Phase"
      source="currentPhaseId"
      reference="phase"
      perPage={Number.MAX_SAFE_INTEGER}
      sort={{ field: 'priority', order: 'ASC' }}
      allowEmpty={false}
      resettable
      alwaysOn
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <TextInput
      label="Address"
      alwaysOn
      resettable
      source="address1||$cont"
      data-cy="address1Filter"
    />
  </Filter>
);
