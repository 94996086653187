import { DateTime } from 'luxon';

export const DEFAULT_COLUMN_LENGTH = 1;

export const INITIAL_DRAFT_ROW = {
  windowStart: DateTime.now().toFormat('HH:mm:ss'),
  windowEnd: DateTime.now().toFormat('HH:mm:ss'),
};

export const TABLE_COLUMNS = ['#', 'Window Start', 'Window End'];
