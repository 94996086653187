import { useState } from 'react';

import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import MUIButton from '@material-ui/core/Button';
import { BumpResourceSheetDto } from '@vatos-pas/common';

import { Show } from 'components/Show';
import { CAN_APPROVE_BUMPOUT } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { BumpoutTimesheetFields } from '../views/bumpout-timesheet-list';
import { DateTime } from 'luxon';

type ButtonProps = {
  fields: BumpoutTimesheetFields | null;
  handleRefetch: () => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const Button = ({ record, fields, handleRefetch }: ButtonProps) => {
  if (!record?.id) return null;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';
  const currentFields = fields?.[record.id];

  const handleApproveClick = async () => {
    try {
      setIsLoading(true);

      await dataProvider.update(
        isJobPhaseBump ? '/job-phase-bump' : '/repair-payment',
        {
          id: record.id,
          data: {
            isApprovedHours: true,
            ...(isJobPhaseBump && !currentFields?.workedHours
              ? { approvedHours: 0 }
              : currentFields?.workedHours
              ? { approvedHours: +currentFields.workedHours }
              : {}),
          },
          previousData: record,
        },
      );

      notify('Worked hours approved.');
      handleRefetch();
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const numberValue =
    typeof currentFields?.workedHours === 'string'
      ? parseFloat(currentFields.workedHours)
      : null;

  const timeIn =
    currentFields?.timeIn instanceof DateTime &&
    currentFields.timeIn.toFormat('HH:mm:00');

  const timeOut =
    currentFields?.timeOut instanceof DateTime &&
    currentFields.timeOut.toFormat('HH:mm:00');

  const diffTimeIn = timeIn && timeIn !== record.timeIn;
  const diffTimeOut = timeOut && timeOut !== record.timeOut;

  return (
    <MUIButton
      variant="contained"
      color="primary"
      disabled={
        isLoading ||
        (!isJobPhaseBump &&
          (diffTimeIn ||
            diffTimeOut ||
            numberValue === null ||
            isNaN(numberValue) ||
            numberValue <= 0))
      }
      onClick={handleApproveClick}
    >
      Approve
    </MUIButton>
  );
};

type ApproveButtonProps = {
  fields: BumpoutTimesheetFields | null;
  handleRefetch: () => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const ApproveButton = ({
  record,
  fields,
  handleRefetch,
}: ApproveButtonProps) => {
  if (!record?.id) return null;

  const { hasPermission } = PermissionsProvider.useContainer();

  const canApprove = hasPermission(CAN_APPROVE_BUMPOUT);
  const isBumpoutApproved =
    record?.bumperApprovalStatus === 'APPROVED' ||
    record?.bumperApprovalStatus === 'MISMATCH_APPROVAL' ||
    record?.bumperApprovalStatus === 'OVERRIDDEN';
  const isJobPhaseBump = record?.bumpResourceType === 'JOB_PHASE_BUMP';

  const isPendingSupervisorAction =
    isJobPhaseBump ||
    record?.bumperApprovalStatus === 'PENDING_APPROVAL' ||
    record?.bumperApprovalStatus === 'PENDING_REVIEW';

  if (isBumpoutApproved) return null;

  return (
    <Show condition={canApprove && isPendingSupervisorAction}>
      <Button fields={fields} record={record} handleRefetch={handleRefetch} />
    </Show>
  );
};

export default ApproveButton;
