import { ChangeEvent, FC, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

import { SpMatchesDefaultTableRow } from 'components/SpMatchesDefaultTable';

export interface SpMatchesDefaultRowProps {
  row: SpMatchesDefaultTableRow;
  readOnly: boolean;
  handleRowFlagChange?: (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => Promise<void>;
}

export const SpMatchesDefaultRow: FC<SpMatchesDefaultRowProps> = ({
  row,
  readOnly,
  handleRowFlagChange,
}) => {
  const [loading, setLoading] = useState(false);

  const handleUpdateFlag = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);

    handleRowFlagChange && (await handleRowFlagChange(event, row.id));

    setLoading(false);
  };

  return (
    <>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={1}
          >
            <CircularProgress size={26} />
          </Box>
        ) : (
          <Checkbox
            checked={row.active}
            onChange={handleUpdateFlag}
            disabled={readOnly}
          />
        )}
      </TableCell>
    </>
  );
};
