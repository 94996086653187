import Chip from '@material-ui/core/Chip';

import CustomSelect, {
  ICustomItem,
  ICustomReference,
} from 'components/CustomSelect';

type RegionFieldProps = {
  classes: { [key: string]: string };
  regions: ICustomItem[] | null;
  selectedSupplierRegions: string[];
  setSelectedSupplierRegions: React.Dispatch<React.SetStateAction<string[]>>;
} & Pick<ICustomReference, 'error' | 'helperText'>;

const RegionField = ({
  classes,
  regions,
  selectedSupplierRegions,
  setSelectedSupplierRegions,
  error,
  helperText,
}: RegionFieldProps) => {
  return (
    <CustomSelect
      margin="dense"
      variant="filled"
      renderValue={(value: unknown) => (
        <div className={classes.chips}>
          {(value as string[])
            .map(item => regions?.find(choice => choice.id === item))
            .filter(item => !!item)
            .map(item => (
              <Chip key={item?.id} label={item?.name} />
            ))}
        </div>
      )}
      multiple
      resource="region"
      label="Regions"
      options={regions ?? []}
      value={selectedSupplierRegions}
      onChange={event =>
        setSelectedSupplierRegions(event.target.value as string[])
      }
      error={!!error}
      helperText={helperText ?? ''}
    />
  );
};

export default RegionField;
