import {
  JobsList,
  JobsCreateWithProvider,
  JobsViewWithProvider,
} from './views';

export default {
  create: JobsCreateWithProvider,
  list: JobsList,
  show: JobsViewWithProvider,
  name: 'job-sheet',
  label: 'Imported Jobs',
  to: '/job-sheet',
};
