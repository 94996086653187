import React, { useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowLeftIcon from '@material-ui/icons/ArrowBack';
import ArrowRightIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';

import { luxon } from 'services/luxon';

interface Props {
  from: Date;
  to: Date;
  selected: Date;
  onSelect: (date: Date) => void;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dates: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    overflow: 'auto',
    gap: 8,
    paddingBottom: '8px',
  },
  arrowLeft: {
    marginBottom: '8px',
    marginRight: '8px',
  },
  arrowRight: {
    marginBottom: '8px',
    marginLeft: '8px',
  },
  unselected: {
    userSelect: 'none',
    cursor: 'pointer',
    border: '0.5px solid #007DFF',
    background: 'transparent',
    padding: '4px',
    borderRadius: '8px',
    color: '#007DFF',
  },
  selected: {
    userSelect: 'none',
    cursor: 'pointer',
    border: '0.5px solid #007DFF',
    background: '#007DFF',
    padding: '4px',
    borderRadius: '8px',
    color: '#fff',
  },
});

export const DateSelect: React.FC<Props> = ({
  from,
  to,
  selected,
  onSelect,
}) => {
  const classes = useStyles();
  const canGoBack = luxon.isAfterDay(luxon.date(selected), luxon.date(from));
  const canGoForward = luxon.isBeforeDay(luxon.date(selected), luxon.date(to));

  const scrollToItem = (date: DateTime) => {
    const element = document.getElementById(date.toFormat('yyyy-MM-dd'));
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth' });
  };

  const onBackClick = () => {
    if (!canGoBack) return;
    const date = luxon.addDays(luxon.date(selected), -1);
    const previousDate = date.toBSON();
    onSelect(previousDate);
  };

  const onForwardClick = () => {
    if (!canGoForward) return;
    const date = luxon.addDays(luxon.date(selected), 1);
    const nextDate = date.toBSON();
    onSelect(nextDate);
  };

  const dates = useMemo(() => {
    const start = luxon.date(from);
    const end = luxon.addDays(luxon.date(to), 1);

    if (luxon.isAfterDay(start, end) || luxon.isSameDay(start, end)) {
      return [];
    }

    const days: DateTime[] = [];
    let date = start;

    while (luxon.isBeforeDay(date, end)) {
      days.push(date);
      date = luxon.addDays(date, 1);
    }

    return days;
  }, [from, to]);

  useEffect(() => {
    scrollToItem(luxon.date(selected));
  }, [selected]);

  return (
    <Box className={classes.container}>
      <IconButton
        disabled={!canGoBack}
        size="small"
        className={classes.arrowLeft}
        onClick={onBackClick}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Box className={classes.dates}>
        {dates.map(date => (
          <Box
            id={date.toFormat('yyyy-MM-dd')}
            key={date.toBSON().toISOString()}
            onClick={() => onSelect(date.toBSON())}
            className={
              luxon.isSameDay(date, luxon.date(selected))
                ? classes.selected
                : classes.unselected
            }
          >
            <Typography>{date.toFormat('MM/dd/yyyy')}</Typography>
          </Box>
        ))}
      </Box>
      <IconButton
        disabled={!canGoForward}
        size="small"
        className={classes.arrowRight}
        onClick={onForwardClick}
      >
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export default DateSelect;
