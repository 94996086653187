import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { RegionTimeWindowDto } from '@vatos-pas/common';

import { Show } from 'components/Show';
import { RegionTimeWindowTableDraftRow } from './RegionTimeWindowTableDraftRow';
import { RegionTimeWindowTableContainer } from './RegionTimeWindowTableContainer';
import { RegionTimeWindowTableRow } from './RegionTimeWindowTableRow';
import { RegionTimeWindowTableDataRow } from './types';
import { DEFAULT_COLUMN_LENGTH, INITIAL_DRAFT_ROW } from './constants';

type RegionTimeWindowEditProps = {
  rows: RegionTimeWindowTableDataRow[] | null;
  columns: string[];
  regionId: string | undefined;
  setRegionTimeWindows: React.Dispatch<
    React.SetStateAction<RegionTimeWindowDto[] | null>
  >;
};

export const RegionTimeWindowEdit = ({
  rows,
  columns,
  regionId,
  setRegionTimeWindows,
}: RegionTimeWindowEditProps) => {
  const classes = useStyles();

  const [draftRow, setDraftRow] =
    useState<Partial<RegionTimeWindowTableDataRow> | null>(null);

  const handleAddDraftRow = () => {
    setDraftRow({
      incrementalId: rows?.length ? rows.length + 1 : 1,
      ...INITIAL_DRAFT_ROW,
    });
  };

  return (
    <div className={classes.container}>
      <RegionTimeWindowTableContainer columns={columns}>
        {rows?.length ? (
          rows.map(row => (
            <TableRow key={row.id}>
              <RegionTimeWindowTableRow
                row={row}
                regionId={regionId}
                readOnly={false}
                setRegionTimeWindows={setRegionTimeWindows}
              />
            </TableRow>
          ))
        ) : (
          <Show condition={!draftRow}>
            <TableRow>
              <TableCell
                colSpan={columns.length + DEFAULT_COLUMN_LENGTH}
                className={classes.tableCellCenter}
              >
                No H/O region time window found
              </TableCell>
            </TableRow>
          </Show>
        )}
        <Show condition={!!draftRow}>
          <RegionTimeWindowTableDraftRow
            row={{
              incrementalId: rows?.length ? rows.length + 1 : 1,
              ...INITIAL_DRAFT_ROW,
            }}
            regionId={regionId}
            setRegionTimeWindows={setRegionTimeWindows}
            setDraftRow={setDraftRow}
          />
        </Show>
      </RegionTimeWindowTableContainer>
      <Show condition={!draftRow}>
        <Box className={classes.boxActions}>
          <IconButton color="secondary" onClick={handleAddDraftRow}>
            <AddIcon />
          </IconButton>
        </Box>
      </Show>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  boxActions: {
    display: 'flex',
    columnGap: 8,
    paddingLeft: 8,
    paddingBottom: 16,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
