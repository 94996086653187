/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cancelButton: {
    color: '#e6005a',
  },
});

type OriginalExpectedModalProps = {
  open: boolean;
  handleClose(): void;
  record: any;
};

export const OriginalExpectedModal: FC<OriginalExpectedModalProps> = ({
  open,
  handleClose,
  record = {
    originalDateExpected: '',
  },
}) => {
  const styles = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box>
          <Typography>
            Initial Expected Date:{' '}
            {new Date(record.originalDateExpected).toLocaleDateString('en-US')}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
          paddingTop="10px"
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default OriginalExpectedModal;
