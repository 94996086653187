import {
  ModelBoardSheetEdit,
  ModelBoardSheetList,
  ModelBoardSheetCreate,
} from './views';

export default {
  edit: ModelBoardSheetEdit,
  create: ModelBoardSheetCreate,
  list: ModelBoardSheetList,
  name: 'board-pre-fill',
  label: 'Board Pre-Fill',
  to: '/board-pre-fill',
};
