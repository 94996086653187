import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import SpreadsheetExampleImg from 'assets/quick-copy-example.png';

const useStyles = makeStyles({
  img: {
    width: '100%',
  },
});

type SpreadsheetCopyExampleDialogProps = {
  open: boolean;
  handleClose: () => void;
};

export const SpreadsheetCopyExampleDialog: FC<SpreadsheetCopyExampleDialogProps> =
  ({ open, handleClose }) => {
    const classes = useStyles();

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <img className={classes.img} src={SpreadsheetExampleImg} />
        </DialogContent>
        <DialogActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX="20px"
            paddingY="20px"
          >
            <Button color="primary" variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };
