import React, { FC } from 'react';
import {
  List,
  EmailField,
  EditButton,
  SingleFieldList,
  Filter,
  TextInput,
  ChipField,
  TextField,
  ArrayField,
  ListProps,
  FilterProps,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';

const UserFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <TextInput
      label="First Name"
      source="firstName||$cont"
      data-cy="userFilterFirstName"
      alwaysOn
      resettable
    />
    <TextInput
      label="Last Name"
      source="lastName||$cont"
      data-cy="userFilterLastName"
      alwaysOn
      resettable
    />

    <ReferenceInput
      label="Role"
      source="useRoles.role.id"
      reference="role"
      resettable
      data-cy="userFilterRole"
      alwaysOn
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput
        style={{ width: 300 }}
        allowEmpty={false}
        optionText="name"
        optionValue="id"
      />
    </ReferenceInput>
    <TextInput
      label="Email"
      source="email||$cont"
      data-cy="userFilterEmail"
      resettable
    />
    <TextInput
      label="Primary Phone"
      source="primaryPhone||$cont"
      data-cy="userFilterPrimaryPhone"
      resettable
    />
  </Filter>
);

export const UserList: FC<ListProps> = props => (
  <List
    {...props}
    exporter={exporter('users')}
    filters={<UserFilter>{props.filters}</UserFilter>}
    filter={{
      $join: [
        {
          field: 'userRoles',
          select: ['id', 'userId', 'roleId', 'role'],
        },
        { field: 'userRoles.role', select: ['id', 'name'] },
        {
          field: 'userRegions',
          select: ['id', 'userId', 'regionId', 'region'],
        },
        { field: 'userRegions.region', select: ['id', 'name'] },
      ],
    }}
    bulkActionButtons={<React.Fragment />}
    sort={{ field: 'firstName', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="firstName" label="First Name" />
      <TextField source="lastName" label="Last Name" />
      <EmailField source="email" label="Email" />
      <EmailField source="primaryPhone" label="Primary Phone" />
      <ArrayField
        source="userRoles"
        fieldKey="id"
        label="Roles"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="role.name" />
        </SingleFieldList>
      </ArrayField>
      <ArrayField
        source="userRegions"
        fieldKey="id"
        label="Regions"
        sortable={false}
      >
        <SingleFieldList linkType={false}>
          <ChipField source="region.name" />
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);
