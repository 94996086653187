import { useState } from 'react';

import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { BumpResourceSheetDto, ContractorDto } from '@vatos-pas/common';

import { PermissionsProvider } from 'providers/permissionsProvider';
import { Show } from 'components/Show';
import { CAN_APPROVE_BUMPOUT } from 'providers/permissions';

type ContractorProps = {
  contractors: ContractorDto[];
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const ContractorSelect = ({ contractors, record }: ContractorProps) => {
  if (!record?.id) return null;

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [isLoading, setIsLoading] = useState(false);
  const [contractorSelected, setContractorSelected] = useState(
    record?.contractorId,
  );

  const regionIdAndRepairId = `${record.regionId}-${record.repairId}`;
  const contractorsOrEmpty = record?.regionId
    ? contractors?.[regionIdAndRepairId]
    : [];
  const isJobPhaseBump = record?.bumpResourceType === 'JOB_PHASE_BUMP';
  const isNotStarted = record?.bumperApprovalStatus === 'NOT_STARTED';

  const onChangeContractor = async event => {
    if (!record?.id) return;

    const current = contractorSelected;
    try {
      setContractorSelected(event.target.value);
      setIsLoading(true);

      await dataProvider.update(
        isJobPhaseBump ? '/job-phase-bump' : '/repair-payment',
        {
          id: record?.id ?? '',
          data: {
            contractorId: event.target.value,
          },
          previousData: record,
        },
      );

      notify('Contractor updated.');
    } catch (error) {
      setContractorSelected(current);
      notify(error.message, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" gridColumnGap={8}>
      <Show
        condition={!isJobPhaseBump && isNotStarted}
        fallback={<>{record?.contractorName}</>}
      >
        <Select
          onChange={onChangeContractor}
          labelId={`custom-reference-contractor`}
          id={`reference-contractor`}
          fullWidth
          value={contractorSelected}
          disabled={isLoading || !hasPermission(CAN_APPROVE_BUMPOUT)}
        >
          {contractorsOrEmpty?.map((item: ContractorDto) => (
            <MenuItem key={item.id} value={item.id} data-name={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <Show condition={isLoading}>
          <CircularProgress size={16} />
        </Show>
      </Show>
    </Box>
  );
};

export default ContractorSelect;
