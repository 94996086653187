import React, { useEffect, useRef, useState } from 'react';
import {
  useDataProvider,
  useInput,
  InputProps,
  useRecordContext,
} from 'react-admin';
import { RouteDto } from '@vatos-pas/common';

import CustomSelect from 'components/CustomSelect';
import { findRouteBySupervisor } from 'services/route';
import ConfirmDialog from 'components/ConfirmDialog';

type SupervisorInputProps = {
  regionId: string | undefined;
} & InputProps;

export const SupervisorInput = ({
  regionId,
  onChange,
  ...props
}: SupervisorInputProps) => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const regionIdCache = useRef(regionId);

  const [supervisorHasRouteModal, setSupervisorHasRouteModal] = useState(false);
  const [supervisorSelectedRoute, setSupervisorSelectedRoute] =
    useState<RouteDto | null>(null);

  const isRouteAssignedToSupervisor = async (supervisorId: string) => {
    return await findRouteBySupervisor(dataProvider, supervisorId);
  };

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const handleChange = async (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const supervisorHasRoute = await isRouteAssignedToSupervisor(
      event.target.value as string,
    );

    if (supervisorHasRoute?.length && supervisorHasRoute[0].id !== record.id) {
      setSupervisorHasRouteModal(true);
      setSupervisorSelectedRoute(supervisorHasRoute[0]);
    }
    onChange && onChange(event.target.value);
    input.onChange(event);
  };

  // Reset subdivision state whenever region changes
  useEffect(() => {
    if (regionIdCache.current !== regionId) {
      input.onChange(null);
      regionIdCache.current = regionId;
    }
  }, [regionId]);

  return (
    <>
      <CustomSelect
        label="Supervisor"
        resource="supervisorUserId"
        variant="filled"
        options={props.options}
        margin="dense"
        error={!!(touched && error)}
        helperText={error}
        {...input}
        onChange={handleChange}
      />
      <ConfirmDialog
        open={supervisorHasRouteModal}
        title="Notice"
        content={`The Supervisor you have selected (${supervisorSelectedRoute?.supervisorUser?.firstName} ${supervisorSelectedRoute?.supervisorUser?.lastName}) is already assigned to ${supervisorSelectedRoute?.name}. Do you wish to continue?`}
        handleClose={() => {
          setSupervisorHasRouteModal(false);
          input.onChange(null);
        }}
        onConfirm={() => setSupervisorHasRouteModal(false)}
        maxWidth="xs"
      />
    </>
  );
};
