import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '500px',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

export const ImageViewerRepair = (props: any) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image Viewer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.imageSelected && (
              <img className={classes.img} src={props.imageSelected}></img>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ImageViewerRepair;
