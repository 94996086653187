import React, { FC } from 'react';
import { List, ListProps, TextField } from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';

export const RolesList: FC<ListProps> = props => (
  <List
    {...props}
    exporter={exporter('roles')}
    bulkActionButtons={<React.Fragment />}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid isRowSelectable={() => false}>
      <TextField source="name" />
    </Datagrid>
  </List>
);
