/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { useState, useEffect, FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import { useDataProvider, GetListResult, useNotify } from 'react-admin';
import { ContractorDto, ContractorPaymentTypeEnum } from '@vatos-pas/common';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CustomSelect from 'components/CustomSelect';
import { useStyles } from './master-sheet-styles';

type Filters = {
  archived: boolean;
  active: boolean;
  suspended: boolean;
  'contractorRegions.regionId': string;
  hangerAvailable?: boolean;
  finisherAvailable?: boolean;
  sprayerAvailable?: boolean;
  bumperAvailable?: boolean;
  specialistAvailable?: boolean;
  bumperPaymentType?: string;
};

const convertFilters = (
  resourceType: string,
  regionId: string,
  perBoard: boolean,
  perHour: boolean,
) => {
  const filter: Filters = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  if (!perBoard && perHour) {
    filter['paymentType||$eq'] = 'PerHour';
  }
  if (perBoard && !perHour) {
    filter['paymentType||$eq'] = 'PerBoard';
  }

  if (resourceType === 'Hanger') {
    filter.hangerAvailable = true;
  } else if (resourceType === 'Finisher') {
    filter.finisherAvailable = true;
  } else if (resourceType === 'Sprayer') {
    filter.sprayerAvailable = true;
  } else if (resourceType === 'Specialist') {
    filter.specialistAvailable = true;
  } else if (resourceType === 'Bumper') {
    delete filter['paymentType||$eq'];
    if (!perBoard && perHour) filter['bumperPaymentType||$eq'] = 'PerHour';
    if (perBoard && !perHour) filter['bumperPaymentType||$eq'] = 'PerBoard';
    filter.bumperAvailable = true;
  }

  return filter;
};

export const RepairContractorDialog: FC<any> = props => {
  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [resources, setResources] = useState<any[]>([]);
  const [contractorSelected, setContractorSelected] = useState();
  const [resourceType, setResourceType] = useState<any>();
  const [hours, setHours] = useState<any>(0);
  const [extraHours, setExtraHours] = useState<any>(0);
  const [fixedAmount, setFixedAmount] = useState<any>(0);
  const [perBoard, setPerBoard] = useState<any>(false);
  const [perHour, setPerHour] = useState<any>(false);
  const [additionalBumperFilter, setAdditionalBumperFilter] = useState<any>('');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const onChangeContractor = (event: any) =>
    setContractorSelected(event.target.value);

  useEffect(() => {
    const getContractors = async () => {
      try {
        const { data }: GetListResult<ContractorDto> =
          await dataProvider.getList('contractor', {
            filter: {
              ...convertFilters(
                resourceType,
                props.theJob.subdivision.regionId,
                perBoard,
                perHour,
              ),
              $join: [{ field: 'contractorRegions' }],
            },
            pagination: { page: 1, perPage: 200 },
            sort: { field: 'name', order: 'ASC' },
          });

        if (!data) throw new Error('Job phases not found');

        let newContractors = [...data];

        const filterByPaymentType =
          (type: ContractorPaymentTypeEnum) => (contractor: ContractorDto) => {
            const { bumperAvailable, bumperPaymentType, paymentType } =
              contractor;
            if (bumperAvailable) return bumperPaymentType === type;
            return paymentType === type;
          };

        if (additionalBumperFilter) {
          if (additionalBumperFilter === 'perBoard') {
            newContractors = newContractors.filter(
              filterByPaymentType(ContractorPaymentTypeEnum.PerBoard),
            );
          }
          if (additionalBumperFilter === 'perHour') {
            newContractors = newContractors.filter(
              filterByPaymentType(ContractorPaymentTypeEnum.PerHour),
            );
          }
        }

        setContractors(newContractors);
      } catch (error) {
        notify('Failed to load contractors, try again', 'error');
      }
    };

    getContractors();
  }, [resourceType, perHour, perBoard, props.theJob, additionalBumperFilter]);

  useEffect(() => {
    const loadResources = () => {
      if (!props.repairType) return;

      const newResources: any = [];

      props.theJob.repairFormula
        .filter(
          (repairFormulaItem: any) =>
            repairFormulaItem.repair.id === props.repairType,
        )
        .forEach(item => {
          const { repairResourceType } = item;

          const hasResourceWithType =
            newResources.filter(
              itemFind => itemFind.id === item.repairResourceType,
            ).length === 0;

          if (hasResourceWithType) {
            newResources.push({
              name: item.repairResourceType,
              id: item.repairResourceType,
            });
          }
        });

      setResources(newResources);

      const perBoard = props.theJob.repairFormula
        .filter(item => item.repairId === props.repairType)
        .find(item => {
          if (item.repairResourceType === 'Bumper')
            return item.repairBumperPaymentType === 'PerBoard';
          return item.repairPaymentType === 'PerBoard';
        });

      const perHour = props.theJob.repairFormula
        .filter(item => item.repairId === props.repairType)
        .find(item => {
          if (item.repairResourceType === 'Bumper')
            return item.repairBumperPaymentType === 'PerHour';
          return item.repairPaymentType === 'PerHour';
        });

      const hasPerBoard = perBoard !== undefined;
      const hasPerHour = perHour !== undefined;

      setPerBoard(hasPerBoard);
      setPerHour(hasPerHour);

      if (hasPerBoard) setAdditionalBumperFilter('perBoard');
      if (hasPerHour) setAdditionalBumperFilter('perHour');
      if (hasPerBoard && hasPerHour) setAdditionalBumperFilter('*');
    };
    loadResources();
  }, [props.repairType]);

  const fullContractorSelected = contractors.find(
    item => item.id === contractorSelected,
  );

  const formulaSelected = props.theJob.repairFormula.find(item => {
    if (fullContractorSelected) {
      if (resourceType === 'Bumper') {
        return (
          item.repairResourceType === resourceType &&
          item.repairId === props.repairType &&
          item.repairBumperPaymentType ===
            fullContractorSelected.bumperPaymentType
        );
      }
      return (
        item.repairResourceType === resourceType &&
        item.repairId === props.repairType
      );
    }
  });

  const isBumpoutTimesheetFlow = props.theJob.subdivision.reqBumpoutTimesheet;

  const showHours = () => {
    // It doesn't matter the repair payment type
    // If the resource type is `Bumper` the user must add the estimated hours
    if (isBumpoutTimesheetFlow && resourceType === 'Bumper') return true;

    if (!formulaSelected || !contractorSelected) {
      return false;
    }

    return formulaSelected.repairPaymentType === 'PerHour';
  };

  const showExtraHours = () => {
    if (!formulaSelected || !contractorSelected) {
      return false;
    }
    return formulaSelected.repairPaymentType === 'ExtraHours';
  };

  const showFixedAmount = () => {
    if (!formulaSelected || !contractorSelected) {
      return false;
    }
    return formulaSelected.repairPaymentType === 'SetPrice';
  };

  const submitContractor = () => {
    const repairPayment: any = {
      repairFormulaId: formulaSelected.id,
      contractorId: contractorSelected,
      resourceType,
      contractorName: fullContractorSelected?.name,
    };
    if (showHours()) {
      repairPayment.estimatedHours = hours;
    }
    if (showFixedAmount()) {
      repairPayment.fixedAmount = fixedAmount;
    }
    if (showExtraHours()) {
      repairPayment.extraHours = parseInt(extraHours);
    }
    props.setRepairPayments([...props.repairPayments, repairPayment]);
    notify('Contractor Added!');
    props.handleClose();
  };

  console.log(
    // !contractorSelected ||
    !formulaSelected,
    // showFixedAmount() && (!fixedAmount || fixedAmount <= 0),
    // (showExtraHours() && (!extraHours || extraHours <= 0)) ||
    // (showHours() && (!hours || hours <= 0)),
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Add Contractor</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box
            display={props.isMobile ? 'block' : 'flex'}
            minWidth={props.isMobile ? 'unset' : '600px'}
          >
            <Box width="100%" mr={3}>
              <CustomSelect
                resource="resourceType"
                label="Resource Type"
                options={resources}
                value={resourceType}
                onChange={event => {
                  if (event.target?.value) {
                    setResourceType(event.target.value);
                  }
                }}
              />
            </Box>
            <Box width="100%">
              <label>Contractor</label>
              <Select
                onChange={onChangeContractor}
                labelId={`custom-reference-contractor`}
                id={`reference-contractor`}
                fullWidth
                value={contractorSelected}
                disabled={!resourceType}
              >
                {contractors.map((item: ContractorDto) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    data-item={item.id}
                    data-name={item.name}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box>
            {showHours() && (
              <Box width="150px">
                <TextField
                  label="Estimated Hours"
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                  }}
                  type="number"
                  className={classes.input}
                  value={hours}
                  onChange={event => setHours(event?.target.value)}
                />
              </Box>
            )}
            {showFixedAmount() && (
              <Box width="150px">
                <TextField
                  label="Fixed Amount $"
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                  }}
                  type="number"
                  className={classes.input}
                  value={fixedAmount}
                  onChange={event => setFixedAmount(event?.target.value)}
                />
              </Box>
            )}
            {showExtraHours() && (
              <Box width="150px">
                <TextField
                  InputProps={{
                    inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                  }}
                  type="number"
                  label="Extra Hours"
                  fullWidth
                  className={classes.input}
                  value={extraHours}
                  onChange={event => setExtraHours(event?.target.value)}
                />
              </Box>
            )}
          </Box>
        </DialogContentText>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={submitContractor}
            color="primary"
            disabled={
              !contractorSelected ||
              !formulaSelected ||
              (showFixedAmount() && (!fixedAmount || fixedAmount <= 0)) ||
              (showExtraHours() && (!extraHours || extraHours <= 0)) ||
              (showHours() && (!hours || hours <= 0))
            }
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RepairContractorDialog;
