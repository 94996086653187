/* eslint-disable no-debugger */
import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Typography from '@material-ui/core/Typography';
import CustomReference from 'components/CustomReference';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify } from 'react-admin';

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  inputDate: {
    margin: '8px 0px 15px',
  },
  inputAddress: {
    margin: '15px 0px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  inputMarginRight: {
    margin: '15px 15px 15px 0px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
    minWidth: '1000px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  leftPanel: {
    width: '70%',
  },
  rightPanel: {
    marginLeft: '20px',
    width: '30%',
    border: '1.5px solid lightgray',
    padding: '15px',
    borderRadius: '10px',
  },
  grayBox: {
    border: '1.5px solid lightgray',
    borderRadius: '10px',
    padding: '25px',
    marginBottom: '5px',
  },
  flex: {
    display: 'flex',
    maxWidth: '1500px',
    width: '1500px',
  },
  button: {
    marginLeft: 'auto',
  },
  flexRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100% !important',
    margin: '10px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldInput: {
    fontWeight: 'bold',
    width: '100%',
  },
  floorBoard: {
    backgroundColor: '#efefef',
    borderRadius: '10px',
    padding: '10px',
  },
  addFloor: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '10px',
  },
  pointer: {
    cursor: 'pointer',
  },
  hide: {
    display: 'none',
  },
  spinner: {
    color: 'white',
  },
});

const sum = function (items, prop) {
  return items.reduce(function (a, b) {
    return a + b[prop];
  }, 0);
};

export const OverrideCountDialog = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [fields, setFields] = useState<any>([]);
  const [newFields, setNewFields] = useState<any>([]);
  const [floors, setFloors] = useState<any>([]);
  const [notes, setNotes] = useState('');
  const classes = useStyles();

  const onChangeField = (boardTypeId, floor) => event => {
    const newFieldsOverride = { ...newFields };
    newFieldsOverride[floor][boardTypeId] = event.target.value;
    setNewFields(newFieldsOverride);
  };

  useEffect(() => {
    const floorsNumber = [
      ...new Set(props.boardPurchaseFloors.map((item: any) => item.floor)),
    ];
    const newFloors: any = floorsNumber.map(floorNumber => ({
      name: floorNumber,
      boards: getBoardsPerFloor(floorNumber),
    }));
    const newFieldsOverride = {};
    const newFieldsOverrided = {};
    newFloors.map(floorItem => {
      newFieldsOverride[floorItem.name] = {};
      newFieldsOverrided[floorItem.name] = {};
      floorItem.boards.map(boardItem => {
        const boardPurchaseFloor = props.boardPurchaseFloors.find(
          boardPurchaseFloorItem =>
            boardPurchaseFloorItem.floor === parseInt(floorItem.name) &&
            boardPurchaseFloorItem.boardTypeId === boardItem.boardTypeId,
        );
        newFieldsOverrided[floorItem.name][boardItem.boardTypeId] =
          boardPurchaseFloor?.quantity || 0;
        newFieldsOverride[floorItem.name][boardItem.boardTypeId] = 0;
      });
    });
    setFields(newFieldsOverrided);
    setNewFields(newFieldsOverride);
    const floorsSorted = newFloors.sort((a, b) => (a.name < b.name ? -1 : 1));
    setFloors(floorsSorted);
    setNotes(props.boardPurchase.notesForOverride);
  }, [props.boardPurchase]);

  const onConfirm = async () => {
    const promises: any = [];
    Object.keys(newFields).forEach(floor => {
      Object.keys(newFields[floor]).forEach(boardTypeId => {
        const boardPurchase = props.boardPurchaseFloors.find(
          boardPurchaseFloor =>
            boardPurchaseFloor.boardTypeId === boardTypeId &&
            boardPurchaseFloor.floor === parseInt(floor),
        );
        const params = {
          id: boardPurchase.id,
          data: {
            quantity: parseInt(newFields[floor][boardTypeId]),
          },
          previousData: boardPurchase,
        };
        promises.push(dataProvider.update('board-purchase-floor', params));
      });
    });
    const params = {
      id: props.boardPurchase.id,
      data: { notesForOverride: notes },
      previousData: props.boardPurchas,
    };
    promises.push(dataProvider.update('board-purchase', params));
    if (props.job.pendingReview) {
      const paramsJob = {
        id: props.job.id,
        data: { pendingReview: false },
        previousData: props.job,
      };
      promises.push(dataProvider.update('job', paramsJob));
    }
    try {
      await Promise.all(promises);
      notify('Board count overridden successfully!');
      props.history.push('/job-master-sheet');
      props.handleClose();
    } catch {
      notify(`We have a problem with board count override!`, 'warning');
    }
  };

  const selectedPurchaseOrder = props.selectedPurchaseOrder;

  const getBoardsPerFloor = floorNumber => {
    const ordered = props.boardPurchaseFloors.filter(
      boardPurchaseFloor =>
        boardPurchaseFloor.floor === floorNumber &&
        boardPurchaseFloor.boardPurchaseId === props.boardPurchase.id,
    );
    const receipt = props.boardReceiptFloors.filter(
      boardReceiptFloor =>
        boardReceiptFloor.floor === floorNumber &&
        boardReceiptFloor.boardPurchaseId === props.boardPurchase.id,
    );
    const boardsType = [
      ...new Set([...ordered.map((item: any) => item.boardTypeId)]),
    ];
    const floorsBoards = boardsType.map(boardTypeId => ({
      boardTypeId,
      quantityOrdered: sum(
        ordered.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantityOrdered',
      ),
      quantityReceipt: sum(
        receipt.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
    }));

    return floorsBoards;
  };

  const sumFields = () => {
    let sum = 0;
    Object.keys(fields).map(floor => {
      Object.keys(fields[floor]).forEach(boardType => {
        sum += parseInt(fields[floor][boardType]);
      });
    });
    return sum;
  };

  const sumNewFields = () => {
    let sum = 0;
    Object.keys(newFields).map(floor => {
      Object.keys(newFields[floor]).forEach(boardType => {
        sum += parseInt(newFields[floor][boardType]);
      });
    });
    return sum;
  };

  const sumFloorsReceipt = () => {
    let sum = 0;
    floors.forEach(floorItems => {
      if (floorItems && floorItems.boards) {
        floorItems.boards.forEach(floorItem => {
          sum += floorItem.quantityReceipt;
        });
      }
    });
    return sum;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogContent>
        <Fragment>
          {floors.map((floor, floorIndex) => (
            <Box
              key={floorIndex}
              className={classes.floorBoard}
              display="flex"
              width="calc(100% - 20px)"
              flexDirection="column"
              mt={1}
            >
              <Box display="flex" width="100%" alignItems="center">
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Floor #{floor.name}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    PO
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Field Count
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Delta
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Current Quantity
                  </Typography>
                </Box>
                {!props.disabled && (
                  <Box width="100%">
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.bold}
                    >
                      Override
                    </Typography>
                  </Box>
                )}
              </Box>
              {floor.boards.map((board, boardIndex) => (
                <Box
                  display="flex"
                  width="100%"
                  key={boardIndex}
                  alignItems="center"
                >
                  <CustomReference
                    value={board.boardTypeId || 0}
                    label="Board Type"
                    resource="board-type"
                    disabled={selectedPurchaseOrder !== null}
                    customItemName="shortName"
                    sort={{ field: 'shortName', order: 'ASC' }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    disabled
                    value={board.quantityOrdered}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    disabled
                    value={board.quantityReceipt || 0}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    value={
                      (parseInt(board.quantityReceipt) || 0) -
                      (parseInt(board.quantityOrdered) || 0)
                    }
                    type="number"
                    disabled
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    value={fields[floor.name][board.boardTypeId]}
                    type="number"
                    disabled
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  {!props.disabled && (
                    <TextField
                      label="Quantity"
                      fullWidth
                      className={classes.inputNoMarginRight}
                      value={newFields[floor.name][board.boardTypeId]}
                      type="number"
                      disabled={props.disabled}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={onChangeField(board.boardTypeId, floor.name)}
                    />
                  )}
                </Box>
              ))}
            </Box>
          ))}
          <Box
            className={classes.floorBoard}
            display="flex"
            width="calc(100% - 20px)"
            mt={1}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            ></Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total: {sum(props.boardPurchaseFloors, 'quantityOrdered')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total: {sumFloorsReceipt()}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total:{' '}
              {sumFloorsReceipt() -
                sum(props.boardPurchaseFloors, 'quantityOrdered')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total: {sumFields()}
            </Typography>
            {!props.disabled && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.boldInput}
              >
                Total: {sumNewFields()}
              </Typography>
            )}
          </Box>
          <Box mt={2}>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              disabled={props.disabled}
              className={classes.input}
              value={notes}
              onChange={event => setNotes(event.target.value)}
            />
          </Box>
        </Fragment>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          {!props.disabled && (
            <Button variant="contained" onClick={onConfirm} color="primary">
              Save
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OverrideCountDialog;
