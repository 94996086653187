import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
import { useDataProvider, GetListResult, useNotify } from 'react-admin';
import { ContractorDto, JobPhaseBumpDto } from '@vatos-pas/common';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { BUMP_PHASES } from './mobile/master-sheet-timeline';

const convertFilters = (phaseName: string, regionId: string) => {
  const filter: {
    archived: boolean;
    active: boolean;
    suspended: boolean;
    'contractorRegions.regionId': string;
    hangerAvailable?: boolean;
    finisherAvailable?: boolean;
    sprayerAvailable?: boolean;
    bumperAvailable?: boolean;
  } = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  const bumpPhases = ['Bump1', 'Bump2', 'Bump3', 'Bump4', 'Bump5'];

  if (phaseName === 'Hanging' || phaseName === 'Garage') {
    filter.hangerAvailable = true;
  } else if (phaseName === 'Finishing') {
    filter.finisherAvailable = true;
  } else if (phaseName === 'Spraying') {
    filter.sprayerAvailable = true;
  } else if (bumpPhases.includes(phaseName)) {
    filter.bumperAvailable = true;
  }

  return filter;
};

export const ContractorChangeDialog = (props: any) => {
  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [contractorSelected, setContractorSelected] = useState();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const phaseType = props.job.phases.find(
    (phase: any) => phase.id === props.selectedJobPhase?.phaseId,
  )?.phaseType;

  const onChangeContractor = (event: any) => {
    setContractorSelected(event.target.value);
  };

  const getContractors = async () => {
    const contractorData: GetListResult<ContractorDto> =
      await dataProvider.getList('contractor', {
        filter: {
          ...convertFilters(phaseType, props.regionId),
          $join: [{ field: 'contractorRegions' }],
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' },
      });
    if (contractorData.data) {
      setContractors(contractorData.data);
    } else {
      throw new Error('Job phases not found');
    }
  };

  useEffect(() => {
    if (props.selectedJobPhase) {
      getContractors();
    }
  }, [props.selectedJobPhase]);

  const onConfirmContractor = async () => {
    try {
      const isBump = BUMP_PHASES.includes(phaseType);

      if (isBump && props.job.subdivision.reqBumpoutTimesheet) {
        const jobPhaseBump = await dataProvider.getList<JobPhaseBumpDto>(
          'job-phase-bump',
          {
            filter: {
              jobPhaseId: props.selectedJobPhase.id,
            },
            pagination: { page: 1, perPage: 200 },
            sort: { field: 'id', order: 'ASC' },
          },
        );

        const params = {
          id: jobPhaseBump.data[0]?.id,
          data: {
            contractorId: contractorSelected,
          },
          previousData: props.selectedJobPhase,
        };
        await dataProvider.update('job-phase-bump', params);
      } else {
        const params = {
          id: props.selectedJobPhase.id,
          data: {
            contractorId: contractorSelected,
          },
          previousData: props.selectedJobPhase,
        };
        await dataProvider.update('job-phase', params);
      }

      notify('Contractor Assigned!');
      props.handleClose();
      props.refreshJobPhases();
    } catch (error) {
      notify(error.message, 'warning');
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Change Contractor</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box display="flex">
            <Select
              onChange={onChangeContractor}
              labelId={`custom-reference-contractor`}
              id={`reference-contractor`}
              fullWidth
              value={contractorSelected}
              disabled={props.isLaborCoordinator}
            >
              {contractors.map((item: ContractorDto) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  data-item={item.id}
                  data-name={item.name}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </DialogContentText>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={onConfirmContractor}
            color="primary"
            disabled={!contractorSelected}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContractorChangeDialog;
