enum WarrantyStatus {
  Production = 'Production',
  Warranty = 'Warranty',
}

const WarrantyStatusChoices = [
  {
    id: WarrantyStatus.Production,
    name: WarrantyStatus.Production,
  },
  {
    id: WarrantyStatus.Warranty,
    name: WarrantyStatus.Warranty,
  },
];

export { WarrantyStatus, WarrantyStatusChoices };
