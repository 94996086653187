import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';

const idProperties = [
  'id',
  'approvingUserId',
  'boardPurchaseId',
  'boardPurchaseFloorId',
  'boardReceiptFloorId',
  'boardTypeId',
  'builderId',
  'buildingId',
  'contractorId',
  'contractorRegionId',
  'jobId',
  'jobPhaseId',
  'jobPhasePhotoId',
  'materialId',
  'modelId',
  'phaseId',
  'previousBumpContractorId',
  'regionId',
  'roleId',
  'subdivisionId',
  'supervisorUserId',
  'supplierId',
  'supplierRegionId',
  'userId',
  'userRegionId',
  'userRoleId',
];

const JobPhaseSprayReadyHourEnum = {
  8: '8am',
  9: '9am',
  10: '10am',
  11: '11am',
  12: '12pm',
  13: '1pm',
  14: '2pm',
};

export const exporter = list => items => {
  const itemsForExport = items.map((item: any) => {
    Object.keys(item).map((key: string) => {
      if (item[key] && typeof item[key] === 'object' && item[key]?.id) {
        delete item[key].id;
      }
    });

    for (const fkIdProperty of idProperties) {
      if (fkIdProperty in item) {
        delete item[fkIdProperty];
      }
    }

    if (item.userRegions) {
      item.regions = item.userRegions
        .map((region: any) => region.region.name)
        .join(', ');
      delete item.userRegions;
    }
    if (item.userRoles) {
      item.roles = item.userRoles.map((role: any) => role.role.name).join(', ');
      delete item.userRoles;
    }
    if (item.supplierRegions) {
      item.regions = item.supplierRegions
        .map((region: any) => region.region.name)
        .join(', ');
      delete item.supplierRegions;
    }
    if (item.contractorRegions) {
      item.regions = item.contractorRegions
        .map((region: any) => region.region.name)
        .join(', ');
      delete item.contractorRegions;
    }

    if (list === 'rm-master-sheet') {
      if (item.jobPhaseReadyHour) {
        item.sprayReadyHour =
          JobPhaseSprayReadyHourEnum[item.jobPhaseReadyHour];
      } else {
        item.sprayReadyHour = '';
      }
      delete item.jobPhaseReadyHour;
    }

    if (list === 'pre-rock-sheet') {
      delete item.jobNotes;
    }

    if (list === 'routes') {
      delete item['subdivisions'];
    }

    if (list === 'master-sheet') {
      item.payableBoards = item.pendingReview
        ? 'Pending review'
        : item.payableBoardsOrderedTotal;
      item.payableOverride = item.pendingReview
        ? 'Pending review'
        : item.payableBoardsTotal;
      delete item.payableBoardsOrderedTotal;
      delete item.payableBoardsTotal;
    }

    return item;
  });

  jsonExport(itemsForExport, (_err, csv) => {
    downloadCSV(csv, list);
  });
};
