import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import { RegionTimeWindowTableDataRow } from './types';
import { RegionTimeWindowTableContainer } from './RegionTimeWindowTableContainer';
import { RegionTimeWindowTableRow } from './RegionTimeWindowTableRow';
import { DEFAULT_COLUMN_LENGTH } from './constants';

type RegionTimeWindowListProps = {
  rows: RegionTimeWindowTableDataRow[] | null;
  columns: string[];
  regionId: string | undefined;
};

export const RegionTimeWindowList = ({
  rows,
  columns,
  regionId,
}: RegionTimeWindowListProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <RegionTimeWindowTableContainer columns={columns}>
        {rows?.length ? (
          rows.map(row => (
            <TableRow key={row.id}>
              <RegionTimeWindowTableRow
                regionId={regionId}
                row={row}
                readOnly
              />
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length + DEFAULT_COLUMN_LENGTH}
              className={classes.tableCellCenter}
            >
              No H/O region time window found
            </TableCell>
          </TableRow>
        )}
      </RegionTimeWindowTableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
