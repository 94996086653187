/* eslint-disable react/jsx-key */
import * as React from 'react';
import { Route } from 'react-router-dom';
import RecoveryPassword from 'modules/recoveryPassword/RecoveryPassword';
import ConfirmRecovery from 'modules/recoveryPassword/ConfirmRecovery';
import { CustomRoute } from 'react-admin';

export default [
  <Route<CustomRoute>
    exact
    path="/recovery"
    component={RecoveryPassword}
    noLayout
  />,
  <Route<CustomRoute>
    exact
    path="/confirmRecovery/:token"
    component={ConfirmRecovery}
    noLayout
  />,
];
