import { BoardDto, MaterialDto } from './types';

export const getBoardsCount = (floors?: BoardDto[]) => {
  if (!floors) return 0;

  let count = 0;
  floors.forEach(floor => {
    count += floor.quantityOrdered;
  });
  return count;
};

const getMaterialsFromBoards = (
  boardPurchaseFloors?: BoardDto[],
  materials?: MaterialDto[],
) => {
  if (!materials) return;

  const boardsCount = getBoardsCount(boardPurchaseFloors);
  const newMaterials = [...materials];
  const [firstMaterial, secondMaterial] = newMaterials;

  const newFirstMaterial = { ...firstMaterial };
  const newSecondMaterial = { ...secondMaterial };

  newFirstMaterial.amount =
    Math.round(boardsCount / 7) > 1 ? Math.round(boardsCount / 7) : 1;

  if (newSecondMaterial?.amount) {
    newSecondMaterial.amount =
      Math.round(boardsCount / 15 + 2) > 2
        ? Math.round(boardsCount / 15 + 2)
        : 1;
  }

  newMaterials[0] = newFirstMaterial;
  newMaterials[1] = newSecondMaterial;

  return newMaterials;
};

const getBuildingData = (
  subdivision: string,
  buildingName: string,
  buildingId: string,
  toggleBuilding: boolean,
) => {
  const hasBuildingNameOrBuildingId = buildingName || buildingId;

  if (!hasBuildingNameOrBuildingId) return null;

  if (!toggleBuilding) return buildingId;

  return {
    number: buildingName,
    subdivisionId: subdivision,
  };
};

const getFilterSubdivision = (builder: string) => {
  if (builder) {
    return { builderId: builder, active: true };
  }
  return null;
};

const getFilterModel = (subDivision: string) => {
  if (subDivision) {
    return { subdivisionId: subDivision, active: true };
  }
  return null;
};

const getFilterBuilding = (subDivision: string) => {
  if (subDivision) {
    return { subdivisionId: subDivision };
  }
  return null;
};

const getFieldValidation = (errors: Record<string, string>, name: string) => {
  if (!Object.keys(errors).length || !errors[name]) {
    return;
  }
  return errors[name];
};

export {
  getMaterialsFromBoards,
  getFilterSubdivision,
  getFilterModel,
  getFilterBuilding,
  getFieldValidation,
  getBuildingData,
};
