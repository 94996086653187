import React, { FC, Fragment, ReactElement, cloneElement } from 'react';
import {
  List,
  TextField,
  Filter,
  TextInput,
  BooleanField,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
  ExportButton,
  FilterProps,
  ListProps,
  ToolbarProps,
  ExportButtonProps,
  ShowButton,
  EditButton,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { SubdivisionDto } from '@vatos-pas/common';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_MODEL } from 'providers/permissions';

const ModelsFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Subdivision"
      source="subdivisionId"
      reference="subdivision"
      resettable
      alwaysOn
      data-cy="modelFilterSubdivision"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: { name: value },
          };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(subdivison: SubdivisionDto) => {
          return subdivison.name;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <TextInput
      label="Name"
      source="name||$cont"
      alwaysOn
      data-cy="modelFilterName"
    />
    <TextInput
      label="External ID"
      source="externalId||$cont"
      data-cy="modelFilterExternalId"
    />
    <BooleanInput
      label="Garage First"
      source="garageFirst||$eq"
      data-cy="modelFilterGarageFirst"
    />
    <BooleanInput
      label="In Building?"
      source="inBuilding||$eq"
      data-cy="modelFilterInBuilding"
    />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };


const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_MODEL) ? <CreateButton basePath={basePath} /> : <Fragment />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const ModelsList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('models')}
      filters={<ModelsFilter>{props.filters}</ModelsFilter>}
      filter={{ $join: [{ field: 'subdivision', select: ['id', 'name'] }] }}
      sort={{ field: 'subdivisionId', order: 'ASC' }}
      bulkActionButtons={<React.Fragment />}
      actions={<ListActions />}
      hasEdit={false}
    >
      <Datagrid rowClick={() => hasPermission(CAN_EDIT_MODEL) ? 'edit' : 'show'}>
        <TextField label="Subdivision" source="subdivision.name" />
        <TextField label="Name" source="name" />
        <TextField label="External ID" source="externalId" />
        <BooleanField label="Garage First" source="garageFirst" />
        <BooleanField label="In Building?" source="inBuilding" />
        {hasPermission(CAN_EDIT_MODEL) ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
};
