import { ModelsList, ModelsCreate, ModelsEdit, ModelsShow } from './views';

export default {
  show: ModelsShow,
  create: ModelsCreate,
  edit: ModelsEdit,
  list: ModelsList,
  name: 'model',
  label: 'Models',
  to: '/model',
};
