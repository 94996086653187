import React, { FC } from 'react';
import {
  EditProps,
  Show,
  TextField,
  SimpleShowLayout,
  NumberField,
  BooleanField,
  ShowActionsProps,
  sanitizeListRestProps,
  EditButton,
  TopToolbar,
} from 'react-admin';
import {
  ManyToManyReferenceContextProvider,
  ReferenceManyToManyField,
} from '@react-admin/ra-relationships';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_CONTRACTOR } from 'providers/permissions';

const ShowActions: FC<ShowActionsProps> = props => {
  const { className, basePath, data, ...rest } = props;
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {hasPermission(CAN_EDIT_CONTRACTOR) && (
        <EditButton basePath={basePath} record={data} />
      )}
    </TopToolbar>
  );
};

export const ContractorsShow: FC<EditProps> = props => {
  return (
    <Show actions={<ShowActions />} {...props}>
      <ManyToManyReferenceContextProvider>
        <SimpleShowLayout>
          <TextField fullWidth source="name" label="Name" />
          <TextField fullWidth source="corporation" label="Corporation" />
          <TextField fullWidth label="Payment Type" source="paymentType" />
          <NumberField fullWidth source="paymentRate" label="Payment Rate" />
          <TextField
            fullWidth
            label="Bumper Payment Type"
            source="bumperPaymentType"
          />
          <NumberField fullWidth source="bumperPaymentRate" />
          <BooleanField label="Hanger" source="hangerAvailable" />
          <BooleanField label="Finisher" source="finisherAvailable" />
          <BooleanField label="Sprayer" source="sprayerAvailable" />
          <BooleanField label="Bumper" source="bumperAvailable" />
          <BooleanField label="Specialist" source="specialistAvailable" />
          <ReferenceManyToManyField
            source="id"
            reference="region"
            through="contractor-region"
            using="contractorId,regionId"
            label="Regions"
            initialValue={[]}
          >
            <TextField source="name" />
          </ReferenceManyToManyField>
          <BooleanField source="active" />
          <BooleanField source="suspended" />
        </SimpleShowLayout>
      </ManyToManyReferenceContextProvider>
    </Show>
  );
};
