import React, { FC, ReactElement } from 'react';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  warning: {
    fontSize: '40px',
    textAlign: 'center',
    color: '#e6005a',
    marginTop: 20,
  },
  button: {
    backgroundColor: '#e6005a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e6005a',
      color: 'white',
    },
  },
  buttonGreen: {
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
      color: 'white',
    },
  },
  cancelButton: {
    color: '#e6005a',
  },
  normal: {},
});

type ConfirmDialogProps = {
  open: boolean;
  showWarning?: boolean;
  handleClose(): void;
  title: string;
  content?: string | ReactElement;
  onConfirm?(): void;
  greenWarning?: boolean;
  confirmLabel?: string;
  disabled?: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
} & DialogProps;

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  handleClose,
  showWarning = false,
  title,
  content,
  onConfirm,
  greenWarning = false,
  confirmLabel,
  align,
  disabled = false,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...rest}
    >
      {showWarning && (
        <Typography className={classes.warning}>Warning!</Typography>
      )}
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align={align}>
            {content}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
        >
          <Button
            className={classes.cancelButton}
            style={{ color: 'red' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {onConfirm && (
            <Button
              onClick={onConfirm}
              color="primary"
              autoFocus
              disabled={disabled}
              variant="contained"
              className={
                !showWarning
                  ? classes.normal
                  : greenWarning
                  ? classes.buttonGreen
                  : classes.button
              }
            >
              {confirmLabel ? confirmLabel : 'Confirm'}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
