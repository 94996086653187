/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { dataProvider } from 'providers/dataProvider';
import { useNotify } from 'react-admin';

export const MasterSheetRepairNotes = (props: any) => {
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');
  const notify = useNotify();

  const getNotes = async id => {
    try {
      const notesResponse: any = await dataProvider.getList('job-repair-note', {
        filter: { jobRepairId: id },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'createdAt', order: 'DESC' },
      });
      setNotes(notesResponse.data);
    } catch (err) {
      notify('Failed to get notes', 'error');
    }
  };

  useEffect(() => {
    if (props.selectedReceivable) {
      getNotes(props.selectedReceivable);
    }
  }, [props.selectedReceivable]);

  const submitNote = async () => {
    try {
      const newNote = await dataProvider.create('job-repair-note', {
        data: { note, jobRepairId: props.selectedReceivable },
      });
      if (notes.length === 0) {
        props.refresh();
        props.handleClose();
      } else {
        getNotes(props.selectedReceivable);
      }
      setNote('');
      setNotes([]);
    } catch (err) {
      notify('Failed to create a note', 'error');
    }
  };

  console.log(notes);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogContent>
        <Box width="100vw" pb={3} maxWidth="40vw">
          {props.canAddNotes && (
            <Box>
              <TextField
                label="Notes"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                inputProps={{
                  maxLength: 999,
                }}
                value={note}
                onChange={event => setNote(event.target.value)}
              />
            </Box>
          )}
          <Box display="flex" justifyContent="space-between" my={3}>
            {props.canAddNotes && (
              <Button
                disabled={note.length <= 0}
                onClick={submitNote}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            )}
            <Button
              onClick={() => {
                props.handleClose();
                setNote('');
                setNotes([]);
              }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </Box>
          <Box>
            <Box borderTop={1} pt={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Notes History
              </Typography>
            </Box>
            {notes.map((item: any, index) => (
              <Box
                key={item.id}
                mb={2}
                borderTop={index === 0 ? '' : 1}
                borderColor="lightgray"
                pt={2}
              >
                <Box>
                  <Box>
                    <Typography>{`${item.user.firstName} ${
                      item.user.lastName
                    } - ${new Date(item.createdAt).toLocaleTimeString(
                      'en-US',
                    )} ${new Date(item.createdAt).toLocaleDateString(
                      'en-US',
                    )}`}</Typography>
                  </Box>
                  <Box mt={2}>
                    <Typography>{item.note}</Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MasterSheetRepairNotes;
