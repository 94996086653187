import React, { useState, useEffect, FC, ChangeEvent, useMemo } from 'react';
import { useDataProvider, GetListResult, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import {
  ContractorDto,
  ContractorPaymentTypeEnum,
  RegionTimeWindowDto,
  RepairFormulaDto,
  RepairFormulaInterface,
  RepairPaymentTypeEnum,
  RepairTypeEnum,
} from '@vatos-pas/common';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import CustomSelect from 'components/CustomSelect';
import { useStyles } from '../views/master-sheet-styles';
import { Repair } from '.';
import { Job } from '../views';
import { Show } from 'components/Show';
import { getFormattedTimeWindow } from '../utils/getFormattedTimeWindow';

type Filter = {
  archived: boolean;
  active: boolean;
  suspended: boolean;
  'contractorRegions.regionId': string;
  hangerAvailable?: boolean;
  finisherAvailable?: boolean;
  sprayerAvailable?: boolean;
  bumperAvailable?: boolean;
  specialistAvailable?: boolean;
  bumperPaymentType?: string;
  paymentType?: string;
};

const convertFilters = (
  resourceType: string,
  regionId: string,
  perBoard: boolean,
  perHour: boolean,
) => {
  const filter: Filter = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  if (!perBoard && perHour) {
    filter['paymentType||$eq'] = 'PerHour';
  }
  if (perBoard && !perHour) {
    filter['paymentType||$eq'] = 'PerBoard';
  }

  if (resourceType === 'Hanger') {
    filter.hangerAvailable = true;
  } else if (resourceType === 'Finisher') {
    filter.finisherAvailable = true;
  } else if (resourceType === 'Sprayer') {
    filter.sprayerAvailable = true;
  } else if (resourceType === 'Specialist') {
    filter.specialistAvailable = true;
  } else if (resourceType === 'Bumper') {
    delete filter['paymentType||$eq'];
    if (!perBoard && perHour) filter['bumperPaymentType||$eq'] = 'PerHour';
    if (perBoard && !perHour) filter['bumperPaymentType||$eq'] = 'PerBoard';
    filter.bumperAvailable = true;
  }

  return filter;
};

type Option = { name?: string; value: unknown };

type Resource = {
  name: string;
  id: string;
};

type RepairPayment = {
  id: '';
  repairResourceType: string;
  repairFormulaId: string;
  contractorId: string;
  contractor?: { name: string };
  estimatedHours?: number;
  fixedAmount?: number;
  extraHours?: number;
  repairPaymentDate?: string;
  regionTimeWindowId?: string;
  windowStartTime?: string | null;
  windowEndTime?: string | null;
};

type RepairContractorDialogProps = {
  theJob: Job;
  repair?: Repair;
  open: boolean;
  handleClose(): void;
  handleConfirm(payment: RepairPayment): void;
};

export const RepairContractorDialog: FC<RepairContractorDialogProps> = ({
  theJob,
  repair,
  open,
  handleClose,
  handleConfirm,
}) => {
  const [repairFormulas, setRepairFormulas] = useState<any[]>([]);
  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [regionTimeWindows, setRegionTimeWindows] = useState<
    RegionTimeWindowDto[] | null
  >(null);
  const [selectedTimeWindow, setSelectedTimeWindow] = useState<string>();
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedContractorId, setselectedContractorId] = useState('');
  const [resourceType, setResourceType] = useState('');
  const [loadingContractors, setLoadingContractors] = useState(false);
  const [hours, setHours] = useState('');
  const [contractorDate, setContractorDate] = useState<any>(null);
  const [extraHours, setExtraHours] = useState('');
  const [fixedAmount, setFixedAmount] = useState('');
  const [perBoard, setPerBoard] = useState(false);
  const [perHour, setPerHour] = useState(false);
  const [additionalBumperFilter, setAdditionalBumperFilter] = useState('');
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const onChangeContractor = (event: ChangeEvent<Option>) =>
    setselectedContractorId(String(event.target.value));

  useEffect(() => {
    const getContractors = async () => {
      try {
        setLoadingContractors(true);

        const { data }: GetListResult<ContractorDto> =
          await dataProvider.getList('contractor', {
            filter: {
              ...convertFilters(
                resourceType,
                theJob.subdivision.regionId,
                perBoard,
                perHour,
              ),
              $join: [{ field: 'contractorRegions' }],
            },
            pagination: { page: 1, perPage: 200 },
            sort: { field: 'name', order: 'ASC' },
          });

        if (!data) throw new Error('Job phases not found');

        let newContractors = [...data];

        const filterByPaymentType =
          (type: ContractorPaymentTypeEnum) => (contractor: ContractorDto) => {
            const { bumperAvailable, bumperPaymentType, paymentType } =
              contractor;
            if (bumperAvailable) return bumperPaymentType === type;
            return paymentType === type;
          };

        if (additionalBumperFilter) {
          if (additionalBumperFilter === 'perBoard') {
            newContractors = newContractors.filter(
              filterByPaymentType(ContractorPaymentTypeEnum.PerBoard),
            );
          }
          if (additionalBumperFilter === 'perHour') {
            newContractors = newContractors.filter(
              filterByPaymentType(ContractorPaymentTypeEnum.PerHour),
            );
          }
        }

        setContractors(newContractors);
      } catch (error) {
        notify('Failed to load contractors, try again', 'error');
      } finally {
        setLoadingContractors(false);
      }
    };

    if (resourceType) {
      getContractors();
    }
  }, [resourceType, perHour, perBoard, repair, additionalBumperFilter]);

  useEffect(() => {
    const getFormulas = async () => {
      try {
        const { data: list } =
          await dataProvider.getList<RepairFormulaInterface>('repair-formula', {
            filter: { active: true },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'createdAt', order: 'ASC' },
          });

        setRepairFormulas(list);
      } catch (error) {
        notify('Failed to load formulas, try again', 'error');
      }
    };
    getFormulas();
  }, []);

  useEffect(() => {
    const loadResources = async () => {
      const newResources: Resource[] = [];

      repairFormulas
        .filter(
          repairFormulaItem => repairFormulaItem.repairId === repair?.repairId,
        )
        .forEach(item => {
          const { repairResourceType } = item;

          const hasResourceWithType =
            newResources.filter(itemFind => itemFind.id === repairResourceType)
              .length === 0;

          if (hasResourceWithType) {
            newResources.push({
              name: repairResourceType,
              id: repairResourceType,
            });
          }
        });

      setResources(newResources);

      const perBoard = repairFormulas
        .filter(item => item.repairId === repair?.repairId)
        .find(item => {
          if (item.repairResourceType === 'Bumper')
            return item.repairBumperPaymentType === 'PerBoard';
          return item.repairPaymentType === 'PerBoard';
        });

      const perHour = repairFormulas
        .filter(item => item.repairId === repair?.repairId)
        .find(item => {
          if (item.repairResourceType === 'Bumper')
            return item.repairBumperPaymentType === 'PerHour';
          return item.repairPaymentType === 'PerHour';
        });

      const hasPerBoard = perBoard !== undefined;
      const hasPerHour = perHour !== undefined;

      setPerBoard(hasPerBoard);
      setPerHour(hasPerHour);

      if (hasPerBoard) setAdditionalBumperFilter('perBoard');
      if (hasPerHour) setAdditionalBumperFilter('perHour');
      if (hasPerBoard && hasPerHour) setAdditionalBumperFilter('*');
    };
    loadResources();
  }, [repairFormulas, repair]);

  useEffect(() => {
    const getRegionTimeWindows = async () => {
      try {
        const { data } = await dataProvider.getList<RegionTimeWindowDto>(
          'region-time-window',
          {
            filter: {
              regionId: theJob.subdivision.regionId,
            },
            pagination: { page: 1, perPage: 200 },
            sort: { field: 'windowStartTime', order: 'ASC' },
          },
        );

        setRegionTimeWindows(data);
      } catch (error) {
        notify(
          error?.message ?? 'Error while loading region time windows',
          'warning',
        );
      }
    };

    getRegionTimeWindows();
  }, []);

  const contractor = useMemo(
    () => contractors.find(({ id }) => id === selectedContractorId),
    [selectedContractorId],
  );

  const selectedFormula = useMemo<RepairFormulaDto | undefined>(() => {
    if (!contractor) return undefined;

    return repairFormulas.find(item => {
      const { repairResourceType, repairId, repairBumperPaymentType } = item;
      if (resourceType === 'Bumper') {
        return (
          repairResourceType === resourceType &&
          repairId === repair?.repairId &&
          repairBumperPaymentType ===
            (contractor.bumperPaymentType as keyof typeof RepairPaymentTypeEnum)
        );
      }
      return (
        repairResourceType === resourceType && repairId === repair?.repairId
      );
    });
  }, [contractor, resourceType, repair, repairFormulas]);

  const isBumpoutTimesheetFlow = theJob.subdivision.reqBumpoutTimesheet;

  const showTimeWindow =
    isBumpoutTimesheetFlow &&
    repair?.repair.repairType === RepairTypeEnum.HOService &&
    resourceType === 'Bumper';

  const showHours = useMemo(() => {
    // It doesn't matter the repair payment type
    // If the resource type is `Bumper` the user must add the estimated hours
    if (isBumpoutTimesheetFlow && resourceType === 'Bumper') return true;

    if (!selectedFormula || !selectedContractorId) return false;
    return selectedFormula.repairPaymentType === 'PerHour';
  }, [selectedFormula, selectedContractorId, resourceType]);

  const showExtraHours = useMemo(() => {
    if (!selectedFormula || !selectedContractorId) return false;
    return selectedFormula.repairPaymentType === 'ExtraHours';
  }, [selectedFormula, selectedContractorId]);

  const showFixedAmount = useMemo(() => {
    if (!selectedFormula || !selectedContractorId) return false;
    return selectedFormula.repairPaymentType === 'SetPrice';
  }, [selectedFormula, selectedContractorId]);

  const submitContractor = () => {
    if (!contractor) return;
    if (!selectedFormula) return;

    const repairPayment: RepairPayment = {
      id: '',
      contractorId: contractor.id,
      repairFormulaId: selectedFormula.id,
      repairResourceType: resourceType,
      contractor,
      repairPaymentDate: contractorDate,
    };

    if (showHours) repairPayment.estimatedHours = Number(hours);
    if (showFixedAmount) repairPayment.fixedAmount = Number(fixedAmount);
    if (showExtraHours) repairPayment.extraHours = Number(extraHours);
    if (showTimeWindow) {
      const regionTimeWindow = regionTimeWindows?.find(
        timeWindow => timeWindow.id === selectedTimeWindow,
      );

      repairPayment.regionTimeWindowId = selectedTimeWindow;
      repairPayment.windowStartTime = regionTimeWindow?.windowStartTime;
      repairPayment.windowEndTime = regionTimeWindow?.windowEndTime;
    }

    handleConfirm(repairPayment);
    handleClose();
    notify('Contractor Added!');
  };

  const isFixedAmountValid =
    !Number.isNaN(fixedAmount) && Number(fixedAmount) > 0;

  const isExtraHoursValid = !Number.isNaN(extraHours) && Number(extraHours) > 0;

  const isHoursValid = !Number.isNaN(hours) && Number(hours) > 0;

  const disabled =
    !contractorDate ||
    !selectedContractorId ||
    !selectedFormula ||
    (showFixedAmount && !isFixedAmountValid) ||
    (showExtraHours && !isExtraHoursValid) ||
    (showHours && !isHoursValid) ||
    (showTimeWindow && !selectedTimeWindow);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-ti9tle">Add Contractor</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box display="flex" flexDirection="column" gridGap={8}>
            <Box width="100%" mr={3}>
              <CustomSelect
                resource="resourceType"
                label="Select Resource Type"
                disabled={loadingContractors}
                options={resources}
                value={resourceType}
                onChange={event => {
                  if (event.target?.value)
                    setResourceType(String(event.target.value));
                }}
              />
            </Box>
            <Box width="100%">
              <label id="custom-reference-contractor">Contractor</label>
              <Select
                id="reference-contractor"
                labelId="custom-reference-contractor"
                onChange={onChangeContractor}
                fullWidth
                value={selectedContractorId}
                disabled={!resourceType}
              >
                {contractors.map((item: ContractorDto) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    data-item={item.id}
                    data-name={item.name}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {showHours && (
              <TextField
                label={isBumpoutTimesheetFlow ? 'Estimated Hours' : 'Hours'}
                fullWidth
                InputProps={{
                  inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                }}
                type="number"
                className={classes.input}
                value={hours}
                onChange={event => setHours(event?.target.value)}
                error={Number.isNaN(hours) || Number(hours) < 0}
              />
            )}
            {showFixedAmount && (
              <TextField
                label="Fixed Amount $"
                fullWidth
                InputProps={{
                  inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                }}
                type="number"
                className={classes.input}
                value={fixedAmount}
                onChange={event => setFixedAmount(event?.target.value)}
                error={Number.isNaN(fixedAmount) || Number(fixedAmount) < 0}
              />
            )}
            {showExtraHours && (
              <TextField
                InputProps={{
                  inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                }}
                type="number"
                label="Extra Hours"
                fullWidth
                className={classes.input}
                value={extraHours}
                onChange={event => setExtraHours(event?.target.value)}
                error={Number.isNaN(extraHours) || Number(extraHours) < 0}
              />
            )}
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Repair Date"
              fullWidth
              // error={errors.repairDate}
              value={contractorDate}
              onChange={setContractorDate}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
            />
            <Show condition={showTimeWindow}>
              <Box width="100%" mr={3}>
                <CustomSelect
                  resource="regionTimeWindows"
                  label="Time Window"
                  options={regionTimeWindows?.map(timeWindow => ({
                    id: timeWindow.id,
                    name: getFormattedTimeWindow(
                      timeWindow.windowStartTime,
                      timeWindow.windowEndTime,
                    ),
                  }))}
                  value={selectedTimeWindow}
                  onChange={event => {
                    if (event.target?.value)
                      setSelectedTimeWindow(String(event.target.value));
                  }}
                />
              </Box>
            </Show>
          </Box>
        </DialogContentText>
        <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
          <Button
            color="default"
            variant="contained"
            className={classes.buttonRepairsCancel}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonRepairs}
            onClick={submitContractor}
            disabled={disabled}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RepairContractorDialog;
