import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import { PermissionsProvider } from 'providers/permissionsProvider';
import Button from '@material-ui/core/Button';
import { CAN_SEND_EMAIL_CLIENT } from 'providers/permissions';
import { Identifier } from 'react-admin';
import { Repair } from '.';

type EmailManagementDialogProps = {
  hasEmailScreenshot: boolean;
  emailManagementOpen: boolean;
  loadingBuilderSupervisorEmail: boolean;
  emailRepair: Repair | null;
  handleEmailClick: () => void;
  handleSendMail: (repairId: Identifier | undefined) => Promise<void>;
  handleClose: () => void;
};

export const EmailManagementDialog = ({
  hasEmailScreenshot,
  emailManagementOpen,
  loadingBuilderSupervisorEmail,
  emailRepair,
  handleEmailClick,
  handleSendMail,
  handleClose,
}: EmailManagementDialogProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <Dialog open={emailManagementOpen} onClose={handleClose}>
      <DialogTitle>Email Management</DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          style={{
            gap: 16,
          }}
        >
          <Button
            disabled={!hasEmailScreenshot}
            variant="contained"
            color="primary"
            onClick={handleEmailClick}
          >
            Screenshot
          </Button>
          {hasPermission(CAN_SEND_EMAIL_CLIENT) && (
            <Button
              variant="contained"
              disabled={loadingBuilderSupervisorEmail}
              startIcon={
                loadingBuilderSupervisorEmail && <CircularProgress size={16} />
              }
              color="primary"
              onClick={async () => await handleSendMail(emailRepair?.id)}
            >
              Send Mail
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
