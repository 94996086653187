import { FC } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useContainer } from 'unstated-next';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { MaterialDto } from '../../types';
import { INITIAL_DRAFT_PURCHASE_ORDER } from '../../constants';
import { JobContainer } from '../../providers/job';
import { Show } from 'components/Show';

const removeMaterial = (
  materialIndex: number,
  setDraftPurchaseOrder: React.Dispatch<
    React.SetStateAction<typeof INITIAL_DRAFT_PURCHASE_ORDER>
  >,
  materials?: MaterialDto[],
) => {
  if (!materials) return;

  const newMaterials = [...materials];
  newMaterials.splice(materialIndex, 1);
  setDraftPurchaseOrder(prevState => ({
    ...prevState,
    materials: newMaterials,
  }));
};

const getMaterialsValidation = (
  errors: Record<string, string>,
  index: number,
  name: string,
) => {
  if (
    !Object.keys(errors).length ||
    !errors['materials'] ||
    !errors['materials'][index] ||
    !errors['materials'][index][name]
  ) {
    return;
  }
  return errors['materials'][index][name];
};

export const PurchaseOrderMaterials: FC = () => {
  const {
    draftPurchaseOrder,
    setDraftPurchaseOrder,
    selectedPurchaseOrder,
    isEdit,
    isNew,
    errors,
    isDraft,
  } = useContainer(PurchaseOrderContainer);
  const { purchaseOrders } = useContainer(JobContainer);

  const classes = useStyles();

  const purchaseOrder =
    isNew || isDraft ? draftPurchaseOrder : selectedPurchaseOrder;

  return (
    <Box className={classes.floorBoard}>
      <Box display="flex" width="100%" mt={1} justifyContent="space-between">
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Materials
        </Typography>
        <Show condition={isNew || isDraft}>
          <AddCircleIcon
            className={classes.pointer}
            onClick={() => {
              if (!draftPurchaseOrder.materials) return;

              const newMaterials = [...draftPurchaseOrder.materials];
              newMaterials.push({
                name: '',
                unit: '',
                amount: 0,
              });
              setDraftPurchaseOrder(prevState => ({
                ...prevState,
                materials: newMaterials,
              }));
            }}
          />
        </Show>
      </Box>
      {purchaseOrder?.materials?.map((material, materialIndex) => (
        <Box
          display="flex"
          width="100%"
          key={materialIndex}
          alignItems="center"
        >
          <TextField
            label="Material Type"
            fullWidth
            disabled={isEdit && !isDraft}
            className={classes.input}
            value={material.name}
            onChange={event => {
              if (!draftPurchaseOrder.materials) return;

              const newMaterials = [...draftPurchaseOrder.materials];
              newMaterials[materialIndex].name = event.target.value;
              setDraftPurchaseOrder(prevState => ({
                ...prevState,
                materials: newMaterials,
              }));
            }}
            error={getMaterialsValidation(errors, materialIndex, 'name')}
            helperText={getMaterialsValidation(errors, materialIndex, 'name')}
          />
          <TextField
            label="Unit"
            value={material.unit}
            fullWidth
            disabled={isEdit && !isDraft}
            className={classes.inputNoMarginRight}
            onChange={event => {
              if (!draftPurchaseOrder.materials) return;

              const newMaterials = [...draftPurchaseOrder.materials];
              newMaterials[materialIndex].unit = event.target.value;
              setDraftPurchaseOrder(prevState => ({
                ...prevState,
                materials: newMaterials,
              }));
            }}
            error={getMaterialsValidation(errors, materialIndex, 'unit')}
            helperText={getMaterialsValidation(errors, materialIndex, 'unit')}
          />
          <TextField
            label="Amount"
            fullWidth
            type="number"
            value={material.amount}
            InputProps={{ inputProps: { min: 0 } }}
            disabled={isEdit && !isDraft}
            className={classes.inputNoMarginRight}
            onChange={event => {
              if (!draftPurchaseOrder.materials) return;

              const newMaterials = [...draftPurchaseOrder.materials];
              newMaterials[materialIndex].amount = parseInt(event.target.value);
              setDraftPurchaseOrder(prevState => ({
                ...prevState,
                materials: newMaterials,
              }));
            }}
            error={getMaterialsValidation(errors, materialIndex, 'amount')}
            helperText={getMaterialsValidation(errors, materialIndex, 'amount')}
          />
          <Show condition={isNew || isDraft}>
            <DeleteIcon
              onClick={() =>
                removeMaterial(
                  materialIndex,
                  setDraftPurchaseOrder,
                  draftPurchaseOrder.materials,
                )
              }
              className={
                materialIndex > 1 ||
                (purchaseOrders && purchaseOrders.length > 1)
                  ? classes.pointer
                  : classes.hide
              }
            />
          </Show>
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  floorBoard: {
    backgroundColor: '#efefef',
    borderRadius: '10px',
    padding: '10px',
  },
  bold: {
    fontWeight: 'bold',
  },
  pointer: {
    cursor: 'pointer',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  hide: {
    display: 'none',
  },
  input: {
    margin: '8px 0px',
  },
});
