/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { FC, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cancelButton: {
    color: '#e6005a',
  },
});

export type FormValues = {
  date: DateTime;
  description: string;
};

type ValidationFields = {
  date: DateTime | null;
  description: string;
};

type Errors = Record<keyof FormValues, string | undefined>;

const defaultErrors: Errors = { date: undefined, description: undefined };

const validateFields = (fields: ValidationFields): Errors => {
  const { date, description } = fields;
  const errors: Errors = { ...defaultErrors };

  if (date === null) {
    errors.date = 'Delivery Date is required';
  }

  const descriptionLength = String(description).trim().length;

  if (descriptionLength === 0) {
    errors.description = 'Delivery Notes is required';
  }

  if (descriptionLength > 2500) {
    errors.description = 'Delivery Notes with 2500 character limit reached';
  }

  return errors;
};

type PartialDeliveryModalProps = {
  open: boolean;
  disabled?: boolean;
  handleClose(): void;
  onConfirm(values: FormValues): void;
};

export const PartialDeliveryModal: FC<PartialDeliveryModalProps> = ({
  open,
  disabled = false,
  handleClose,
  onConfirm,
}) => {
  const styles = useStyles();

  const [date, setDate] = useState<DateTime | null>(null);
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState<Errors>({ ...defaultErrors });

  const handleSubmit = () => {
    const newErrors = validateFields({ date, description });
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some(error => !!error);
    if (hasErrors) return;

    onConfirm({ date: date!, description });
  };

  useEffect(() => {
    setErrors(validateFields({ date, description }));
  }, [date, description]);

  useEffect(() => {
    // Reset states
    if (open) {
      setErrors({ ...defaultErrors });
      setDate(null);
      setDescription('');
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Box>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Partial Delivery Date"
            fullWidth
            value={date}
            onChange={setDate}
            maxDate={new Date()}
            error={!!errors.date}
            helperText={errors.date}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </Box>
        <Box>
          <TextField
            label="Description"
            fullWidth
            value={description}
            multiline
            rows={5}
            error={!!errors.description}
            helperText={errors.description}
            onChange={event => setDescription(event.target.value)}
            InputProps={{
              inputProps: { min: 0, max: 2500, title: '', step: 'any' },
            }}
          />
        </Box>
        <Box width="100%" alignItems="center" justifyContent="center" mt="20px">
          <Typography align="center">
            You cannot change the date and notes after you confirm.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
          paddingTop="10px"
        >
          <Button onClick={handleClose} className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={disabled}>
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PartialDeliveryModal;
