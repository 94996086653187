import { Identifier } from 'react-admin';
import { axiosInstance } from 'services/http';
import {
  JobRepairBuilderSupervisorEmailDto,
  JobRepairDataDto,
} from '../../../../../common/dist';
import { maskDate } from './maskDate';

const getEmailContent = async (repairId: Identifier) => {
  const builderSupervisorEmail =
    await axiosInstance.get<JobRepairBuilderSupervisorEmailDto>(
      `/job-repair/${repairId}/builder-supervisor-email`,
    );

  return builderSupervisorEmail.data;
};

const getBuilderSupervisorEmailBody = (jobRepairData: JobRepairDataDto) => {
  return `You were added to the following Repair on ${maskDate(
    jobRepairData.jobRepairDate,
  )}. Please see below for the original information related to the repair.\r\n\r\nPlease approve the following PO for payment by replying to ${
    jobRepairData.supervisorEmail
  }\r\n\r\n\u2003\u2003\u2003${
    jobRepairData.builderName
  }\r\n\u2003\u2003\u2003${
    jobRepairData.subDivisionName
  }\r\n\u2003\u2003\u2003Lot: ${jobRepairData.lot}\r\n\u2003\u2003\u2003${
    jobRepairData?.jobBlock ? `Block: ${jobRepairData?.jobBlock}` : ''
  }\r\n\u2003\u2003\u2003${jobRepairData.address1}\r\n\u2003\u2003\u2003${
    jobRepairData?.address2 ? `${jobRepairData?.address2}` : ''
  }\r\n\u2003\u2003\u2003${jobRepairData?.city}, ${jobRepairData?.state} ${
    jobRepairData?.zip
  }\r\n\r\n\u2003\u2003\u2003$ ${jobRepairData.poAmount}\r\n\u2003\u2003\u2003${
    jobRepairData.repairDescription
  }\r\n\u2003\u2003\u2003\r\n\u2003\u2003\u2003Vatos Supervisor\r\n\u2003\u2003\u2003${
    jobRepairData?.supervisorName
  }\r\n\u2003\u2003\u2003${
    jobRepairData?.supervisorPhoneNumber
  }\r\n\u2003\u2003\u2003\r\n\u2003\u2003\u2003Vatos, Inc. 550 Poyner Dr, Longwood, FL 32750\r\n\u2003\u2003\u2003Phone: 407-365-3303 Fax: 407-365-3302
`;
};

const openEmailClient = (to: string, body: string, subject: string) => {
  window.location.href = `mailto:${to}?body=${encodeURI(
    body,
  )}&subject=${subject}`;
};

const sendMail = async (repairId: Identifier | undefined) => {
  if (!repairId) return;

  const builderSupervisorEmail = await getEmailContent(repairId);

  if (!builderSupervisorEmail?.jobRepairData) return;

  const builderSupervisorEmailBody = getBuilderSupervisorEmailBody(
    builderSupervisorEmail.jobRepairData,
  );

  const emailDestination = builderSupervisorEmail.to?.join(';');

  openEmailClient(
    emailDestination,
    builderSupervisorEmailBody,
    'New Repair Assignment',
  );
};

export { sendMail };
