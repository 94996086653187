import jwt_decode, { JwtPayload } from 'jwt-decode';
import { axiosInstance } from 'services/http';
import dataProvider from './dataProvider';
import { AppRole } from './roles';
import { checkPermissions } from './permissionsProvider';
import { CAN_SEE_MATERIALS_DELIVERY } from './permissions';

export interface AuthCredentialsInterface {
  username: string;
  password: string;
  assertRoles?: string[];
}

interface loginResponse {
  accessToken: string;
}

interface errorResponse {
  response: { status: number };
}

export const login = async (
  credentials: AuthCredentialsInterface,
): Promise<any> => {
  const { username, password } = credentials;
  const { data } = await axiosInstance.post<loginResponse>('auth/signin', {
    username,
    password,
  });
  localStorage.setItem('accessToken', data.accessToken);
  const { sub: userId = '' }: JwtPayload = jwt_decode(data.accessToken);
  localStorage.setItem('userId', userId);

  const { data: rolesList } = await dataProvider.getList('user-role', {
    filter: { userId },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'ASC' },
  });

  const rolesId = rolesList.map((role: any) => role.roleId);
  const { data: roles } = await dataProvider.getList('role', {
    filter: { 'id||$in||': rolesId.join() },
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'ASC' },
  });

  const roleEnums = roles.map((role: any) => role.name);
  localStorage.setItem('roles', JSON.stringify(roleEnums));

  const hasRole = (role: AppRole, includesAdmin = true) => {
    if (!includesAdmin) return roleEnums.includes(role);
    return (
      roleEnums.includes(AppRole.SuperAdmin) ||
      roleEnums.includes(AppRole.Admin) ||
      roleEnums.includes(role)
    );
  };

  const permissions = checkPermissions(hasRole);

  let redirectTo = '/job-master-sheet';

  if (permissions[CAN_SEE_MATERIALS_DELIVERY]) {
    redirectTo = '/material-delivery-sheet';
  }

  if (hasRole(AppRole.RegionalManager, false)) {
    redirectTo = '/job-pending-resource';
  }

  if (hasRole(AppRole.AccountingViewOnly, false)) {
    redirectTo = '/prod-pay-master-sheet';
  }

  if (hasRole(AppRole.Bumper, false)) {
    redirectTo = '/bump-resource-sheet';
  }

  return { redirectTo };
};

export default {
  // called when the user attempts to log in
  login,
  // called when the user clicks on the logout button
  logout: (): Promise<void> => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('roles');
    // accept all username/password combinations
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ response }: errorResponse): Promise<void> => {
    if (response && (response.status === 401 || response.status === 403)) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('roles');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: (): Promise<void> => {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return Promise.reject();
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: (): Promise<any> => {
    try {
      const rolesStr = localStorage.getItem('roles');
      if (!rolesStr) return Promise.resolve([{}]);
      const roles = JSON.parse(rolesStr) as string[];
      if (!Array.isArray(roles)) return Promise.resolve([{}]);
      const hasRole = (role: AppRole) =>
        roles.includes(AppRole.SuperAdmin) ||
        roles.includes(AppRole.Admin) ||
        roles.includes(role);

      return Promise.resolve([checkPermissions(hasRole)]);
    } catch (error) {
      return Promise.resolve([{}]);
    }
  },
};
