import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useContainer } from 'unstated-next';
import { JobCreateContainer } from '../providers/job-create';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';
import { ImportedJobContainer } from '../providers/imported-job';

type SubdivisionInputProps = {
  showImported: boolean;
};

export const CreateSubdivisionInput = ({
  showImported,
}: SubdivisionInputProps) => {
  const classes = useStyles();
  const {
    getSubdivisions,
    loadingSubdivisions,
    getModels,
    subdivisions,
    currentSubdivisionRequestId,
    fields,
    setFields,
    setSubdivisions,
    isDuplicatedJob,
  } = useContainer(JobCreateContainer);

  const { importedJob } = useContainer(ImportedJobContainer);

  const [selectedSubdivision, setSelectedSubdivision] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [inputValue, setInputValue] = useState('');

  const builderId =
    !isDuplicatedJob && importedJob?.builderId
      ? importedJob.builderId
      : fields.builder;
  const currentBuilderId = useRef(builderId);

  const jobSubdivision = subdivisions?.find(
    subdivision => subdivision.id === fields.subdivision,
  );
  useEffect(() => {
    setSelectedSubdivision(prevState => {
      if (!isDuplicatedJob && importedJob?.subdivisionId) {
        return {
          id: importedJob?.subdivisionId ?? '',
          name: importedJob?.subdivisionName ?? '',
        };
      }

      if (jobSubdivision) {
        return {
          id: jobSubdivision?.id,
          name: jobSubdivision?.name,
        };
      }

      return prevState;
    });
  }, [
    isDuplicatedJob,
    jobSubdivision?.id,
    importedJob?.subdivisionId,
    fields?.subdivision,
  ]);

  const handleChange = async (_event, value, _reason, _details) => {
    if (value?.id === fields['subdivision']) return;

    setFields(prevState => ({
      ...prevState,
      subdivision: value?.id ?? '',
      model: '',
    }));
    setSelectedSubdivision(value);
    getModels(value?.id ?? '');
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(name => {
        if (name === selectedSubdivision?.name) return;

        currentSubdivisionRequestId.current += 1;
        const requestId = currentSubdivisionRequestId.current;
        getSubdivisions(
          builderId,
          requestId,
          name,
          isDuplicatedJob && fields.subdivision
            ? fields.subdivision
            : importedJob?.subdivisionId,
        );
      }, 300),
    [
      builderId,
      fields?.subdivision,
      isDuplicatedJob,
      importedJob?.subdivisionId,
    ],
  );

  // Reset subdivision state whenever builder changes
  useEffect(() => {
    if (builderId !== currentBuilderId.current) {
      setSelectedSubdivision(null);
      setSubdivisions([]);
    }
  }, [builderId]);

  useEffect(() => {
    debouncedSearch(inputValue);

    // Clean up the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [builderId, inputValue, debouncedSearch]);

  return (
    <Autocomplete
      fullWidth
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => (option ? option?.name : '')}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.box}
          name="subdivision"
          label="Subdivision"
        />
      )}
      disabled={
        showImported ||
        (!!fields.subdivision && !!importedJob) ||
        !fields.builder
      }
      options={subdivisions?.map(item => ({
        name: item.name,
        id: item.id,
      }))}
      loading={loadingSubdivisions}
      onChange={handleChange}
      value={selectedSubdivision}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
    />
  );
};

const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});
