import React, { FC, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Email from '@material-ui/icons/Email';
import TextField from '@material-ui/core/TextField';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import {
  RepairTypeEnum,
  SubdivisionBuilderSupervisorDto,
} from '@vatos-pas/common';

import CustomReference from 'components/CustomReference';
import MasterSheetRepairPhotoUpload from './master-sheet-repair-photo-upload';
import RepairContractorDialog from './master-sheet-repair-contractor';
import { useStyles } from './master-sheet-styles';
import { Job } from './master-sheet-edit';
import { Repair } from 'modules/job-repair-sheet/views';

const SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE = [];
const MIN_SUBDIVISION_BUILDER_SUPERVISOR = 1;

type AddRepairFields = {
  repairPayments: any;
  poTotal: string;
  repairDate: DateTime | null;
  checkedNonBillable: boolean;
  selectedBuilderSupervisor: string[];
  repairType: RepairTypeEnum | null;
  shouldSelectSubdivisionBuilderSupervisor: boolean | null;
};

type AddRepairDialogProps = {
  theJob: Job;
  subdivisionBuilderSupervisors: SubdivisionBuilderSupervisorDto[] | null;
  onAdd: (repair: Repair, hasEmailScreenshot: boolean) => Promise<void>;
  onClose: () => void;
  open: boolean;
  isMobile?: boolean;
};

const validateFields = (fields: AddRepairFields): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};

  if (!fields.checkedNonBillable) {
    const poTotal = Number(fields.poTotal);

    if (Number.isNaN(poTotal)) {
      errors['poTotal'] = true;
    } else if (poTotal <= 0) {
      errors['poTotal'] = true;
    }
  }

  if (!fields.repairDate) {
    errors['repairDate'] = true;
  }

  if (!fields.repairType) {
    errors['repairType'] = true;
  }

  if (
    fields.shouldSelectSubdivisionBuilderSupervisor &&
    fields.selectedBuilderSupervisor.length === 0
  ) {
    errors[`selectedBuilderSupervisor`] = true;
  }
  return errors;
};

export const AddRepairDialog: FC<AddRepairDialogProps> = ({
  theJob,
  subdivisionBuilderSupervisors,
  onAdd,
  onClose,
  open,
  isMobile = false,
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [repairType, setRepairType] = useState<RepairTypeEnum | null>(null);
  const [repairDate, setRepairDate] = useState<DateTime | null>(
    DateTime.local(),
  );
  const [poTotal, setPoTotal] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [addContractorModalOpen, setAddContractorModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [photosStaged, setPhotosStaged] = useState<string[]>([]);
  const [photosStagedEmail, setPhotosStagedEmail] = useState<string[]>([]);
  const [repairPayments, setRepairPayments] = useState<any[]>([]);
  const [type, setType] = useState<string>('');
  const [checkedNonBillable, setCheckedNonBillable] = useState(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [selectedBuilderSupervisor, setSelectedBuilderSupervisor] = useState<
    string[]
  >(SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE);

  const shouldSelectSubdivisionBuilderSupervisor =
    subdivisionBuilderSupervisors &&
    subdivisionBuilderSupervisors?.length > MIN_SUBDIVISION_BUILDER_SUPERVISOR;

  const hasSubdivisionBuilderSupervisorErrors = Object.keys(errors).includes(
    'selectedBuilderSupervisor',
  );

  const handleSubdivisionBuilderSupervisorChange = (id: string) => {
    setSelectedBuilderSupervisor(prevState => {
      const newState = [...prevState];

      if (!newState.includes(id)) {
        newState.push(id);
      } else {
        return newState.filter(item => item !== id);
      }

      return newState;
    });
  };

  const createRepair = async () => {
    const newErrors = validateFields({
      repairPayments,
      poTotal,
      repairDate,
      checkedNonBillable,
      selectedBuilderSupervisor,
      repairType,
      shouldSelectSubdivisionBuilderSupervisor,
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;

    setLoading(true);
    const repairPayment = repairPayments.map(item => {
      delete item.resourceType;
      delete item.contractorName;
      return item;
    });
    const data = {
      description,
      repairDate,
      ...(!checkedNonBillable && { poTotal: parseFloat(poTotal) }),
      nonBillable: checkedNonBillable,
      jobId: theJob.job.id,
      repairId: repairType,
      repairPayment,
      ...(shouldSelectSubdivisionBuilderSupervisor &&
        selectedBuilderSupervisor && {
          subdivisionBuilderSupervisor: { insert: selectedBuilderSupervisor },
        }),
    };

    try {
      const { data: createdRepair } = await dataProvider.create<Repair>(
        '/job-repair',
        {
          data,
        },
      );
      const promises: any[] = [];
      photosStaged.forEach((photo: any) => {
        const data = {
          fileName: photo,
          repairPhotoType: 'Photo',
          jobRepairId: createdRepair.id,
        };
        promises.push(dataProvider.create('/job-repair-photo', { data }));
      });
      photosStagedEmail.forEach(photoEmail => {
        const data = {
          fileName: photoEmail,
          repairPhotoType: 'Screenshot',
          jobRepairId: createdRepair.id,
        };
        promises.push(dataProvider.create('/job-repair-photo', { data }));
      });
      await Promise.all(promises);
      await onAdd(createdRepair, !!photosStagedEmail?.length);
      setSelectedBuilderSupervisor(
        SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE,
      );
      setRepairDate(DateTime.local());
      setErrors({});
      setDescription('');
      setPoTotal('');
      setCheckedNonBillable(false);
      setRepairType(null);
      setRepairPayments([]);
      setPhotosStaged([]);
      setPhotosStagedEmail([]);
      notify('Repair created!');
      setLoading(false);
      onClose();
    } catch (error) {
      setLoading(false);
      notify(`Repair create Error: ${error.message}`, 'warning');
    }
  };

  const stagePhoto = fileName => setPhotosStaged([...photosStaged, fileName]);

  const stagePhotoEmail = fileName =>
    setPhotosStagedEmail([...photosStagedEmail, fileName]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      maxWidth={isMobile ? 'xs' : undefined}
      fullWidth={isMobile ? true : false}
    >
      <DialogTitle id="alert-dialog-ti9tle">Add Repairs</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box
            width={isMobile ? 'auto' : '600px'}
            display={isMobile ? 'block' : 'flex'}
          >
            <Box mr={2} width="100%">
              <CustomReference
                label="Select Repair Type"
                value={repairType ?? undefined}
                resource="repair"
                error={errors.repairType}
                disabled={repairPayments.length > 0}
                customItemName="description"
                sort={{ field: 'description', order: 'ASC' }}
                onChange={event =>
                  setRepairType(event.target.value as RepairTypeEnum)
                }
              />
            </Box>
            <Box width="100%" mt="-8px">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="PO Request Date"
                fullWidth
                error={errors.repairDate}
                value={repairDate}
                onChange={setRepairDate}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={1}
            >
              <TextField
                label="PO Total $"
                InputProps={{
                  inputProps: { min: 0, max: 5000, title: '', step: 'any' },
                }}
                disabled={checkedNonBillable}
                error={errors.poTotal}
                type="number"
                fullWidth
                className={classes.input}
                value={poTotal}
                onChange={event => setPoTotal(event.target.value)}
              />
            </Box>
            <Box
              justifyContent="justify-between"
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
              alignItems={isMobile ? 'center' : 'initial'}
              mb={isMobile ? -8 : undefined}
              flex={1}
              gridGap={8}
            >
              <Box ml={1}>
                <FormControlLabel
                  control={<Switch />}
                  onClick={() => {
                    setPoTotal('');
                    setCheckedNonBillable(prevState => !prevState);
                  }}
                  checked={checkedNonBillable}
                  label="Non-billable"
                />
              </Box>
              <Box
                display="flex"
                flex={1}
                justifyContent="flex-end"
                alignItems="center"
                gridGap={8}
              >
                <CameraAltIcon
                  className={classes.iconsUpload}
                  onClick={() => {
                    setType('photo');
                    setUploadModalOpen(true);
                  }}
                />
                <Email
                  className={classes.iconsUpload}
                  onClick={() => {
                    setType('email');
                    setUploadModalOpen(true);
                  }}
                />
              </Box>
            </Box>
          </Box>
          {checkedNonBillable && (
            <Box width="50%">
              <Alert severity="warning">
                PO Total $ cannot be added when repair is set as non-billable.
              </Alert>
            </Box>
          )}
          {subdivisionBuilderSupervisors &&
            shouldSelectSubdivisionBuilderSupervisor && (
              <Box
                display="flex"
                alignItems="center"
                maxWidth={648}
                marginTop={isMobile ? 6 : undefined}
              >
                <FormControl
                  fullWidth
                  className={classes.builderSupervisorFormControl}
                  error={hasSubdivisionBuilderSupervisorErrors}
                  component="fieldset"
                >
                  <FormLabel component="legend">Builder Supervisor</FormLabel>
                  <FormGroup className={classes.builderSupervisorFormGroup}>
                    <Grid container>
                      {subdivisionBuilderSupervisors?.map(item => (
                        <Grid key={item.id} item xs={isMobile ? 6 : 4}>
                          <FormControlLabel
                            style={{
                              flexGrow: 1,
                            }}
                            control={
                              <Checkbox
                                onChange={() =>
                                  handleSubdivisionBuilderSupervisorChange(
                                    item.id,
                                  )
                                }
                              />
                            }
                            checked={selectedBuilderSupervisor.includes(
                              item.id,
                            )}
                            label={`${item.user?.firstName} ${item.user?.lastName}`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </FormGroup>
                  {hasSubdivisionBuilderSupervisorErrors &&
                    !selectedBuilderSupervisor?.length && (
                      <FormHelperText error>
                        Please select at least one Builder Supervisor before
                        creating the repair.
                      </FormHelperText>
                    )}
                </FormControl>
              </Box>
            )}

          <Box>
            <Box>
              <TextField
                label="Description"
                fullWidth
                className={classes.input}
                value={description}
                multiline
                rows={3}
                onChange={event => setDescription(event.target.value)}
              />
            </Box>
          </Box>
          {/* <Box mt={1}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!repairType}
              className={classes.buttonRepairs}
              onClick={() => setAddContractorModalOpen(true)}
            >
              Add Contractor
            </Button>
          </Box> */}
          {/* <Box width="100%" mt={2}>
            <Typography className={classes.titleFont}>Contractors</Typography>
            {repairPayments.map((repairPayment, i) => {
              const { amount, type } = getPayment(repairPayment);
              return (
                <Box width="100%" display="flex" key={i}>
                  <Box minWidth="50px" display="flex" alignItems="center">
                    <DeleteIcon
                      onClick={() => deleteContractor(i)}
                      className={classes.pointer}
                    />
                  </Box>
                  <Box width="100%" mr={1}>
                    <Typography className={classes.titleFont}>
                      Resource
                    </Typography>
                    <Typography>{repairPayment.resourceType}</Typography>
                  </Box>
                  <Box width="100%" mr={1}>
                    <Typography className={classes.titleFont}>
                      Contractor
                    </Typography>
                    <Typography>{repairPayment.contractorName}</Typography>
                  </Box>
                  <Box width="100%">
                    <Typography className={classes.titleFont}>
                      {repairPayment.hours
                        ? 'Hours'
                        : repairPayment.extraHours
                        ? 'Extra Hours'
                        : repairPayment.fixedAmount
                        ? 'Fixed Amount $'
                        : 'Per Day'}
                    </Typography>
                    <Typography>
                      {repairPayment.hours
                        ? repairPayment.hours
                        : repairPayment.extraHours
                        ? repairPayment.extraHours
                        : repairPayment.fixedAmount
                        ? repairPayment.fixedAmount
                        : ''}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Box> */}
          {uploadModalOpen && (
            <MasterSheetRepairPhotoUpload
              setPhotosStaged={stagePhoto}
              setPhotosStagedEmail={stagePhotoEmail}
              photosStagedEmail={photosStagedEmail}
              photosStaged={photosStaged}
              open={uploadModalOpen}
              handleClose={() => setUploadModalOpen(false)}
              type={type}
            />
          )}
          {addContractorModalOpen && (
            <RepairContractorDialog
              isMobile={isMobile}
              setRepairPayments={setRepairPayments}
              theJob={theJob}
              repairType={repairType}
              repairPayments={repairPayments}
              open={addContractorModalOpen}
              handleClose={() => setAddContractorModalOpen(false)}
              type={type}
            />
          )}
        </DialogContentText>
        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap={16}
          marginY={2}
          mt={4}
        >
          <Button
            variant="contained"
            onClick={() => {
              setErrors({});
              setRepairDate(DateTime.local());
              setSelectedBuilderSupervisor(
                SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE,
              );
              setDescription('');
              setPoTotal('');
              setRepairType(null);
              setRepairPayments([]);
              setPhotosStaged([]);
              setPhotosStagedEmail([]);
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={createRepair}
            color="primary"
            className={classes.buttonRepairs}
            disabled={loading}
          >
            Confirm
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddRepairDialog;
