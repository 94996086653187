import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const Handyman: React.FC = () => {
  return (
    <SvgIcon viewBox="0 0 48 48">
      <path d="M38.2 43.95Q37.9 43.95 37.65 43.875Q37.4 43.8 37.15 43.55L25.9 32.3V28.5L28.45 25.95H32.25L43.5 37.2Q43.75 37.45 43.825 37.7Q43.9 37.95 43.9 38.25Q43.9 38.55 43.825 38.8Q43.75 39.05 43.5 39.3L39.25 43.55Q39 43.8 38.75 43.875Q38.5 43.95 38.2 43.95ZM38.2 40.4 40.35 38.25 30.35 28.25 28.2 30.4ZM9.75 44Q9.45 44 9.175 43.9Q8.9 43.8 8.65 43.55L4.45 39.35Q4.2 39.1 4.1 38.825Q4 38.55 4 38.25Q4 37.95 4.1 37.7Q4.2 37.45 4.45 37.2L15.7 25.95H19.95L21.85 24.05L13.1 15.3H10.25L4 9.05L8.95 4.1L15.2 10.35V13.2L23.95 21.95L30.45 15.45L27.1 12.1L29.9 9.3H24.25L23.35 8.4L29.75 2L30.65 2.9V8.55L33.45 5.75L41.9 14.2Q42.65 14.95 43.075 15.925Q43.5 16.9 43.5 18Q43.5 18.95 43.2 19.875Q42.9 20.8 42.25 21.6L38 17.35L35.2 20.15L32.6 17.55L22.05 28.1V32.3L10.8 43.55Q10.55 43.8 10.3 43.9Q10.05 44 9.75 44ZM9.75 40.4 19.75 30.4 17.6 28.25 7.6 38.25Z" />
    </SvgIcon>
  );
};

export default Handyman;
