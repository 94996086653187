import { FC, useEffect, useRef, useState } from 'react';
import {
  Edit,
  TextInput,
  EditProps,
  DataProvider,
  useDataProvider,
  useNotify,
  LinearProgress,
  useRedirect,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ManyToManyReferenceContextProvider } from '@react-admin/ra-relationships';
import {
  SupplierRegionDto,
  SupplierRepresentativeDto,
} from '@vatos-pas/common';
import difference from 'lodash/difference';

import { validateSupplier } from './supplierValidation';
import SimpleForm from 'components/SimpleForm';
import { Show } from 'components/Show';
import SupplierRepresentativeField from '../components/supplier-representative-field';
import { ICustomItem } from 'components/CustomSelect';
import {
  findManySupplierRepresentatives,
  findManyUserRepresentatives,
} from 'services/users';
import RegionField from '../components/region-field';
import { findManyRegions, findManySupplierRegions } from 'services/regions';

const updateSupplierRegions = async (
  regions: SupplierRegionDto[],
  selectedSupplierRegions: string[],
  dataProvider: DataProvider,
  supplierId: string,
) => {
  const supplierRegionIds = regions?.map(item => item.regionId);
  const deleteSupplierRegionIds = difference(
    supplierRegionIds,
    selectedSupplierRegions,
  );

  const insertSupplierRegionIds = difference(
    selectedSupplierRegions,
    supplierRegionIds,
  );

  if (deleteSupplierRegionIds?.length) {
    const promises = deleteSupplierRegionIds.map(async id => {
      const supplierRegion = regions?.find(item => item.regionId === id);

      if (!supplierRegion?.id) return;

      return dataProvider.delete('supplier-region', {
        id: supplierRegion.id,
        previousData: supplierRegion,
      });
    });

    await Promise.all(promises);
  }

  if (insertSupplierRegionIds?.length) {
    const promises = insertSupplierRegionIds.map(async id =>
      dataProvider.create('supplier-region', {
        data: {
          supplierId,
          regionId: id,
        },
      }),
    );

    await Promise.all(promises);
  }
};

const updateSupplierRepresentatives = async (
  supplierRepresentatives: SupplierRepresentativeDto[],
  selectedSupplierRepresentatives: string[],
  dataProvider: DataProvider,
  supplierId: string,
) => {
  const supplierRepresentativeIds = supplierRepresentatives?.map(
    item => item.userId,
  );
  const deleteSupplierRepresentativeIds = difference(
    supplierRepresentativeIds,
    selectedSupplierRepresentatives,
  );

  const insertSupplierRepresentativeIds = difference(
    selectedSupplierRepresentatives,
    supplierRepresentativeIds,
  );

  if (deleteSupplierRepresentativeIds?.length) {
    const promises = deleteSupplierRepresentativeIds.map(async id => {
      const supplierRepresentative = supplierRepresentatives?.find(
        item => item.userId === id,
      );

      if (!supplierRepresentative?.id) return;

      return dataProvider.delete('supplier-representative', {
        id: supplierRepresentative.id,
        previousData: supplierRepresentative,
      });
    });

    await Promise.all(promises);
  }

  if (insertSupplierRepresentativeIds?.length) {
    const promises = insertSupplierRepresentativeIds.map(async id =>
      dataProvider.create('supplier-representative', {
        data: {
          supplierId,
          userId: id,
        },
      }),
    );

    await Promise.all(promises);
  }
};

export const SuppliersEdit: FC<EditProps> = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const [userRepresentatives, setUserRepresentatives] = useState<
    ICustomItem[] | null
  >(null);
  const [regions, setRegions] = useState<ICustomItem[] | null>(null);
  const [selectedUserRepresentatives, setSelectedUserRepresentatives] =
    useState<string[]>([]);
  const [selectedSupplierRegions, setSelectedSupplierRegions] = useState<
    string[]
  >([]);
  const [supplierRepresentativeError, setSupplierRepresentativeError] =
    useState(null);
  const [loadingSupplierRepresentatives, setLoadingSupplierRepresentatives] =
    useState(false);

  const [supplierRegionError, setSupplierRegionError] = useState(null);

  // This ref is needed because <Edit /> form memoizes onSuccess function.
  // This memoization prevents `handleSuccess` function from having the updated state when
  // user saves its changes.
  // By passing a reference to the function and updating the reference value in every render
  // We make sure that the value inside `handleSuccess` will always be up to date.
  const supplierRegionsRef = useRef([]);
  const supplierRepresentativesRef = useRef([]);
  const selectedUserRepresentativesRef = useRef(selectedUserRepresentatives);
  selectedUserRepresentativesRef.current = selectedUserRepresentatives;
  const selectedSupplierRegionsRef = useRef(selectedSupplierRegions);
  selectedSupplierRegionsRef.current = selectedSupplierRegions;

  const getRegions = async () => {
    const regions = await findManyRegions(dataProvider);

    if (regions?.message) {
      setSupplierRegionError(regions.message);
      return;
    }

    if (regions?.length) {
      setRegions(
        regions?.map(item => ({
          name: item.name,
          id: item.id,
        })),
      );
    }
  };
  const getSupplierRegions = async () => {
    if (!props.id) return;

    const supplierRegions = await findManySupplierRegions(
      dataProvider,
      props.id,
    );

    if (supplierRegions?.message) {
      setSupplierRegionError(supplierRegions.message);
      return;
    }

    const supplierRegionsIds =
      supplierRegions?.map(item => item.regionId) || [];

    supplierRegionsRef.current = supplierRegions;

    setSelectedSupplierRegions(supplierRegionsIds);
  };

  const getUserRepresentatives = async () => {
    const users = await findManyUserRepresentatives(dataProvider);

    if (users?.message) {
      setSupplierRepresentativeError(users.message);
      return;
    }

    if (users?.length) {
      setUserRepresentatives(
        users?.map(item => ({
          name: `${item.firstName} ${item.lastName}`,
          id: item.id,
        })),
      );
    }
  };

  const getSupplierRepresentatives = async () => {
    if (!props.id) return;

    const supplierRepresentatives = await findManySupplierRepresentatives(
      dataProvider,
      props.id,
    );

    if (supplierRepresentatives?.message) {
      setSupplierRepresentativeError(supplierRepresentatives.message);
      return;
    }

    const supplierRepresentativesIds =
      supplierRepresentatives?.map(item => item.userId) || [];

    supplierRepresentativesRef.current = supplierRepresentatives;

    setSelectedUserRepresentatives(supplierRepresentativesIds);
  };

  const handleSuccess = async () => {
    if (!props.id) return;

    try {
      await updateSupplierRepresentatives(
        supplierRepresentativesRef.current,
        selectedUserRepresentativesRef.current,
        dataProvider,
        props.id,
      );

      await updateSupplierRegions(
        supplierRegionsRef.current,
        selectedSupplierRegionsRef.current,
        dataProvider,
        props.id,
      );

      redirect('/supplier');
      // Default message when updating a contractor in the normal flow
      notify('Element updated');
    } catch (error) {
      notify(error.message, 'warning');
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setLoadingSupplierRepresentatives(true);
      await Promise.all([
        getRegions(),
        getSupplierRegions(),
        getUserRepresentatives(),
        getSupplierRepresentatives(),
      ]);
      setLoadingSupplierRepresentatives(false);
    };

    initialize();
  }, []);

  return (
    <Edit
      onSuccess={handleSuccess}
      mutationMode="pessimistic"
      className={classes.createBox}
      {...props}
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm validate={validateSupplier}>
          <Box className={classes.fields}>
            <TextInput fullWidth source="name" />
            <TextInput fullWidth source="externalId" label="External ID" />
          </Box>
          <Box className={classes.fields}>
            <TextInput fullWidth source="email" />
            <TextInput fullWidth source="phone" />
            <TextInput fullWidth source="contactInfo" />
          </Box>
          <TextInput fullWidth source="description" multiline rows={3} />
          <Show
            condition={!loadingSupplierRepresentatives}
            fallback={<LinearProgress timeout={0} />}
          >
            <RegionField
              regions={regions}
              selectedSupplierRegions={selectedSupplierRegions}
              setSelectedSupplierRegions={setSelectedSupplierRegions}
              classes={classes}
              error={!!supplierRegionError}
              helperText={supplierRegionError ?? ''}
            />
          </Show>
          <Show
            condition={!loadingSupplierRepresentatives}
            fallback={<LinearProgress timeout={0} />}
          >
            <SupplierRepresentativeField
              userRepresentatives={userRepresentatives}
              selectedUserRepresentatives={selectedUserRepresentatives}
              setSelectedUserRepresentatives={setSelectedUserRepresentatives}
              classes={classes}
              error={!!supplierRepresentativeError}
              helperText={supplierRepresentativeError ?? ''}
            />
          </Show>
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </Edit>
  );
};

const useStyles = makeStyles({
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
  },
  chips: {
    gap: '4px',
    display: 'flex',
    flexWrap: 'wrap',
  },
});
