import { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import InfoIcon from '@material-ui/icons/Info';

import CustomReference from 'components/CustomReference';
import { useContainer } from 'unstated-next';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { Show } from 'components/Show';

export const PurchaseOrderPhaseSelection: FC = () => {
  const { getMaterialPhase, selectedPhase, setSelectedPhase, isEdit, isDraft } =
    useContainer(PurchaseOrderContainer);

  return (
    <Box>
      <Box>
        <Box>
          <Typography style={{ fontWeight: 'bold' }}>
            Phase selection
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <CustomReference
            label="Phase"
            value={selectedPhase}
            resource="phase"
            sort={{ field: 'name', order: 'ASC' }}
            disabled={isEdit && !isDraft}
            onChange={(event: any) => {
              setSelectedPhase(event.target.value);
            }}
            formatResponse={getMaterialPhase}
          />
        </Box>
        <Show condition={!selectedPhase}>
          <Box my={2}>
            <Box display="flex">
              <InfoIcon style={{ color: '#d08f19', marginRight: '10px' }} />
              <Typography style={{ color: '#d08f19' }}>
                Please select a phase before creating the purchase order.
              </Typography>
            </Box>
          </Box>
        </Show>
      </Box>
    </Box>
  );
};
