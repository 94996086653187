import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Edit,
  EditProps,
  EditButton,
  Loading,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { validateBuilder } from './buildersValidation';
import SimpleForm from 'components/SimpleForm';
import { SpBuilderNameInterface } from '@vatos-pas/common';

import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_SP_POTENTIAL_MATCHES } from 'providers/permissions';
import {
  SpMatchesDefaultModal,
  SpMatchesDefaultTableList,
  SpMatchesDefaultTableRow,
  SpMatchesError,
} from 'components/SpMatchesDefaultTable';

const createTableData = ({
  id,
  name,
  active,
}: SpMatchesDefaultTableRow): SpMatchesDefaultTableRow => {
  return { id, name, active };
};

export const BuildersEdit: FC<EditProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [loading, setLoading] = useState(false);
  const [spBuilders, setSpBuilders] = useState<SpBuilderNameInterface[] | null>(
    null,
  );
  const [showEditBuildersNameModal, setShowEditBuildersNameModal] =
    useState(false);
  const [error, setError] = useState<SpMatchesError | null>(null);

  const openCancelConfirmationModal = () => setShowEditBuildersNameModal(true);

  const closeCancelConfirmationModal = () =>
    setShowEditBuildersNameModal(false);

  const getSpBuilders = async () => {
    setLoading(true);

    try {
      const spBuilders = await dataProvider.getList<SpBuilderNameInterface>(
        'sp-builder-name',
        {
          filter: { builderId: props.id },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'spName', order: 'ASC' },
        },
      );

      if (spBuilders?.data) {
        setSpBuilders(spBuilders.data);
      }
    } catch (err) {
      notify('Failed to load SupplyPro builder names.', 'error');
    }

    setLoading(false);
  };

  const onUpdateActiveFlag = async (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (!spBuilders) return;

    const rowIndex = spBuilders.findIndex(row => row.id === id);

    if (rowIndex < 0) return;

    const newSpBuilders = [...spBuilders];
    const newRow = { ...spBuilders[rowIndex], active: event.target.checked };

    newSpBuilders[rowIndex] = newRow;

    try {
      await dataProvider.update('sp-builder-name', {
        id,
        data: {
          active: newRow.active,
        },
        previousData: spBuilders[rowIndex],
      });

      setSpBuilders(newSpBuilders);
      notify('SupplyPro builder name updated!', 'success');
    } catch (err) {
      notify('Failed to update SupplyPro builder name.', 'error');
    }
  };

  const onAddNewItem = async (spName: string, active: boolean) => {
    const spNameTrimmed = spName.trim();

    if (spNameTrimmed.length === 0) {
      setError({ message: 'Builder name cannot be empty.' });
      return;
    }

    try {
      const { data } = await dataProvider.create<SpBuilderNameInterface>(
        'sp-builder-name',
        {
          data: {
            builderId: props.id,
            spName: spNameTrimmed,
            active,
          },
        },
      );

      setSpBuilders(prevState => {
        if (!prevState) {
          return [data];
        }

        return [...prevState, data];
      });

      notify('SupplyPro builder name created!', 'success');
      return data;
    } catch (err) {
      notify('Failed to add a new SupplyPro builder name.', 'error');
    }
  };

  const rows: SpMatchesDefaultTableRow[] | null =
    spBuilders?.map(builder =>
      createTableData({
        id: builder.id,
        name: builder.spName,
        active: builder.active,
      }),
    ) || null;

  useEffect(() => {
    getSpBuilders();
  }, [dataProvider]);

  return (
    <>
      <Edit {...props} className={classes.createBox}>
        <SimpleForm validate={validateBuilder}>
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="name" />
            <TextInput
              fullWidth
              className={classes.input}
              source="code"
              label="ID Code"
            />
          </Box>
        </SimpleForm>
      </Edit>
      {
        <>
          {hasPermission(CAN_EDIT_SP_POTENTIAL_MATCHES) && (
            <>
              {loading ? (
                <Loading loadingPrimary="" loadingSecondary="" />
              ) : (
                <Box className={classes.buildersMatchesBox}>
                  <Typography
                    className={classes.buildersHeading}
                    variant="h6"
                    component="h3"
                  >
                    SupplyPro - Potential Matches
                  </Typography>

                  <SpMatchesDefaultTableList
                    columns={['SupplyPro Potential Matches']}
                    rows={rows}
                  />

                  <SpMatchesDefaultModal
                    open={showEditBuildersNameModal}
                    onClose={closeCancelConfirmationModal}
                    error={error}
                    columns={['SupplyPro Potential Matches']}
                    onUpdateActiveFlag={onUpdateActiveFlag}
                    onAddNewItem={onAddNewItem}
                    rows={rows}
                  />

                  <EditButton
                    variant="contained"
                    size="medium"
                    onClick={openCancelConfirmationModal}
                    className={classes.editButton}
                  />
                </Box>
              )}
            </>
          )}
        </>
      }
    </>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  buildersMatchesBox: {
    maxWidth: '500px',
    marginTop: '24px',
  },
  editButton: {
    marginTop: '24px',
  },
  buildersHeading: {
    marginBottom: '16px',
  },
});
