import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  EditProps,
  useDataProvider,
  useNotify,
  Loading,
  EditButton,
  Show,
  ReferenceField,
  TextField,
  BooleanField,
  SimpleShowLayout,
  sanitizeListRestProps,
  TopToolbar,
  ShowActionsProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ModelDto, SpModelNameInterface } from '@vatos-pas/common';

import { PermissionsProvider } from 'providers/permissionsProvider';
import {
  CAN_EDIT_MODEL,
  CAN_EDIT_SP_POTENTIAL_MATCHES,
  CAN_SEE_SP_POTENTIAL_MATCHES,
} from 'providers/permissions';
import {
  SpMatchesDefaultModal,
  SpMatchesDefaultTableList,
  SpMatchesDefaultTableRow,
  SpMatchesError,
} from 'components/SpMatchesDefaultTable';

const createTableData = ({
  id,
  name,
  active,
}: SpMatchesDefaultTableRow): SpMatchesDefaultTableRow => {
  return { id, name, active };
};

const ShowActions: FC<ShowActionsProps> = props => {
  const { className, basePath, data, ...rest } = props;
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {hasPermission(CAN_EDIT_MODEL) && (
        <EditButton basePath={basePath} record={data} />
      )}
    </TopToolbar>
  );
};

export const ModelsShow: FC<EditProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [loading, setLoading] = useState(false);
  const [spModels, setSpModels] = useState<SpModelNameInterface[] | null>(null);
  const [showEditModelsNameModal, setShowEditModelsNameModal] = useState(false);
  const [error, setError] = useState<SpMatchesError | null>(null);
  const [model, setModel] = useState<ModelDto | null>();
  const [builderName, setBuilderName] = useState('');

  const openCancelConfirmationModal = () => setShowEditModelsNameModal(true);

  const closeCancelConfirmationModal = () => setShowEditModelsNameModal(false);

  const getSpModels = async () => {
    setLoading(true);

    try {
      const spModels = await dataProvider.getList<SpModelNameInterface>(
        'sp-model-name',
        {
          filter: { modelId: props.id },
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'spName', order: 'ASC' },
        },
      );

      if (spModels.data) {
        setSpModels(spModels.data);
      }
    } catch (err) {
      notify('Failed to load SupplyPro model names.', 'error');
    }

    setLoading(false);
  };

  const onUpdateActiveFlag = async (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (!spModels) return;

    const rowIndex = spModels.findIndex(row => row.id === id);

    if (rowIndex < 0) return;

    const newSpModels = [...spModels];
    const newRow = { ...spModels[rowIndex], active: event.target.checked };

    newSpModels[rowIndex] = newRow;

    try {
      await dataProvider.update('sp-model-name', {
        id,
        data: {
          active: newRow.active,
        },
        previousData: spModels[rowIndex],
      });

      setSpModels(newSpModels);
      notify('SupplyPro model name updated!', 'success');
    } catch (err) {
      notify('Failed to update SupplyPro model name.', 'error');
    }
  };

  const onAddNewItem = async (spName: string, active: boolean) => {
    const spNameTrimmed = spName.trim();

    if (spNameTrimmed.length === 0) {
      setError({ message: 'Model name cannot be empty.' });
      return;
    }

    try {
      const { data } = await dataProvider.create<SpModelNameInterface>(
        'sp-model-name',
        {
          data: {
            modelId: props.id,
            spName: spNameTrimmed,
            active,
            subdivisionId: model?.subdivisionId || '',
          },
        },
      );

      setSpModels(prevState => {
        if (!prevState) {
          return [data];
        }

        return [...prevState, data];
      });

      notify('SupplyPro model name created!', 'success');
      return data;
    } catch (err) {
      notify('Failed to add a new SupplyPro model name.', 'error');
    }
  };

  const getModel = async () => {
    if (!props.id) {
      return;
    }
    try {
      const modelFind = await dataProvider.getOne<ModelDto>('model', {
        id: props.id,
      });

      if (modelFind?.data && modelFind.data) {
        setModel(modelFind.data);
        getBuilder(modelFind.data.subdivisionId);
      }
    } catch (err) {
      notify('Failed to load SupplyPro model names.', 'error');
    }
  };

  const getBuilder = async subdivisionId => {
    if (!subdivisionId) {
      return;
    }
    try {
      const subDivisionFound = await dataProvider.getOne<any>('subdivision', {
        id: subdivisionId,
      });
      const builderFound = await dataProvider.getOne<any>('builder', {
        id: subDivisionFound.data.builderId,
      });

      if (builderFound?.data && builderFound.data) {
        setBuilderName(builderFound.data.name);
      }
    } catch (err) {
      notify('Failed to load SupplyPro model names.', 'error');
    }
  };

  const rows: SpMatchesDefaultTableRow[] | null =
    spModels?.map(model =>
      createTableData({
        id: model.id,
        name: model.spName,
        active: model.active,
      }),
    ) || null;

  useEffect(() => {
    getModel();
    getSpModels();
  }, []);

  return (
    <>
      <Show actions={<ShowActions />} {...props} className={classes.createBox}>
        <SimpleShowLayout>
          <Box marginLeft="17px">
            <Typography
              style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)' }}
            >
              Builder
            </Typography>
            <Typography style={{ marginLeft: '12px' }}>
              {builderName}
            </Typography>
          </Box>
          <ReferenceField
            label="Subdivision"
            source="subdivisionId"
            reference="subdivision"
            className={classes.input}
          >
            <TextField label="Subdivision" source="name" />
          </ReferenceField>
          <TextField
            label="Name"
            fullWidth
            className={classes.input}
            source="name"
          />
          <TextField
            fullWidth
            className={classes.input}
            source="externalId"
            label="External ID"
          />
          <TextField
            className={classes.halfWidth}
            label="Description"
            source="description"
          />
          <BooleanField
            label="Garage First"
            className={classes.input}
            source="garageFirst"
          />
          <BooleanField
            label="In Building"
            className={classes.input}
            source="inBuilding"
          />
        </SimpleShowLayout>
      </Show>
      {hasPermission(CAN_SEE_SP_POTENTIAL_MATCHES) && (
        <>
          {loading ? (
            <Loading loadingPrimary="" loadingSecondary="" />
          ) : (
            <Box className={classes.modelsMatchesBox}>
              <Typography
                className={classes.modelsHeading}
                variant="h6"
                component="h3"
              >
                SupplyPro - Potential Matches
              </Typography>

              <SpMatchesDefaultTableList
                columns={['SupplyPro Potential Matches']}
                rows={rows}
              />

              {hasPermission(CAN_EDIT_SP_POTENTIAL_MATCHES) && (
                <>
                  <SpMatchesDefaultModal
                    open={showEditModelsNameModal}
                    onClose={closeCancelConfirmationModal}
                    error={error}
                    columns={['SupplyPro Potential Matches']}
                    onUpdateActiveFlag={onUpdateActiveFlag}
                    onAddNewItem={onAddNewItem}
                    rows={rows}
                  />

                  <EditButton
                    variant="contained"
                    size="medium"
                    onClick={openCancelConfirmationModal}
                    className={classes.editButton}
                  />
                </>
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldsWithBoolean: {
    width: '100%',
    display: 'flex',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '60%',
    margin: '0px 15px',
  },
  modelsMatchesBox: {
    maxWidth: '500px',
    marginTop: '24px',
  },
  editButton: {
    marginTop: '24px',
  },
  modelsHeading: {
    marginBottom: '16px',
  },
});