import { BumpResourceSheetInterface } from '@vatos-pas/common';
import { CAN_EDIT_BUMPOUT_TIMESHEET_PAID } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { useEffect, useState } from 'react';
import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useListContext,
  useNotify,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

type PaidCheckboxProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetInterface>;

const PaidCheckboxField = (props: PaidCheckboxProps) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { refetch } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [status, setStatus] = useState(!!props.record?.paid);

  const isJobPhaseBump = props?.record?.bumpResourceType === 'JOB_PHASE_BUMP';
  const canEdit = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_PAID);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!props.record?.id) return;
    setStatus(event.target.checked);
    try {
      const params = {
        id: props.record.id,
        data: {
          paid: event.target.checked,
        },
        previousData: props.record,
      };

      await dataProvider.update(
        isJobPhaseBump ? '/job-phase-bump' : '/repair-payment',
        params,
      );
      notify('Changes were made successfully!');
      refetch();
    } catch (error) {
      notify(error.message, 'warning');
      setStatus(!event.target.checked);
    }
  };

  // React-admin uses stale-while-revalidate technique
  // Because of that, the first render of this component will always have the old state.
  // We need to update when react-admin passes the revalidated version of record.
  useEffect(() => {
    setStatus(!!props.record?.paid);
  }, [props.record]);

  if (!props.record?.id) return null;

  return (
    <Box display="flex" minWidth={80}>
      <Checkbox disabled={!canEdit} checked={status} onChange={handleChange} />
    </Box>
  );
};

export default PaidCheckboxField;
