import { FC } from 'react';
import { useFormState } from 'react-final-form';
import TableCell from '@material-ui/core/TableCell';

import { TableDefaultRow } from './TableList';

export interface TableRowProps {
  row: TableDefaultRow;
}

export const TableRow: FC<TableRowProps> = ({ row }) => {
  const { values } = useFormState();

  return (
    <>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {values.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {`${values?.supervisorUser?.firstName ?? ''} 
        ${values?.supervisorUser?.lastName ?? ''}`}
      </TableCell>
      <TableCell component="th" scope="row">
        {values.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {`${values?.supervisorUser?.firstName ?? ''} 
        ${values?.supervisorUser?.lastName ?? ''}`}
      </TableCell>
    </>
  );
};
