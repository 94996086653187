/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, Fragment, useRef, useMemo, useEffect } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CustomReference from 'components/CustomReference';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { SubdivisionDto } from '@vatos-pas/common';
import { findManySubdivisions } from 'services/jobs';
import debounce from 'lodash/debounce';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

type SubdivisionInputProps = {
  builderId: string | undefined;
  subdivision: string;
  helperText: string;
  error: boolean;
  setSubdivision: React.Dispatch<any>;
  setModel: React.Dispatch<any>;
};

const SubdivisionInput = ({
  builderId,
  subdivision,
  helperText,
  error,
  setSubdivision,
  setModel,
}: SubdivisionInputProps) => {
  const dataProvider = useDataProvider();
  const currentRequestId = useRef(0);
  const classes = useStyles();

  const [loadingSubdivisions, setLoadingSubdivisions] = useState(false);
  const [selectedSubdivision, setSelectedSubdivision] = useState(null);
  const [subdivisions, setSubdivisions] = useState<SubdivisionDto[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = async (_event, value, _reason, _details) => {
    if (value?.id !== subdivision) {
      setSelectedSubdivision(value);
      setSubdivision(value?.id ?? '');
      setModel('');
    }
  };

  const getSubdivisions = async (
    builderId: string | undefined,
    requestId: number,
    name: string,
  ) => {
    if (!builderId) return;
    setLoadingSubdivisions(true);

    const subdivisions = await findManySubdivisions(
      dataProvider,
      {
        builderId,
        active: true,
        ...(name && { name }),
      },
      25,
    );

    if (subdivisions && requestId === currentRequestId.current) {
      setSubdivisions(subdivisions);
    }

    setLoadingSubdivisions(false);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(name => {
        currentRequestId.current += 1;
        const requestId = currentRequestId.current;
        getSubdivisions(builderId, requestId, name);
      }, 300),
    [builderId, dataProvider],
  );

  // Reset subdivision state whenever builder changes
  useEffect(() => {
    setSelectedSubdivision(null);
  }, [builderId]);

  useEffect(() => {
    debouncedSearch(inputValue);

    // Clean up the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [builderId, inputValue, debouncedSearch]);

  return (
    <Autocomplete
      fullWidth
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => (option ? option?.name : '')}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.box}
          label="Subdivision"
          error={error}
          helperText={helperText}
        />
      )}
      loading={loadingSubdivisions}
      disabled={!builderId && !loadingSubdivisions}
      options={subdivisions?.map(item => ({
        name: item.name,
        id: item.id,
      }))}
      value={selectedSubdivision}
      onChange={handleChange}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
    />
  );
};

const getFilterModel = (subDivision: string) => {
  if (subDivision) {
    return { subdivisionId: subDivision, active: true };
  }
  return null;
};

export const ModelBoardSheetCreate = () => {
  const dataProvider = useDataProvider();

  const [model, setModel] = useState<any>();
  const [builder, setBuilder] = useState<any>();
  const [subDivision, setSubDivision] = useState<any>();
  const [laborOnly, setLaborOnly] = useState(false);
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [boardFloorsValidation, setBoardFloorsValidation] = useState({});
  const [modelBoardFloors, setModelBoardFloors] = useState<any>([
    {
      floor: 1,
      phaseId: '',
      boardTypeId: '',
      quantity: 0,
      id: uuidv4(),
    },
  ]);

  const [fieldsValidation, setFieldsValidation] = useState({});

  const mappedFloors = {};
  modelBoardFloors.forEach(item => {
    if (mappedFloors[item.floor]) {
      mappedFloors[item.floor] = [...mappedFloors[item.floor], item];
    } else {
      mappedFloors[item.floor] = [item];
    }
  });

  const onChangeBoardFloor = (id, field) => value => {
    const newModelBoards = [...modelBoardFloors];
    const item = newModelBoards.find(item => item.id === id);
    item[field] = value;
    setModelBoardFloors(newModelBoards);
  };

  const addNewFloor = () => {
    const sortedFloors = [...modelBoardFloors];
    if (sortedFloors.length) {
      const emptyFloorItem = {
        floor: sortedFloors[sortedFloors.length - 1].floor + 1,
        phaseId: '',
        boardTypeId: '',
        id: uuidv4(),
        quantity: '',
      };
      sortedFloors.push(emptyFloorItem);
    } else {
      const emptyFloorItem = {
        floor: 1,
        phaseId: '',
        boardTypeId: '',
        quantity: '',
        id: uuidv4(),
      };
      sortedFloors.push(emptyFloorItem);
    }
    setModelBoardFloors(sortedFloors);
  };

  const addNewBoardFloor = floor => {
    const sortedFloors = [...modelBoardFloors];
    const emptyFloorItem = {
      floor,
      phaseId: '',
      boardTypeId: '',
      id: uuidv4(),
      quantity: '',
    };
    sortedFloors.push(emptyFloorItem);
    setModelBoardFloors(sortedFloors);
  };

  const validateFields = () => {
    let error = false;
    // Top Fields
    const newFieldsValidate = {};
    if (!builder) {
      newFieldsValidate['builder'] = true;
      error = true;
    } else {
      if (!subDivision) {
        newFieldsValidate['subDivision'] = true;
        error = true;
      } else {
        if (!model) {
          newFieldsValidate['model'] = true;
          error = true;
        }
      }
    }
    setFieldsValidation(newFieldsValidate);

    // Board Floor
    const boardFloorsValidate = {};
    modelBoardFloors.forEach(boardFloorItem => {
      Object.keys(boardFloorItem).forEach(key => {
        const value = boardFloorItem[key];
        if (!value && value !== 0) {
          if (boardFloorsValidate[boardFloorItem.id]) {
            boardFloorsValidate[boardFloorItem.id][key] = true;
          } else {
            boardFloorsValidate[boardFloorItem.id] = { [key]: true };
          }
          error = true;
        }
      });
    });
    setBoardFloorsValidation(boardFloorsValidate);

    return error;
  };

  const submit = async () => {
    const errored = validateFields();
    if (errored) return;
    setLoading(true);
    const modelBoardFloorsMapped = modelBoardFloors.map(item => {
      delete item.id;
      item.modelId = model;
      return item;
    });

    try {
      const newBoardSheet = await dataProvider.put('model-board-purchase', {
        id: model,
        data: {
          modelBoardFloors: modelBoardFloorsMapped,
        },
      });
      notify('Board Pre-Fill created with success!');
      redirect('/board-pre-fill');
    } catch (error: any) {
      console.log('error', error);
      notify(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteBoard = id => {
    const newBoardFloors = [...modelBoardFloors];
    const index = modelBoardFloors.findIndex(item => item.id === id);
    newBoardFloors.splice(index, 1);
    setModelBoardFloors(newBoardFloors);
  };

  const formatResponse = phases => {
    let newPhases = phases;
    if (newPhases.length > 0) {
      newPhases = newPhases.filter(item => {
        return (
          item.name === 'Materials'
          // item.name === 'Hanging' ||
          // item.name === 'Spraying' ||
          // item.name === 'Finishing'
        );
      });
    }
    return newPhases;
  };

  const sumBoardsFloor = floor => {
    const filteredFloor = modelBoardFloors.filter(
      item => item.floor === parseInt(floor),
    );
    let sum = 0;
    filteredFloor.forEach(item => {
      sum += item.quantity;
    });
    return sum;
  };

  const clearBoards = () => {
    setModelBoardFloors([
      {
        floor: 1,
        phaseId: '',
        boardTypeId: '',
        quantity: 0,
        id: uuidv4(),
      },
    ]);
  };

  return (
    <Box>
      <Box display="flex" width="100%" gridGap={50}>
        <CustomReference
          label="Builder"
          value={builder}
          resource="builder"
          sort={{ field: 'name', order: 'ASC' }}
          onChange={event => {
            if (event.target.value !== builder) {
              setBuilder(event.target.value as string);
              setSubDivision('');
              setModel('');
            }
          }}
          error={fieldsValidation['builder']}
          helperText={fieldsValidation['builder'] ? 'Builder is required' : ''}
        />
        <SubdivisionInput
          subdivision={subDivision}
          helperText={
            fieldsValidation['subDivision'] ? 'Subdivision is required' : ''
          }
          setSubdivision={setSubDivision}
          setModel={setModel}
          error={fieldsValidation['subDivision']}
          builderId={builder}
        />
        <CustomReference
          value={model}
          label="Model"
          resource="model"
          needsFilter
          sort={{ field: 'name', order: 'ASC' }}
          disabled={!subDivision}
          onChange={event => {
            setModel(event.target.value as string);
          }}
          filters={getFilterModel(subDivision)}
          required
          error={fieldsValidation['model']}
          helperText={fieldsValidation['model'] ? 'Model is required' : ''}
        />
      </Box>
      {/* <Box my={3}>
        <FormControlLabel
          control={
            <Switch
              checked={laborOnly}
              onChange={() => {
                setLaborOnly(!laborOnly);
                clearBoards();
              }}
              name="laborOnly"
              color="primary"
            />
          }
          label="Labor Only"
        />
      </Box> */}
      <Box>
        {Object.keys(mappedFloors).map(floor => {
          return (
            <Box key={floor} width="100%" mt={3}>
              <Box>
                <Typography>Floor #{floor}</Typography>
              </Box>
              {mappedFloors[floor].map((item, index) => (
                <Box
                  key={item.id}
                  display="flex"
                  width="100%"
                  gridGap={50}
                  alignItems="center"
                >
                  <Box width="100%">
                    <CustomReference
                      label="Phase"
                      value={item.phaseId}
                      resource="phase"
                      sort={{ field: 'name', order: 'ASC' }}
                      onChange={event => {
                        onChangeBoardFloor(
                          item.id,
                          'phaseId',
                        )(event.target.value);
                      }}
                      error={boardFloorsValidation[item.id]?.phaseId}
                      helperText={
                        boardFloorsValidation[item.id]?.phaseId
                          ? 'This field is required'
                          : ''
                      }
                      formatResponse={formatResponse}
                    />
                  </Box>
                  <Box width="100%">
                    <CustomReference
                      label="Board Type"
                      value={item.boardTypeId}
                      resource="board-type"
                      customItemName="shortName"
                      needsFilter
                      filters={
                        laborOnly
                          ? {
                              laborOnly: true,
                            }
                          : {
                              laborOnly: false,
                            }
                      }
                      sort={{ field: 'name', order: 'ASC' }}
                      onChange={event => {
                        onChangeBoardFloor(
                          item.id,
                          'boardTypeId',
                        )(event.target.value);
                      }}
                      error={boardFloorsValidation[item.id]?.boardTypeId}
                      helperText={
                        boardFloorsValidation[item.id]?.boardTypeId
                          ? 'This field is required'
                          : ''
                      }
                    />
                  </Box>
                  <Box width="100%">
                    <TextField
                      label="Quantity"
                      value={item.quantity}
                      onChange={event => {
                        onChangeBoardFloor(
                          item.id,
                          'quantity',
                        )(parseInt(event.target.value));
                      }}
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      error={boardFloorsValidation[item.id]?.quantity}
                      helperText={
                        boardFloorsValidation[item.id]?.quantity
                          ? 'This field is required'
                          : ''
                      }
                    />
                  </Box>
                  <Box style={{ cursor: 'pointer' }}>
                    <DeleteIcon onClick={() => deleteBoard(item.id)} />
                  </Box>
                  <Box style={{ cursor: 'pointer' }} width="120px">
                    {index === mappedFloors[floor].length - 1 && (
                      <AddIcon onClick={() => addNewBoardFloor(item.floor)} />
                    )}
                  </Box>
                </Box>
              ))}
              <Box>
                <Typography style={{ fontSize: '14px' }}>
                  Floor {floor} Total Boards: {sumBoardsFloor(floor)}
                </Typography>
              </Box>
            </Box>
          );
        })}
        <Box
          display="inline-flex"
          gridGap={10}
          mt={2}
          style={{ cursor: 'pointer' }}
          onClick={addNewFloor}
        >
          <Typography>Add New Floor</Typography>
          <AddIcon />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ChevronLeftIcon />}
          onClick={() => redirect('/board-pre-fill')}
        >
          GO BACK
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <Fragment /> : <SaveIcon />}
          onClick={submit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} size={24} />
          ) : (
            'Save'
          )}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});
