import React, { Fragment, FC, cloneElement, ReactElement } from 'react';
import {
  List,
  TextField,
  EditButton,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  FunctionField,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
  ExportButton,
  FilterProps,
  ListProps,
  ToolbarProps,
  ExportButtonProps,
  ShowButton,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { SubdivisionInterface, UserDto } from '@vatos-pas/common';
import { exporter } from 'providers/export';
import { CAN_EDIT_SUB_DIVISION } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { AppRole } from 'providers/roles';

const SubDivisionFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" data-cy="subdivisionFilterName" />
    <TextInput
      label="Address"
      source="address1"
      data-cy="subdivisionFilterAddress"
    />
    <TextInput label="City" source="city" data-cy="subdivisionFilterCity" />
    <TextInput label="State" source="state" data-cy="subdivisionFilterState" />
    <TextInput label="Zip" source="zip" data-cy="subdivisionFilterZip" />
    <ReferenceInput
      label="Builder"
      source="builderId"
      reference="builder"
      resettable
      alwaysOn
      data-cy="subdivisionFilterBuilder"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        allowEmpty={false}
        optionText="name"
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      perPage={Number.MAX_SAFE_INTEGER}
      label="Region"
      source="regionId"
      data-cy="subdivisionFilterRegion"
      reference="region"
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Supervisor"
      source="supervisorUserId"
      reference="user"
      resettable
      data-cy="subdivisionFilterSupervisor"
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: {
              $and: [
                {
                  $or: [
                    { firstName: { $cont: value } },
                    { lastName: { $cont: value } },
                  ],
                },
                {
                  'userRoles.role.name': { $eq: AppRole.Supervisor },
                },
              ],
            },
          };
        } else {
          return {
            s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
          };
        }
      }}
      sort={{ field: 'firstName', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(user: UserDto) => {
          return `${user.firstName} ${user.lastName}`;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <SelectInput
      source="warrantyStatus||$eq"
      label="Warranty Status"
      data-cy="subdivisionFilterWarrantyStatus"
      choices={[
        { val: 'Production', text: 'Production' },
        { val: 'Warranty', text: 'Warranty' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
    />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_SUB_DIVISION) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const SubDivisionsList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('subdivisions')}
      bulkActionButtons={<React.Fragment />}
      filters={<SubDivisionFilter>{props.filters}</SubDivisionFilter>}
      filter={{
        $join: [
          { field: 'supervisorUser', select: ['id', 'firstName', 'lastName'] },
          { field: 'builder', select: ['id', 'name'] },
          { field: 'region', select: ['id', 'name'] },
        ],
      }}
      sort={{ field: 'builderId', order: 'ASC' }}
      actions={<ListActions />}
    >
      <Datagrid
        rowClick={() =>
          hasPermission(CAN_EDIT_SUB_DIVISION) ? 'edit' : 'show'
        }
      >
        <TextField label="Builder" source="builder.name" />
        <TextField label="Region" source="region.name" />
        <FunctionField<SubdivisionInterface>
          label="Supervisor"
          render={record =>
            record?.supervisorUser
              ? `${record.supervisorUser.firstName} ${record.supervisorUser.lastName}`
              : ''
          }
          source="supervisorUser"
          sortBy="supervisorUser.firstName"
        />
        <TextField source="name" />
        <TextField source="address1" label="Address" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="zip" />
        <TextField source="idCode" label="ID Code" />
        <TextField source="warrantyStatus" label="Warranty Status" />
        {hasPermission(CAN_EDIT_SUB_DIVISION) ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
};
