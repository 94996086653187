import React, { FC, ReactElement } from 'react';
import { cloneElement } from 'react';
import {
  List,
  TextField,
  EditButton,
  Filter,
  SelectInput,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  ReferenceInput,
  FilterProps,
  ListProps,
  ToolbarProps,
  ExportButtonProps,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';

import { RepairResourceType } from './constants';

const RepairsSettingsFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <ReferenceInput
      perPage={Number.MAX_SAFE_INTEGER}
      sort={{ field: 'description', order: 'ASC' }}
      label="Repair Type"
      source="repairId||$eq"
      reference="repair"
    >
      <SelectInput optionText="description" />
    </ReferenceInput>
    <SelectInput
      label="Resource Type"
      source="repairResourceType||$eq"
      choices={RepairResourceType}
    />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const RepairsSettingsList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter('repairs-settings')}
      filters={<RepairsSettingsFilter>{props.filters}</RepairsSettingsFilter>}
      filter={{ $join: [{ field: 'repair', select: ['id', 'description'] }] }}
      bulkActionButtons={<React.Fragment />}
      actions={<ListActions />}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source="repair.description" label="Repair Type" />
        <TextField source="repairResourceType" label="Resource Type" />
        <TextField
          source="repairBumperPaymentType"
          label="Bumper Payment Type"
        />
        <TextField source="repairPaymentType" label="Repair Payment Type" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
