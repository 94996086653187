import React, { FC } from 'react';
import { Create, SelectInput, ReferenceInput, BooleanInput, CreateProps } from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { validateSupplier } from './repairsSettingsValidation';
import SimpleForm from 'components/SimpleForm';

import {
  RepairPaymentType,
  RepairResourceType,
  BumperPaymentType,
} from './constants';

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
});

export const RepairsSettingsCreate: FC<CreateProps> = props => {
  const classes = useStyles();

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm validate={validateSupplier}>
        <Box className={classes.fields}>
          <ReferenceInput
            perPage={Number.MAX_SAFE_INTEGER}
            sort={{ field: 'description', order: 'ASC' }}
            className={classes.input}
            fullWidth
            label="Repair Type"
            source="repairId"
            reference="repair"
          >
            <SelectInput optionText="description" />
          </ReferenceInput>
          <SelectInput
            fullWidth
            className={classes.input}
            source="repairResourceType"
            label="Resource Type"
            choices={RepairResourceType}
          />
        </Box>
        <Box className={classes.fields}>
          <SelectInput
            fullWidth
            source="repairBumperPaymentType"
            label="Bumper Payment Type"
            className={classes.input}
            choices={BumperPaymentType}
          />
          <SelectInput
            fullWidth
            source="repairPaymentType"
            className={classes.input}
            label="Repair Payment Type"
            choices={RepairPaymentType}
          />
        </Box>
        <Box className={classes.fields}>
          <BooleanInput
            source="active"
            defaultValue={true}
            className={classes.input}
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
