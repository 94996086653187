import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BuildIcon from '@material-ui/icons/Build';
import AssessmentIcon from '@material-ui/icons/Assessment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MapIcon from '@material-ui/icons/Map';
import GroupIcon from '@material-ui/icons/Group';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SecurityIcon from '@material-ui/icons/Security';
import HouseIcon from '@material-ui/icons/House';
import GridOnIcon from '@material-ui/icons/GridOn';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachMoney from '@material-ui/icons/AttachMoney';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PinDropIcon from '@material-ui/icons/PinDrop';

import UserModule from 'modules/users';
import MaterialsDeliveryModule from 'modules/materials-delivery';
import SupplierModule from 'modules/supplier';
import RolesModule from 'modules/roles';
import RegionsModule from 'modules/regions';
import BuildersModule from 'modules/builders';
import ModelsModule from 'modules/model';
import ScheduleModule from 'modules/schedule';
import JobRepairSheetModule from 'modules/job-repair-sheet';
import MasterSheetModule from 'modules/master-sheet';
import WarrantySheetModule from 'modules/job-warranty-sheet';
import ModelBoardSheetModule from 'modules/model-board-sheet';
import RMMasterSheetModule from 'modules/rm-master-sheet';
import ProdPayMasterSheet from 'modules/prod-pay-master-sheet';
import RepairReceivablesSheet from 'modules/repair-receivables-sheet';
import RepairPayablesSheet from 'modules/repair-payables-sheet';
import BoardModule from 'modules/board';
import JobsModule from 'modules/jobs';
import RepairsSettings from 'modules/repairs-settings';
import ContractorsModule from 'modules/contractor';
import SubDivisionsModule from 'modules/sub-divisions';
import PreRockSheet from 'modules/pre-rock-sheet';
import { Handyman } from 'components/Icons';
import PendingJobsModule from 'modules/pending-jobs';
import BumpoutTimesheetsModule from 'modules/bumpout-timesheet';
import RoutesModule from 'modules/route';

export enum AppModules {
  // Settings
  UserModule = 'UserModule',
  RolesModule = 'RolesModule',
  SupplierModule = 'SupplierModule',
  RegionsModule = 'RegionsModule',
  BuildersModule = 'BuildersModule',
  SubDivisionsModule = 'SubDivisionsModule',
  ModelsModule = 'ModelsModule',
  ModelBoardSheetModule = 'ModelBoardSheetModule',
  BoardModule = 'BoardModule',
  ContractorsModule = 'ContractorsModule',
  RepairsSettings = 'RepairsSettings',

  // General
  MaterialsDeliveryModule = 'MaterialsDeliveryModule',
  MasterSheetModule = 'MasterSheetModule',
  WarrantySheetModule = 'WarrantySheetModule',
  ScheduleModule = 'ScheduleModule',
  RMMasterSheetModule = 'RMMasterSheetModule',
  ProdPayMasterSheet = 'ProdPayMasterSheet',
  RepairReceivablesSheet = 'RepairReceivablesSheet',
  RepairPayablesSheet = 'RepairPayablesSheet',
  JobsModule = 'JobsModule',
  PendingJobsModule = 'PendingJobsModule',
  PreRockSheet = 'PreRockSheet',
  JobRepairSheetModule = 'JobRepairSheetModule',
  BumpoutTimesheetsModule = 'BumpoutTimesheetsModule',
  RoutesModule = 'RoutesModule',
}

export const settingsModules = [
  {
    name: AppModules.UserModule,
    module: UserModule,
    icon: GroupIcon,
  },
  {
    name: AppModules.RolesModule,
    module: RolesModule,
    icon: SecurityIcon,
  },
  {
    name: AppModules.SupplierModule,
    module: SupplierModule,
    icon: LocalShippingIcon,
  },
  {
    name: AppModules.RegionsModule,
    module: RegionsModule,
    icon: MapIcon,
  },
  {
    name: AppModules.BuildersModule,
    module: BuildersModule,
    icon: ApartmentIcon,
  },
  {
    name: AppModules.SubDivisionsModule,
    module: SubDivisionsModule,
    icon: HomeWorkIcon,
  },
  {
    name: AppModules.RoutesModule,
    module: RoutesModule,
    icon: PinDropIcon,
  },
  {
    name: AppModules.ModelsModule,
    module: ModelsModule,
    icon: HouseIcon,
  },
  {
    name: AppModules.ModelBoardSheetModule,
    module: ModelBoardSheetModule,
    icon: HomeWorkIcon,
  },
  {
    name: AppModules.BoardModule,
    module: BoardModule,
    icon: DashboardIcon,
  },
  {
    name: AppModules.ContractorsModule,
    module: ContractorsModule,
    icon: BuildIcon,
  },
  {
    name: AppModules.RepairsSettings,
    module: RepairsSettings,
    icon: GridOnIcon,
  },
];

export const generalModules = [
  {
    name: AppModules.ScheduleModule,
    module: ScheduleModule,
    icon: PinDropIcon,
    mobileOnly: true,
  },
  {
    name: AppModules.JobRepairSheetModule,
    module: JobRepairSheetModule,
    icon: Handyman,
    mobileOnly: true,
  },
  {
    name: AppModules.PendingJobsModule,
    module: PendingJobsModule,
    icon: DescriptionIcon,
  },
  {
    name: AppModules.JobsModule,
    module: JobsModule,
    icon: DescriptionIcon,
  },
  {
    name: AppModules.MaterialsDeliveryModule,
    module: MaterialsDeliveryModule,
    icon: LocalShippingIcon,
  },
  {
    name: AppModules.MasterSheetModule,
    module: MasterSheetModule,
    icon: GridOnIcon,
  },
  {
    name: AppModules.PreRockSheet,
    module: PreRockSheet,
    icon: HomeWorkIcon,
  },
  {
    name: AppModules.WarrantySheetModule,
    module: WarrantySheetModule,
    icon: GridOnIcon,
  },
  {
    name: AppModules.RMMasterSheetModule,
    module: RMMasterSheetModule,
    icon: AssessmentIcon,
  },
  {
    name: AppModules.ProdPayMasterSheet,
    module: ProdPayMasterSheet,
    icon: AttachMoney,
  },
  {
    name: AppModules.RepairReceivablesSheet,
    module: RepairReceivablesSheet,
    icon: AttachMoney,
  },
  {
    name: AppModules.RepairPayablesSheet,
    module: RepairPayablesSheet,
    icon: AttachMoney,
  },
  {
    name: AppModules.BumpoutTimesheetsModule,
    module: BumpoutTimesheetsModule,
    icon: AssignmentIcon,
  },
];
