import { ReactElement, useState } from 'react';

import {
  DataProvider,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { TableDefaultRow } from './TableList';
import { TableAddSubdivision } from './TableAddSubdivision';
import { RouteDto } from '@vatos-pas/common';
import { UserChoice } from '../views/route-create';
import { Modal } from './Modal';
import { TableDraftRowPayload } from './TableDraftRow';

interface ModalTableAddSubdivisionProps {
  rows: TableDefaultRow[] | null;
  open: boolean;
  columns: string[];
  supervisor: UserChoice | null;
  draftRows: TableDraftRowPayload[] | null;
  type?: 'edit' | 'create';
  updateDraftRows: (rows: TableDraftRowPayload[] | null) => void;
  onClose: () => void;
}

export const ModalTableAddSubdivision = ({
  rows,
  open,
  columns,
  supervisor,
  draftRows,
  type = 'create',
  updateDraftRows,
  onClose,
}: ModalTableAddSubdivisionProps): ReactElement => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { values } = useFormState();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [_draftRows, _setDraftRows] = useState<TableDraftRowPayload[] | null>(
    draftRows,
  );

  const currentSupervisor: UserChoice = {
    id: values.id,
    name: `${values?.supervisorUser?.firstName ?? ''} ${
      values?.supervisorUser?.lastName ?? ''
    }`,
  };

  const updateSubdivisionsRoute = async (
    subdivisionIds: string[],
    route: RouteDto,
    dataProvider: DataProvider,
  ) => {
    try {
      setLoading(true);
      await dataProvider.update('route', {
        id: route.id,
        data: {
          subdivisions: subdivisionIds.map(subdivisionId => ({
            id: subdivisionId,
          })),
        },
        previousData: route,
      });
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const handleApplyChanges = async () => {
    if (type === 'create') {
      updateDraftRows(_draftRows);
      return;
    }

    const ids = _draftRows?.map(draftRow => draftRow.subdivisionId);

    if (!ids) {
      return;
    }

    await updateSubdivisionsRoute(ids, values as RouteDto, dataProvider);
    refresh(true);
  };

  return (
    <Modal
      title="Subdivision Addition"
      open={open}
      isApplyChangesDisabled={_draftRows?.some(
        draftRow => !draftRow.subdivisionId,
      )}
      onApplyChanges={handleApplyChanges}
      loading={loading}
      onClose={onClose}
      maxWidth="md"
    >
      <TableAddSubdivision
        draftRows={_draftRows}
        setDraftRows={_setDraftRows}
        rows={rows}
        columns={columns}
        route={values.name}
        supervisor={supervisor ?? currentSupervisor}
      />
    </Modal>
  );
};
