import { useEffect, useState } from 'react';
import { useNotify, useRefresh } from 'react-admin';

import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { dataProvider } from 'providers/dataProvider';
import {
  BumpResourceSheetDto,
  JobPhaseBumpNoteDto,
  RepairPaymentNoteDto,
} from '@vatos-pas/common';

type NotesManagementDialog = {
  handleClose: () => void;
  record: BumpResourceSheetDto;
} & DialogProps;

export const NotesManagementDialog = ({
  record,
  handleClose,
  open,
  ...rest
}: NotesManagementDialog) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<
    (JobPhaseBumpNoteDto | RepairPaymentNoteDto)[] | null
  >(null);
  const [note, setNote] = useState('');

  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';
  const resource = isJobPhaseBump
    ? 'job-phase-bump-note'
    : 'repair-payment-note';
  const resourceFilter = isJobPhaseBump
    ? { jobPhaseBumpId: record.id }
    : { repairPaymentId: record.id };

  const getNotes = async () => {
    try {
      setLoading(true);
      const notesResponse = await dataProvider.getList<
        JobPhaseBumpNoteDto | RepairPaymentNoteDto
      >(resource, {
        filter: resourceFilter,
        pagination: { page: 1, perPage: 1000 },
        sort: { field: 'createdAt', order: 'DESC' },
      });

      setNotes(notesResponse.data);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  const submitNote = async () => {
    try {
      await dataProvider.create(resource, {
        data: {
          note,
          ...resourceFilter,
        },
      });

      if (notes?.length === 0) {
        refresh();
        handleClose();
      } else {
        getNotes();
      }

      setNote('');
      setNotes([]);
    } catch (err) {
      notify('Failed to create a note', 'error');
    }
  };

  useEffect(() => {
    if (record.id && open) {
      getNotes();
    }
  }, [open, record.id]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      {...rest}
    >
      <DialogContent>
        <Box width="100vw" pb={3} maxWidth="40vw">
          <Box>
            <TextField
              label="Notes"
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              inputProps={{
                maxLength: 999,
              }}
              value={note}
              onChange={event => setNote(event.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="space-between" my={3}>
            <Button
              disabled={note.length <= 0}
              onClick={submitNote}
              color="primary"
              variant="contained"
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                handleClose();
                setNote('');
                setNotes([]);
              }}
              color="primary"
              variant="contained"
            >
              Close
            </Button>
          </Box>
          <Box>
            <Box borderTop={1} pt={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Notes History
              </Typography>
            </Box>
            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            ) : (
              <>
                {notes?.map((item, index) => (
                  <Box
                    key={item.id}
                    mb={2}
                    borderTop={index === 0 ? '' : 1}
                    borderColor="lightgray"
                    pt={2}
                  >
                    <Box>
                      <Box>
                        <Typography>{`${item.user?.firstName} ${
                          item.user?.lastName
                        } - ${new Date(item.createdAt).toLocaleTimeString(
                          'en-US',
                        )} ${new Date(item.createdAt).toLocaleDateString(
                          'en-US',
                        )}`}</Typography>
                      </Box>
                      <Box mt={2}>
                        <Typography>{item.note}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NotesManagementDialog;
