/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ImageViewer from './master-sheet-image-viewer';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
});

export const MasterSheetRepairsCheckPhotos = (props: any) => {
  const classes = useStyles();
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<any>();

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">{props.title}</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            {props.photos.map((photo: any) => {
              return (
                <img
                  className={classes.img}
                  onClick={() => {
                    setImageSelected(photo);
                    setImageViewerOpen(true);
                  }}
                  src={photo.fileUrl}
                  key={photo.id}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
      <ImageViewer
        imageSelected={imageSelected}
        open={imageViewerOpen}
        isViewPhotos
        handleClose={() => setImageViewerOpen(false)}
      />
    </Dialog>
  );
};

export default MasterSheetRepairsCheckPhotos;
