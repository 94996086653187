import { useState } from 'react';
import { InjectedFieldProps, PublicFieldProps } from 'react-admin';
import { BumpResourceSheetInterface } from '@vatos-pas/common';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';

import { Show } from 'components/Show';
import InfoDialog from 'components/InfoDialog';

type AddressFieldProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetInterface>;

const AddressField = ({ record }: AddressFieldProps) => {
  const classes = useStyles();

  if (!record) return null;

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box display="flex" alignItems="center" gridColumnGap={4}>
      <Show condition={!!record.fullAddress}>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <InfoOutlinedIcon className={classes.infoWarning} />
        </IconButton>
      </Show>
      <InfoDialog
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        title="Address"
        content={
          <>
            {record.fullAddress && (
              <Typography>{record.fullAddress}</Typography>
            )}
          </>
        }
      />
    </Box>
  );
};

const useStyles = makeStyles({
  infoWarning: {
    color: '#ff9800',
  },
});

export default AddressField;
