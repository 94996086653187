import { useState } from 'react';

import {
  InjectedFieldProps,
  PublicFieldProps,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { DateTime } from 'luxon';
import IconButton from '@material-ui/core/IconButton';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import SaveIcon from '@material-ui/icons/Save';

import { Show } from 'components/Show';
import { CAN_LOG_HOURS_BUMPOUT } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { BumpoutTimesheetFields } from '../views/bumpout-timesheet-list';

type ButtonProps = {
  fields: BumpoutTimesheetFields | null;
  isJobPhaseBump: boolean;
  handleRefetch: () => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const Button = ({
  record,
  fields,
  isJobPhaseBump,
  handleRefetch,
}: ButtonProps) => {
  if (!record?.id) return null;

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitClick = async () => {
    setIsLoading(true);

    try {
      await dataProvider.update(
        isJobPhaseBump ? '/job-phase-bump' : '/repair-payment',
        {
          id: record.id,
          data: {
            timeIn:
              currentFields?.timeIn instanceof DateTime &&
              currentFields.timeIn.toFormat('HH:mm:00'),
            timeOut:
              currentFields?.timeOut instanceof DateTime &&
              currentFields.timeOut.toFormat('HH:mm:00'),
          },
          previousData: record,
        },
      );

      notify('Worked hours logged.');
      handleRefetch();
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const currentFields = fields?.[record.id];

  const numberValue =
    typeof currentFields?.workedHours === 'string'
      ? parseFloat(currentFields.workedHours)
      : null;

  const workedHoursDirty =
    record.workedHours && numberValue !== record.workedHours;

  return (
    <IconButton
      color="primary"
      size="small"
      disabled={
        workedHoursDirty ||
        isLoading ||
        !currentFields?.timeIn ||
        !currentFields?.timeOut ||
        isLoading
      }
      onClick={handleSubmitClick}
    >
      <SaveIcon />
    </IconButton>
  );
};

type SaveButtonProps = {
  fields: BumpoutTimesheetFields | null;
  handleRefetch: () => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const SaveButton = ({ record, fields, handleRefetch }: SaveButtonProps) => {
  if (!record?.id) return null;

  const { hasPermission } = PermissionsProvider.useContainer();

  const canLogHours = hasPermission(CAN_LOG_HOURS_BUMPOUT);
  const isBumpoutApproved =
    record?.bumperApprovalStatus === 'APPROVED' ||
    record?.bumperApprovalStatus === 'MISMATCH_APPROVAL' ||
    record?.bumperApprovalStatus === 'OVERRIDDEN';
  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';

  if (isBumpoutApproved) return null;

  return (
    <Show condition={canLogHours && !isBumpoutApproved}>
      <Button
        fields={fields}
        isJobPhaseBump={isJobPhaseBump}
        record={record}
        handleRefetch={handleRefetch}
      />
    </Show>
  );
};

export default SaveButton;
