import React, { FC, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    maxWidth: '500px',
    width: '100%',
    maxHeight: '80%',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

type ImageViewerRepairProps = {
  open: boolean;
  handleClose(): void;
  imageSelected: string;
};

export const ImageViewerRepair: FC<ImageViewerRepairProps> = ({
  open,
  handleClose,
  imageSelected,
}) => {
  const classes = useStyles();

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image Viewer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {imageSelected && (
              <img className={classes.img} src={imageSelected}></img>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ImageViewerRepair;
