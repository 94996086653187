import {
  RepairBumperPaymentTypeEnum,
  RepairFormulaInterface,
} from '@vatos-pas/common';

const findRepairFormula = (
  repairFormulas: RepairFormulaInterface[] | undefined,
  repairId: string,
  paymentType: RepairBumperPaymentTypeEnum,
) => {
  return repairFormulas?.find(item => {
    return (
      item.repairId === repairId && item.repairBumperPaymentType === paymentType
    );
  });
};

const getContractorFilter = (
  repairFormulas: RepairFormulaInterface[] | undefined,
  repairId: string,
  regionId: string,
  bumpResourceType: string,
) => {
  const commonFilter = {
    active: true,
    suspended: false,
    archived: false,
    bumperAvailable: true,
    'contractorRegions.regionId': regionId,
    $join: [{ field: 'contractorRegions' }],
  };

  const isRepairPayment = bumpResourceType === 'REPAIR_PAYMENT';

  if (!isRepairPayment) {
    return commonFilter;
  }

  const perBoard = findRepairFormula(
    repairFormulas,
    repairId,
    RepairBumperPaymentTypeEnum.PerBoard,
  );
  const perHour = findRepairFormula(
    repairFormulas,
    repairId,
    RepairBumperPaymentTypeEnum.PerHour,
  );

  const hasPerBoard = perBoard !== undefined;
  const hasPerHour = perHour !== undefined;

  return {
    ...commonFilter,
    ...(isRepairPayment &&
      !hasPerBoard &&
      hasPerHour && {
        'bumperPaymentType||$eq': RepairBumperPaymentTypeEnum.PerHour,
      }),
    ...(isRepairPayment &&
      hasPerBoard &&
      !hasPerHour && {
        'bumperPaymentType||$eq': RepairBumperPaymentTypeEnum.PerBoard,
      }),
  };
};

export { getContractorFilter, findRepairFormula };
