import { FC } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { useContainer } from 'unstated-next';
import { DefaultModelContainer } from '../../providers/default-model';
import { JobContainer } from '../../providers/job';
import { useDataProvider, useNotify } from 'react-admin';
import { MaterialDto } from 'modules/jobs/types';
import { BoardPurchaseFloorDto } from '../../../../../../common/dist';
import { Show } from 'components/Show';
import { createPurchaseOrder } from 'services/jobs';
import { Dictionary, groupBy } from 'lodash';

const validateFields = (
  floors: Dictionary<BoardPurchaseFloorDto[]>,
  supplierId: string,
  materials?: MaterialDto[],
) => {
  const newErrors = {};

  // Supplier
  if (!supplierId) {
    newErrors['supplier'] = 'The Supplier is Required';
  }

  // Materials
  materials?.forEach((material, indexMaterial: number) => {
    if (!material.amount && material.amount !== 0) {
      if (!newErrors['materials']) {
        newErrors['materials'] = {};
      }
      if (!newErrors['materials'][indexMaterial]) {
        newErrors['materials'][indexMaterial] = {};
      }
      newErrors['materials'][indexMaterial]['amount'] =
        'The Amount is Required';
    }
    if (!material.name) {
      if (!newErrors['materials']) {
        newErrors['materials'] = {};
      }
      if (!newErrors['materials'][indexMaterial]) {
        newErrors['materials'][indexMaterial] = {};
      }
      newErrors['materials'][indexMaterial]['name'] = 'The Type is Required';
    }
    if (!material.unit) {
      if (!newErrors['materials']) {
        newErrors['materials'] = {};
      }
      if (!newErrors['materials'][indexMaterial]) {
        newErrors['materials'][indexMaterial] = {};
      }
      newErrors['materials'][indexMaterial]['unit'] = 'The Unit is Required';
    }
  });
  // Board Purchases
  Object.values(floors).forEach(floor => {
    floor.forEach((board, boardIndex) => {
      if (!board.boardTypeId) {
        if (!newErrors['boards']) {
          newErrors['boards'] = {};
        }
        if (!newErrors['boards'][board.floor]) {
          newErrors['boards'][board.floor] = {};
        }
        if (!newErrors['boards'][board.floor][boardIndex]) {
          newErrors['boards'][board.floor][boardIndex] = {};
        }
        newErrors['boards'][board.floor][boardIndex]['boardTypeId'] =
          'The Board Type is Required';
      }
      if (!board.quantityOrdered && board.quantityOrdered !== 0) {
        if (!newErrors['boards']) {
          newErrors['boards'] = {};
        }
        if (!newErrors['boards'][board.floor]) {
          newErrors['boards'][board.floor] = {};
        }
        if (!newErrors['boards'][board.floor][boardIndex]) {
          newErrors['boards'][board.floor][boardIndex] = {};
        }
        if (!newErrors['boards'][board.floor][boardIndex]) {
          newErrors['boards'][board.floor][boardIndex] = {};
        }
        newErrors['boards'][board.floor][boardIndex]['quantityOrdered'] =
          'The Quantity is Required';
      }
    });
  });

  if (Object.keys(newErrors).length) {
    // Building or BuildingId
    return {
      valid: false,
      errors: newErrors,
    };
  } else {
    return {
      valid: true,
      errors: {},
    };
  }
};

export const PurchaseOrderFooter: FC = () => {
  const {
    selectedPhase,
    draftPurchaseOrder,
    isEdit,
    isDraft,
    setErrors,
    setDraftPurchaseOrder,
    setIsLoading,
    setPOHasBoardExcelFloors,
    setSameSupplier,
    isLoading,
    isNew,
  } = useContainer(PurchaseOrderContainer);
  const {
    hasDefaultModel,
    createModelDefaults,
    setCreateModelDefaults,
    setImportedDefault,
    getDefaultModel,
  } = useContainer(DefaultModelContainer);

  const {
    job,
    loadJobData,
    setIsPurchaseOrderOpen,
    purchaseOrders,
    selectedPurchaseOrder,
  } = useContainer(JobContainer);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const currentJobPhase = job?.jobPhases?.find(
    job => job.phase?.id === selectedPhase,
  );

  const handleConfirm = async (fields, job) => {
    if (!job?.id) return;

    const { supplierId, boardPurchaseFloors } = fields;
    const boardsGroupedByFloor = groupBy<BoardPurchaseFloorDto>(
      boardPurchaseFloors,
      'floor',
    );

    const validation = validateFields(
      boardsGroupedByFloor,
      supplierId,
      draftPurchaseOrder.materials,
    );

    if (!validation.valid) {
      return setErrors(validation.errors);
    } else {
      setErrors({});
    }

    const jobPhaseId = job.jobPhases.find(
      item => item.phaseId === selectedPhase,
    ).id;

    const data = {
      orderNumber: fields.orderNumber,
      dateExpected:
        typeof fields.dateExpected === 'string'
          ? fields.dateExpected
          : fields.dateExpected && fields.dateExpected.toISO(),
      supplierId,
      jobId: job.id,
      laborOnly: fields.laborOnly,
      isReplacement: fields.isReplacement,
      approved: false,
      approvedGeo: null,
      boardPurchaseFloors: fields.boardPurchaseFloors,
      materials: fields.materials,
      notesForSupplier: fields.notesForSupplier,
      createModelDefaults,
      jobPhaseId,
    };

    try {
      setIsLoading(true);

      await createPurchaseOrder(dataProvider, { data });
      await loadJobData(job.id);
      await getDefaultModel(job.modelId);
      notify('Board Purchase created with success!');

      setPOHasBoardExcelFloors(false);
      setIsPurchaseOrderOpen(false);
    } catch (err: any) {
      notify(`Board Purchase creation Failed! ${err.message}`, 'warning');
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setIsPurchaseOrderOpen(false);
    setPOHasBoardExcelFloors(false);
    setImportedDefault(false);
    setSameSupplier(true);

    if (purchaseOrders && purchaseOrders?.length > 0) {
      setCreateModelDefaults(false);
    } else {
      setCreateModelDefaults(true);
    }
  };

  return (
    <Box>
      <Show
        condition={
          !!selectedPhase &&
          !hasDefaultModel(currentJobPhase) &&
          (!selectedPurchaseOrder || isDraft)
        }
      >
        <Box mt={2}>
          <FormControlLabel
            control={
              <Switch
                checked={createModelDefaults}
                onChange={event => {
                  const { checked } = event.target;

                  setCreateModelDefaults(checked);
                }}
                disabled={draftPurchaseOrder?.laborOnly || (isEdit && !isDraft)}
                name="createModelDefaults"
                color="primary"
              />
            }
            label="Save as Default Model specifications"
          />
        </Box>
      </Show>
      <Box mt={2}>
        <TextField
          label="Notes"
          fullWidth
          multiline
          rows={3}
          className={classes.input}
          disabled={isEdit && !isDraft}
          value={
            isEdit && !isDraft
              ? selectedPurchaseOrder?.notesForSupplier
              : draftPurchaseOrder.notesForSupplier
          }
          onChange={event =>
            setDraftPurchaseOrder(prevState => ({
              ...prevState,
              notesForSupplier: event.currentTarget.value,
            }))
          }
        />
      </Box>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {isNew || isDraft ? 'Cancel' : 'Close'}
        </Button>
        <Show condition={isNew || isDraft}>
          <Button
            disabled={isLoading || !selectedPhase}
            onClick={() => handleConfirm(draftPurchaseOrder, job)}
            color="primary"
            autoFocus
          >
            Submit
          </Button>
        </Show>
      </DialogActions>
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
});
