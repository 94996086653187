import React, { FC, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  warning: {
    fontSize: '40px',
    textAlign: 'center',
    color: '#e6005a',
    marginTop: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonConfirmCancel: {
    marginLeft: '10px',
    height: '40px',
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
    },
  },
  purchaseDeleteTitle: {
    textAlign: 'center',
    fontSize: '20px',
  },
  underline: {
    textDecoration: 'underline',
  },
});

type FulfillmentModalProps = {
  open: boolean;
  onClose(): void;
  onConfirm(sendEmail: boolean): void;
  disabled?: boolean;
};

export const FulfillmentModal: FC<FulfillmentModalProps> = ({
  open,
  onClose,
  onConfirm,
  disabled = false,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (!open) {
      // reset states
      setChecked(true);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <Typography className={classes.warning}>Warning!</Typography>
      <DialogContent className={classes.content}>
        <Typography className={classes.purchaseDeleteTitle}>
          Are you sure you want to stop the fulfillment of this purchase order?
          <br />
          <Typography
            className={`${classes.purchaseDeleteTitle} ${classes.underline}`}
          >
            This action cannot be undone
          </Typography>
        </Typography>
        <Box display="flex" alignItems="center" mt="20px">
          <Checkbox checked={checked} onClick={() => setChecked(c => !c)} />
          <Typography>Send email notification to supplier</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX="20px"
          paddingY="20px"
        >
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm(checked)}
            color="primary"
            variant="contained"
            className={classes.buttonConfirmCancel}
            disabled={disabled}
          >
            Confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
