import React, { FC, useEffect, useState } from 'react';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  PublicFieldProps,
  InjectedFieldProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { useFormState } from 'react-final-form';
import { SubdivisionInterface } from '@vatos-pas/common';

import { getCoordinatorsByRegion } from 'services/subDivisions';
import { SupervisorInput, TableDraftRowPayload } from '../components';
import { UserChoice } from '../views/route-create';

type ReferencedInputsProps = {
  isRegionDisabled?: boolean;
  updateDraftRows: (rows: TableDraftRowPayload[] | null) => void;
  onSupervisorChange: (supervisor: UserChoice | null) => void;
} & PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface>;

export const ReferencedInputs: FC<ReferencedInputsProps> = ({
  isRegionDisabled = false,
  updateDraftRows,
  onSupervisorChange,
}) => {
  const { values } = useFormState();

  const [usersChoice, setUsersChoice] = useState<UserChoice[]>([]);

  const getSupervisors = async (regionId: string) => {
    updateDraftRows(null);
    onSupervisorChange(null);

    const users = await getCoordinatorsByRegion({
      regionId,
    });
    setUsersChoice(
      users.data.data.map(({ id, firstName, lastName }) => ({
        id,
        name: `${firstName} ${lastName}`,
      })),
    );
  };

  const handleChangeSupervisor = (value: string | undefined) => {
    updateDraftRows(null);

    const supervisor = usersChoice?.find(user => user.id === value);

    if (supervisor) {
      onSupervisorChange(supervisor);
    }
  };

  useEffect(() => {
    const initSupervisors = async () => {
      await getSupervisors(values.regionId);
    };

    initSupervisors();
  }, []);

  return (
    <Box display="flex" width="100%" gridGap={16}>
      <TextInput source="name" label="Name" fullWidth />
      <ReferenceInput
        sort={{ field: 'name', order: 'ASC' }}
        disabled={isRegionDisabled}
        fullWidth
        onChange={event => getSupervisors(event.target.value)}
        label="Region"
        source="regionId"
        reference="region"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <SupervisorInput
        source="supervisorUserId"
        regionId={values.regionId}
        onChange={handleChangeSupervisor}
        options={usersChoice}
      />
    </Box>
  );
};
