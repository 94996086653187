import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  whiteBox: {
    margin: '8px 16px',
    padding: '8px 16px',
    backgroundColor: 'white',
    borderRadius: '10px',
    border: '1px solid lightgray',
    overflow: 'hidden',
    gap: '16px',
  },
  label: {
    color: '#666',
    fontSize: '12px',
  },
  info: {
    gap: '4px',
  },
});
