import { RepairBumperPaymentTypeEnum, RepairPaymentTypeEnum, RepairResourceTypeEnum } from "@vatos-pas/common";

interface IErrors {
  repairId?: string[];
  repairResourceType?: string[];
  repairBumperPaymentType?: string[];
  repairPaymentType?: string[];
}

interface SupplierValues {
  repairId?: string;
  repairResourceType?: RepairResourceTypeEnum;
  repairBumperPaymentType?: RepairBumperPaymentTypeEnum;
  repairPaymentType?: RepairPaymentTypeEnum;
}

export const validateSupplier = async (values: SupplierValues): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.repairId) {
    errors.repairId = ['The Repair Type is required'];
  }
  if (!values.repairResourceType) {
    errors.repairResourceType = ['The Resource Type Id is required'];
  }
  if (!values.repairBumperPaymentType) {
    errors.repairBumperPaymentType = ['The Bumper Payment Type is required'];
  }
  if (!values.repairPaymentType) {
    errors.repairPaymentType = ['The Repair Payment Type is required'];
  }
  if (
    values.repairResourceType === 'Bumper' &&
    values.repairBumperPaymentType === 'NA'
  ) {
    errors.repairBumperPaymentType = [
      "If the Resource Type is Bumper, the Bumper Payment Type can't be NA",
    ];
  }
  if (
    values.repairResourceType !== 'Bumper' &&
    values.repairPaymentType === 'PerDay'
  ) {
    errors.repairPaymentType = [
      'Error! This Repair Payment Type is exclusive to Bumpers, please select a different Repair Payment Type to proceed.',
    ];
  }
  if (
    values.repairResourceType !== 'Bumper' &&
    values.repairPaymentType === 'ExtraHours'
  ) {
    errors.repairPaymentType = [
      'Error! This Repair Payment Type is exclusive to Bumpers, please select a different Repair Payment Type to proceed.',
    ];
  }
  return errors;
};
