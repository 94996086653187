import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  ReactElement,
} from 'react';
import _, { Dictionary } from 'lodash';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { dataProvider } from 'providers/dataProvider';
import { Identifier, Record } from 'react-admin';

const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});

type Sort = { field: string; order: 'ASC' | 'DESC' };

type Filter = { [key: string]: string | boolean | number | Filter[] };

type Options = {
  id: Identifier;
  name: string;
};

const getList = async <T extends Record>(
  resource: string,
  setOptions: Dispatch<SetStateAction<T[]>>,
  filters?: Filter | ((data: T[]) => Filter) | null,
  sort?: Sort,
) => {
  const response = await dataProvider.getList<T>(resource, {
    filter: filters ? filters : {},
    pagination: { page: 1, perPage: 9999 },
    sort: sort ? sort : { field: 'id', order: 'ASC' },
  });

  setOptions(response.data);
};

interface ICustomReference<T extends unknown> {
  label: string;
  resource: string;
  onChange?: SelectInputProps['onChange'];
  onLoad?: (data: T[], more: Dictionary<T>) => void;
  disabled?: boolean;
  filters?: Filter | ((data: T[]) => Filter) | null;
  sort?: Sort;
  value?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  customItemName?: string;
  needsFilter?: boolean;
  formatResponse?: any;
  appendExternalId?: boolean;
}

export function CustomReference<T extends Record>(
  props: ICustomReference<T>,
): ReactElement {
  const classes = useStyles();
  const [options, setOptions] = useState<T[]>([]);

  useEffect(() => {
    if ((props.needsFilter && props.filters) || !props.needsFilter) {
      getList<T>(props.resource, setOptions, props.filters, props.sort);
    }
  }, [JSON.stringify(props.filters)]);

  useEffect(() => {
    props.onLoad?.(options, _.keyBy(options, 'id'));
  }, [JSON.stringify(options)]);

  let newOptions: Options[] = options.map(item => {
    const name = props.appendExternalId
      ? `${item.name} (${item.externalId})`
      : item.name;

    return {
      id: item.id,
      name: props.customItemName ? item[props.customItemName] : name,
    };
  });

  if (props.formatResponse && newOptions.length > 0) {
    newOptions = props.formatResponse(newOptions);
  }

  return (
    <FormControl error={props.error} className={classes.box}>
      <InputLabel id={`custom-reference-${props.resource}`}>
        {props.label}
      </InputLabel>
      <Select
        disabled={props.disabled}
        onChange={props.onChange}
        labelId={`custom-reference-${props.resource}`}
        id={`reference-${props.resource}`}
        fullWidth
        value={props.value}
      >
        {newOptions.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

export default CustomReference;
