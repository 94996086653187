/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, { Fragment, FC } from 'react';
import { List, TextField, ListProps, EditButton } from 'react-admin';
import Datagrid from 'components/Datagrid';
import { makeStyles } from '@material-ui/core';
import { exporter } from 'providers/export';
import { UIProvider } from 'providers/uiProvider';
import { ModelBoardSheetFilters } from '../filters/model-board-sheet-filters';

const useStyles = makeStyles({
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  link: {
    backgroundColor: '#e6005a',
    height: '50px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    color: 'white',
  },
  pink: {
    backgroundColor: 'lightpink',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  normal: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  info: {
    cursor: 'pointer',
    marginRight: '10px',
  },
});

export const ModelBoardSheetList: FC<ListProps> = props => {
  const { opened } = UIProvider.useContainer();
  const classes = useStyles(props);

  return (
    <Fragment>
      <List
        classes={{
          content: opened ? classes.content : classes.contentClosed,
          root: opened ? classes.root : classes.rootClosed,
        }}
        {...props}
        exporter={exporter('model-board-sheet')}
        filters={
          <ModelBoardSheetFilters>{props.filters}</ModelBoardSheetFilters>
        }
        // sort={{ field: 'createdAt', order: 'ASC' }}
        bulkActionButtons={<React.Fragment />}
      >
        <Datagrid>
          <TextField source="builderName" label="Builder" />
          <TextField source="subdivisionName" label="Subdivision" />
          <TextField source="modelName" label="Model" />
          <EditButton />
        </Datagrid>
      </List>
    </Fragment>
  );
};
