import React from 'react';
import { RegionTimeWindowDto } from '@vatos-pas/common';
import { makeStyles } from '@material-ui/core/styles';

import { Modal } from 'components/Modal';
import { RegionTimeWindowTableDataRow } from './types';
import { RegionTimeWindowEdit } from './RegionTimeWindowEdit';

const useStyles = makeStyles({
  originalDialogPaperSm: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '850px !important',
    },
  },
});

type RegionTimeWindowModalProps = {
  rows: RegionTimeWindowTableDataRow[] | null;
  open: boolean;
  columns: string[];
  regionId: string | undefined;
  setRegionTimeWindows: React.Dispatch<
    React.SetStateAction<RegionTimeWindowDto[] | null>
  >;
  onClose: () => void;
};

export const RegionTimeWindowModal = ({
  rows,
  open,
  columns,
  regionId,
  setRegionTimeWindows,
  onClose,
}: RegionTimeWindowModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      title="H/O Service Time Windows"
      maxWidth="sm"
      open={open}
      onClose={onClose}
      className={classes.originalDialogPaperSm}
    >
      <RegionTimeWindowEdit
        regionId={regionId}
        setRegionTimeWindows={setRegionTimeWindows}
        rows={rows}
        columns={columns}
      />
    </Modal>
  );
};
