export const paymentTypeOptions = [
  {
    id: 'PerBoard',
    name: 'PerBoard',
  },
  {
    id: 'PerHour',
    name: 'PerHour',
  },
];

export const bumperPaymentTypeOptions = [
  {
    id: '',
    name: 'N/A',
  },
  {
    id: 'PerBoard',
    name: 'PerBoard',
  },
  {
    id: 'PerHour',
    name: 'PerHour',
  },
];
