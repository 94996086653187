import React, { ReactElement, ReactNode } from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

type ModalProps = {
  children: ReactNode;
  open: boolean;
  title: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  loading?: boolean;
  isApplyChangesDisabled?: boolean;
  onApplyChanges: () => Promise<void>;
  onClose(): void;
} & DialogProps;

export const Modal = ({
  children,
  open,
  title,
  maxWidth = 'xs',
  loading = false,
  isApplyChangesDisabled = false,
  onApplyChanges,
  onClose,
  ...props
}: ModalProps): ReactElement => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth
      {...props}
      keepMounted={false}
    >
      <Typography id="alert-dialog-title" className={classes.heading}>
        {title}
      </Typography>
      <DialogContent className={classes.content}>{children}</DialogContent>
      <DialogActions>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          gridColumnGap="24px"
          justifyContent="end"
          paddingX="20px"
          paddingY="20px"
        >
          <Button
            onClick={async () => {
              await onApplyChanges();
              onClose();
            }}
            color="primary"
            variant="contained"
            size="medium"
            disabled={loading || isApplyChangesDisabled}
          >
            Apply Changes
          </Button>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            size="medium"
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles({
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: '24px 24px 0 24px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 16,
  },
});
