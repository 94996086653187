import React, { FC } from 'react';
import { SpMatchesDefaultTableRow } from './SpMatchesDefaultTableList';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';

import { SpMatchesError } from 'components/SpMatchesDefaultTable';

const MAX_NAME_LENGTH = 250;

export type SpMatchesDefaultDraftRowPayload = Partial<
  Pick<SpMatchesDefaultTableRow, 'name' | 'active'>
>;

export interface SpMatchesDefaultDraftRowProps {
  row: SpMatchesDefaultDraftRowPayload;
  error: SpMatchesError | null;
  handleDraftRowChange: (payload: SpMatchesDefaultDraftRowPayload) => void;
}

export const SpMatchesDefaultDraftRow: FC<SpMatchesDefaultDraftRowProps> =
  ({ row, error, handleDraftRowChange }) => {
    const isTextLengthExceeded =
      !!row?.name?.length && row.name.length > MAX_NAME_LENGTH;
    const errorMessage = isTextLengthExceeded
      ? 'Name must be shorter than or equal to 250 characters.'
      : error?.message;

    return (
      <>
        <TableCell component="th" scope="row">
          <TextField
            fullWidth
            value={row.name}
            error={
              !!error ||
              (!!row?.name?.length && row.name.length > MAX_NAME_LENGTH)
            }
            helperText={errorMessage}
            onChange={event =>
              handleDraftRowChange({
                name: event.target.value,
              })
            }
          />
        </TableCell>
        <TableCell>
          <Checkbox
            checked={row.active}
            onChange={event =>
              handleDraftRowChange({
                active: event.target.checked,
              })
            }
          />
        </TableCell>
      </>
    );
  };
