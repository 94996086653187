import React, { cloneElement, FC, Fragment, ReactElement } from 'react';
import {
  List,
  Filter,
  TextInput,
  TextField,
  FunctionField,
  FilterProps,
  ListProps,
  FunctionFieldProps,
  BooleanField,
  EditButton,
  ToolbarProps,
  ExportButtonProps,
  sanitizeListRestProps,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';

import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';
import { OrderButtons } from 'components/OrderButton';
import { BoardTypeInterface } from '@vatos-pas/common';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CREATE_BOARD } from 'providers/permissions';

const BoardFilter = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$cont" data-cy="boardFilterName" />
    <TextInput
      label="Shortname"
      source="shortName||$cont"
      data-cy="boardFilterShortName"
    />
    <TextInput
      label="External Id"
      source="externalId||$cont"
      data-cy="boardFilterExternalId"
    />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_CREATE_BOARD) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const ResourceTypesField: FC<
  Omit<FunctionFieldProps<BoardTypeInterface>, 'render'>
> = props => (
  <FunctionField<BoardTypeInterface>
    {...props}
    render={record =>
      record?.payableResourceTypes.map((name, key) => (
        <Chip key={key} label={name} />
      ))
    }
  />
);

export const BoardList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('boards')}
      filters={<BoardFilter>{props.filters}</BoardFilter>}
      sort={{ field: 'sortPriority', order: 'ASC' }}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      <Datagrid rowClick={hasPermission(CAN_CREATE_BOARD) ? 'edit' : ''}>
        <TextField source="name" />
        <TextField source="shortName" label="Shortname" />
        <TextField source="externalId" label="External Id" />
        <ResourceTypesField label="Payable Resource Types" />
        <BooleanField source="laborOnly" label="Labor only?" />
        <OrderButtons source="sortPriority" />
        {hasPermission(CAN_CREATE_BOARD) && <EditButton />}
      </Datagrid>
    </List>
  );
};
