import React, { FC } from 'react';
import { AutocompleteInput, Filter, ReferenceInput } from 'react-admin';
import Box from '@material-ui/core/Box';

export const ModelBoardSheetFilters: FC<any> = props => {
  return (
    <Box>
      <Filter {...props}>
        <ReferenceInput
          label="Builder"
          source="builderId"
          reference="builder"
          resettable
          data-cy="masterSheetFilterBuilder"
          alwaysOn
          allowEmpty={false}
          filterToQuery={value => {
            if (value) {
              return { name: value };
            } else {
              return {};
            }
          }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput
            allowEmpty={false}
            optionText="name"
            optionValue="id"
            suggestionLimit={25}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Subdivision"
          source="subdivisionId"
          reference="subdivision"
          resettable
          data-cy="masterSheetFilterSubdivision"
          alwaysOn
          allowEmpty={false}
          filterToQuery={value => {
            if (value) {
              return { name: value };
            } else {
              return {};
            }
          }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          label="Model"
          source="modelId"
          reference="model"
          resettable
          data-cy="masterSheetFilterModel"
          alwaysOn
          allowEmpty={false}
          filterToQuery={value => {
            if (value) {
              return { name: value };
            } else {
              return {};
            }
          }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </Filter>
    </Box>
  );
};
