import {
  BuildersList,
  BuildersCreate,
  BuildersEdit,
  BuildersShow,
} from './views';

export default {
  create: BuildersCreate,
  show: BuildersShow,
  edit: BuildersEdit,
  list: BuildersList,
  name: 'builder',
  label: 'Builders',
  to: '/builder',
};
