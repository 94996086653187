import { DataProvider } from 'react-admin';
import { RoleDto } from '@vatos-pas/common';

export const findManyRoles = async (dataProvider: DataProvider) => {
  try {
    const roles = await dataProvider.getList<RoleDto>('role', {
      filter: {},
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'id', order: 'DESC' },
    });

    return roles.data;
  } catch (error) {
    return error;
  }
};
