import React from 'react';
import { states } from './states';
import { SelectInput } from 'react-admin';

interface IState {
  className?: string;
  fullWidth?: boolean;
  source?: string;
}

export const State: React.FC<IState> = (props: IState) => (
  <SelectInput
    {...props}
    className={props.className}
    label="State"
    choices={states.map(state => ({
      id: state,
      name: state,
    }))}
  />
);
