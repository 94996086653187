import { FC } from 'react';
import { Create, TextInput, SelectArrayInput, CreateProps } from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReferenceManyToManyInput,
  ManyToManyReferenceContextProvider,
} from '@react-admin/ra-relationships';
import { validateSupplier } from './supplierValidation';
import SimpleForm from 'components/SimpleForm';

const useStyles = makeStyles({
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '16px',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
  },
});

export const SuppliersCreate: FC<CreateProps> = props => {
  const classes = useStyles();
  return (
    <Create {...props} className={classes.createBox}>
      <ManyToManyReferenceContextProvider>
        <SimpleForm validate={validateSupplier}>
          <Box className={classes.fields}>
            <TextInput fullWidth source="name" />
            <TextInput fullWidth source="externalId" label="External ID" />
          </Box>
          <Box className={classes.fields}>
            <TextInput fullWidth source="email" />
            <TextInput fullWidth source="phone" />
            <TextInput fullWidth source="contactInfo" />
          </Box>
          <TextInput fullWidth source="description" multiline rows={3} />
          <ReferenceManyToManyInput
            source="id"
            reference="region"
            through="supplier-region"
            using="supplierId,regionId"
            label="Regions"
          >
            <SelectArrayInput fullWidth optionText="name" initialValue="" />
          </ReferenceManyToManyInput>
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </Create>
  );
};
