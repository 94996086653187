import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { CAN_CHANGE_JOB } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { DateTime } from 'luxon';
import { useContainer } from 'unstated-next';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { JobContainer } from '../../providers/job';
import { BoardPurchaseDto } from '../../../../../../common/dist';
import {
  EMPTY_MATERIALS,
  INITIAL_DRAFT_PURCHASE_ORDER,
  INITIAL_FLOORS,
  INITIAL_MATERIALS,
} from '../../constants';
import { Show } from 'components/Show';
import { ShowProps, useDataProvider } from 'react-admin';
import { DefaultModelContainer } from 'modules/jobs/providers/default-model';

const sumBoards = (purchaseOrder: BoardPurchaseDto) => {
  let sum = 0;
  purchaseOrder?.boardPurchaseFloors?.forEach(
    floor => (sum += floor.quantityOrdered),
  );
  return sum;
};

export const CreatePurchaseOrder: FC<ShowProps> = props => {
  const dataProvider = useDataProvider();
  const classes = useStyles();

  const { setCreateModelDefaults } = useContainer(DefaultModelContainer);
  const { setDraftPurchaseOrder, setIsDraft } = useContainer(
    PurchaseOrderContainer,
  );
  const {
    job,
    purchaseOrders,
    setSelectedPurchaseOrder,
    setIsPurchaseOrderOpen,
  } = useContainer(JobContainer);
  const { hasPermission } = PermissionsProvider.useContainer();

  const [clipboardPurchaseOrder, setClipboardPurchaseOrder] =
    useState<BoardPurchaseDto | null>(null);

  const duplicateRef = props.location?.search.split('duplicateRef=')[1];

  const handleSelectedPurchaseOrder = (purchaseOrder: BoardPurchaseDto) => {
    setIsPurchaseOrderOpen(true);
    setIsDraft(false);
    setSelectedPurchaseOrder(purchaseOrder);
  };

  const getClipboardPurchaseOrder = async (id: string) => {
    const purchaseOrders = await dataProvider.getList<BoardPurchaseDto>(
      'board-purchase',
      {
        filter: { jobId: id },
        sort: { field: 'createdAt', order: 'ASC' },
        pagination: { page: 1, perPage: 200 },
      },
    );
    const firstPO = purchaseOrders.data[0];

    if (!firstPO) return;

    setClipboardPurchaseOrder(firstPO);
  };

  const handleCreateDraftPO = async () => {
    if (!clipboardPurchaseOrder) return;

    const {
      boardPurchaseFloors,
      materials,
      laborOnly,
      isReplacement,
      invoiceNumber,
    } = clipboardPurchaseOrder;

    const mappedBoardPurchaseFloors = boardPurchaseFloors?.map(floor => ({
      floor: floor.floor,
      quantityOrdered: floor.quantityOrdered,
      boardTypeId: floor.boardTypeId,
    }));

    const mappedMaterials = materials?.map(material => ({
      name: material.name,
      unit: material.unit,
      amount: material.amount,
    }));

    setDraftPurchaseOrder({
      boardPurchaseFloors: mappedBoardPurchaseFloors,
      materials: mappedMaterials,
      // @ts-expect-error:: FIX TYPES
      dateExpected: DateTime.local(),
      laborOnly,
      isReplacement,
      invoiceNumber,
      notesForSupplier: null,
      orderNumber: '',
      supplierId: '',
    });
    setIsPurchaseOrderOpen(true);
    setIsDraft(true);
    if (clipboardPurchaseOrder?.laborOnly) {
      setCreateModelDefaults(false);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!duplicateRef) return;

      await getClipboardPurchaseOrder(duplicateRef);
    };

    initialize();
  }, []);

  return (
    <Box width="100%">
      <Show condition={!!job?.voided}>
        <Box
          border={1}
          borderRadius={5}
          borderColor="lightgray"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={2}
          className={classes.textVoid}
        >
          <span>VOID</span>
        </Box>
      </Show>

      <Show condition={hasPermission(CAN_CHANGE_JOB) && !job?.voided}>
        <Box
          border={1}
          borderRadius={5}
          borderColor="lightgray"
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={2}
          className={classes.pointer}
          onClick={() => {
            const hasOrders = !!purchaseOrders && purchaseOrders.length > 0;
            const supplierId =
              hasOrders && purchaseOrders?.length
                ? purchaseOrders[purchaseOrders.length - 1]?.supplierId
                : '';

            setSelectedPurchaseOrder(null);

            setDraftPurchaseOrder(prevState => ({
              ...prevState,
              supplierId,
              orderNumber: '',
              notesForSupplier: '',
              invoiceNumber: '',
              laborOnly: false,
              isReplacement: false,
              materials: hasOrders
                ? [...EMPTY_MATERIALS]
                : [...INITIAL_MATERIALS],
              dateExpected: DateTime.local() as unknown as any,
              boardPurchaseFloors: [...INITIAL_FLOORS],
            }));

            setIsPurchaseOrderOpen(true);

            setIsDraft(false);
          }}
        >
          <Typography>Create New Purchase Order</Typography>
        </Box>
      </Show>
      {purchaseOrders &&
        purchaseOrders.length > 0 &&
        purchaseOrders.map((purchaseOrder, index) => (
          <Box
            py={2}
            my={2}
            border={1}
            display="flex"
            gridGap="8px"
            className={classes.pointer}
            borderRadius={5}
            borderColor="lightgray"
            onClick={() => {
              handleSelectedPurchaseOrder(purchaseOrder);
              setDraftPurchaseOrder(INITIAL_DRAFT_PURCHASE_ORDER);
              setIsDraft(false);
            }}
            key={purchaseOrder.id}
            px={2}
          >
            <Box flex={1}>
              <Typography className={classes.poTitle}>
                PO {index + 1}: (
                {purchaseOrder.isReplacement ? 'Replacement ' : ''}
                {sumBoards(purchaseOrder)} Boards)
              </Typography>
              <Typography className={classes.poDescription}>
                Purchase Order ID: {purchaseOrder.orderNumber}
              </Typography>
              <Typography className={classes.poDescription}>
                Expected Delivery Date:{' '}
                {purchaseOrder.dateExpected &&
                  new Date(purchaseOrder.dateExpected).toLocaleDateString(
                    'en-US',
                  )}
              </Typography>
            </Box>
            <Show condition={!!purchaseOrder.canceled}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className={classes.canceledText}>
                  Canceled
                </Typography>
                {purchaseOrder.dateCanceled && (
                  <Typography className={classes.canceledDateText}>
                    {new Date(purchaseOrder.dateCanceled).toLocaleDateString(
                      'en-US',
                    )}
                  </Typography>
                )}
              </Box>
            </Show>
          </Box>
        ))}
      {clipboardPurchaseOrder &&
        purchaseOrders &&
        purchaseOrders?.length === 0 && (
          <Box
            border={1}
            my={2}
            borderRadius={5}
            borderColor="lightgray"
            py={2}
            className={classes.greens}
            onClick={handleCreateDraftPO}
            px={2}
            display="flex"
            justifyContent="center"
          >
            <Typography className={classes.clipboard}>
              Create Purchase Order from Clipboard
            </Typography>
            <AssignmentReturnedIcon />
          </Box>
        )}
    </Box>
  );
};

const useStyles = makeStyles({
  pointer: {
    cursor: 'pointer',
  },
  poTitle: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  greens: {
    cursor: 'pointer',
    backgroundColor: '#4caf51',
  },
  clipboard: {
    marginRight: '10px',
  },
  poDescription: {
    color: 'gray',
    fontWeight: 'bold',
    fontSize: '13px',
  },
  textVoid: {
    '& > span': {
      transform: 'rotate(-20deg)',
      fontSize: 64,
      color: '#EB5757',
      fontWeight: 'bold',
    },
  },
  canceledText: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#EB5757',
  },
  canceledDateText: {
    textAlign: 'center',
    fontSize: '13px',
  },
});
