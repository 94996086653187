import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import { useStyles } from '../master-sheet-styles';

const sum = (items: any[], prop: string) =>
  items.reduce<number>((a: any, b: any) => Number(a) + Number(b[prop]), 0);

interface Props {
  job: any;
  open: boolean;
  title: string;
  theJob: any;
  history: any;
  disabled: boolean;
  boardTypes: any[];
  handleClose: () => void;
  boardPurchase: any;
  boardReceipts: any[];
  boardReceiptFloors: any[];
  boardPurchaseFloors: any[];
}

export const OverrideCountDialog: React.FC<Props> = ({
  job,
  open,
  title,
  theJob,
  history,
  disabled,
  boardTypes,
  handleClose,
  boardPurchase,
  boardReceipts,
  boardReceiptFloors,
  boardPurchaseFloors,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [fields, setFields] = useState<any>([]);
  const [newFields, setNewFields] = useState<any>([]);
  const [floors, setFloors] = useState<any>([]);
  const [notes, setNotes] = useState('');
  const classes = useStyles();

  const onChangeField = (boardTypeId, floor) => event => {
    const newFieldsOverride = { ...newFields };
    newFieldsOverride[floor][boardTypeId] = event.target.value;
    setNewFields(newFieldsOverride);
  };

  useEffect(() => {
    const floorsNumber = [
      ...new Set(boardPurchaseFloors.map((item: any) => item.floor)),
    ];
    const newFloors: any = floorsNumber.map(floorNumber => ({
      name: floorNumber,
      boards: getBoardsPerFloor(floorNumber),
    }));
    const newFieldsOverride = {};
    const newFieldsOverrided = {};
    newFloors.map(floorItem => {
      newFieldsOverride[floorItem.name] = {};
      newFieldsOverrided[floorItem.name] = {};
      floorItem.boards.map(boardItem => {
        const boardPurchaseFloor = boardPurchaseFloors.find(
          boardPurchaseFloorItem =>
            boardPurchaseFloorItem.floor === parseInt(floorItem.name) &&
            boardPurchaseFloorItem.boardTypeId === boardItem.boardTypeId,
        );
        newFieldsOverrided[floorItem.name][boardItem.boardTypeId] =
          boardPurchaseFloor?.quantity || 0;
        newFieldsOverride[floorItem.name][boardItem.boardTypeId] = 0;
      });
    });
    setFields(newFieldsOverrided);
    setNewFields(newFieldsOverride);
    const floorsSorted = newFloors.sort((a, b) => (a.name < b.name ? -1 : 1));
    setFloors(floorsSorted);
    setNotes(boardPurchase.notesForOverride);
  }, [boardPurchase]);

  const onConfirm = async () => {
    const promises: any = [];
    Object.keys(newFields).forEach(floor => {
      Object.keys(newFields[floor]).forEach(boardTypeId => {
        const previousData = boardPurchaseFloors.find(
          boardPurchaseFloor =>
            boardPurchaseFloor.boardTypeId === boardTypeId &&
            boardPurchaseFloor.floor === parseInt(floor),
        );
        const params = {
          id: previousData.id,
          data: { quantity: parseInt(newFields[floor][boardTypeId]) },
          previousData,
        };
        promises.push(dataProvider.update('board-purchase-floor', params));
      });
    });
    const params = {
      id: boardPurchase.id,
      data: { notesForOverride: notes },
      previousData: boardPurchase,
    };
    promises.push(dataProvider.update('board-purchase', params));
    if (job.pendingReview) {
      const paramsJob = {
        id: job.id,
        data: { pendingReview: false },
        previousData: job,
      };
      promises.push(dataProvider.update('job', paramsJob));
    }
    try {
      await Promise.all(promises);
      notify('Board count overridden successfully!');
      history.push('/job-master-sheet');
      handleClose();
    } catch {
      notify(`We have a problem with board count override!`, 'warning');
    }
  };

  const getBoardsPerFloor = floorNumber => {
    const ordered = boardPurchaseFloors.filter(
      boardPurchaseFloor =>
        boardPurchaseFloor.floor === floorNumber &&
        boardPurchaseFloor.boardPurchaseId === boardPurchase.id,
    );
    const receipt = boardReceiptFloors.filter(
      boardReceiptFloor =>
        boardReceiptFloor.floor === floorNumber &&
        boardReceiptFloor.boardPurchaseId === boardPurchase.id,
    );
    const boardsType = [
      ...new Set([...ordered.map((item: any) => item.boardTypeId)]),
    ];
    const floorsBoards = boardsType.map(boardTypeId => ({
      boardTypeId,
      quantityOrdered: sum(
        ordered.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantityOrdered',
      ),
      quantityReceipt: sum(
        receipt.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
    }));

    return floorsBoards;
  };

  const isCountedWrong = boardType => {
    if (!boardReceipts.length) {
      return false;
    }

    if (!boardType) {
      return false;
    }

    const purchaseBoards = boardPurchase.boardPurchaseFloors.filter(
      boardReceipt => boardReceipt.boardTypeId === boardType.id,
    );

    const originalPO = sum(purchaseBoards, 'quantity');

    const receiptBoards = theJob.boardReceiptFloors.filter(
      boardReceipt => boardReceipt.boardTypeId === boardType.id,
    );

    const counted = sum(receiptBoards, 'quantity');
    return originalPO !== counted;
  };

  const isFloorCountedWrong = (floorNumber: number) =>
    getBoardsPerFloor(floorNumber).some(
      ({ quantityOrdered, quantityReceipt }) =>
        quantityOrdered !== quantityReceipt,
    );

  const sumFloorFields = (floorNumber: number) => {
    const floor = fields[floorNumber];
    if (!floor) return 0;
    return Object.values(floor).reduce<number>(
      (acc: number, value) => acc + Number(value),
      0,
    );
  };

  const sumFloorNewFields = (floorNumber: number) => {
    const floor = newFields[floorNumber];
    if (!floor) return 0;
    return Object.values(floor).reduce<number>(
      (acc: number, value) => acc + Number(value),
      0,
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      fullWidth
    >
      <DialogContent>
        <Box mb={2}>
          <Typography gutterBottom>{title}</Typography>
        </Box>
        <Box>
          {floors.map((floor, floorIndex) => (
            <Box key={floorIndex} className={classes.whiteBoxMobile}>
              <Box className={classes.floorHeader}>
                <Typography>Floor #{floor.name}</Typography>
              </Box>
              {floor.boards.map(board => {
                const boardType = boardTypes.find(
                  b => b.id === board.boardTypeId,
                );

                const delta =
                  (parseInt(board.quantityReceipt) || 0) -
                  (parseInt(board.quantityOrdered) || 0);
                const value = Number(newFields[floor.name][board.boardTypeId]);
                const isValueANumber = !Number.isNaN(value);

                const onChange = onChangeField(board.boardTypeId, floor.name);

                const onRemoveClick = () =>
                  onChange({
                    target: {
                      value: !isValueANumber ? 0 : value <= 0 ? 0 : value - 1,
                    },
                  });

                const onAddClick = () =>
                  onChange({
                    target: { value: !isValueANumber ? 0 : value + 1 },
                  });

                return (
                  <Box
                    key={board.id}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.floorGroupMobile}
                  >
                    <Box display="flex" flexDirection="row">
                      <Box display="flex" flex={0.5}>
                        <Box>
                          <Typography
                            className={
                              isCountedWrong(boardType)
                                ? classes.contedWrong
                                : ''
                            }
                          >
                            {boardType?.shortName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        gridGap={16}
                        display="flex"
                        flexDirection="column"
                      >
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            PO
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {board.quantityOrdered}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            Field Count
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {board.quantityReceipt}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            Delta
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {delta}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            Current Qt.
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {fields[floor.name][board.boardTypeId]}
                          </Typography>
                        </Box>
                        {!disabled && (
                          <Box display="flex" alignItems="center">
                            <Box display="flex" flex={1}>
                              <Typography className={classes.mobileFieldText}>
                                Override
                              </Typography>
                            </Box>
                            <Box
                              gridGap={4}
                              display="flex"
                              flex={1}
                              alignItems="center"
                            >
                              <Box
                                className={classes.circleSmall}
                                onClick={onRemoveClick}
                              >
                                <RemoveIcon className={classes.iconCircle} />
                              </Box>
                              <TextField
                                type="number"
                                value={value}
                                placeholder="0"
                                disabled={disabled}
                                onChange={onChange}
                                InputProps={{ inputProps: { min: 0 } }}
                              />
                              <Box
                                className={classes.circleSmall}
                                onClick={onAddClick}
                              >
                                <AddIcon className={classes.iconCircle} />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                display="flex"
                justifyContent="center"
                className={classes.floorGroupMobile}
              >
                <Box display="flex" flex={1} alignItems="center">
                  <Typography>Total boards</Typography>
                </Box>
                <Box
                  display="flex"
                  flex={0.5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={
                      isFloorCountedWrong(floor.name) ? classes.error : ''
                    }
                  >
                    {disabled
                      ? sumFloorFields(floor.name)
                      : sumFloorNewFields(floor.name)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box mt={2}>
          <TextField
            rows={3}
            fullWidth
            multiline
            label="Notes"
            value={notes}
            disabled={disabled}
            className={classes.input}
            onChange={event => setNotes(event.target.value)}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {!disabled && (
            <Button variant="contained" onClick={onConfirm} color="primary">
              Save
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OverrideCountDialog;
