import React from 'react';
import { SelectInput } from 'react-admin';

interface IState {
  className?: string;
  fullWidth?: boolean;
  source?: string;
  label: string;
  options: Options[];
}

interface Options {
  id: string;
  name: string;
}

export const OptionsInput: React.FC<IState> = (props: IState) => (
  <SelectInput
    {...props}
    className={props.className}
    label={props.label}
    choices={props.options.map((option: Options) => ({
      id: option.id,
      name: option.name,
    }))}
  />
);
