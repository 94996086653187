export enum AppRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Bumper = 'Bumper',
  BuilderSupervisor = 'BuilderSupervisor',
  RegionalManager = 'RegionalManager',
  Supervisor = 'Supervisor',
  MaterialDispatcher = 'MaterialDispatcher',
  LaborCoordinator = 'LaborCoordinator',
  CustomerServiceRepresentative = 'CustomerServiceRepresentative',
  SupplierRepresentative = 'SupplierRepresentative',
  SupplyProTester = 'SupplyProTester',
  AccountingViewOnly = 'AccountingViewOnly',
}
