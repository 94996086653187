import { BoardTypeDto } from '@vatos-pas/common';
import { Identifier } from 'react-admin';

interface IErrors {
  name?: string[];
  shortName?: string[];
  externalId?: string[];
  payableResourceTypes?: string[];
}

export interface BoardValues {
  name: string;
  shortName: string;
  externalId: string;
  payableResourceTypes: string;
}

const NAME_MAX_LENGTH = 100;
const SHORT_NAME_MAX_LENGTH = 50;
const EXTERNAL_ID_MAX_LENGTH = 100;

export const validateBoard = async (
  values: BoardValues,
  existingBoards: BoardTypeDto[] | null,
  currentBoardId?: Identifier,
): Promise<IErrors> => {
  const errors: IErrors = {};

  // Name
  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  if (values.name && existingBoards) {
    if (values.name.length > NAME_MAX_LENGTH) {
      errors.name = ['Name must be shorter than or equal to 100 characters.'];
    }

    const boardIndex = existingBoards.findIndex(
      board => board.name === values.name,
    );

    const currentBoard = existingBoards.find(
      board => board.id === currentBoardId,
    );
    const boardExists = boardIndex && boardIndex > 0;

    if (
      (boardExists && !currentBoard) ||
      (boardExists && currentBoard?.name !== values.name)
    ) {
      errors.name = ['This name is already in use. Please try another.'];
    }
  }

  // Short name
  if (!values.shortName) {
    errors.shortName = ['Short name is required.'];
  }

  if (values.shortName && existingBoards) {
    if (values.shortName.length > SHORT_NAME_MAX_LENGTH) {
      errors.shortName = [
        'Short name must be shorter than or equal to 50 characters',
      ];
    }

    const boardIndex = existingBoards.findIndex(
      board => board.shortName === values.shortName,
    );

    const currentBoard = existingBoards.find(
      board => board.id === currentBoardId,
    );
    const boardExists = boardIndex && boardIndex > 0;

    if (
      (boardExists && !currentBoard) ||
      (boardExists && currentBoard?.shortName !== values.shortName)
    ) {
      errors.shortName = [
        'This short name is already in use. Please try another.',
      ];
    }
  }

  // External ID
  if (!values.externalId) {
    errors.externalId = ['External ID is required.'];
  } else {
    if (values.externalId.length > EXTERNAL_ID_MAX_LENGTH) {
      errors.externalId = [
        'External ID must be shorter than or equal to 100 characters.',
      ];
    }
  }

  // Payable Resource Type
  if (!values.payableResourceTypes?.length) {
    errors.payableResourceTypes = ['Payable Resource Types is required.'];
  }

  return errors;
};
