import {
  RepairsSettingsList,
  RepairsSettingsCreate,
  RepairsSettingsEdit,
} from './views';

export default {
  create: RepairsSettingsCreate,
  edit: RepairsSettingsEdit,
  list: RepairsSettingsList,
  name: 'repair-formula',
  label: 'Repairs',
  to: '/repair-formula',
};
