import { Fragment, useState } from 'react';

import {
  Confirm,
  useDataProvider,
  useListContext,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import some from 'lodash/some';

import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_APPROVE_BUMPOUT } from 'providers/permissions';
import { Show } from 'components/Show';
import { BumpoutTimesheetFields } from '../views/bumpout-timesheet-list';
import { DateTime } from 'luxon';

type BulkActionsButtonsProps = {
  fields: BumpoutTimesheetFields | null;
  selectedIds?: string[];
};

const BulkActionButtons = ({
  fields,
  selectedIds,
}: BulkActionsButtonsProps) => {
  const { data } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { refetch } = useListContext();

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const unselectAll = useUnselectAll();

  const hasWorkedHoursZeroed = some(fields, { workedHours: '0' });
  const hasDirtyWorkedHours = selectedIds?.filter(id => {
    const timeIn =
      fields?.[id]?.timeIn instanceof DateTime &&
      (fields[id].timeIn as DateTime).toFormat('HH:mm:00');

    const timeOut =
      fields?.[id]?.timeOut instanceof DateTime &&
      (fields[id].timeOut as DateTime).toFormat('HH:mm:00');

    const diffTimeIn = timeIn && timeIn !== data?.[id].timeIn;
    const diffTimeOut = timeOut && timeOut !== data?.[id].timeOut;

    return diffTimeIn || diffTimeOut;
  });

  const handleConfirm = async () => {
    try {
      await dataProvider.create('repair-payment/batch-process', {
        data: {
          update: selectedIds?.map(id => ({
            id,
            isApprovedHours: true,
            ...(fields?.[id]?.workedHours && {
              approvedHours: Number(fields[id].workedHours),
            }),
          })),
        },
      });
      notify('Approved successfully.');
      unselectAll('bump-resource-sheet');
      refetch();
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Show condition={hasPermission(CAN_APPROVE_BUMPOUT)}>
        <Button
          disabled={!!hasDirtyWorkedHours?.length || hasWorkedHoursZeroed}
          onClick={handleClick}
        >
          Approve
        </Button>
      </Show>
      <Confirm
        isOpen={open}
        title="Approve logs"
        content="Are you sure you want to approve these logs?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default BulkActionButtons;
