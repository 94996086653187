/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */

import React, {
  Fragment,
  FC,
  useState,
  cloneElement,
  ReactElement,
  ChangeEvent,
} from 'react';
import {
  DateField,
  List,
  TextField,
  useDataProvider,
  useRefresh,
  useNotify,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ExportButton,
  ToolbarProps,
  ExportButtonProps,
  ListProps,
  InjectedFieldProps,
  PublicFieldProps,
} from 'react-admin';
import Input from '@material-ui/core/Input';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ConfirmDialog from 'components/ConfirmDialog';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Datagrid from 'components/Datagrid';
import { RepairPayablesSheetFilter } from '../filters/repair-payables-sheet-filters';
import { makeStyles } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Button from '@material-ui/core/Button';
import { exporter } from 'providers/export';
import {
  JobPhaseDto,
  RepairPayablesSheetInterface,
  RepairPaymentInterface,
} from '@vatos-pas/common';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_PAID_PHASE } from 'providers/permissions';
import { Show } from 'components/Show';
import InfoDialog from 'components/InfoDialog';
import { UIProvider } from 'providers/uiProvider';

const Supervisor: FC<
  PublicFieldProps & InjectedFieldProps<RepairPayablesSheetInterface>
> = props => {
  return (
    <label>{`${props.record?.supervisorFirstName} ${props.record?.supervisorLastName}`}</label>
  );
};

interface DefaultFieldsInterface {
  [id: string]: {
    currentCost: number;
    check: string;
    paidDate: MaterialUiPickersDate;
  };
}

type CostFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<RepairPayablesSheetInterface>;

const CostField: FC<CostFieldProps> = props => {
  if (!props?.record) return null;

  const value =
    props.fields[props.record.id] &&
    props.fields[props.record.id].currentCost !== undefined
      ? props.fields[props.record.id].currentCost
      : props.record.currentCost
      ? props.record.currentCost
      : '';
  return (
    <Box display="flex" minWidth={80}>
      <Input
        inputProps={{ min: 0, max: 5000, title: '', step: 'any' }}
        value={value}
        fullWidth
        disabled={!props.canPaid}
        type="number"
        onChange={props.onChangeField(props.record.id)}
      />
    </Box>
  );
};

type CheckFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (
    id: string,
  ) =>
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined;
} & PublicFieldProps &
  InjectedFieldProps<RepairPayablesSheetInterface>;

const CheckField: FC<CheckFieldProps> = props => {
  if (!props?.record) return null;

  const value =
    props.fields[props.record.id] &&
    props.fields[props.record.id].check !== undefined
      ? props.fields[props.record.id].check
      : props.record.check
      ? props.record.check
      : '';
  return (
    <Box display="flex" minWidth={80}>
      <Input
        value={value}
        fullWidth
        disabled={!props.canPaid}
        onChange={props.onChangeField(props.record.id)}
      />
    </Box>
  );
};

type OnChangeDateProps = {
  target: {
    value: MaterialUiPickersDate;
  };
};

type PaidDateFieldProps = {
  fields: DefaultFieldsInterface;
  canPaid: boolean;
  onChangeField: (id: string) => (event: OnChangeDateProps) => void;
} & PublicFieldProps &
  InjectedFieldProps<RepairPayablesSheetInterface>;

const PaidDateField: FC<PaidDateFieldProps> = props => {
  if (!props?.record) return null;

  const value =
    props.fields[props.record.id] && props.fields[props.record.id].paidDate
      ? props.fields[props.record.id].paidDate
      : props.record.paidDate
      ? props.record.paidDate
      : null;
  return (
    <Box display="flex" minWidth={120}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        fullWidth
        disabled={!props.canPaid}
        value={value}
        onChange={date => {
          if (!props?.record) return null;

          props.onChangeField(props.record.id)({
            target: { value: date },
          });
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </Box>
  );
};

type SubmitButtonProps = {
  fields: DefaultFieldsInterface;
  onClickSubmit: (record: RepairPayablesSheetInterface) => () => void;
} & PublicFieldProps &
  InjectedFieldProps<RepairPayablesSheetInterface>;

const SubmitButton: FC<SubmitButtonProps> = props => {
  if (!props?.record) return null;

  const isDisabled = () => {
    if (!props?.record) return;

    const fields = props.fields[props.record.id];
    return (
      ((fields && fields.check) ||
        (props.record.check && fields && fields.check === undefined)) &&
      // ((fields && fields.currentCost) ||
      //   (props.record.currentCost &&
      //     fields &&
      //     fields.currentCost === undefined)) &&
      ((fields && fields.paidDate) ||
        (props.record.paidDate && fields && fields.paidDate === undefined))
    );
  };

  return (
    <Button
      disabled={!isDisabled()}
      variant="contained"
      color="primary"
      onClick={props.onClickSubmit(props.record)}
    >
      {props.record.currentCost ? 'Update' : 'Submit'}
    </Button>
  );
};

const useStyles = makeStyles({
  content: {
    overflow: 'auto',
  },
  root: {
    width: 'calc(100vw - 290px)',
  },
  rootClosed: {
    width: 'calc(100vw - 132px)',
  },
  link: {
    backgroundColor: '#e6005a',
    height: '50px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    color: 'white',
  },
  pink: {
    backgroundColor: 'lightpink',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  normal: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
});

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const RepairDateField = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box display="flex" alignItems="center" gridColumnGap={8}>
      <DateField source="repairPaymentDate" label="Repair Date" />
      <Show condition={props.record?.isBackdated}>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <InfoOutlinedIcon
            style={{
              color: '#ff9800',
            }}
          />
        </IconButton>
        <InfoDialog
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          content={`Repair Date: ${new Date(
            props.record?.repairPaymentDate,
          ).toLocaleDateString('en-US')}\nEntry Date: ${new Date(
            props.record?.createdAt,
          ).toLocaleDateString('en-US')}`}
        />
      </Show>
    </Box>
  );
};

export const RepairPayablesSheet: FC<ListProps> = props => {
  const [fields, setFields] = useState<DefaultFieldsInterface>({});
  const [idSelected, setIdSelected] = useState<JobPhaseDto['id']>('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { opened } = UIProvider.useContainer();

  const classes = useStyles(props);
  const { hasPermission } = PermissionsProvider.useContainer();
  const canPaid = hasPermission(CAN_PAID_PHASE);
  const onClose = () => setOpenConfirm(false);

  const onChangeField =
    (field: string) =>
    (id: string) =>
    (
      event:
        | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | OnChangeDateProps,
    ) => {
      setFields({
        ...fields,
        [id]: { ...fields[id], [field]: event.target.value },
      });
    };

  const submitCost = record => () => {
    setIdSelected(record.id);
    setOpenConfirm(true);
  };

  const onConfirmSubmit = async () => {
    try {
      const repairPaymentFound =
        await dataProvider.getOne<RepairPaymentInterface>('repair-payment', {
          id: idSelected,
        });
      const currentCost =
        fields[idSelected] && fields[idSelected].currentCost
          ? parseFloat(fields[idSelected]?.currentCost.toString())
          : parseFloat(repairPaymentFound.data.calculateCost?.toString() || '');
      const check =
        fields[idSelected] && fields[idSelected].check
          ? fields[idSelected].check
          : repairPaymentFound.data.check;
      const paidDate =
        fields[idSelected] && fields[idSelected].paidDate
          ? fields[idSelected]?.paidDate?.toISO()
          : repairPaymentFound.data.paidDate;
      const params = {
        id: idSelected,
        data: {
          currentCost,
          check,
          paidDate,
        },
        previousData: repairPaymentFound.data,
      };
      await dataProvider.update('repair-payment', params);
      notify('Changes were made successfully!');
      refresh();
    } catch {
      notify(`An error has occurred!`, 'warning');
    }
    setOpenConfirm(false);
  };

  return (
    <Fragment>
      <List
        classes={{
          content: classes.content,
          root: opened ? classes.root : classes.rootClosed,
        }}
        {...props}
        exporter={exporter('prod-pay-master-sheet')}
        filters={
          <RepairPayablesSheetFilter>{props.filters}</RepairPayablesSheetFilter>
        }
        filterDefaultValues={{
          'status||$eq': 'Unpaid',
        }}
        sort={{ field: 'repairPaymentDate', order: 'DESC' }}
        bulkActionButtons={<React.Fragment />}
        actions={<ListActions maxResults={20000} />}
      >
        <Datagrid>
          <Supervisor label="Supervisor" />
          <TextField source="regionName" label="Region" />
          <TextField source="repairType" label="Repair Type" />
          <RepairDateField label="Repair Date" />
          <TextField source="description" label="Description" />
          <TextField source="builderName" label="Builder" />
          <TextField source="subdivisionName" label="Subdivision" />
          <TextField source="jobLot" label="Lot" />
          <TextField source="jobBlock" label="Block" />
          <TextField source="contractorName" label="Contractor" />
          <TextField source="calculateCost" label="Calculated Cost" />
          <CostField
            canPaid={canPaid}
            onChangeField={onChangeField('currentCost')}
            fields={fields}
            label="Current Cost"
          />
          <CheckField
            canPaid={canPaid}
            onChangeField={onChangeField('check')}
            fields={fields}
            label="Check #"
          />
          <PaidDateField
            canPaid={canPaid}
            onChangeField={onChangeField('paidDate')}
            fields={fields}
            label="Paid Date"
          />
          {canPaid && (
            <SubmitButton fields={fields} onClickSubmit={submitCost} />
          )}
        </Datagrid>
      </List>
      <ConfirmDialog
        open={openConfirm}
        title={`Are you sure that you want to make this change?`}
        handleClose={onClose}
        onConfirm={onConfirmSubmit}
      />
    </Fragment>
  );
};
