import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const HomeRepairService: React.FC = () => {
  return (
    <SvgIcon viewBox="0 0 48 48">
      <path d="M4 40V19Q4 17.8 4.9 16.9Q5.8 16 7 16H14V11Q14 9.8 14.9 8.9Q15.8 8 17 8H31Q32.2 8 33.1 8.9Q34 9.8 34 11V16H41Q42.2 16 43.1 16.9Q44 17.8 44 19V40ZM7 37H41V29.5H35.5V31.5H32.5V29.5H15.5V31.5H12.5V29.5H7ZM7 19V26.5H12.5V24.5H15.5V26.5H32.5V24.5H35.5V26.5H41V19Q41 19 41 19Q41 19 41 19H7Q7 19 7 19Q7 19 7 19ZM17 16H31V11Q31 11 31 11Q31 11 31 11H17Q17 11 17 11Q17 11 17 11Z" />
    </SvgIcon>
  );
};

export default HomeRepairService;
