import { cloneElement, ReactElement } from 'react';
import {
  ExportButton,
  ExportButtonProps,
  sanitizeListRestProps,
  ToolbarProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions = ({
  className,
  filters,
  maxResults,
  ...rest
}: ListActionProps) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    currentSort,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export default ListActions;
