import { useState } from 'react';
import { useDataProvider } from 'react-admin';
import {
  findManyPurchaseOrders,
  findOneJob,
  findOneSubdivision,
} from 'services/jobs';
import { createContainer } from 'unstated-next';
import {
  BoardPurchaseDto,
  JobDto,
  SubdivisionDto,
} from '../../../../../common/dist';

const useJob = () => {
  const dataProvider = useDataProvider();

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<BoardPurchaseDto | null>(null);
  const [isPurchaseOrderOpen, setIsPurchaseOrderOpen] = useState(false);
  const [job, setJob] = useState<JobDto | null>(null);
  const [subdivision, setSubdivision] = useState<SubdivisionDto | null>(null);
  const [purchaseOrders, setPurchaseOrders] = useState<
    BoardPurchaseDto[] | null
  >(null);

  const handleSelectedPurchaseOrder = (purchaseOrder: BoardPurchaseDto) => {
    setIsPurchaseOrderOpen(true);
    setSelectedPurchaseOrder(purchaseOrder);
  };

  const loadJobData = async (jobId?: string) => {
    if (!jobId) return;

    const job = await findOneJob(dataProvider, jobId);

    if (!job) return;

    const subdivision = await findOneSubdivision(
      dataProvider,
      job.model?.subdivisionId,
    );
    const purchaseOrders = await findManyPurchaseOrders(dataProvider, job.id);

    setJob(job);
    setSubdivision(subdivision);
    setPurchaseOrders(purchaseOrders);
  };

  return {
    loadJobData,
    job,
    subdivision,
    purchaseOrders,
    isPurchaseOrderOpen,
    setIsPurchaseOrderOpen,
    selectedPurchaseOrder,
    setSelectedPurchaseOrder,
    handleSelectedPurchaseOrder,
  };
};

export const JobContainer = createContainer(useJob);
