import { UserList, UserCreate, UserEdit } from './views';

export default {
  create: UserCreate,
  edit: UserEdit,
  list: UserList,
  to: '/user',
  name: 'user',
  label: 'Users',
};
