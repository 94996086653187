/* eslint-disable no-debugger */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { useDataProvider, useNotify, useRedirect } from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CustomReference from 'components/CustomReference';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

export const ModelBoardSheetEdit = props => {
  const dataProvider = useDataProvider();
  const modelId = props.id;
  const [model, setModel] = useState<any>();
  const [builder, setBuilder] = useState<any>();
  const [laborOnly, setLaborOnly] = useState(true);
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [sync, setSync] = useState(true);
  const notify = useNotify();
  const [boardFloorsValidation, setBoardFloorsValidation] = useState({});
  const [modelBoardFloors, setModelBoardFloors] = useState<any>([]);

  const getModelBoardFloors = async () => {
    const modelResponse = await dataProvider.getList('model-board-floor', {
      filter: { modelId },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'floor', order: 'ASC' },
    });

    setModelBoardFloors(modelResponse.data);
  };

  const getModel = async () => {
    const modelResponse = await dataProvider.getOne('model', { id: modelId });
    setModel(modelResponse.data);
    setLaborOnly(modelResponse.data.laborOnly);
    getbuilder(modelResponse.data.subdivision.builderId);
    setSync(false);
  };

  const getbuilder = async id => {
    const builderResponse = await dataProvider.getOne('builder', { id });
    setBuilder(builderResponse.data);
  };

  useEffect(() => {
    getModel();
    getModelBoardFloors();
  }, []);

  const mappedFloors = {};
  modelBoardFloors.forEach(item => {
    if (mappedFloors[item.floor]) {
      mappedFloors[item.floor] = [...mappedFloors[item.floor], item];
    } else {
      mappedFloors[item.floor] = [item];
    }
  });

  const onChangeBoardFloor = (id, field) => value => {
    const newModelBoards = [...modelBoardFloors];
    const item = newModelBoards.find(item => item.id === id);
    item[field] = value;
    setModelBoardFloors(newModelBoards);
  };

  const addNewFloor = () => {
    const sortedFloors = [...modelBoardFloors];
    if (sortedFloors.length) {
      const emptyFloorItem = {
        floor: sortedFloors[sortedFloors.length - 1].floor + 1,
        phaseId: '',
        boardTypeId: '',
        id: uuidv4(),
        quantity: '',
      };
      sortedFloors.push(emptyFloorItem);
    } else {
      const emptyFloorItem = {
        floor: 1,
        phaseId: '',
        boardTypeId: '',
        quantity: '',
        id: uuidv4(),
      };
      sortedFloors.push(emptyFloorItem);
    }
    setModelBoardFloors(sortedFloors);
  };

  const addNewBoardFloor = floor => {
    const sortedFloors = [...modelBoardFloors];
    const emptyFloorItem = {
      floor,
      phaseId: '',
      boardTypeId: '',
      id: uuidv4(),
      quantity: '',
    };
    sortedFloors.push(emptyFloorItem);
    setModelBoardFloors(sortedFloors);
  };

  const validateFields = () => {
    let error = false;
    // Board Floor
    const boardFloorsValidate = {};
    modelBoardFloors.forEach(boardFloorItem => {
      Object.keys(boardFloorItem).forEach(key => {
        const value = boardFloorItem[key];
        if (!value && value !== 0) {
          if (boardFloorsValidate[boardFloorItem.id]) {
            boardFloorsValidate[boardFloorItem.id][key] = true;
          } else {
            boardFloorsValidate[boardFloorItem.id] = { [key]: true };
          }
          error = true;
        }
      });
    });
    setBoardFloorsValidation(boardFloorsValidate);

    return error;
  };

  const submit = async () => {
    const errored = validateFields();
    if (errored) return;
    setLoading(true);
    const modelBoardFloorsMapped = modelBoardFloors.map(item => {
      const { id: removedId, ...restItem } = item;
      restItem.modelId = modelId;
      return restItem;
    });

    try {
      const newBoardSheet = await dataProvider.put('model-board-purchase', {
        id: modelId,
        data: {
          modelBoardFloors: modelBoardFloorsMapped,
        },
      });
      notify('Board Pre-Fill updated with success!');
      redirect('/board-pre-fill');
    } catch (error: any) {
      console.log('error', error);
      notify(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const deleteBoard = id => {
    const newBoardFloors = [...modelBoardFloors];
    const index = modelBoardFloors.findIndex(item => item.id === id);
    newBoardFloors.splice(index, 1);
    setModelBoardFloors(newBoardFloors);
  };

  const formatResponse = phases => {
    let newPhases = phases;
    if (newPhases.length > 0) {
      newPhases = newPhases.filter(item => {
        return (
          item.name === 'Materials'
          // item.name === 'Hanging' ||
          // item.name === 'Spraying' ||
          // item.name === 'Finishing'
        );
      });
    }
    return newPhases;
  };

  const sumBoardsFloor = floor => {
    const filteredFloor = modelBoardFloors.filter(
      item => item.floor === parseInt(floor),
    );
    let sum = 0;
    filteredFloor.forEach(item => {
      sum += item.quantity;
    });
    return sum;
  };

  const clearBoards = () => {
    setModelBoardFloors([
      {
        floor: 1,
        phaseId: '',
        boardTypeId: '',
        quantity: 0,
        id: uuidv4(),
      },
    ]);
  };

  return (
    <Box>
      <Box display="flex" width="100%" gridGap={50}>
        <TextField
          label="Builder Name"
          fullWidth
          value={builder?.name || ''}
          disabled
        />
        <TextField
          label="Subdivision Name"
          fullWidth
          value={model?.subdivision?.name || ''}
          disabled
        />
        <TextField
          label="Model Name"
          fullWidth
          value={model?.name || ''}
          disabled
        />
      </Box>
      {/* <Box my={3}>
        <FormControlLabel
          control={
            <Switch
              checked={laborOnly}
              onChange={() => {
                setLaborOnly(!laborOnly);
                clearBoards();
              }}
              name="laborOnly"
              color="primary"
            />
          }
          label="Labor Only"
        />
      </Box> */}
      <Box>
        {!sync &&
          Object.keys(mappedFloors).map(floor => {
            return (
              <Box key={floor} width="100%" mt={3}>
                <Box>
                  <Typography>Floor #{floor}</Typography>
                </Box>
                {mappedFloors[floor].map((item, index) => (
                  <Box
                    key={item.id}
                    display="flex"
                    width="100%"
                    gridGap={50}
                    alignItems="center"
                  >
                    <Box width="100%">
                      <CustomReference
                        label="Phase"
                        value={item.phaseId}
                        resource="phase"
                        sort={{ field: 'name', order: 'ASC' }}
                        onChange={event => {
                          onChangeBoardFloor(
                            item.id,
                            'phaseId',
                          )(event.target.value);
                        }}
                        error={boardFloorsValidation[item.id]?.phaseId}
                        helperText={
                          boardFloorsValidation[item.id]?.phaseId
                            ? 'This field is required'
                            : ''
                        }
                        formatResponse={formatResponse}
                      />
                    </Box>
                    <Box width="100%">
                      <CustomReference
                        label="Board Type"
                        value={item.boardTypeId}
                        resource="board-type"
                        customItemName="shortName"
                        needsFilter
                        filters={
                          laborOnly
                            ? {
                                laborOnly: true,
                              }
                            : {
                                laborOnly: false,
                              }
                        }
                        sort={{ field: 'name', order: 'ASC' }}
                        onChange={event => {
                          onChangeBoardFloor(
                            item.id,
                            'boardTypeId',
                          )(event.target.value);
                        }}
                        error={boardFloorsValidation[item.id]?.boardTypeId}
                        helperText={
                          boardFloorsValidation[item.id]?.boardTypeId
                            ? 'This field is required'
                            : ''
                        }
                      />
                    </Box>
                    <Box width="100%">
                      <TextField
                        label="Quantity"
                        value={item.quantity}
                        onChange={event => {
                          onChangeBoardFloor(
                            item.id,
                            'quantity',
                          )(parseInt(event.target.value));
                        }}
                        type="number"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        error={boardFloorsValidation[item.id]?.quantity}
                        helperText={
                          boardFloorsValidation[item.id]?.quantity
                            ? 'This field is required'
                            : ''
                        }
                      />
                    </Box>
                    <Box style={{ cursor: 'pointer' }}>
                      <DeleteIcon onClick={() => deleteBoard(item.id)} />
                    </Box>
                    <Box style={{ cursor: 'pointer' }} width="120px">
                      {index === mappedFloors[floor].length - 1 && (
                        <AddIcon onClick={() => addNewBoardFloor(item.floor)} />
                      )}
                    </Box>
                  </Box>
                ))}
                <Box>
                  <Typography style={{ fontSize: '14px' }}>
                    Floor {floor} Total Boards: {sumBoardsFloor(floor)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        <Box
          display="inline-flex"
          gridGap={10}
          mt={2}
          style={{ cursor: 'pointer' }}
          onClick={addNewFloor}
        >
          <Typography>Add New Floor</Typography>
          <AddIcon />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={3}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<ChevronLeftIcon />}
          onClick={() => redirect('/board-pre-fill')}
        >
          GO BACK
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={loading ? <Fragment /> : <SaveIcon />}
          onClick={submit}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} size={24} />
          ) : (
            'Save'
          )}
        </Button>
      </Box>
    </Box>
  );
};
