import Chip from '@material-ui/core/Chip';

import CustomSelect, {
  ICustomItem,
  ICustomReference,
} from 'components/CustomSelect';

type SupplierRepresentativeFieldProps = {
  classes: { [key: string]: string };
  userRepresentatives: ICustomItem[] | null;
  selectedUserRepresentatives: string[];
  setSelectedUserRepresentatives: React.Dispatch<
    React.SetStateAction<string[]>
  >;
} & Pick<ICustomReference, 'error' | 'helperText'>;

const SupplierRepresentativeField = ({
  classes,
  userRepresentatives,
  selectedUserRepresentatives,
  setSelectedUserRepresentatives,
  error,
  helperText,
}: SupplierRepresentativeFieldProps) => {
  return (
    <CustomSelect
      margin="dense"
      variant="filled"
      renderValue={(value: unknown) => (
        <div className={classes.chips}>
          {(value as string[])
            .map(item =>
              userRepresentatives?.find(choice => choice.id === item),
            )
            .filter(item => !!item)
            .map(item => (
              <Chip key={item?.id} label={item?.name} />
            ))}
        </div>
      )}
      multiple
      resource="user"
      label="Supplier Representatives"
      options={userRepresentatives ?? []}
      value={selectedUserRepresentatives}
      onChange={event =>
        setSelectedUserRepresentatives(event.target.value as string[])
      }
      error={!!error}
      helperText={helperText ?? ''}
    />
  );
};

export default SupplierRepresentativeField;
