import React, { FC, useLayoutEffect, useRef } from 'react';
import {
  TextInput,
  SimpleForm,
  CheckboxGroupInput,
  EditProps,
  Edit,
  useDataProvider,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { BoardTypeDto, ContractorResourceTypeEnum } from '@vatos-pas/common';
import { makeStyles } from '@material-ui/core/styles';
import ToolBar from 'components/Toolbar';
import { validateBoard } from './boardValidation';

const payableResourcesTypes = Object.values(ContractorResourceTypeEnum).map(
  resourceType => ({
    id: resourceType,
    name: resourceType,
  }),
);

export const BoardEdit: FC<EditProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const existingBoards = useRef<BoardTypeDto[] | null>(null);

  const getBoardTypes = async () => {
    const boards = await dataProvider.getList<BoardTypeDto>('board-type', {
      filter: {},
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
    });

    if (boards?.data.length) {
      existingBoards.current = boards.data;
    }
  };

  useLayoutEffect(() => {
    const initialize = async () => await getBoardTypes();

    initialize();
  }, []);

  return (
    <Edit {...props} className={classes.createBox}>
      <SimpleForm
        validateOnBlur
        toolbar={<ToolBar />}
        validate={values =>
          validateBoard(values, existingBoards.current, props.id)
        }
      >
        <Box display="flex" className={classes.flexBox}>
          <TextInput source="name" label="Board Name" />
          <TextInput source="shortName" label="Board Shortname" />
          <TextInput source="externalId" label="External ID" />
        </Box>

        <Box className={classes.fullBox}>
          <Typography>Payable Resource Types</Typography>
          <CheckboxGroupInput
            row
            label=""
            source="payableResourceTypes"
            choices={payableResourcesTypes}
          />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  flexBox: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  fullBox: {
    width: '100%',
  },
  createBox: {
    maxWidth: '100%',
  },
  builderBoardTypeMatchesBox: {
    marginTop: '24px',
  },
  editButton: {
    marginTop: '24px',
  },
  builderBoardTypeHeading: {
    marginBottom: '16px',
  },
});
