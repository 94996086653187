import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const StickyNote2: React.FC = () => {
  return (
    <SvgIcon viewBox="0 0 48 48">
      <path d="M9 39H29V29H39V9Q39 9 39 9Q39 9 39 9H9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39ZM9 42Q7.75 42 6.875 41.125Q6 40.25 6 39V9Q6 7.75 6.875 6.875Q7.75 6 9 6H39Q40.25 6 41.125 6.875Q42 7.75 42 9V30L30 42ZM15 27V24H23.5V27ZM15 19V16H33V19ZM9 39V29V9Q9 9 9 9Q9 9 9 9Q9 9 9 9Q9 9 9 9V39Q9 39 9 39Q9 39 9 39Z" />
    </SvgIcon>
  );
};

export default StickyNote2;
