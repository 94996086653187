import React, { ReactElement } from 'react';

import MUITableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { v4 as uuidv4 } from 'uuid';

import {
  TableContainer,
  TableRow,
  TableDefaultRow,
  TableDraftRowPayload,
  TableDraftRow,
} from '.';
import { UserChoice } from '../views/route-create';

type TableAddSubdivisionProps = {
  rows: TableDefaultRow[] | null;
  columns: string[];
  supervisor: UserChoice | null;
  draftRows: TableDraftRowPayload[] | null;
  route: string;
  setDraftRows: React.Dispatch<
    React.SetStateAction<TableDraftRowPayload[] | null>
  >;
};

export const TableAddSubdivision = ({
  rows,
  columns,
  supervisor,
  draftRows,
  route,
  setDraftRows,
}: TableAddSubdivisionProps): ReactElement => {
  const classes = useStyles();

  const handleDraftRowChange = (
    rowId: string,
    payload: TableDraftRowPayload,
  ) => {
    setDraftRows(prevState => {
      if (!prevState) return [{ id: rowId, subdivisionId: '', value: null }];

      const row = prevState.findIndex(row => row.id === rowId);
      if (row < 0) return prevState;

      return prevState.map(row => (row.id === rowId ? payload : row));
    });
  };

  return (
    <div className={classes.container}>
      <TableContainer columns={columns}>
        {rows?.length
          ? rows.map(row => (
              <MUITableRow key={row.subdivisionId}>
                <TableRow row={row} />
              </MUITableRow>
            ))
          : !draftRows && (
              <MUITableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  className={classes.tableCellCenter}
                >
                  No subdivisions found
                </TableCell>
              </MUITableRow>
            )}
        {!!draftRows &&
          draftRows?.map(draftRow => (
            <MUITableRow key={draftRow.id}>
              <TableDraftRow
                draftRows={draftRows}
                row={draftRow}
                route={route}
                supervisor={supervisor}
                handleDraftRowChange={handleDraftRowChange}
              />
            </MUITableRow>
          ))}
      </TableContainer>
      <Box className={classes.boxActions}>
        <IconButton
          onClick={() =>
            setDraftRows(prevState => {
              if (!prevState) {
                return [
                  {
                    id: uuidv4(),
                    subdivisionId: '',
                    value: null,
                  },
                ];
              }

              return [
                ...prevState,
                { id: uuidv4(), subdivisionId: '', value: null },
              ];
            })
          }
        >
          <AddIcon color="primary" />
        </IconButton>

        {!!draftRows?.length && (
          <IconButton
            onClick={() => {
              setDraftRows(prevState => {
                if (prevState?.length === 1) {
                  return null;
                }

                const newState = [...(prevState ?? [])];
                return newState.slice(0, -1);
              });
            }}
          >
            <DeleteIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  boxActions: {
    display: 'flex',
    columnGap: 8,
    paddingLeft: 8,
    paddingBottom: 16,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
