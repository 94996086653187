import { ContractorInterface } from '@vatos-pas/common';

interface IErrors {
  name?: string[];
  corporation?: string[];
  paymentRate?: string[];
  paymentType?: string[];
  bumperPaymentType?: string[];
  bumperPaymentRate?: string[];
}

export const validateContractor = async (
  values: ContractorInterface,
): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.corporation) {
    errors.corporation = ['The corporation is required'];
  }
  if (!values.paymentType) {
    errors.paymentType = ['The Payment Type is required'];
  }
  if (values.paymentRate) {
    if (values.paymentRate < 1) {
      errors.paymentRate = ['The Payment Rate must be at least 1'];
    }
    if (values.paymentRate > 100) {
      errors.paymentRate = ['The Payment Rate must be 100 or less'];
    }
  } else {
    errors.paymentRate = ['The Payment Rate is required'];
  }
  if (values.bumperAvailable) {
    if (!values.bumperPaymentType) {
      errors.bumperPaymentType = ['The Bumper Payment Type is required'];
    }
    if (values.bumperPaymentRate) {
      if (values.bumperPaymentRate < 1) {
        errors.bumperPaymentRate = [
          'The Bumper Payment Rate must be at least 1',
        ];
      }
      if (values.bumperPaymentRate > 100) {
        errors.bumperPaymentRate = [
          'The Bumper Payment Rate must be 100 or less',
        ];
      }
    } else {
      errors.bumperPaymentRate = ['The Bumper Payment Rate is required'];
    }
  } else {
    if (values.bumperPaymentType) {
      errors.bumperPaymentType = [
        'The Bumper Payment Type is not allowed unless a Bumper is available',
      ];
    }
    if (values.bumperPaymentRate) {
      errors.bumperPaymentRate = [
        'The Bumper Payment Rate is not allowed unless a Bumper is available',
      ];
    }
  }
  return errors;
};
