import React, { useEffect, useState } from 'react';
import { useInput, InputProps } from 'react-admin';
import { useFormState } from 'react-final-form';

import CustomSelect from 'components/CustomSelect';
import { getCoordinatorsByRegion } from 'services/subDivisions';
import { UserChoice } from 'modules/route/views/route-create';

type SupervisorInputProps = {
  classes: { [key: string]: string };
} & InputProps;

export const SupervisorInput = ({
  classes,
  onChange,
  ...props
}: SupervisorInputProps) => {
  const { values } = useFormState();
  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const [usersChoice, setUsersChoice] = useState<UserChoice[]>([]);

  const getSupervisors = async () => {
    const users = await getCoordinatorsByRegion({
      regionId: values.regionId,
    });
    setUsersChoice(
      users.data.data.map(({ id, firstName, lastName }) => ({
        id,
        name: `${firstName} ${lastName}`,
      })),
    );
  };

  const handleChange = async (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    onChange && onChange(event.target.value);
    input.onChange(event);
  };

  useEffect(() => {
    // Reset subdivision state whenever route changes
    if (!values.routeId) {
      input.onChange(null);
      return;
    }

    input.onChange(props.value);
  }, [values.routeId]);

  useEffect(() => {
    getSupervisors();
  }, [values.regionId]);

  return (
    <CustomSelect
      label="Supervisor"
      disabled
      className={classes.thirdWidth}
      resource="supervisorId"
      variant="filled"
      options={usersChoice}
      margin="dense"
      error={!!(touched && error)}
      helperText={error}
      {...input}
      onChange={handleChange}
    />
  );
};
