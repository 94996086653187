import React, { FC } from 'react';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
  DateInput,
  TextInput,
  FilterProps,
} from 'react-admin';
import { UserDto } from '@vatos-pas/common';
import { AppRole } from 'providers/roles';

export const ProdPayMasterSheetFilter: FC<FilterProps> = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Supervisor"
      source="supervisorUserId"
      reference="user"
      resettable
      data-cy="ProdPayMasterSheetSheetFilterSupervisor"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: {
              $and: [
                {
                  $or: [
                    { firstName: { $cont: value } },
                    { lastName: { $cont: value } },
                  ],
                },
                {
                  'userRoles.role.name': { $eq: AppRole.Supervisor },
                },
              ],
            },
          };
        } else {
          return {
            s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
          };
        }
      }}
      sort={{ field: 'firstName', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(user: UserDto) => {
          return `${user.firstName} ${user.lastName}`;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <TextInput
      label="Lot"
      source="jobLot||$eq"
      alwaysOn
      resettable
      data-cy="ProdPayMasterSheetSheetFilterLot"
    />
    <TextInput
      label="Block"
      source="jobBlock||$eq"
      alwaysOn
      resettable
      data-cy="ProdPayMasterSheetSheetFilterBlock"
    />
    <ReferenceInput
      label="Builder"
      source="builderId"
      reference="builder"
      resettable
      data-cy="ProdPayMasterSheetFilterBuilder"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        allowEmpty={false}
        optionText="name"
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Subdivision"
      source="subdivisionId"
      reference="subdivision"
      data-cy="ProdPayMasterSheetFilterSubdivision"
      resettable
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Contractor"
      source="contractorId"
      reference="contractor"
      resettable
      data-cy="ProdPayMasterSheetFilterContractor"
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return { name: value };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        allowEmpty={false}
        optionText="name"
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Region"
      data-cy="ProdPayMasterSheetFilterRegion"
      source="regionId"
      reference="region"
      resettable
      perPage={Number.MAX_SAFE_INTEGER}
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Phase"
      data-cy="ProdPayMasterSheetFilterPhase"
      source="phaseId"
      reference="phase"
      perPage={Number.MAX_SAFE_INTEGER}
      resettable
      allowEmpty={false}
      sort={{ field: 'priority', order: 'ASC' }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <DateInput
      label="Completed On or After"
      source="dateApproved||$gte"
      data-cy="ProdPayMasterSheetFilterDateApprovedBegin"
    />
    <DateInput
      label="Completed On or Before"
      source="dateApproved||$lte"
      data-cy="ProdPayMasterSheetFilterDateApprovedEnd"
    />
    <DateInput
      label="Paid On or After"
      source="datePaid||$gte"
      data-cy="ProdPayMasterSheetFilterDatePaidBegin"
    />
    <DateInput
      label="Paid On or Before"
      source="datePaid||$lte"
      data-cy="ProdPayMasterSheetFilterDatePaidEnd"
    />
    <SelectInput
      source="isPaid"
      label="Status"
      data-cy="ProdPayMasterSheetFilterIsPaid"
      choices={[
        { val: true, text: 'Paid' },
        { val: false, text: 'Unpaid' },
      ]}
      emptyText="All"
      optionValue="val"
      optionText="text"
      alwaysOn
    />
  </Filter>
);
