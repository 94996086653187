type IErrors = {
  name?: string[];
  regionId?: string[];
  supervisorUserId?: string[];
};

export type RouteValues = {
  name: string;
  regionId: string;
  supervisorUserId: string;
};

export const validateRoute = async (values: RouteValues): Promise<IErrors> => {
  const errors: IErrors = {};

  // Name
  if (!values.name) {
    errors.name = ['Name is required.'];
  }

  // Region
  if (!values.regionId) {
    errors.regionId = ['Region is required.'];
  }

  // Supervisor
  if (!values.supervisorUserId) {
    errors.supervisorUserId = ['Supervisor is required.'];
  }

  return errors;
};
