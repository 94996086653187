interface IErrors {
  name?: string[];
  shortName?: string[];
  repairsRate?: string[];
}

interface RegionValues {
  name: string;
  shortName: string;
  repairsRate: number;
}

export const validateRegion = async (
  values: RegionValues,
): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The Name is required'];
  }
  if (!values.shortName) {
    errors.shortName = ['The Shortname is required'];
  }
  if (values.repairsRate) {
    if (values.repairsRate < 1) {
      errors.repairsRate = ['The Repairs Rate must be at least 1'];
    }
    if (values.repairsRate > 99.99) {
      errors.repairsRate = ['The Repairs Rate must be 99.99 or less'];
    }
  } else {
    errors.repairsRate = ['The Repairs Rate is required'];
  }
  return errors;
};
