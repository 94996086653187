import { FC, Fragment, useEffect, useState } from 'react';
import { ShowProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SimpleForm from 'components/SimpleForm';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CircularProgress from '@material-ui/core/CircularProgress';
import PurchaseOrder from '../components/purchase-order/modal-purchase-order';
import { useNotify, useRedirect } from 'react-admin';
import ConfirmDialog from 'components/ConfirmDialog';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CHANGE_JOB } from 'providers/permissions';

import dataProvider from 'providers/dataProvider';
import { ProjectDetails } from '../components/section-project-details';
import { CreatePurchaseOrder } from '../components/purchase-order/section-purchase-order';
import { PurchaseOrderContainer } from '../providers/purchase-order';
import { DefaultModelContainer } from '../providers/default-model';
import { JobContainer } from '../providers/job';
import { useContainer } from 'unstated-next';

const JobsView: FC<ShowProps> = ({ id: jobId, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const { job, purchaseOrders, loadJobData } = useContainer(JobContainer);
  const { hasPermission } = PermissionsProvider.useContainer();
  const redirect = useRedirect();

  const [isVoidConfirmOpen, setIsVoidConfirmOpen] = useState(false);
  const [isShowDuplicateConfirmOpen, setIsShowDuplicateConfirmOpen] =
    useState(false);
  const [isVoidLoading, setIsVoidLoading] = useState(false);

  const handleVoid = async () => {
    if (!job?.id) return;

    setIsVoidLoading(true);

    if (!isVoidLoading) {
      try {
        const params = {
          id: job.id,
          data: { voided: true },
          previousData: job,
        };
        await dataProvider.update('job', params);
        notify('Job Voided!');
        redirect('/job-sheet');
      } catch (error) {
        notify(`Error on VOID job`, 'warning');
      } finally {
        setIsVoidConfirmOpen(false);
        setIsVoidLoading(false);
      }
    }
  };

  useEffect(() => {
    loadJobData(jobId);
  }, []);

  return (
    <>
      <Box>
        {job ? (
          <Fragment>
            <SimpleForm className={classes.createBox} hideTollBar>
              <Box className={classes.flexRight}>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    New Project - Take Off Report
                  </Typography>
                  <Box ml={3}>
                    {hasPermission(CAN_CHANGE_JOB) &&
                      !job.voided &&
                      job.isVoidable && (
                        <Button
                          variant="contained"
                          size="large"
                          className={classes.buttonVoid}
                          disabled={isVoidLoading}
                          onClick={() => setIsVoidConfirmOpen(true)}
                        >
                          Void Job
                        </Button>
                      )}
                  </Box>
                </Box>
                <Box ml="auto">
                  {hasPermission(CAN_CHANGE_JOB) &&
                    !!purchaseOrders?.length &&
                    purchaseOrders.length > 0 && (
                      <Button
                        variant="contained"
                        size="large"
                        disabled={isVoidLoading}
                        className={classes.button}
                        onClick={() => setIsShowDuplicateConfirmOpen(true)}
                      >
                        Duplicate Job
                      </Button>
                    )}
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => {
                      redirect('/job-sheet');
                    }}
                  >
                    Go Back
                  </Button>
                </Box>
              </Box>
              <Box className={classes.flex}>
                <Box className={classes.leftPanel}>
                  <ProjectDetails isVoidLoading={isVoidLoading} />
                </Box>
                <Box className={classes.rightPanel}>
                  <CreatePurchaseOrder {...props} />
                </Box>
              </Box>
            </SimpleForm>
            <PurchaseOrder />
          </Fragment>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            flexDirection="column"
          >
            <CircularProgress />
            <Typography className={classes.loadingText}>
              Loading Job Info
            </Typography>
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={isVoidConfirmOpen}
        confirmLabel="VOID JOB"
        showWarning
        title="Are you sure you want to VOID this Job? This is not reversable you will lose edit functionality to this job"
        handleClose={() => setIsVoidConfirmOpen(false)}
        onConfirm={handleVoid}
      />
      <ConfirmDialog
        open={isShowDuplicateConfirmOpen}
        confirmLabel="Duplicate Job"
        showWarning
        greenWarning
        title="This will close the current job and create a new job with a copy of the Project Details and the PO1 order details on clipboard"
        handleClose={() => setIsShowDuplicateConfirmOpen(false)}
        onConfirm={() => redirect('/job-sheet/create?duplicateRef=' + jobId)}
      />
    </>
  );
};

export const JobsViewWithProvider: FC<ShowProps> = props => {
  return (
    <JobContainer.Provider>
      <PurchaseOrderContainer.Provider>
        <DefaultModelContainer.Provider>
          <JobsView {...props} />
        </DefaultModelContainer.Provider>
      </PurchaseOrderContainer.Provider>
    </JobContainer.Provider>
  );
};

const useStyles = makeStyles({
  createBox: {
    marginTop: '25px',
    maxWidth: '1500px',
    display: 'flex',
  },
  leftPanel: {
    width: '70%',
  },
  rightPanel: {
    marginLeft: '20px',
    width: '30%',
    border: '1.5px solid lightgray',
    padding: '5px',
    borderRadius: '10px',
  },
  flex: {
    display: 'flex',
    maxWidth: '1500px',
    width: '1500px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 20,
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
    },
  },
  flexRight: {
    display: 'flex',
    width: '100% !important',
    margin: '10px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  loadingText: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px',
    marginTop: '10px',
  },
  buttonVoid: {
    backgroundColor: '#e6005a',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e6005a',
    },
  },
});
