import React, { Fragment, useState, FC } from 'react';
import { DateField, List, TextField, ListProps, ShowButton } from 'react-admin';
import Datagrid from 'components/Datagrid';
import { PreRockSheetFilter } from '../filters/pre-rock-sheet-filters';
import { makeStyles } from '@material-ui/core';
import { exporter } from 'providers/export';
import JobNotesModal from './job-notes-modal';
import { UIProvider } from 'providers/uiProvider';
import InfoIcon from '@material-ui/icons/Info';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  link: {
    backgroundColor: '#e6005a',
    height: '50px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    color: 'white',
  },
  pink: {
    backgroundColor: 'lightpink',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  normal: {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  info: {
    cursor: 'pointer',
    marginRight: '10px',
  },
});

const NotesField = props => {
  const classes = useStyles(props);

  const lastJobNote =
    !!props.record.jobNotes?.length && props.record.jobNotes.at(-1);

  const cleanText =
    lastJobNote.note && lastJobNote.note.replace(/<\/?[^>]+(>|$)/g, ' ');

  return (
    <Box height="30px" width="30vw" display="flex" alignItems="center">
      {cleanText && (
        <>
          <InfoIcon
            className={classes.info}
            onClick={() => props.setSelectedJob(props.record)}
          />
          <Box
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            width="100%"
          >
            <span>{cleanText}</span>
          </Box>
        </>
      )}
    </Box>
  );
};

const CustomShow = props => {
  const { record } = props;

  const redirect = () => {
    const origin = window.location.href.split('#')[0];
    window.setTimeout(() => {
      if (record.jobId) {
        window.location.href = origin + '#/job-master-sheet/' + record.jobId;
      }
    }, 500);
  };

  return <ShowButton onClick={redirect} component={Box} />;
};

export const PreRockSheetList: FC<ListProps> = props => {
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');
  const { opened } = UIProvider.useContainer();
  const classes = useStyles(props);

  return (
    <Fragment>
      <List
        classes={{
          content: opened ? classes.content : classes.contentClosed,
          root: opened ? classes.root : classes.rootClosed,
        }}
        {...props}
        exporter={exporter('pre-rock-sheet')}
        filters={<PreRockSheetFilter>{props.filters}</PreRockSheetFilter>}
        sort={{ field: 'createdAt', order: 'ASC' }}
        bulkActionButtons={<React.Fragment />}
      >
        <Datagrid>
          <DateField source="createdAt" label="Date Created" />
          <TextField source="supervisorFullName" label="Supervisor" />
          <TextField source="jobLot" label="Lot" />
          <NotesField
            setSelectedJob={selected => {
              setSelectedJob(selected);
              setOpenNotesModal(true);
            }}
            source="notes"
            sortable={false}
            label="Notes"
          />
          <CustomShow />
        </Datagrid>
      </List>
      <JobNotesModal
        handleClose={() => setOpenNotesModal(false)}
        open={openNotesModal}
        selectedJob={selectedJob}
      />
    </Fragment>
  );
};
