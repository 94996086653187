import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import {
  Edit,
  TextInput,
  NumberInput,
  BooleanInput,
  EditProps,
  useDataProvider,
  useNotify,
  DataProvider,
  SelectArrayInput,
  LinearProgress,
  Record,
  PublicFieldProps,
  InjectedFieldProps,
} from 'react-admin';
import difference from 'lodash/difference';
import union from 'lodash/union';
import { ICustomItem } from 'components/CustomSelect';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { validateContractor } from './contractorValidation';
import SimpleForm from 'components/SimpleForm';
import { OptionsInput } from 'components/OptionsInput';
import { bumperPaymentTypeOptions, paymentTypeOptions } from './constants';
import {
  ContractorInterface,
  ContractorUserDto,
  UserDto,
} from '@vatos-pas/common';
import { useHistory } from 'react-router-dom';
import BumperField from '../components/bumper-field';
import {
  findManyBumperUsersByContractor,
  findManyContractorUsers,
} from 'services/users';
import {
  ManyToManyReferenceContextProvider,
  ReferenceManyToManyInput,
} from '@react-admin/ra-relationships';
import { Show } from 'components/Show';

type BoxWrapperProps = {
  children: ReactElement[];
} & PublicFieldProps &
  InjectedFieldProps<ContractorInterface>;

// This component is necessary because `ReferenceManyToManyInput` needs
// To be a direct child of SimpleForm. By passing down the props of the `Box`
// we replicate this behavior and the form renders as expected.
const BoxWrapper = ({ children, ...rest }: BoxWrapperProps) => {
  const classes = useStyles();

  const renderChildren = () => {
    return React.Children.map(children, child => {
      // We want to clone `ReferenceManyToManyInput` passing down
      // the SimpleForm props
      if (child?.props?.reference === 'region') {
        return React.cloneElement(child, {
          ...rest,
        });
      }

      return child;
    });
  };

  return <Box className={classes.fields}>{renderChildren()}</Box>;
};

type LocationState = {
  user: UserDto;
  formState: Record;
  selectedBumpers: string[];
};

const updateContractorUsers = async (
  contractorUsers: ContractorUserDto[],
  selectedBumpers: string[],
  dataProvider: DataProvider,
  contractorId: string,
) => {
  const contractorUsersIds = contractorUsers?.map(item => item.userId);
  const deleteContractorUsersIds = difference(
    contractorUsersIds,
    selectedBumpers,
  );

  const insertContractorUsersIds = difference(
    selectedBumpers,
    contractorUsersIds,
  );

  if (
    !!deleteContractorUsersIds?.length ||
    !!insertContractorUsersIds?.length
  ) {
    await dataProvider.create('contractor-user/batch-process', {
      data: {
        delete: deleteContractorUsersIds.map(
          userId => contractorUsers?.find(item => item.userId === userId)?.id,
        ),
        insert: insertContractorUsersIds.map(bumper => ({
          contractorId,
          userId: bumper,
        })),
      },
    });
  }
};

export const ContractorsEdit: FC<EditProps> = props => {
  const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const routerState = (props?.location?.state ||
    props?.history?.location?.state) as LocationState;
  const user: UserDto | null = routerState?.user ?? null;
  const formState = routerState?.formState ?? null;
  const initialSelectedBumpers: string[] = routerState?.selectedBumpers ?? [];

  const [inactive, setInactive] = useState(false);
  // Bumpers field
  const [loadingBumpers, setLoadingBumpers] = useState(false);
  const [bumpers, setBumpers] = useState<ICustomItem[] | null>(null);
  const [bumperError, setBumperError] = useState(null);
  const [selectedBumpers, setSelectedBumpers] = useState<string[]>(
    initialSelectedBumpers,
  );

  // This ref is needed because <Edit /> form memoizes onSuccess function.
  // This memoization prevents `handleSuccess` function from having the updated state when
  // user saves its changes.
  // By passing a reference to the function and updating the reference value in every render
  // We make sure that the value inside `handleSuccess` will always be up to date.
  const recordBumpersRef = useRef([]);
  const selectedBumpersRef = useRef(selectedBumpers);
  selectedBumpersRef.current = selectedBumpers;

  const getContractorUser = async () => {
    if (!props.id) return;

    const contractorUsers = await findManyContractorUsers(
      dataProvider,
      props.id,
    );

    if (contractorUsers?.message) {
      setBumperError(contractorUsers.message);
      return;
    }

    const contractorUserIds = contractorUsers?.map(item => item.userId) || [];

    // If there is a `user`, it implies that we arrived at this page through a redirect
    // triggered by the creation of a bumper user.
    // This suggests that the contractor's form may have already been modified,
    // and our objective is to retain only the `selectedBumpers`,
    // discarding any response received from the API.
    const selectedDiffContractor = user
      ? difference(selectedBumpers, contractorUserIds)
      : contractorUserIds;

    // Remove duplicates
    const unifiedBumpers = union(selectedDiffContractor, selectedBumpers);

    const currentBumpers = [
      ...(unifiedBumpers || []),
      ...(user ? [user.id] : []),
    ];

    recordBumpersRef.current = contractorUsers;
    setSelectedBumpers(currentBumpers);
  };

  const getBumpers = async () => {
    if (!props.id) return;
    const bumpers = await findManyBumperUsersByContractor(
      dataProvider,
      props.id,
    );

    if (bumpers?.message) {
      setBumperError(bumpers.message);
      return;
    }

    if (bumpers?.length) {
      setBumpers(
        bumpers?.map(item => ({
          name: `${item.firstName} ${item.lastName}`,
          id: item.id,
        })),
      );
    }
  };

  const handleSuccess = async ({ data }) => {
    if (!props.id) return;

    try {
      if (data?.bumperAvailable) {
        await updateContractorUsers(
          recordBumpersRef.current,
          selectedBumpersRef.current,
          dataProvider,
          props.id,
        );
      }

      history.push('/contractor');
      // Default message when updating a contractor in the normal flow
      notify('Element updated');
    } catch (error) {
      notify(error.message, 'warning');
    }
  };

  useEffect(() => {
    const initialize = async () => {
      setLoadingBumpers(true);
      await Promise.all([getBumpers(), getContractorUser()]);
      setLoadingBumpers(false);
    };

    initialize();
  }, []);

  return (
    <Edit
      onSuccess={handleSuccess}
      mutationMode="pessimistic"
      className={classes.createBox}
      {...props}
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm
          // SimpleForm will merge `record` and `initialValues`, but record has higher priority
          // We set to an empty object so we only have `initialValues`
          {...(formState && {
            record: {},
          })}
          initialValues={formState}
          validate={validateContractor}
        >
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="name" />
            <TextInput
              fullWidth
              className={classes.input}
              source="corporation"
            />
          </Box>
          <Box className={classes.fields}>
            <OptionsInput
              fullWidth
              className={classes.input}
              label="Payment Type"
              options={paymentTypeOptions}
              source="paymentType"
            />
            <NumberInput
              fullWidth
              className={classes.input}
              min="1"
              max="100"
              step="0.01"
              source="paymentRate"
            />
            <OptionsInput
              fullWidth
              className={classes.input}
              label="Bumper Payment Type"
              options={bumperPaymentTypeOptions}
              source="bumperPaymentType"
            />
            <NumberInput
              fullWidth
              className={classes.input}
              min="1"
              max="100"
              step="0.01"
              source="bumperPaymentRate"
            />
          </Box>
          <Box className={classes.fields}>
            <BooleanInput
              label="Hanger"
              className={classes.input}
              source="hangerAvailable"
            />
            <BooleanInput
              label="Finisher"
              className={classes.input}
              source="finisherAvailable"
            />
            <BooleanInput
              label="Sprayer"
              className={classes.input}
              source="sprayerAvailable"
            />
            <BooleanInput
              label="Bumper"
              className={classes.input}
              source="bumperAvailable"
            />
            <BooleanInput
              label="Specialist"
              className={classes.input}
              source="specialistAvailable"
            />
          </Box>
          <BoxWrapper>
            <ReferenceManyToManyInput
              className={classes.halfWidth}
              source="id"
              reference="region"
              through="contractor-region"
              using="contractorId,regionId"
              label="Regions"
            >
              <SelectArrayInput
                optionText="name"
                className={classes.halfWidth}
              />
            </ReferenceManyToManyInput>
            <TextInput
              className={classes.halfWidth}
              source="notes"
              multiline
              rows={2}
            />
          </BoxWrapper>
          <Show
            condition={!loadingBumpers}
            fallback={<LinearProgress timeout={0} />}
          >
            <BumperField
              id={props.id}
              bumpers={bumpers}
              selectedBumpers={selectedBumpers}
              setSelectedBumpers={setSelectedBumpers}
              classes={classes}
              error={!!bumperError}
              helperText={bumperError ?? ''}
            />
          </Show>
          <Box display="flex">
            <BooleanInput
              onChange={event => setInactive(!event)}
              source="active"
              className={classes.input}
            />
            <BooleanInput source="suspended" className={classes.input} />
          </Box>
          {inactive && (
            <Box display="flex" minWidth="100%">
              <Typography color="error">
                Are you sure you wish to remove this contractor as an active
                contractor? This will remove this contractor from the Contractor
                List and Move them to the Inactive Filter.
              </Typography>
            </Box>
          )}
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </Edit>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  fullWidth: {
    width: '100%',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  addBumperButton: {
    width: '50%',
    marginRight: '30px',
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  chips: {
    gap: '4px',
    display: 'flex',
    flexWrap: 'wrap',
  },
});
