import { cloneElement, Fragment, ReactElement } from 'react';
import {
  List,
  Filter,
  TextInput,
  TextField,
  FilterProps,
  ListProps,
  ToolbarProps,
  ExportButtonProps,
  sanitizeListRestProps,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  FunctionField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  EditButton,
} from 'react-admin';

import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CREATE_ROUTE } from 'providers/permissions';
import { SubdivisionDto, UserDto } from '@vatos-pas/common';
import { AppRole } from 'providers/roles';

const RouteFilter = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput
      label="Route"
      source="name||$cont"
      data-cy="routeFilterName"
      alwaysOn
    />
    <ReferenceInput
      label="Region"
      source="regionId"
      data-cy="routeFilterRegion"
      reference="region"
      sort={{ field: 'name', order: 'ASC' }}
      alwaysOn
      resettable
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Supervisor"
      source="supervisorUserId"
      reference="user"
      resettable
      data-cy="routeFilterSupervisor"
      alwaysOn
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: {
              $and: [
                {
                  $or: [
                    { firstName: { $cont: value } },
                    { lastName: { $cont: value } },
                  ],
                },
                {
                  'userRoles.role.name': { $eq: AppRole.Supervisor },
                },
              ],
            },
          };
        } else {
          return {
            s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
          };
        }
      }}
      sort={{ field: 'firstName', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(user: UserDto) => {
          return `${user.firstName} ${user.lastName}`;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
    <ReferenceInput
      label="Subdivision"
      source="subdivisions.id"
      reference="subdivision"
      resettable
      alwaysOn
      data-cy="routeFilterSubdivision"
      allowEmpty={false}
      filterToQuery={value => {
        if (value) {
          return {
            s: { name: { $contL: value } },
          };
        } else {
          return {};
        }
      }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <AutocompleteInput
        optionText={(subdivison: SubdivisionDto) => {
          return subdivison.name;
        }}
        optionValue="id"
        suggestionLimit={25}
      />
    </ReferenceInput>
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions = (props: ListActionProps) => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_CREATE_ROUTE) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const RouteList = (props: ListProps) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('routes')}
      filters={<RouteFilter>{props.filters}</RouteFilter>}
      filter={{
        $join: [{ field: 'subdivisions', select: ['id', 'name'] }],
      }}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      <Datagrid rowClick={hasPermission(CAN_CREATE_ROUTE) ? 'edit' : ''}>
        <TextField label="Route" source="name" />
        <TextField label="Region" source="region.name" />
        <FunctionField
          label="Supervisor"
          render={record =>
            record &&
            `${record.supervisorUser?.firstName ?? ''} ${
              record.supervisorUser?.lastName ?? ''
            }`
          }
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
