import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateTime } from 'luxon';
import {
  RegionTimeWindowDto,
  RegionTimeWindowInterface,
} from '@vatos-pas/common';

import { Show } from 'components/Show';
import { RegionTimeWindowTableDataRow } from './types';
import { RegionTimeWindowTimePicker } from './RegionTimeWindowTimePicker';

export type RegionTimeWindowTableRowProps = {
  row: RegionTimeWindowTableDataRow;
  readOnly: boolean;
  regionId: string | undefined;
  setRegionTimeWindows?: React.Dispatch<
    React.SetStateAction<RegionTimeWindowDto[] | null>
  >;
};

const convertTo12HourFormat = (time24: string) => {
  // Extract hours and minutes
  const [hours, minutes] = time24.split(':').map(Number);

  // Determine AM/PM and convert hours
  const meridiem = hours < 12 ? 'AM' : 'PM';
  const hours12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

  // Format the result
  const time12 = `${hours12.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${meridiem}`;

  return time12;
};

export const RegionTimeWindowTableRow = ({
  row,
  readOnly,
  setRegionTimeWindows,
}: RegionTimeWindowTableRowProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [windowStart, setWindowStart] = useState<DateTime | null>(() => {
    const date = DateTime.now();
    const [hours, minutes] = row.windowStart.split(':');

    const updatedDate = date.set({
      hour: Number(hours),
      minute: Number(minutes),
      second: 0,
    });

    return updatedDate;
  });
  const [windowEnd, setWindowEnd] = useState<DateTime | null>(() => {
    const date = DateTime.now();
    const [hours, minutes] = row.windowEnd.split(':');

    const updatedDate = date.set({
      hour: Number(hours),
      minute: Number(minutes),
      second: 0,
    });

    return updatedDate;
  });

  const updateTimeRegionWindow = (
    id: string,
    newProperties: RegionTimeWindowDto,
  ) => {
    if (!setRegionTimeWindows) return;

    setRegionTimeWindows(prevState => {
      return (
        prevState?.map(item => {
          if (id === item.id) {
            return {
              ...item,
              ...newProperties,
            };
          }

          return item;
        }) ?? null
      );
    });
  };

  const removeTimeRegionWindow = (id: string) => {
    if (!setRegionTimeWindows) return;
    setRegionTimeWindows(prevState => {
      return prevState?.filter(item => item.id !== id) ?? null;
    });
  };

  const handleSave = async () => {
    if (!row?.id) return;

    try {
      setLoading(true);

      const { data } = await dataProvider.update<RegionTimeWindowInterface>(
        'region-time-window',
        {
          id: row.id,
          previousData: row,
          data: {
            windowStartTime: windowStart?.toFormat('HH:mm:ss'),
            windowEndTime: windowEnd?.toFormat('HH:mm:ss'),
          },
        },
      );

      notify('Region time window updated!');
      updateTimeRegionWindow(data.id, data as RegionTimeWindowDto);
      return data;
    } catch (error) {
      notify(
        error?.message ?? 'Failed to update the region time window.',
        'error',
      );
    } finally {
      setIsEditing(false);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);

      await dataProvider.delete<RegionTimeWindowInterface>(
        'region-time-window',
        {
          id: row.id,
          previousData: row,
        },
      );

      notify('Region time window removed!');
      removeTimeRegionWindow(row.id);
    } catch (error) {
      notify(
        error?.message ?? 'Failed to remove the region time window.',
        'error',
      );
    } finally {
      setIsEditing(false);
      setLoading(false);
    }
  };

  return (
    <>
      <TableCell>{row.incrementalId}</TableCell>
      <TableCell>
        <Show
          condition={isEditing}
          fallback={<>{convertTo12HourFormat(row.windowStart)}</>}
        >
          <RegionTimeWindowTimePicker
            value={windowStart}
            onChange={date => setWindowStart(date)}
          />
        </Show>
      </TableCell>
      <TableCell>
        <Show
          condition={isEditing}
          fallback={<>{convertTo12HourFormat(row.windowEnd)}</>}
        >
          <RegionTimeWindowTimePicker
            value={windowEnd}
            onChange={date => setWindowEnd(date)}
          />
        </Show>
      </TableCell>
      <Show condition={!readOnly}>
        <Show
          condition={!loading}
          fallback={
            <TableCell>
              <Box display="flex" py={1}>
                <CircularProgress color="primary" size={26} />
              </Box>
            </TableCell>
          }
        >
          <TableCell>
            <Box display="flex" gridColumnGap={8}>
              <Show
                condition={!isEditing}
                fallback={
                  <IconButton color="secondary" onClick={handleSave}>
                    <SaveIcon />
                  </IconButton>
                }
              >
                <IconButton
                  color="secondary"
                  onClick={() => setIsEditing(true)}
                >
                  <EditIcon />
                </IconButton>
              </Show>
              <IconButton color="secondary" onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </TableCell>
        </Show>
      </Show>
    </>
  );
};
