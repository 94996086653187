import React, { FC } from 'react';
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  TextInput,
  BooleanInput,
  SelectInput,
  FilterProps,
  NullableBooleanInput,
} from 'react-admin';
import { UserDto } from '@vatos-pas/common';
import Box from '@material-ui/core/Box';
import { AppRole } from 'providers/roles';

export enum POFilterStatus {
  'Signed' = 'Signed',
  'UnSigned' = 'UnSigned',
}

type StatusChoice = {
  id: POFilterStatus;
  name: string;
};

const statusChoices: StatusChoice[] = [
  { id: POFilterStatus.Signed, name: 'Signed' },
  { id: POFilterStatus.UnSigned, name: 'Unsigned' },
];

export const JobSheetFilters: FC<FilterProps> = props => (
  <Box>
    <Filter {...props}>
      <ReferenceInput
        label="Supervisor"
        source="supervisorUserId"
        reference="user"
        resettable
        data-cy="masterSheetFilterSupervisor"
        allowEmpty={false}
        filterToQuery={value => {
          if (value) {
            return {
              s: {
                $and: [
                  {
                    $or: [
                      { firstName: { $cont: value } },
                      { lastName: { $cont: value } },
                    ],
                  },
                  { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
                ],
              },
            };
          }
          return { s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } } };
        }}
        sort={{ field: 'firstName', order: 'ASC' }}
      >
        <AutocompleteInput
          optionText={(user: UserDto) => `${user.firstName} ${user.lastName}`}
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <TextInput
        label="Lot"
        source="jobLot||$eq"
        resettable
        data-cy="masterSheetFilterLot"
      />
      <TextInput
        label="Block"
        source="jobBlock||$eq"
        resettable
        data-cy="masterSheetFilterBlock"
      />
      <TextInput label="PO ID" source="orderNumber" resettable />
      <ReferenceInput
        label="Builder"
        source="builderId"
        reference="builder"
        resettable
        data-cy="masterSheetFilterBuilder"
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Model"
        source="modelId"
        reference="model"
        resettable
        data-cy="masterSheetFilterBuilder"
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Subdivision"
        source="subdivisionId"
        reference="subdivision"
        resettable
        data-cy="masterSheetFilterSubdivision"
        alwaysOn
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        label="Current Supplier"
        source="supplierId"
        reference="supplier"
        resettable
        data-cy="masterSheetFilterSupplier"
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <BooleanInput alwaysOn source="voided||$eq" label="Voided" />
      <TextInput
        label="Address"
        source="jobAddress1||$cont"
        data-cy="address1Filter"
      />
      <SelectInput
        label="Status"
        source="poStatus||$eq"
        choices={statusChoices}
      />
      <NullableBooleanInput
        source="preRock||$eq"
        label="Pre-Rock"
        alwaysOn
        options={{
          SelectProps: { displayEmpty: true },
          InputLabelProps: { shrink: true },
        }}
        nullLabel="All"
        falseLabel="No"
        trueLabel="Yes"
        data-cy="masterSheetFilterActivesJob"
      />
    </Filter>
  </Box>
);
