import { FC, ReactNode } from 'react';

type ShowProps = {
  children: ReactNode;
  condition: boolean;
  fallback?: ReactNode;
};

export const Show: FC<ShowProps> = ({ condition, children, fallback }) => {
  return <>{condition ? children : fallback ?? null}</>;
};
