import React, { FC, useLayoutEffect, useRef } from 'react';
import {
  TextInput,
  CreateProps,
  SimpleForm,
  Create,
  CheckboxGroupInput,
  BooleanInput,
  useDataProvider,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { BoardTypeDto, ContractorResourceTypeEnum } from '@vatos-pas/common';
import { makeStyles } from '@material-ui/core/styles';
import ToolBar from 'components/Toolbar';
import { BoardValues, validateBoard } from './boardValidation';

const useStyles = makeStyles({
  flexBox: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  fullBox: {
    width: '100%',
  },
  createBox: {
    maxWidth: '100%',
  },
});

const payableResourcesTypes = Object.values(ContractorResourceTypeEnum).map(
  resourceType => ({
    id: resourceType,
    name: resourceType,
  }),
);

export const BoardCreate: FC<CreateProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const existingBoards = useRef<BoardTypeDto[] | null>(null);

  const getBoardTypes = async () => {
    const boards = await dataProvider.getList<BoardTypeDto>('board-type', {
      filter: {},
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'name', order: 'ASC' },
    });

    if (boards?.data.length) {
      existingBoards.current = boards.data;
    }
  };

  useLayoutEffect(() => {
    getBoardTypes();
  }, []);

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm
        toolbar={<ToolBar />}
        validate={(values: BoardValues) =>
          validateBoard(values, existingBoards.current)
        }
      >
        <Box display="flex" className={classes.flexBox}>
          <TextInput source="name" label="Board Name" />
          <TextInput source="shortName" label="Board Shortname" />
          <TextInput source="externalId" label="External ID" />
        </Box>

        <Box className={classes.fullBox}>
          <Typography>Payable Resource Types</Typography>
          <CheckboxGroupInput
            row
            label=""
            source="payableResourceTypes"
            choices={payableResourcesTypes}
          />
        </Box>

        <Box className={classes.fullBox}>
          <Typography>Visible to Labor Only</Typography>
          <BooleanInput label="Labor Only" source="laborOnly" />
        </Box>
      </SimpleForm>
    </Create>
  );
};
