import React, { FC } from 'react';
import { Create, CreateProps, TextInput } from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { validateBuilder } from './buildersValidation';
import SimpleForm from 'components/SimpleForm';

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
});

export const BuildersCreate: FC<CreateProps> = props => {
  const classes = useStyles();

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm validate={validateBuilder}>
        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="name" />
          <TextInput
            fullWidth
            className={classes.input}
            source="code"
            label="ID Code"
          />
        </Box>
      </SimpleForm>
    </Create>
  );
};
