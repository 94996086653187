import React, { cloneElement, FC, Fragment, ReactElement } from 'react';
import {
  List,
  TextField,
  EditButton,
  Filter,
  TextInput,
  TopToolbar,
  sanitizeListRestProps,
  useListContext,
  CreateButton,
  ExportButton,
  ListProps,
  FilterProps,
  ToolbarProps,
  ExportButtonProps,
  ShowButton,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_BUILDER } from 'providers/permissions';

const UserFilter = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput label="Name" source="name||$cont" data-cy="builderFilterName" />
    <TextInput label="Code" source="code||$cont" data-cy="builderFilterCode" />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_BUILDER) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const BuildersList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('builders')}
      filters={<UserFilter>{props.filters}</UserFilter>}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={<React.Fragment />}
      actions={<ListActions />}
    >
      <Datagrid rowClick={() => hasPermission(CAN_EDIT_BUILDER) ? 'edit' : 'show'}>
        <TextField source="name" />
        <TextField source="code" />
        {hasPermission(CAN_EDIT_BUILDER) ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
};
