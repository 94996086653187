import React from 'react';
import {
  Datagrid as DatagridRA,
  DatagridProps as DatagridRAProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    fontWeight: 'bold',
    '& .MuiCheckbox-root': {
      display: (props: Pick<DatagridProps, 'isHeaderSelectable'>) =>
        !props.isHeaderSelectable ? 'none' : 'block',
    },
  },
});

type DatagridProps = {
  isHeaderSelectable?: boolean;
} & DatagridRAProps;

export const Datagrid: React.FC<DatagridProps> = ({
  isHeaderSelectable = true,
  ...props
}) => {
  const classes = useStyles({
    isHeaderSelectable,
  });

  return (
    <DatagridRA {...props} classes={{ headerCell: classes.header }}>
      {props.children}
    </DatagridRA>
  );
};

export default Datagrid;
