import React, { ChangeEvent, FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { useContainer } from 'unstated-next';
import { JobCreateContainer } from '../providers/job-create';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '@material-ui/core';
import { ImportedJobContainer } from '../providers/imported-job';
import { Show } from 'components/Show';
import { getFieldValidation } from '../utils';
import { JobCreateFormFields } from '../types';
import CustomSelect from 'components/CustomSelect';
import { MappingSubdivisionInput } from './MappingSubdivisionInput';

type FieldsPayload = {
  [key: string]: string;
};

type Props = {
  handleOpenInfoModal: (message: string) => void;
  resource?: string;
};

export const SpFieldMapping: FC<Props> = ({ handleOpenInfoModal }) => {
  const { setFields, models, fields, errors, builderOptions, modelOptions } =
    useContainer(JobCreateContainer);

  const {
    importedJob,
    isBuilderMatchActive,
    isModelMatchInactive,
    isSubdivisionMatchActive,
  } = useContainer(ImportedJobContainer);

  const classes = useStyles();

  const model = models?.filter(model => model.id === fields?.model)?.[0];

  const onChangeFields =
    (currentField: keyof JobCreateFormFields, payload?: FieldsPayload) =>
    (
      event: ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      if (event.target.value === fields[currentField]) return;

      setFields(prevState => ({
        ...prevState,
        [currentField]: event.target.value,
        ...payload,
      }));
    };

  return (
    <>
      <Box width="70% !important" display="flex" flexDirection="column">
        <Typography variant="body1" gutterBottom className={classes.bold}>
          SupplyPro Field Mapping
        </Typography>
        <Box display="flex" px={3} mt={2}>
          <Box display="flex" width="50%" flexDirection="column">
            <Typography variant="body1" gutterBottom>
              SupplyPro Fields
            </Typography>
            <TextField
              value={importedJob?.spOrderBuilderName}
              label="Builder"
              fullWidth
              disabled
              className={classes.inputIntegration}
              style={{ marginTop: '9px' }}
            />
            <TextField
              value={importedJob?.spOrderSubdivisionName}
              label="Subdivision"
              fullWidth
              disabled
              className={classes.inputIntegration}
              style={{ marginTop: '12px' }}
            />
            <TextField
              value={importedJob?.spOrderJobPlan}
              label="Model"
              fullWidth
              disabled
              className={classes.inputIntegration}
              style={{ marginTop: '14px' }}
            />
          </Box>
          <Box display="flex" width="50%" flexDirection="column" px={3}>
            <Typography variant="body1" gutterBottom>
              Vatos Matching Fields
            </Typography>
            <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
              <CustomSelect
                label="Builder"
                resource="builder"
                options={builderOptions}
                value={
                  importedJob?.builderId
                    ? importedJob.builderId
                    : fields.builder
                }
                onChange={onChangeFields('builder', {
                  subdivision: '',
                  model: '',
                })}
                disabled={!!importedJob?.builderId}
                error={isBuilderMatchActive}
              />
              <Show condition={isBuilderMatchActive}>
                <Box
                  onClick={() =>
                    handleOpenInfoModal(
                      'Match already exists in an inactive state. Contact an administrator to review the match.',
                    )
                  }
                >
                  <InfoIcon color="error" />
                </Box>
              </Show>
            </Box>
            <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
              <MappingSubdivisionInput error={isSubdivisionMatchActive} />

              <Show condition={isSubdivisionMatchActive}>
                <Box
                  onClick={() =>
                    handleOpenInfoModal(
                      'Match already exists in an inactive state. Contact an administrator to review the match.',
                    )
                  }
                >
                  <InfoIcon color="error" />
                </Box>
              </Show>
            </Box>
            <Box display="flex" alignItems="center" style={{ gap: '10px' }}>
              <CustomSelect
                label="Model"
                resource="model"
                options={modelOptions}
                value={
                  importedJob?.modelId ? importedJob.modelId : fields.model
                }
                onChange={onChangeFields('model')}
                required
                disabled={!fields.subdivision || !!importedJob?.modelId}
                error={
                  isModelMatchInactive || !!getFieldValidation(errors, 'model')
                }
              />
              <Show condition={isModelMatchInactive}>
                <IconButton
                  onClick={() =>
                    handleOpenInfoModal(
                      'Match already exists in an inactive state. Contact an administrator to review the match.',
                    )
                  }
                >
                  <InfoIcon color="error" />
                </IconButton>
              </Show>
              <Show condition={!isModelMatchInactive && !!model?.stockingSpec}>
                <IconButton
                  onClick={() =>
                    model?.stockingSpec
                      ? handleOpenInfoModal(model.stockingSpec)
                      : undefined
                  }
                >
                  <InfoOutlinedIcon className={classes.infoWarning} />
                </IconButton>
              </Show>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  bold: {
    fontWeight: 'bold',
  },
  infoWarning: {
    color: '#ff9800',
  },
  inputIntegration: {
    margin: '10px 0px',
  },
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});
