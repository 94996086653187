interface IErrors {
  name?: string[];
  externalId?: string[];
  description?: string[];
  email?: string[];
  phone?: string[];
  contactInfo?: string[];
  supplierRepresentatives?: string[];
}

type SupplierValues = {
  name: string;
  externalId: string;
  description: string;
  email: string;
  phone: string;
  contactInfo: string;
  supplierRepresentatives: string;
}

export const validateSupplier = (values: SupplierValues): IErrors => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.externalId) {
    errors.externalId = ['The External Id is required'];
  }
  if (!values.description) {
    errors.description = ['The Description is required'];
  }
  if (!values.email) {
    errors.email = ['The Email is required'];
  }
  if (!values.phone) {
    errors.phone = ['The Phone is required'];
  }
  if (!values.contactInfo) {
    errors.contactInfo = ['The Contact Info is required'];
  }

  return errors;
};
