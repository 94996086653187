import React, { Fragment, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageLogo from 'assets/logo.png';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
// import CachedIcon from '@material-ui/icons/Cached';
import { changePassword, verifyToken } from 'services/auth';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
  },
  logo: {
    width: '100%',
    marginBottom: '30px',
    '@media(max-width: 1300px)': {
      maxWidth: '325px',
    },
  },
  cardLogin: {
    maxWidth: '400px',
    width: '20vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '@media(max-width: 1300px)': {
      width: '70vw',
      padding: '20px 30px',
      maxWidth: '600px',
    },
  },
  input: {
    marginBottom: '10px',
  },
  submitButton: {
    marginLeft: 'auto',
    width: 'fit-content',
    fontWeight: 'bold',
  },
  recoveryButton: {
    width: 'fit-content',
    fontWeight: 'bold',
  },
  successText: {
    color: 'green',
    marginBottom: '10px',
  },
  rotate: {
    animation: 'rotating 2s linear infinite',
  },
  nonRotate: {
    animation: 'none',
  },
});

interface IConfirm {
  match: {
    params: {
      token: string;
    };
  };
  children?: React.ReactNode;
}

export const ConfirmRecovery: React.FC<IConfirm> = props => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const classes = useStyles();
  const [password, setPassword] = useState('');

  useEffect(() => {
    const token = props.match.params.token;
    // Check this behavior
    verifyToken({ token }).then(_response => {
      return;
    });
  }, []);

  const confirmChange = () => {
    changePassword({ password, token: props.match.params.token }).then(
      _response => {
        setPasswordChanged(true);
      },
    );
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        borderRadius={10}
        boxShadow={3}
        p={4}
        px={6}
        className={classes.cardLogin}
      >
        <img src={ImageLogo} className={classes.logo} />
        {passwordChanged ? (
          <Fragment>
            <Typography className={classes.successText}>
              Password Changed!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.recoveryButton}
              href={`${window.location.origin}/#/login`}
            >
              Go to login
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Typography variant="h4">Add the new password</Typography>
            <form className={classes.form}>
              <Fragment>
                <TextField
                  className={classes.input}
                  id="standard-basic"
                  label="Password"
                  type="password"
                  variant="outlined"
                  value={password}
                  onChange={onChangePassword}
                />
                <Box display="flex" justifyContent="flex-end">
                  {/* <Button
                    variant="contained"
                    color="default"
                    disabled={sending}
                    className={classes.recoveryButton}
                    startIcon={
                      <CachedIcon
                        className={sending ? classes.rotate : classes.nonRotate}
                      />
                    }
                    onClick={() => {
                      setSending(true);
                      setTimeout(() => setSending(false), 1500);
                    }}
                  >
                    {sending ? 'Sending' : 'Send Again'}
                  </Button> */}
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.recoveryButton}
                    startIcon={<CheckIcon />}
                    onClick={confirmChange}
                  >
                    Confirm
                  </Button>
                </Box>
              </Fragment>
            </form>
          </Fragment>
        )}
      </Box>
    </Box>
  );
};

export default ConfirmRecovery;
