import React, { FC, useEffect, useState } from 'react';
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  Create,
  PublicFieldProps,
  InjectedFieldProps,
  CreateProps,
  BooleanInput,
  useDataProvider,
} from 'react-admin';
import { validateSubdivisionCreation } from './subDivisionValidation';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { State } from 'components/State';
import { useFormState } from 'react-final-form';
import SimpleForm from 'components/SimpleForm';
import { RouteDto, SubdivisionInterface } from '@vatos-pas/common';
import InfoDialog from 'components/InfoDialog';
import { CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { RouteInput } from '../components/RouteInput';
import { findRouteByRegion } from 'services/route';
import { SupervisorInput } from '../components/SupervisorInput';
import { WarrantyStatus, WarrantyStatusChoices } from '../constants';

type BumpoutTimesheetBooleanInputProps = PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface>;

const BumpoutTimesheetBooleanInput = (
  props: BumpoutTimesheetBooleanInputProps,
) => {
  const { values } = useFormState();
  const classes = useStyles();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  return (
    <>
      <BooleanInput
        {...props}
        fullWidth
        onChange={() => {
          if (!values.reqBumpoutTimesheet) {
            setIsConfirmModalOpen(true);
          }
        }}
        className={classes.bumpoutTimesheetFlow}
        source="reqBumpoutTimesheet"
        defaultValue={false}
      />
      <InfoDialog
        title="Attention!"
        handleClose={() => {
          setIsConfirmModalOpen(false);
        }}
        open={isConfirmModalOpen}
        content="Activating this setting is a permanent change with no option to revert. All jobs will progress through the new bump flows if this change is made. Please be aware before saving."
      />
    </>
  );
};

type ReferencedInputsProps = {
  setSelectedRoute: React.Dispatch<React.SetStateAction<RouteDto | null>>;
  classes: { [key: string]: string };
} & PublicFieldProps &
  InjectedFieldProps<SubdivisionInterface>;

const ReferencedInputs: FC<ReferencedInputsProps> = props => {
  const dataProvider = useDataProvider();
  const { values } = useFormState();

  const [routes, setRoutes] = useState<RouteDto[]>([]);

  const getRoutes = async e => {
    const routes = await findRouteByRegion(dataProvider, e.target.value);
    setRoutes(routes);
  };

  useEffect(() => {
    const e = {
      target: {
        value: props.record?.regionId,
      },
    };
    getRoutes(e);
  }, []);

  return (
    <Box display="flex">
      <ReferenceInput
        perPage={Number.MAX_SAFE_INTEGER}
        sort={{ field: 'name', order: 'ASC' }}
        className={props.classes.input}
        fullWidth
        label="Builder"
        source="builderId"
        reference="builder"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        perPage={Number.MAX_SAFE_INTEGER}
        sort={{ field: 'name', order: 'ASC' }}
        className={props.classes.input}
        fullWidth
        onChange={e => getRoutes(e)}
        label="Region"
        source="regionId"
        reference="region"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <RouteInput
        classes={props.classes}
        source="routeId"
        regionId={values.regionId}
        onChange={e => {
          props.setSelectedRoute(routes.find(route => route.id === e) ?? null);
        }}
        options={routes}
      />
      <BooleanInput defaultValue={true} label="Active" source="active" />
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
  flexFields: {
    width: '100%',
    display: 'flex',
  },
  thirdWidth: {
    width: '30%',
    margin: '0px 15px',
  },
  bumpoutTimesheetFlow: {
    margin: '20px 15px',
  },
});

export const SubDivisionsCreate: FC<CreateProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();
  const classes = useStyles();

  const [selectedRoute, setSelectedRoute] = useState<RouteDto | null>(null);

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm
        validate={validateSubdivisionCreation}
        initialValues={{
          reqBumpoutTimesheet: false,
        }}
      >
        <ReferencedInputs
          classes={classes}
          setSelectedRoute={setSelectedRoute}
        />
        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="name" />
          <TextInput
            fullWidth
            className={classes.input}
            source="idCode"
            label="ID Code"
          />
        </Box>
        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="address1" />
          <TextInput fullWidth className={classes.input} source="address2" />
        </Box>
        <Box className={classes.fields}>
          <TextInput fullWidth className={classes.input} source="city" />
          <TextInput fullWidth className={classes.input} source="zip" />
          <State fullWidth className={classes.input} source="state" />
        </Box>
        <Box className={classes.flexFields}>
          <SelectInput
            fullWidth
            source="warrantyStatus"
            label="Warranty Status"
            initialValue={WarrantyStatus.Production}
            className={classes.thirdWidth}
            choices={WarrantyStatusChoices}
          />

          <SupervisorInput
            classes={classes}
            source="supervisorUserId"
            onChange={() => ({})}
            value={selectedRoute?.supervisorUserId}
          />
        </Box>
        {hasPermission(CAN_TOGGLE_SUBDIVISION_BUMPOUT_TIMESHEET_FLOW) && (
          <BumpoutTimesheetBooleanInput label="Follow Bumpout Timesheets Flow" />
        )}
      </SimpleForm>
    </Create>
  );
};
