import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useContainer } from 'unstated-next';
import { JobCreateContainer } from '../providers/job-create';
import { ImportedJobContainer } from '../providers/imported-job';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash/debounce';

type SubdivisionInputProps = {
  error: boolean;
};

export const MappingSubdivisionInput = ({ error }: SubdivisionInputProps) => {
  const classes = useStyles();
  const {
    getModels,
    getSubdivisions,
    loadingSubdivisions,
    subdivisions,
    currentSubdivisionRequestId,
    fields,
    setFields,
    setSubdivisions,
  } = useContainer(JobCreateContainer);

  const { importedJob } = useContainer(ImportedJobContainer);

  const [selectedSubdivision, setSelectedSubdivision] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [inputValue, setInputValue] = useState('');

  const builderId = importedJob?.builderId ?? fields.builder;

  const value = importedJob?.subdivisionId
    ? {
        id: importedJob.subdivisionId,
        name: importedJob.subdivisionName ?? '',
      }
    : selectedSubdivision;

  const handleChange = async (_event, value, _reason, _details) => {
    if (value?.id === fields['subdivision']) return;

    setFields(prevState => ({
      ...prevState,
      subdivision: value?.id ?? '',
      model: '',
    }));
    setSelectedSubdivision(value);
    getModels(value?.id ?? '');
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(name => {
        if (name === value?.name) return;

        currentSubdivisionRequestId.current += 1;
        const requestId = currentSubdivisionRequestId.current;
        getSubdivisions(
          builderId,
          requestId,
          name,
          importedJob?.subdivisionId ?? undefined,
        );
      }, 300),
    [builderId],
  );

  // Reset subdivision state whenever builder changes
  useEffect(() => {
    setSelectedSubdivision(null);
    setSubdivisions([]);
  }, [builderId]);

  useEffect(() => {
    debouncedSearch(inputValue);

    // Clean up the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [builderId, inputValue, debouncedSearch]);

  return (
    <Autocomplete
      fullWidth
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => (option ? option?.name : '')}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.box}
          name="subdivision"
          label="Subdivision"
          error={error}
        />
      )}
      disabled={!fields.builder || !!importedJob?.subdivisionId}
      options={subdivisions?.map(item => ({
        name: item.name,
        id: item.id,
      }))}
      loading={loadingSubdivisions}
      onChange={handleChange}
      value={value}
      onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
    />
  );
};

const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});
