import {
  MaterialDto,
  PurchaseOrderFieldsType,
  BoardDto,
  JobCreateFormFields,
  SpMatchFields,
  SpMatchFieldsName,
  SpMatchFieldsId,
  SpFieldMapping,
  SpFieldMappingFields,
} from './types';

export const EMPTY_MATERIALS = [];

export const INITIAL_MATERIALS: MaterialDto[] = [
  { name: 'Mud', unit: 'Box', amount: 1 },
  { name: 'Tape', unit: 'Roll', amount: 1 },
  { name: "8' CB", unit: 'Box', amount: 1 },
  { name: "10' CB", unit: 'Box', amount: 1 },
];

export const INITIAL_BOARD: BoardDto = {
  floor: 1,
  boardTypeId: '',
  quantityOrdered: 0,
};

export const INITIAL_FLOORS: BoardDto[] = [{ ...INITIAL_BOARD }];

export const INITIAL_DRAFT_PURCHASE_ORDER: PurchaseOrderFieldsType = {
  orderNumber: '',
  dateExpected: new Date(),
  supplierId: '',
  laborOnly: false,
  isReplacement: false,
  notesForSupplier: null,
  invoiceNumber: null,
  boardPurchaseFloors: [...INITIAL_FLOORS],
  materials: [...INITIAL_MATERIALS],
};

export const NOT_A_BOARD_ID = 'NOT_A_BOARD';

export const NOT_A_BOARD_OPTION = {
  id: NOT_A_BOARD_ID,
  name: 'Not a board',
};

export const INITIAL_JOB_CREATE: JobCreateFormFields = {
  idNumber: '',
  block: undefined,
  lot: '',
  communityPhase: '',
  buildingName: '',
  supervisor: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  builder: '',
  subdivision: '',
  model: '',
  regionId: '',
};

export const SP_MATCHING_FIELDS = {
  [SpMatchFields.Builder]: {
    name: SpMatchFieldsName.Builder,
    id: SpMatchFieldsId.Builder,
  },
  [SpMatchFields.Subdivision]: {
    name: SpMatchFieldsName.Subdivision,
    id: SpMatchFieldsId.Subdivision,
  },
  [SpMatchFields.Model]: {
    name: SpMatchFieldsName.Model,
    id: SpMatchFieldsId.Model,
  },
};

export const SP_FIELD_MAPPING = {
  [SpFieldMapping.SpOrderBuilderOrderNumber]: SpFieldMappingFields.IdNumber,
  [SpFieldMapping.SpOrderJobBlock]: SpFieldMappingFields.Block,
  [SpFieldMapping.SpOrderJobCity]: SpFieldMappingFields.City,
  [SpFieldMapping.SpOrderJobLot]: SpFieldMappingFields.Lot,
  [SpFieldMapping.SpOrderJobPostalCode]: SpFieldMappingFields.Zip,
  [SpFieldMapping.SpOrderJobStreet]: SpFieldMappingFields.Address1,
  [SpFieldMapping.SpOrderJobStreetSupplement]: SpFieldMappingFields.Address2,
  [SpFieldMapping.SpOrderJobStateCode]: SpFieldMappingFields.State,
  [SpFieldMapping.SpOrderSubdivisionName]: SpFieldMappingFields.CommunityPhase,
};
