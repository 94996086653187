import {
  BoardFloorDto,
  BoardPurchaseFloorInterface,
  BoardPurchaseInterface,
  JobCreateDto,
  MaterialInterface,
  ModelBoardPurchaseDto,
} from '@vatos-pas/common';
import { EditorState } from 'draft-js';

export type BoardExcelFloorBaseDto = Pick<
  BoardFloorDto,
  'floor' | 'quantity' | 'boardTypeId' | 'boardType'
>[];

export type MaterialDto = Pick<MaterialInterface, 'name' | 'unit' | 'amount'>;

export type BoardDto = Pick<
  BoardPurchaseFloorInterface,
  'floor' | 'boardTypeId' | 'quantityOrdered'
>;

export type PurchaseOrderFieldsType = {
  boardPurchaseFloors?: BoardDto[];
  materials?: MaterialDto[] | [];
} & Pick<
  BoardPurchaseInterface,
  | 'orderNumber'
  | 'dateExpected'
  | 'supplierId'
  | 'laborOnly'
  | 'isReplacement'
  | 'notesForSupplier'
  | 'invoiceNumber'
>;

export type ModelBoardPurchaseDtoWithId = {
  id: string;
} & ModelBoardPurchaseDto;

export interface JobCreateFormFields {
  idNumber: string;
  block: string | undefined;
  lot: string;
  communityPhase: string;
  buildingName: string;
  supervisor: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  builder: string;
  subdivision: string;
  model: string;
  regionId: string;
}

export type EnhancedJobCreateDto = {
  options: EditorState;
} & JobCreateDto;

export enum SpMatchFields {
  Builder = 'Builder',
  Subdivision = 'Subdivision',
  Model = 'Model',
}

export enum SpMatchFieldsName {
  Builder = 'spBuilderNames',
  Subdivision = 'spSubdivisionNames',
  Model = 'spModelNames',
}

export enum SpMatchFieldsId {
  Builder = 'spOrderBuilderNameId',
  Subdivision = 'spOrderSubdivisionNameId',
  Model = 'spOrderJobPlanId',
}

export enum SpFieldMapping {
  SpOrderBuilderOrderNumber = 'spOrderBuilderOrderNumber',
  SpOrderJobBlock = 'spOrderJobBlock',
  SpOrderJobCity = 'spOrderJobCity',
  SpOrderJobLot = 'spOrderJobLot',
  SpOrderJobPostalCode = 'spOrderJobPostalCode',
  SpOrderJobStreet = 'spOrderJobStreet',
  SpOrderJobStreetSupplement = 'spOrderJobStreetSupplement',
  SpOrderJobStateCode = 'spOrderJobStateCode',
  SpOrderSubdivisionName = 'spOrderSubdivisionName',
}

export enum SpFieldMappingFields {
  IdNumber = 'idNumber',
  Block = 'block',
  City = 'city',
  Lot = 'lot',
  Zip = 'zip',
  Address1 = 'address1',
  Address2 = 'address2',
  State = 'state',
  CommunityPhase = 'communityPhase',
}
