import React, { Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'components/ConfirmDialog';
import { useDataProvider, useNotify } from 'react-admin';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '500px',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

const deletePhoto = async (
  dataProvider,
  jobPhasePhotoId,
  setOpenConfirmDelete,
  notify,
  getPhotos,
  handleClose,
) => {
  try {
    await dataProvider.delete('job-repair-photo', {
      id: jobPhasePhotoId,
    });
    notify('Success on Remove!');
  } catch {
    notify(`Error when try to remove the photo`, 'warning');
  }
  getPhotos();
  setOpenConfirmDelete(false);
  handleClose();
};

export const ImageViewerRepair = (props: any) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  console.log(props.imageSelected);

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image Viewer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.imageSelected && (
              <img
                className={classes.img}
                src={props.imageSelected.fileUrl}
              ></img>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
          {props.canPaid && (
            <Button
              onClick={() => setOpenConfirmDelete(true)}
              color="primary"
              autoFocus
            >
              Delete Photo
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDelete}
        title={`Are you sure that you want to delete this photo?`}
        handleClose={() => setOpenConfirmDelete(false)}
        onConfirm={() =>
          deletePhoto(
            dataProvider,
            props.imageSelected.id,
            setOpenConfirmDelete,
            notify,
            props.getPhotos,
            props.handleClose,
          )
        }
      />
    </Fragment>
  );
};

export default ImageViewerRepair;
