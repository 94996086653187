import React, { useRef } from 'react';

import { Dialog, Box, Button } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';

import { Show } from 'components/Show';

interface PurchaseOrderSummaryProps {
  floor?: [];
  open?: boolean;
  onClose?: () => void;
  summaryData?: any;
}

export const PurchaseOrderSummary: React.FC<PurchaseOrderSummaryProps> = ({
  open = false,
  onClose,
  summaryData,
}) => {
  const componentRef = useRef<any>();

  if (!summaryData) return null;
  const {
    address1,
    address2,
    builderName,
    city,
    dateExpected,
    lotNumber,
    jobBlock,
    modelName,
    orderNumber,
    state,
    subdivisionName,
    supervisorEmail,
    supervisorFirstName,
    supervisorLastName,
    supervisorPhoneNumber,
    zip,
    floors,
    notes,
  } = summaryData;
  return (
    <Dialog onClose={onClose} open={open}>
      <div>
        <ReactToPrint
          trigger={() => (
            <PrintIcon
              style={{
                cursor: 'pointer',
                color: '#4f3cc9',
                zIndex: 123,
                right: '16px',
                top: '16px',
                position: 'absolute',
              }}
            >
              Print this out!
            </PrintIcon>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div ref={componentRef} style={{ padding: '0px 16px 16px' }}>
        <Box mb={1} width="100%" minWidth="380px">
          <p style={{ fontSize: '22px', marginTop: '10px' }}>
            Purchase order - {orderNumber}
          </p>
          <p style={{ fontSize: '12px' }}>
            <b>Deliver On:</b>{' '}
            {new Date(dateExpected).toLocaleDateString('en-US')}
          </p>

          <p style={{ margin: '2px', fontSize: '12px' }}>{builderName}</p>
          <p style={{ margin: '2px', fontSize: '12px' }}>{subdivisionName}</p>
          <p style={{ margin: '2px', fontSize: '12px' }}>Lot: {lotNumber}</p>
          <Show condition={!!jobBlock}>
            <p style={{ margin: '2px', fontSize: '12px' }}>Block: {jobBlock}</p>
          </Show>
          <p style={{ margin: '2px', fontSize: '12px' }}>Model: {modelName}</p>
          <p style={{ margin: '2px', fontSize: '12px' }}>
            {address1 + ' ' + address2}
          </p>
          <p style={{ margin: '2px', fontSize: '12px' }}>
            {city}, {state} {zip}
          </p>
          <table
            style={{
              borderCollapse: 'collapse',
              marginTop: '20px',
              marginBottom: '40px',
              width: '100%',
            }}
          >
            {floors.map(floor => {
              if (!floor) return null;
              return (
                <>
                  <thead style={{ borderTop: '2px solid lightgray' }}>
                    <tr
                      style={{
                        borderBottom: '2px solid lightgray',
                        height: '25px',
                      }}
                    >
                      <th style={{ textAlign: 'left', fontSize: '12px' }}>
                        Floor #{floor.floor}
                      </th>
                    </tr>
                    <tr
                      style={{
                        textAlign: 'left',
                        borderBottom: '2px solid lightgray',
                        height: '25px',
                      }}
                    >
                      <th
                        style={{
                          fontWeight: 'unset',
                          fontStyle: 'italic',
                          textAlign: 'right',
                          fontSize: '12px',
                        }}
                      >
                        Quantity
                      </th>
                      <th
                        style={{
                          fontWeight: 'unset',
                          fontStyle: 'italic',
                          paddingLeft: '25px',
                          fontSize: '12px',
                        }}
                      >
                        Description
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      borderTop: '2px solid lightgray',
                      borderBottom: '2px solid lightgray',
                    }}
                  >
                    {floor.items.map(item => {
                      return (
                        <tr
                          key={item.id}
                          style={{
                            borderBottom: '2px solid lightgray',
                          }}
                        >
                          <td
                            style={{
                              textAlign: 'right',
                              height: '25px',
                              fontSize: '12px',
                            }}
                          >
                            {item.quantity}
                          </td>
                          <td
                            style={{
                              borderTop: '2px solid lightgray',
                              borderBottom: '2px solid lightgray',
                              paddingLeft: '30px',
                              height: '25px',
                              fontSize: '12px',
                            }}
                          >
                            {item.description}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              );
            })}
          </table>
          <p style={{ margin: '2px', fontSize: '12px' }}>
            <b>Notes</b>
          </p>
          <pre
            style={{
              fontSize: '12px',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {notes}
          </pre>
          <p style={{ margin: '2px', fontSize: '12px' }}>
            Contact Information {supervisorFirstName + ' ' + supervisorLastName}{' '}
            {supervisorPhoneNumber}
          </p>
          <p style={{ margin: '2px', fontSize: '12px' }}>{supervisorEmail}</p>
        </Box>
      </div>
      <Box mb={2} mr={2} display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Dialog>
  );
};

export default PurchaseOrderSummary;
