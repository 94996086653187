import {
  FunctionField,
  InjectedFieldProps,
  PublicFieldProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Show } from 'components/Show';
import InfoDialog from 'components/InfoDialog';
import { useState } from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core';
import { BumpResourceSheetDto, RepairTypeEnum } from '@vatos-pas/common';
import { getFormattedTimeWindow } from 'modules/master-sheet/utils/getFormattedTimeWindow';

type RepairTypeFieldProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const RepairTypeField = (props: RepairTypeFieldProps) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <FunctionField
      {...props}
      source="repairType"
      render={record => {
        return (
          <Box display="flex" alignItems="center" gridColumnGap={4}>
            {record.repairType}
            <Show
              condition={
                record.repairType === RepairTypeEnum.HOService &&
                record.bumperApprovalStatus === 'NOT_STARTED'
              }
            >
              <IconButton onClick={() => setIsModalOpen(true)}>
                <InfoOutlinedIcon className={classes.infoWarning} />
              </IconButton>
            </Show>
            <InfoDialog
              open={isModalOpen}
              handleClose={() => setIsModalOpen(false)}
              title="Time Window"
              content={
                <>
                  {record.windowStartTime && record.windowEndTime && (
                    <Typography>
                      {getFormattedTimeWindow(
                        record.windowStartTime,
                        record.windowEndTime,
                      )}{' '}
                      expected arrival time
                    </Typography>
                  )}
                </>
              }
            />
          </Box>
        );
      }}
    />
  );
};

const useStyles = makeStyles({
  infoWarning: {
    color: '#ff9800',
  },
});

export default RepairTypeField;
