import { useState } from 'react';
import { createContainer } from 'unstated-next';

const useUI = () => {
  const [opened, setOpened] = useState(true);

  return { opened, setOpened };
};

export const UIProvider = createContainer(useUI);
