import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/file-input/dist/style.css';

import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { Dashboard, useUppy } from '@uppy/react';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { makeStyles } from '@material-ui/core/styles';

import { dataProvider } from 'providers/dataProvider';
import ImageViewerRepair from './master-sheet-image-viewer-repair';

const bucketURL =
  'https://bucketeer-e6c458d7-d98f-42b7-898e-57cb91418260.s3.amazonaws.com/public/';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
});

type MasterSheetRepairPhotoUploadProps = {
  open: boolean;
  handleClose(): void;
  type: string;
  isViewPhotos?: boolean;
  photosStagedEmail: string[];
  photosStaged: string[];
  setPhotosStaged(name: string): void;
  setPhotosStagedEmail(name: string): void;
};

export const MasterSheetRepairPhotoUpload: FC<MasterSheetRepairPhotoUploadProps> =
  ({
    open,
    type,
    handleClose,
    isViewPhotos = false,
    photosStaged,
    photosStagedEmail,
    setPhotosStaged,
    setPhotosStagedEmail,
  }) => {
    const classes = useStyles();
    const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
    const [imageSelected, setImageSelected] = useState<any>();

    const onBeforeUpload = files => {
      Object.keys(files).map((key: any) => {
        files[key].fileName = `job-repair-photo-${new Date().getTime()}-${
          files[key].name
        }`;
      });
      return files;
    };

    const uppy = useUppy(() => {
      return new Uppy({
        autoProceed: true,
        onBeforeUpload,
        restrictions: {
          maxNumberOfFiles: 1,
          allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
        },
      }).use(AwsS3, {
        getUploadParameters(file: any) {
          return dataProvider
            .create('/uploads/sign', {
              data: { contentType: file.type, objectName: `${file.fileName}` },
            })
            .then(({ data }) => {
              return {
                method: 'PUT',
                url: data.signedUrl,
                fields: data.fields,
                headers: { 'Content-Type': file.type },
              };
            });
        },
      });
    });

    useEffect(() => {
      uppy.on('complete', _response => {
        _response.successful.map((itemResponse: any) => {
          if (type === 'email') {
            setPhotosStagedEmail(itemResponse.fileName);
          } else {
            setPhotosStaged(itemResponse.fileName);
          }
          handleClose();
        });
        uppy.reset();
      });
    }, []);

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title-"
        aria-describedby="alert-dialog-description9"
      >
        <DialogTitle id="alert-dialog-ti9tle">
          {isViewPhotos ? 'View Job Phase Photos' : 'Photos'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-desc9ription">
            {!isViewPhotos && (
              <Dashboard
                disableThumbnailGenerator
                showLinkToFileUploadResult={false}
                uppy={uppy}
                height={100}
              />
            )}
          </DialogContentText>
          <Box>
            {type === 'email' ? (
              <Box>
                {photosStagedEmail.map((repairPhoto: any, i) => {
                  return (
                    <img
                      className={classes.img}
                      onClick={() => {
                        setImageSelected(bucketURL + repairPhoto);
                        setImageViewerOpen(true);
                      }}
                      src={bucketURL + repairPhoto}
                      key={i}
                    />
                  );
                })}
              </Box>
            ) : (
              <Box>
                {photosStaged.map((repairPhoto: any, i) => {
                  return (
                    <img
                      className={classes.img}
                      onClick={() => {
                        setImageSelected(bucketURL + repairPhoto);
                        setImageViewerOpen(true);
                      }}
                      src={bucketURL + repairPhoto}
                      key={i}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
        <ImageViewerRepair
          imageSelected={imageSelected}
          open={imageViewerOpen}
          handleClose={() => setImageViewerOpen(false)}
        />
      </Dialog>
    );
  };

export default MasterSheetRepairPhotoUpload;
