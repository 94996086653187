import React, { FC, ReactElement } from 'react';
import { cloneElement, Fragment } from 'react';
import {
  List,
  TextField,
  EditButton,
  Filter,
  SingleFieldList,
  ChipField,
  TextInput,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  ArrayField,
  FunctionField,
  FunctionFieldProps,
  Record,
  ToolbarProps,
  ExportButtonProps,
  ListProps,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_SUPPLIER } from 'providers/permissions';
import { UserInterface } from '@vatos-pas/common';

type RepresentativeChipFieldProps = {
  id: Record['id'];
  user: UserInterface;
};

const RepresentativeChipField: FC<
  Omit<FunctionFieldProps<RepresentativeChipFieldProps>, 'render'>
> = props => (
  <FunctionField<RepresentativeChipFieldProps>
    {...props}
    render={(record: RepresentativeChipFieldProps | undefined) => {
      if (!record) return null;

      const { user } = record;
      const { firstName, lastName } = user;
      const fullName = [firstName, lastName].join(' ');
      return <ChipField source="fullName" record={{ ...record, fullName }} />;
    }}
  />
);

const SupplierFilter: FC<any> = props => (
  <Filter {...props}>
    <TextInput
      label="Name"
      source="name||$cont"
      data-cy="suppliersFilterName"
    />
    <TextInput
      label="Email"
      source="email||$cont"
      data-cy="suppliersFilterEmail"
    />
    <TextInput
      label="Phone"
      source="phone||$cont"
      data-cy="suppliersFilterPhone"
    />
    <TextInput
      label="External ID"
      source="externalId||$cont"
      data-cy="suppliersFilterExternalId"
    />
  </Filter>
);

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_SUPPLIER) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const SuppliersList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('suppliers')}
      filters={<SupplierFilter />}
      filter={{
        $join: [
          {
            field: 'supplierRegions',
          },
          { field: 'supplierRegions.region' },
        ],
      }}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
      actions={<ListActions />}
    >
      <Datagrid rowClick={hasPermission(CAN_EDIT_SUPPLIER) ? 'edit' : ''}>
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="externalId" label="External ID" />
        <ArrayField
          source="supplierRegions"
          fieldKey="id"
          label="Regions"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="region.name" />
          </SingleFieldList>
        </ArrayField>
        <ArrayField
          source="supplierRepresentative"
          fieldKey="id"
          label="Supplier Representatives"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <RepresentativeChipField />
          </SingleFieldList>
        </ArrayField>
        {hasPermission(CAN_EDIT_SUPPLIER) && <EditButton />}
      </Datagrid>
    </List>
  );
};
