import React, { useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DateTime } from 'luxon';
import {
  RegionTimeType,
  RegionTimeWindowDto,
  RegionTimeWindowInterface,
} from '@vatos-pas/common';

import { Show } from 'components/Show';
import { RegionTimeWindowTableDataRow } from './types';
import { RegionTimeWindowTimePicker } from './RegionTimeWindowTimePicker';

type RegionTimeWindowTableDataRowWithoutId = Omit<
  RegionTimeWindowTableDataRow,
  'id'
>;

export type RegionTimeWindowTableDraftRowProps = {
  row: RegionTimeWindowTableDataRowWithoutId;
  regionId: string | undefined;
  setRegionTimeWindows: React.Dispatch<
    React.SetStateAction<RegionTimeWindowDto[] | null>
  >;
  setDraftRow: React.Dispatch<
    React.SetStateAction<Partial<RegionTimeWindowTableDataRow> | null>
  >;
};

type RegionTimeWindowError = {
  windowStart: boolean;
  windowEnd: boolean;
};

export const RegionTimeWindowTableDraftRow = ({
  row,
  regionId,
  setRegionTimeWindows,
  setDraftRow,
}: RegionTimeWindowTableDraftRowProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [loading, setLoading] = useState(false);
  const [windowStart, setWindowStart] = useState<DateTime | null>(null);
  const [windowEnd, setWindowEnd] = useState<DateTime | null>(null);
  const [errors, setErrors] = useState<RegionTimeWindowError>({
    windowStart: false,
    windowEnd: false,
  });

  const addTimeRegionWindow = (newProperties: RegionTimeWindowDto) => {
    setRegionTimeWindows(prevState => {
      if (!prevState) {
        return [newProperties];
      }

      return [...prevState, newProperties];
    });
  };

  const handleCreate = async () => {
    if (!windowStart || !windowEnd) {
      setErrors({
        windowStart: !windowStart,
        windowEnd: !windowEnd,
      });
      return;
    }

    try {
      setLoading(true);

      const { data } = await dataProvider.create<RegionTimeWindowInterface>(
        'region-time-window',
        {
          data: {
            regionId,
            regionTimeType: RegionTimeType.HO_SERVICE,
            windowStartTime: windowStart?.toFormat('HH:mm:ss'),
            windowEndTime: windowEnd?.toFormat('HH:mm:ss'),
          },
        },
      );

      notify('Region time window created!');
      addTimeRegionWindow(data as RegionTimeWindowDto);
      setDraftRow(null);
      return data;
    } catch (error) {
      notify(
        error?.message ?? 'Failed to create the region time window.',
        'warning',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <TableCell>{row.incrementalId}</TableCell>
      <TableCell>
        <RegionTimeWindowTimePicker
          value={windowStart}
          onChange={date => setWindowStart(date)}
          error={errors.windowStart}
          helperText={
            errors.windowStart ? 'Window Start cannot be empty.' : undefined
          }
        />
      </TableCell>
      <TableCell>
        <RegionTimeWindowTimePicker
          value={windowEnd}
          onChange={date => setWindowEnd(date)}
          error={errors.windowEnd}
          helperText={
            errors.windowEnd ? 'Window End cannot be empty.' : undefined
          }
        />
      </TableCell>
      <Show
        condition={!loading}
        fallback={
          <TableCell>
            <Box display="flex" py={1}>
              <CircularProgress color="primary" size={26} />
            </Box>
          </TableCell>
        }
      >
        <TableCell>
          <Box display="flex" gridColumnGap={8}>
            <IconButton color="secondary" onClick={handleCreate}>
              <SaveIcon />
            </IconButton>

            <IconButton color="secondary" onClick={() => setDraftRow(null)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </Show>
    </>
  );
};
