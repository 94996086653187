interface IErrors {
  name?: string[];
  subdivisionId?: string[];
  externalId?: string[];
  description?: string[];
  stockingSpec?: string[];
}

type ModelValues = {
  name: string;
  subdivisionId: string;
  externalId: string;
  description: string;
  stockingSpec?: string;
};

const MAX_STOCKING_SPEC_LENGTH = 500;

export const validateModel = async (values: ModelValues): Promise<IErrors> => {
  const errors: IErrors = {};
  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.subdivisionId) {
    errors.subdivisionId = ['The Subdivision is required'];
  }
  if (!values.externalId) {
    errors.externalId = ['The External Id is required'];
  }
  if (!values.description) {
    errors.description = ['The Description is required'];
  }
  if (
    values.stockingSpec &&
    values.stockingSpec.length > MAX_STOCKING_SPEC_LENGTH
  ) {
    errors.stockingSpec = [
      'Stocking Spec must be shorter than or equal to 500 characters',
    ];
  }
  return errors;
};
