import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberFieldProps,
  ShowButton,
  TextField,
} from 'react-admin';
import { exporter } from 'providers/export';
import { PendingJobsSheetFilter } from './filters/pending-jobs-filter';
import { useStyles } from './pending-jobs-styles';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import Box from '@material-ui/core/Box';
import { CAN_IMPORT_JOBS } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';

const StatusField = props => {
  return <label>{props.record.imported ? 'Imported' : 'Pending'}</label>;
};

const BuilderField = props => {
  const builderName = props.record.builderName;

  return (
    <>
      {builderName ? (
        builderName
      ) : (
        <label
          style={{ display: 'flex', gap: 10, alignItems: 'center' }}
          {...props}
        >
          Undefined
          <ErrorOutlineRoundedIcon color="error" />
        </label>
      )}
    </>
  );
};

const SubdivisionField = props => {
  const subdivisionName = props.record.subdivisionName;

  return (
    <>
      {subdivisionName ? (
        subdivisionName
      ) : (
        <label
          style={{ display: 'flex', gap: 10, alignItems: 'center' }}
          {...props}
        >
          Undefined
          <ErrorOutlineRoundedIcon color="error" />
        </label>
      )}
    </>
  );
};

const ModelField = props => {
  const modelName = props.record.modelName;

  return (
    <>
      {modelName ? (
        modelName
      ) : (
        <label
          style={{ display: 'flex', gap: 10, alignItems: 'center' }}
          {...props}
        >
          Undefined
          <ErrorOutlineRoundedIcon color="error" />
        </label>
      )}
    </>
  );
};

const CustomShow = props => {
  const { record } = props;

  const redirect = () => {
    const origin = window.location.href.split('#')[0];
    window.setTimeout(() => {
      if (record.jobId) {
        window.location.href = origin + '#/job-sheet/' + record.jobId + '/show';
      } else {
        window.location.href =
          origin + '#/job-sheet/create?spOrderId=' + record.spOrderId;
      }
    }, 500);
  };

  return <ShowButton onClick={redirect} component={Box} />;
};

const CustomNumberField = (props: NumberFieldProps) => {
  const { record, ...rest } = props;

  if (!record) return null;

  const newRecord = { ...record };

  if (typeof newRecord.spOrderSummaryOrderTotal === 'string') {
    // Convert to number so NumberField can
    // format as currency
    newRecord.spOrderSummaryOrderTotal = +newRecord.spOrderSummaryOrderTotal;
  }

  return <NumberField record={newRecord} {...rest} />;
};

export const PendingJobs = (props: any) => {
  const classes = useStyles();
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      filters={<PendingJobsSheetFilter />}
      sort={{ field: 'spOrderId', order: 'ASC' }}
      bulkActionButtons={false}
      exporter={exporter('job')}
      filterDefaultValues={{ imported: false }}
      {...props}
    >
      <Datagrid>
        <DateField
          headerClassName={classes.header}
          source="spOrderCreatedAt"
          label="Date Received"
          textAlign="center"
          locales="en-US"
          showTime
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderBuilderName"
          label="Builder (SupplyPro)"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderSubdivisionName"
          label="Subdivision (SupplyPro)"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderJobPlan"
          label="Model (SupplyPro)"
        />
        <BuilderField
          headerClassName={classes.header}
          source="builderName"
          label="Builder"
        />
        <SubdivisionField
          headerClassName={classes.header}
          source="subdivisionName"
          label="Subdivision"
        />
        <ModelField
          headerClassName={classes.header}
          source="modelName"
          label="Model"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderOrderId"
          label="Order Id"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderBuilderOrderNumber"
          label="Builder Order #"
        />
        <DateField
          headerClassName={classes.header}
          source="spOrderStartDate"
          label="Start Date"
          textAlign="center"
          locales="en-US"
          showTime
        />
        <CustomNumberField
          headerClassName={classes.header}
          source="spOrderSummaryOrderTotal"
          options={{
            style: 'currency',
            currency: 'USD',
          }}
          label="Order Total"
          locales="en-US"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderJobLot"
          label="Lot"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderJobBlock"
          label="Block (SupplyPro)"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderPurpose"
          label="Purpose"
        />
        <TextField
          headerClassName={classes.header}
          source="spOrderOrderType"
          label="Order Type"
        />
        <TextField
          headerClassName={classes.header}
          source="orderSiblingCount"
          label="Sibling Count"
        />
        <BooleanField
          headerClassName={classes.header}
          source="isNew"
          label="Is New"
        />
        <BooleanField
          headerClassName={classes.header}
          source="isDuplicate"
          label="Is Duplicate"
        />
        <StatusField
          headerClassName={classes.header}
          source="imported"
          label="Status"
        />
        {hasPermission(CAN_IMPORT_JOBS) && <CustomShow />}
      </Datagrid>
    </List>
  );
};
