import React, { FC, Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'components/ConfirmDialog';
import {
  Record,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { RepairPaymentPhotoDto } from '@vatos-pas/common';
import { CAN_DELETE_BUMP_IMAGES } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    maxWidth: '500px',
    width: '100%',
    maxHeight: '80%',
    marginRight: '10px',
    cursor: 'pointer',
  },
});

type ImageViewerProps = {
  open: boolean;
  handleClose: () => void;
  loadPhotos: () => Promise<void>;
  imageSelected: RepairPaymentPhotoDto | null;
};

export const ImageViewer: FC<ImageViewerProps> = ({
  open,
  handleClose,
  loadPhotos,
  imageSelected,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const record = useRecordContext();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';

  const deletePhoto = async () => {
    try {
      await dataProvider.delete(
        isJobPhaseBump ? 'job-phase-bump-photo' : 'repair-payment-photo',
        {
          id: imageSelected?.id ?? '',
          previousData: imageSelected as Record,
        },
      );
      notify('Photo removed successfully.');
    } catch {
      notify(`Failed to remove the photo. Please try again.`, 'warning');
    }
    loadPhotos();
    setOpenConfirmDelete(false);
    handleClose();
  };

  const cantDeletePhoto = !hasPermission(CAN_DELETE_BUMP_IMAGES);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Image Viewer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {imageSelected && (
              <img
                className={classes.img}
                src={imageSelected.fileUrl ?? ''}
              ></img>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {!cantDeletePhoto && (
            <Button
              onClick={() => setOpenConfirmDelete(true)}
              color="primary"
              autoFocus
            >
              Delete Photo
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openConfirmDelete}
        title="Are you sure that you want to delete this photo?"
        handleClose={() => setOpenConfirmDelete(false)}
        onConfirm={deletePhoto}
      />
    </Fragment>
  );
};

export default ImageViewer;
