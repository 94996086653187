interface IErrors {
  firstName?: string[];
  lastName?: string[];
  email?: string[];
  primaryPhone?: string[];
  idNumber?: string[];
  password?: string[];
  id?: string[];
  fieldCoordinatorUserId?: string[];
  '@@ra-many-to-many/user/user-role/role'?: string[];
}

type UserValues = {
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  idNumber: string;
  password: string;
  id: string;
  fieldCoordinatorUserId: string;
  '@@ra-many-to-many/user/user-role/role': string[];
};

type UserCreation = {
  type: 'create' | 'edit';
  isCustomerServiceRep?: boolean;
  supervisorRoleId?: string;
};

export const validateUserCreation =
  ({ type, isCustomerServiceRep, supervisorRoleId }: UserCreation) =>
  async (values: UserValues): Promise<IErrors> => {
    const errors: IErrors = {};
    const isCreate = type === 'create';

    if (!values.firstName) {
      errors.firstName = ['The firstName is required'];
    }

    if (!values.lastName) {
      errors.lastName = ['The lastName is required'];
    }

    if (!values.email) {
      errors.email = ['The email is required'];
    }

    if (!values.primaryPhone) {
      errors.primaryPhone = ['The primary phone number is required'];
    }

    if (!values.idNumber) {
      errors.idNumber = ['The id number is required'];
    }

    const rolesIds: string[] = values['@@ra-many-to-many/user/user-role/role'];

    if (rolesIds && rolesIds[0]) {
      const isSupervisor = rolesIds.some(id => id === supervisorRoleId);

      if (
        !isCreate &&
        isSupervisor &&
        !isCustomerServiceRep &&
        !values.fieldCoordinatorUserId
      ) {
        errors.fieldCoordinatorUserId = [
          'The Customer Service Representative is required',
        ];
      }
    }

    if (isCreate && !values.password) {
      errors.password = ['The password is required'];
    }

    return errors;
  };
