import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { states } from './states';

const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '15px',
  },
});


interface ICustomReference {
  onChange?: SelectInputProps['onChange'];
  disabled?: boolean;
  value?: string;
  error?: string;
}

export const State: React.FC<ICustomReference> = (props: ICustomReference) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.box} error={Boolean(props.error)}>
      <InputLabel id={`custom-reference-state`}>State</InputLabel>
      <Select
        disabled={props.disabled}
        onChange={props.onChange}
        labelId={`custom-reference-state`}
        id={`reference-state`}
        fullWidth
        value={props.value}
      >
        {states.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};

export default State;
