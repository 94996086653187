import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { TableDefaultRow } from './TableList';
import { useFormState } from 'react-final-form';
import TableCell from '@material-ui/core/TableCell';

import { RouteDto, SubdivisionDto } from '@vatos-pas/common';
import { findRouteBySubdivision } from 'services/route';
import { v4 as uuidv4 } from 'uuid';
import { useDataProvider, useRecordContext } from 'react-admin';
import { UserChoice } from '../views/route-create';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { findSubdivisionsByRegion } from 'services/subDivisions';
import debounce from 'lodash/debounce';

export type TableDraftRowPayload = {
  value: {
    name: string;
    id: string;
  } | null;
} & Pick<TableDefaultRow, 'id' | 'subdivisionId'>;

export interface TableDraftRowProps {
  row: TableDraftRowPayload;
  supervisor: UserChoice | null;
  draftRows: TableDraftRowPayload[] | null;
  route: string;
  handleDraftRowChange: (rowId: string, payload: TableDraftRowPayload) => void;
}

export const TableDraftRow: FC<TableDraftRowProps> = ({
  row,
  supervisor,
  draftRows,
  route,
  handleDraftRowChange,
}) => {
  const { values } = useFormState();
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const currentRequestId = useRef(0);

  const [inputValue, setInputValue] = useState('');
  const [subdivisions, setSubdivisions] = useState<SubdivisionDto[]>([]);
  const [selectedSubdivisionRoute, setSelectedSubdivisionRoute] =
    useState<RouteDto | null>(null);

  const subdivisionsSelected = [
    ...(record.subdivisions?.map(item => ({
      subdivisionId: item.id,
    })) ?? []),
    ...(draftRows ?? []),
  ]?.map(row => row.subdivisionId);
  const availableSubdivisions = subdivisions?.filter(
    subdivision =>
      subdivision.id === row.subdivisionId ||
      !subdivisionsSelected?.includes(subdivision.id),
  );

  const getSubdivisions = async (name: string, requestId: number) => {
    if (!values?.regionId && !record.regionId) return;

    const subdivisionsByRegion = await findSubdivisionsByRegion(
      dataProvider,
      values.regionId ?? record.regionId,
      {
        ...(name && { name }),
      },
    );

    if (subdivisionsByRegion && requestId === currentRequestId.current) {
      setSubdivisions(subdivisionsByRegion);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(name => {
        currentRequestId.current += 1;
        const requestId = currentRequestId.current;
        getSubdivisions(name, requestId);
      }, 300),
    [dataProvider],
  );

  useEffect(() => {
    debouncedSearch(inputValue);

    // Clean up the debounce on unmount
    return () => {
      debouncedSearch.cancel();
    };
  }, [inputValue, debouncedSearch]);

  useEffect(() => {
    const loadSubdivisionRoute = async () => {
      if (!row.subdivisionId) return;

      const route = await findRouteBySubdivision(
        dataProvider,
        row.subdivisionId,
      );

      if (route.length) {
        setSelectedSubdivisionRoute(route[0]);
      }
    };

    loadSubdivisionRoute();
  }, []);

  return (
    <>
      <TableCell component="th" scope="row">
        <Autocomplete
          fullWidth
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => (option ? option?.name : '')}
          renderInput={params => <TextField {...params} />}
          options={availableSubdivisions.map(item => ({
            name: item.name,
            id: item.id,
          }))}
          onInputChange={(_event, newInputValue) =>
            setInputValue(newInputValue)
          }
          value={row.value}
          onChange={async (_event, value, _reason, _details) => {
            handleDraftRowChange(row.id ?? '', {
              id: uuidv4(),
              subdivisionId: value?.id ?? '',
              value,
            });
          }}
        />
      </TableCell>
      <TableCell>{selectedSubdivisionRoute?.name ?? ''}</TableCell>
      <TableCell>
        {`${selectedSubdivisionRoute?.supervisorUser?.firstName ?? ''} ${
          selectedSubdivisionRoute?.supervisorUser?.lastName ?? ''
        }`}
      </TableCell>
      <TableCell>{route}</TableCell>
      <TableCell>{supervisor?.name ?? ''}</TableCell>
    </>
  );
};
