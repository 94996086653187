import React, { FC } from 'react';
import { cloneElement, Fragment } from 'react';
import {
  List,
  TextField,
  EditButton,
  ShowButton,
  Filter,
  ArrayField,
  SingleFieldList,
  ChipField,
  TextInput,
  BooleanField,
  BooleanInput,
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  FilterProps,
  ListProps,
  Record,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import { exporter } from 'providers/export';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_CONTRACTOR } from 'providers/permissions';

const ContractorsFilter = (props: FilterProps) => (
  <Filter {...props}>
    <TextInput
      label="Name"
      source="name||$cont"
      data-cy="contractorFilterName"
    />
    <TextInput
      label="Corporation"
      source="corporation||$cont"
      data-cy="contractorFilterCorporation"
    />
    <BooleanInput
      label="Hanger"
      source="hangerAvailable||$eq"
      data-cy="contractorFilterHanger"
    />
    <BooleanInput
      label="Finisher"
      source="finisherAvailable||$eq"
      data-cy="contractorFilterFinisher"
    />
    <BooleanInput
      label="Sprayer"
      source="sprayerAvailable||$eq"
      data-cy="contractorFilterSprayer"
    />
    <BooleanInput
      label="Bumper"
      source="bumperAvailable||$eq"
      data-cy="contractorFilterBumper"
    />
    <BooleanInput
      label="Active"
      alwaysOn
      source="active||$eq"
      data-cy="contractorFilterActive"
    />
    <BooleanInput
      label="Specialist"
      source="specialistAvailable||$eq"
      data-cy="contractorFilterSpecialist"
    />
  </Filter>
);

const ListActions = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_EDIT_CONTRACTOR) ? (
        <CreateButton basePath={basePath} />
      ) : (
        <Fragment />
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const checkSuspended = (record: Record) => {
  return {
    backgroundColor: !record.suspended ? 'white' : '#ff8282',
  };
};

export const ContractorsList: FC<ListProps> = props => {
  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <List
      {...props}
      exporter={exporter('contractor')}
      filters={<ContractorsFilter>{props.filters}</ContractorsFilter>}
      filterDefaultValues={{ 'active||$eq': true }}
      filter={{
        $join: [
          {
            field: 'contractorRegions',
            select: ['id', 'contractorId', 'regionId', 'region'],
          },
          { field: 'contractorRegions.region', select: ['id', 'name'] },
        ],
      }}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={<React.Fragment />}
      actions={<ListActions />}
    >
      <Datagrid
        rowClick={hasPermission(CAN_EDIT_CONTRACTOR) ? 'edit' : 'show'}
        rowStyle={checkSuspended}
      >
        <TextField source="name" />
        <TextField source="corporation" label="Corporation" />
        <TextField source="paymentType" label="Payment Type" />
        <TextField source="paymentRate" label="Payment Rate" />
        <TextField source="bumperPaymentType" label="Bumper Payment Type" />
        <TextField source="bumperPaymentRate" label="Bumper Payment Rate" />
        <BooleanField label="Hanger" source="hangerAvailable" />
        <BooleanField label="Finisher" source="finisherAvailable" />
        <BooleanField label="Sprayer" source="sprayerAvailable" />
        <BooleanField label="Bumper" source="bumperAvailable" />
        <BooleanField label="Specialist" source="specialistAvailable" />
        <ArrayField source="contractorRegions" fieldKey="id" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="region.name" />
          </SingleFieldList>
        </ArrayField>
        {hasPermission(CAN_EDIT_CONTRACTOR) ? <EditButton /> : <ShowButton />}
      </Datagrid>
    </List>
  );
};
