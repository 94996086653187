import React, { ChangeEvent, ReactElement } from 'react';

import { Modal } from 'components/Modal';
import {
  SpMatchesDefaultTableEdit,
  SpMatchesError,
  SpMatchesDefaultTableRow,
} from 'components/SpMatchesDefaultTable';

interface SpMatchesDefaultModalProps<T extends unknown> {
  rows: SpMatchesDefaultTableRow[] | null;
  open: boolean;
  columns: string[];
  error: SpMatchesError | null;
  onClose: () => void;
  onAddNewItem: (name: string, active: boolean) => Promise<T>;
  onUpdateActiveFlag: (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => Promise<void>;
}

export const SpMatchesDefaultModal = <T extends unknown>({
  rows,
  open,
  columns,
  error,
  onClose,
  onAddNewItem,
  onUpdateActiveFlag,
}: SpMatchesDefaultModalProps<T>): ReactElement => {
  return (
    <Modal title="SupplyPro - Potential Matches" open={open} onClose={onClose}>
      <SpMatchesDefaultTableEdit
        rows={rows}
        columns={columns}
        error={error}
        onAddNewItem={onAddNewItem}
        onUpdateActiveFlag={onUpdateActiveFlag}
      />
    </Modal>
  );
};
