import React, { FC, Fragment, ReactNode, useMemo } from 'react';
import { MenuItem, MultiLevelMenu } from '@react-admin/ra-navigation';
import SettingsIcon from '@material-ui/icons/Settings';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { AppModules, generalModules, settingsModules } from './constants';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import isMobileHook from 'hooks/isMobile';
import { PermType } from 'providers/permissions';

type ModuleType = {
  name: string;
  to: string;
  create?: ReactNode;
  edit?: ReactNode;
  list?: ReactNode;
  label?: string;
};

type RenderableItem = {
  name: AppModules;
  module: ModuleType;
  icon:
    | OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>
    | React.FC<Record<string, unknown>>;
  mobileOnly?: boolean;
};

const getAllowedModules = (
  module: RenderableItem[],
  checkPermissionFn: (moduleName: AppModules, accessType?: PermType) => boolean,
  isMobile: boolean,
) => {
  return module.reduce<RenderableItem[]>((acc, current) => {
    const hasPermission = checkPermissionFn(current.name);
    const onlyRenderOnMobile = current?.mobileOnly;

    if (!hasPermission || (onlyRenderOnMobile && !isMobile)) return acc;

    acc.push({
      name: current.name,
      module: current.module,
      icon: current.icon,
    });

    return acc;
  }, []);
};

const AppMenuItem = () => {
  const { userRoles, hasModulePermission } = PermissionsProvider.useContainer();
  const isMobile = isMobileHook();

  const allowedSettingsModules = useMemo(
    () => getAllowedModules(settingsModules, hasModulePermission, isMobile),
    [settingsModules, userRoles],
  );

  const allowedGeneralModules = useMemo(
    () => getAllowedModules(generalModules, hasModulePermission, isMobile),
    [generalModules, userRoles],
  );

  return (
    <Fragment>
      {!!allowedSettingsModules?.length && (
        <MenuItem
          name="basic-info"
          to={'/user'}
          label="Settings"
          icon={<SettingsIcon />}
        >
          {allowedSettingsModules.map(({ name, module, icon }) => {
            const Icon = icon;

            return <MenuItem key={name} {...module} icon={<Icon />} />;
          })}
        </MenuItem>
      )}
      {allowedGeneralModules.map(({ name, module, icon }) => {
        const Icon = icon;

        return <MenuItem key={name} {...module} icon={<Icon />} />;
      })}
    </Fragment>
  );
};

export const CustomMenu: FC = () => {
  const { user } = PermissionsProvider.useContainer();

  const renderUserMenu = () => {
    if (!user) return undefined;

    return <AppMenuItem />;
  };

  return <MultiLevelMenu>{renderUserMenu()}</MultiLevelMenu>;
};

export default CustomMenu;
