import React, { FC, useRef, useState } from 'react';

import {
  CreateProps,
  SimpleForm,
  Create,
  useDataProvider,
  DataProvider,
  useNotify,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { RouteDto } from '@vatos-pas/common';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import ToolBar from 'components/Toolbar';
import { validateRoute } from './routeValidation';
import {
  ReferencedInputs,
  SubdivisionsInput,
  TableDraftRowPayload,
} from '../components';

export type UserChoice = {
  name: string;
  id: string;
};

const updateSubdivisionsRoute = async (
  subdivisionIds: string[],
  route: RouteDto,
  dataProvider: DataProvider,
) => {
  await dataProvider.update('route', {
    id: route.id,
    data: {
      subdivisions: subdivisionIds.map(subdivisionId => ({
        id: subdivisionId,
      })),
    },
    previousData: route,
  });
};

export const RouteCreate: FC<CreateProps> = props => {
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  // This ref is needed because <Edit /> form memoizes onSuccess function.
  // This memoization prevents `handleSuccess` function from having the updated state when
  // user saves its changes.
  // By passing a reference to the function and updating the reference value in every render
  // We make sure that the value inside `handleSuccess` will always be up to date.
  const draftRowsRef = useRef<TableDraftRowPayload[] | null>(null);

  const [draftRows, setDraftRows] = useState<TableDraftRowPayload[] | null>(
    null,
  );
  const [selectedSupervisor, setSelectedSupervisor] =
    useState<UserChoice | null>(null);

  const updateDraftRows = (rows: TableDraftRowPayload[] | null) => {
    draftRowsRef.current = rows;
    setDraftRows(rows);
  };

  const handleSuccess = async ({ data }) => {
    if (!data.id) return;

    try {
      await updateSubdivisionsRoute(
        draftRowsRef.current?.map(item => item.subdivisionId) ?? [],
        data,
        dataProvider,
      );

      history.push('/route');
      // Default React Admin created
      notify('Element created');
    } catch (error: any) {
      notify(error.message, 'warning');
    }
  };

  return (
    <Create {...props} onSuccess={handleSuccess} className={classes.createBox}>
      <SimpleForm toolbar={<ToolBar />} validate={validateRoute}>
        <Box display="flex" className={classes.flexBox}>
          <ReferencedInputs
            updateDraftRows={updateDraftRows}
            onSupervisorChange={supervisor => setSelectedSupervisor(supervisor)}
          />
        </Box>

        <SubdivisionsInput
          draftRows={draftRows}
          updateDraftRows={updateDraftRows}
          supervisor={selectedSupervisor}
        />
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  flexBox: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  createBox: {
    maxWidth: '100%',
  },
});
