export const convertTo12HourFormat = (time24: string) => {
  // Extract hours and minutes
  const [hours, minutes] = time24.split(':').map(Number);

  // Determine AM/PM and convert hours
  const meridiem = hours < 12 ? 'AM' : 'PM';
  const hours12 = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;

  // Format the result
  const time12 = `${hours12.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${meridiem}`;

  return time12;
};
