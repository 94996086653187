import React, { ReactElement } from 'react';
import MUITableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import { TableContainer, TableRow } from '../components';

const DEFAULT_COLUMN_LENGTH = 1;

export interface TableDefaultRow {
  id: string;
  subdivisionId: string;
  name?: string;
}

interface TableListProps {
  rows: TableDefaultRow[] | null;
  columns: string[];
}

export const TableList = ({ rows, columns }: TableListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TableContainer columns={columns}>
        {rows?.length ? (
          rows.map(row => (
            <MUITableRow key={row.id}>
              <TableRow row={row} />
            </MUITableRow>
          ))
        ) : (
          <MUITableRow>
            <TableCell
              colSpan={columns.length + DEFAULT_COLUMN_LENGTH}
              className={classes.tableCellCenter}
            >
              No subdivisions found
            </TableCell>
          </MUITableRow>
        )}
      </TableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
