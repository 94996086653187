import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import PhotoUpload from '../components/PhotoUpload';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import { InjectedFieldProps, PublicFieldProps } from 'react-admin';
import UploadIcon from '@material-ui/icons/CloudUpload';

type PhotosButtonProps = PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const PhotosButton = (props: PhotosButtonProps) => {
  if (!props.record?.id) return null;

  const [isPhotoUploadOpen, setIsPhotoUploadOpen] = useState(false);

  return (
    <>
      <IconButton
        color="primary"
        size="small"
        onClick={() => setIsPhotoUploadOpen(true)}
      >
        {props.record.hasPhotos ? <CameraAltIcon /> : <UploadIcon />}
      </IconButton>
      <PhotoUpload
        open={isPhotoUploadOpen}
        handleClose={() => setIsPhotoUploadOpen(false)}
      />
    </>
  );
};

export default PhotosButton;
