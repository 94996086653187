import { useRef, useState } from 'react';
import {
  CreateProps,
  SimpleForm,
  Edit,
  useRecordContext,
  ToolbarProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ToolBar from 'components/Toolbar';
import { validateRoute } from './routeValidation';
import {
  ReferencedInputs,
  SubdivisionsInput,
  TableDraftRowPayload,
} from '../components';

const Toolbar = (props: ToolbarProps) => {
  const record = useRecordContext();

  return (
    <ToolBar
      {...props}
      renderDelete
      deleteTitle={`Delete route ${record.name}`}
      deleteContent={`Are you sure that you want to delete the route ${record.name}? You will not be able to reverse this action.`}
    />
  );
};

export type UserChoice = {
  name: string;
  id: string;
};

export const RouteEdit = (props: CreateProps) => {
  const classes = useStyles();

  // This ref is needed because <Edit /> form memoizes onSuccess function.
  // This memoization prevents `handleSuccess` function from having the updated state when
  // user saves its changes.
  // By passing a reference to the function and updating the reference value in every render
  // We make sure that the value inside `handleSuccess` will always be up to date.
  const draftRowsRef = useRef<TableDraftRowPayload[] | null>(null);

  const [draftRows, setDraftRows] = useState<TableDraftRowPayload[] | null>(
    null,
  );
  const [selectedSupervisor, setSelectedSupervisor] =
    useState<UserChoice | null>(null);

  const updateDraftRows = (rows: TableDraftRowPayload[] | null) => {
    draftRowsRef.current = rows;
    setDraftRows(rows);
  };
  return (
    <Edit {...props} mutationMode="pessimistic" className={classes.createBox}>
      <SimpleForm toolbar={<Toolbar />} validate={validateRoute}>
        <Box display="flex" className={classes.flexBox}>
          <ReferencedInputs
            isRegionDisabled
            updateDraftRows={updateDraftRows}
            onSupervisorChange={supervisor => setSelectedSupervisor(supervisor)}
          />
        </Box>

        <SubdivisionsInput
          type="edit"
          draftRows={draftRows}
          updateDraftRows={updateDraftRows}
          supervisor={selectedSupervisor}
        />
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  flexBox: {
    width: '100%',
    display: 'flex',
    gap: 16,
  },
  createBox: {
    maxWidth: '100%',
  },
});
