import React, { Fragment } from 'react';
import { SimpleForm as SimpleFormRA, SimpleFormProps } from 'react-admin';
import ToolBar from 'components/Toolbar';

export const SimpleForm: React.FC<SimpleFormProps> = props => {
  if (props.resource === 'user') {
    delete props.record.userRoles;
    delete props.record.userRegions;
  }
  if (props.resource === 'contractor') {
    delete props.record.contractorRegions;
    delete props.record.contractorUsers;
  }

  if (props.resource === 'supplier') {
    delete props.record.supplierRegions;
  }

  const preSave = payload => {
    props.presave(props.save, payload);
  };

  return (
    <SimpleFormRA
      toolbar={
        props.hideTollBar ? (
          <Fragment />
        ) : (
          <ToolBar removeGoBack={props.removeGoBack} />
        )
      }
      {...props}
      save={
        props.customSave
          ? props.customSave
          : props.presave
          ? preSave
          : props.save
      }
    >
      {props.children}
    </SimpleFormRA>
  );
};

export default SimpleForm;
