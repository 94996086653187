import {
  AutocompleteInput,
  DateInput,
  Filter,
  FilterProps,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { PhaseTypeEnum, RepairTypeEnum, UserDto } from '@vatos-pas/common';

import { AppRole } from 'providers/roles';
import { JOB_PHASE_BUMP_APPROVAL_STATUS_LABEL_MAPPING } from '../constants';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_BUMPOUT_TIMESHEET_PAID } from 'providers/permissions';

const BumpoutTimesheetRepairType = {
  [RepairTypeEnum.FieldRepair]: RepairTypeEnum.FieldRepair,
  [RepairTypeEnum.HOService]: RepairTypeEnum.HOService,
  [RepairTypeEnum.Internal]: RepairTypeEnum.Internal,
  [RepairTypeEnum.Overtime]: RepairTypeEnum.Overtime,
  [RepairTypeEnum.SpecialJobs]: RepairTypeEnum.SpecialJobs,
  [PhaseTypeEnum.Bump1]: PhaseTypeEnum.Bump1,
  [PhaseTypeEnum.Bump2]: PhaseTypeEnum.Bump2,
  [PhaseTypeEnum.Bump3]: PhaseTypeEnum.Bump3,
  [PhaseTypeEnum.Bump4]: PhaseTypeEnum.Bump4,
  [PhaseTypeEnum.Bump5]: PhaseTypeEnum.Bump5,
};

const BumpoutTimesheetFilter = (props: FilterProps) => {
  const { hasRole, hasPermission } = PermissionsProvider.useContainer();

  const isSupervisor = hasRole(AppRole.Supervisor);
  const isBumper = hasRole(AppRole.Bumper);
  const canEditPaid = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_PAID);

  return (
    <Filter {...props}>
      <TextInput
        label="Lot"
        source="jobLot||$eq"
        alwaysOn
        resettable
        data-cy="BumpoutTimesheetsFilterLot"
      />
      <ReferenceInput
        label="Builder"
        source="builderId"
        reference="builder"
        data-cy="BumpoutTimesheetsFilterBuilder"
        filterToQuery={value => (value ? { name: value } : {})}
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty={false}
        alwaysOn
        resettable
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Subdivision"
        source="subdivisionId"
        reference="subdivision"
        data-cy="BumpoutTimesheetsFilterSubdivision"
        resettable
        alwaysOn
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <TextInput
        label="Address"
        alwaysOn
        resettable
        source="address1||$cont"
        data-cy="BumpoutTimesheetsFilterAddress1"
      />
      {!isBumper && (
        <ReferenceInput
          label="Contractor"
          source="contractorId"
          reference="contractor"
          data-cy="BumpoutTimesheetsFilterContractor"
          filterToQuery={value => {
            if (value) {
              return {
                s: {
                  $and: [
                    {
                      $or: [{ name: { $cont: value } }],
                    },
                    { active: true },
                  ],
                },
              };
            }

            return { active: true };
          }}
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty={false}
          resettable
          alwaysOn
        >
          <AutocompleteInput
            optionText="name"
            optionValue="id"
            suggestionLimit={25}
          />
        </ReferenceInput>
      )}
      {!isSupervisor && (
        <ReferenceInput
          label="Supervisor"
          source="supervisorUserId"
          reference="user"
          data-cy="BumpoutTimesheetsFilterSupervisor"
          filterToQuery={value => {
            if (value) {
              return {
                s: {
                  $and: [
                    {
                      $or: [
                        { firstName: { $cont: value } },
                        { lastName: { $cont: value } },
                      ],
                    },
                    { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
                  ],
                },
              };
            }
            return {
              s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
            };
          }}
          sort={{ field: 'firstName', order: 'ASC' }}
          allowEmpty={false}
          resettable
          alwaysOn
        >
          <AutocompleteInput
            optionText={(user: UserDto) => `${user.firstName} ${user.lastName}`}
            optionValue="id"
            suggestionLimit={25}
          />
        </ReferenceInput>
      )}
      <DateInput
        label="Repair On or After"
        source="repairDate||$gte"
        placeholder="dd/mm/yyyy"
        data-cy="BumpoutTimesheetsFilterRepairDateBegin"
        alwaysOn
      />
      <DateInput
        label="Repair On or Before"
        source="repairDate||$lte"
        placeholder="dd/mm/yyyy"
        data-cy="BumpoutTimesheetsFilterRepairDateEnd"
        alwaysOn
      />
      <SelectArrayInput
        style={{
          minWidth: 160,
        }}
        source="bumperApprovalStatus||$in"
        label="Status"
        data-cy="BumpoutTimesheetsFilterStatus"
        choices={Object.entries(
          JOB_PHASE_BUMP_APPROVAL_STATUS_LABEL_MAPPING,
        ).map(([val, text]) => ({
          val,
          text,
        }))}
        emptyText="All"
        optionValue="val"
        optionText="text"
        alwaysOn
      />
      <SelectInput
        source="repairType||$eq"
        label="Repair Type"
        data-cy="BumpoutTimesheetsFilterRepairType"
        choices={Object.values(BumpoutTimesheetRepairType).map(value => ({
          val: value,
          text: value,
        }))}
        emptyText="All"
        optionValue="val"
        optionText="text"
        alwaysOn
      />
      {canEditPaid && (
        <SelectInput
          source="paid||$eq"
          alwaysOn
          label="Paid by Office?"
          data-cy="BumpoutTimesheetsFilterPaid"
          choices={[
            { val: true, text: 'Yes' },
            { val: false, text: 'No' },
          ]}
          emptyText="All"
          optionValue="val"
          optionText="text"
        />
      )}
    </Filter>
  );
};
export default BumpoutTimesheetFilter;
