import React, { ReactElement } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

import {
  SpMatchesDefaultTableContainer,
  SpMatchesDefaultRow,
} from 'components/SpMatchesDefaultTable';

const DEFAULT_COLUMN_LENGTH = 1;

export interface SpMatchesDefaultTableRow {
  id: string;
  name: string;
  active?: boolean;
}

interface SpMatchesDefaultTableListProps {
  rows: SpMatchesDefaultTableRow[] | null;
  columns: string[];
}

export const SpMatchesDefaultTableList = ({
  rows,
  columns,
}: SpMatchesDefaultTableListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SpMatchesDefaultTableContainer columns={columns}>
        {rows?.length ? (
          rows.map(row => (
            <TableRow key={row.id}>
              <SpMatchesDefaultRow row={row} readOnly />
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell
              colSpan={columns.length + DEFAULT_COLUMN_LENGTH}
              className={classes.tableCellCenter}
            >
              No potential matches found
            </TableCell>
          </TableRow>
        )}
      </SpMatchesDefaultTableContainer>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
