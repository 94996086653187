import { AppRole } from 'providers/roles';
import { DataProvider } from 'react-admin';
import {
  ContractorUserDto,
  SupplierRepresentativeDto,
  UserDto,
} from '@vatos-pas/common';

export const findManyContractorUsers = async (
  dataProvider: DataProvider,
  contractorId: string,
) => {
  try {
    const contractorUser = await dataProvider.getList<ContractorUserDto>(
      'contractor-user',
      {
        filter: { contractorId },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
      },
    );

    return contractorUser.data;
  } catch (error) {
    return error;
  }
};

export const findManyUserRepresentatives = async (
  dataProvider: DataProvider,
) => {
  try {
    const userRepresentatives = await dataProvider.getList<UserDto>(
      'user-representative',
      {
        filter: {},
        pagination: { page: 1, perPage: 80 },
        sort: { field: 'firstName', order: 'ASC' },
      },
    );

    return userRepresentatives.data;
  } catch (error) {
    return error;
  }
};

export const findManyBumperUsersByContractor = async (
  dataProvider: DataProvider,
  contractorId: string,
) => {
  try {
    const bumpers = await dataProvider.getList<UserDto>('user', {
      filter: {
        s: {
          $and: [
            { active: true },
            {
              $or: [
                { 'contractorUser.contractorId': { $isnull: true } },
                { 'contractorUser.contractorId': { $eq: contractorId } },
              ],
            },
            { 'userRoles.role.name': { $eq: AppRole.Bumper } },
          ],
        },
      },
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'firstName', order: 'ASC' },
    });

    return bumpers.data;
  } catch (error) {
    return error;
  }
};

export const findManySupplierRepresentatives = async (
  dataProvider: DataProvider,
  supplierId: string,
) => {
  try {
    const supplierRepresentatives =
      await dataProvider.getList<SupplierRepresentativeDto>(
        'supplier-representative',
        {
          filter: { supplierId },
          pagination: { page: 1, perPage: 80 },
          sort: { field: 'id', order: 'ASC' },
        },
      );

    return supplierRepresentatives.data;
  } catch (error) {
    return error;
  }
};
