import { ChangeEvent, FC } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import _ from 'lodash';

import CustomReference from 'components/CustomReference';
import { useContainer } from 'unstated-next';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { JobContainer } from '../../providers/job';
import { INITIAL_BOARD } from '../../constants';
import { Show } from 'components/Show';
import { getBoardsCount, getMaterialsFromBoards } from 'modules/jobs/utils';
import { BoardDto } from 'modules/jobs/types';

const getBoardsValidation = (
  errors: Record<string, string>,
  floor: number,
  boardIndex: number,
  name: string,
) => {
  return errors['boards']?.[floor]?.[boardIndex]?.[name];
};

const updateBoardByFloor = (
  floorIndex: number,
  boardIndex: number,
  property: string,
  value: string | number,
  floors?: BoardDto[],
) => {
  if (!floors) return;

  const newFloors = [...floors];

  const [matchingFloors, nonMatchingFloors] = _.partition(
    newFloors,
    floor => floor.floor === floorIndex,
  );

  const updatedMatchingFloors = matchingFloors.map((floor, index) => {
    if (index === boardIndex) {
      return {
        ...floor,
        [property]: value,
      };
    }
    return floor;
  });

  return [...updatedMatchingFloors, ...nonMatchingFloors];
};

export const PurchaseOrderFloors: FC = () => {
  const {
    isEdit,
    draftPurchaseOrder,
    isNew,
    setDraftPurchaseOrder,
    errors,
    isDraft,
  } = useContainer(PurchaseOrderContainer);
  const { purchaseOrders, selectedPurchaseOrder } = useContainer(JobContainer);

  const classes = useStyles();

  const boardsCount = getBoardsCount(
    isDraft || isNew
      ? draftPurchaseOrder.boardPurchaseFloors
      : selectedPurchaseOrder?.boardPurchaseFloors,
  );
  const boardsLabel = boardsCount >= 0 ? boardsCount : 'Invalid Boards';

  const handleQuantityChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    floorIndex: number,
    boardIndex: number,
  ) => {
    setDraftPurchaseOrder(prevState => {
      const updatedFloors = updateBoardByFloor(
        floorIndex,
        boardIndex,
        'quantityOrdered',
        parseInt(event.target.value as string),
        draftPurchaseOrder.boardPurchaseFloors,
      );

      return {
        ...prevState,
        boardPurchaseFloors: updatedFloors,
        ...(!purchaseOrders?.length && {
          materials: getMaterialsFromBoards(updatedFloors, prevState.materials),
        }),
      };
    });
  };

  const handleBoardTypeChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    floorIndex: number,
    boardIndex: number,
  ) => {
    const updatedFloors = updateBoardByFloor(
      floorIndex,
      boardIndex,
      'boardTypeId',
      event.target.value as string,
      draftPurchaseOrder.boardPurchaseFloors,
    );

    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: updatedFloors,
      ...(!purchaseOrders?.length && {
        materials: getMaterialsFromBoards(updatedFloors, prevState.materials),
      }),
    }));
  };

  const handleRemoveBoard = (floorIndex: number, boardIndex: number) => {
    const [matchingFloors, nonMatchingFloors] = _.partition(
      draftPurchaseOrder.boardPurchaseFloors,
      floor => floor.floor === floorIndex,
    );

    const updatedMatchingFloors = matchingFloors.filter(
      (_, index) => index !== boardIndex,
    );

    const updatedFloors = [...updatedMatchingFloors, ...nonMatchingFloors];

    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: updatedFloors,
      ...(!purchaseOrders?.length && {
        materials: getMaterialsFromBoards(updatedFloors, prevState.materials),
      }),
    }));
  };

  const handleAddBoard = (floorNumber: number) => {
    const newBoard = {
      ...INITIAL_BOARD,
      floor: floorNumber === 0 ? floorNumber + 1 : floorNumber,
    };
    if (!draftPurchaseOrder?.boardPurchaseFloors) return;

    const newFloors = [...draftPurchaseOrder.boardPurchaseFloors];
    newFloors.push(newBoard);

    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: newFloors,
    }));
  };

  const handleRemoveFloor = (floorIndex: number) => {
    const filteredFloors = _.map(
      _.filter(
        draftPurchaseOrder.boardPurchaseFloors,
        floor => floor.floor !== floorIndex,
      ),
      floor => ({
        ...floor,
        // Adjust floor counting in case removed item is in the middle of the array
        floor: floor.floor > floorIndex ? floor.floor - 1 : floor.floor,
      }),
    );

    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: filteredFloors,
      ...(!purchaseOrders?.length && {
        materials: getMaterialsFromBoards(filteredFloors, prevState.materials),
      }),
    }));
  };

  const handleAddFloor = () => {
    if (!draftPurchaseOrder?.boardPurchaseFloors) return;

    const currentFloors = draftPurchaseOrder.boardPurchaseFloors.map(
      floor => floor.floor,
    );

    const nextFloorNumber =
      currentFloors.length > 0 ? Math.max(...currentFloors) + 1 : 1;

    const newBoard = {
      ...INITIAL_BOARD,
      floor: nextFloorNumber,
    };

    const newFloors = [...draftPurchaseOrder.boardPurchaseFloors];

    newFloors.push(newBoard);
    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: newFloors,
    }));
  };

  const currentPurchaseOrder =
    isNew || isDraft ? draftPurchaseOrder : selectedPurchaseOrder;
  const selectedFloors = currentPurchaseOrder?.boardPurchaseFloors || [];
  const groupedFloors = _.groupBy(selectedFloors, 'floor');

  const reducedFloors = _.mapValues(groupedFloors, floors => ({
    floor: floors?.[0]?.floor,
    blocks: _.map(floors, ({ boardTypeId, quantityOrdered }) => ({
      boardTypeId,
      quantityOrdered,
    })),
  }));

  return (
    <Box>
      {Object.values(reducedFloors).map((floor, floorIndex) => (
        <Box
          key={floorIndex}
          className={classes.floorBoard}
          display="flex"
          width="calc(100% - 20px)"
          flexDirection="column"
          mt={1}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="body1" gutterBottom className={classes.bold}>
              {`Floor #${floor.floor} - Boards`}
            </Typography>
            <Show condition={isNew || isDraft}>
              <DeleteIcon
                className={classes.pointer}
                onClick={() => {
                  handleRemoveFloor(floor.floor);
                }}
              />
            </Show>
          </Box>
          {floor.blocks.map((board, boardIndex) => {
            const boardTypeIdValitation = getBoardsValidation(
              errors,
              floor.floor,
              boardIndex,
              'boardTypeId',
            );

            const quantityOrderedValidation = getBoardsValidation(
              errors,
              floor.floor,
              boardIndex,
              'quantityOrdered',
            );

            return (
              <Box
                display="flex"
                width="100%"
                key={boardIndex}
                alignItems="center"
              >
                <CustomReference
                  value={board.boardTypeId}
                  label="Board Type"
                  resource="board-type"
                  disabled={isEdit && !isDraft}
                  customItemName="shortName"
                  filters={{
                    laborOnly:
                      isEdit && !isDraft
                        ? !!selectedPurchaseOrder?.laborOnly
                        : draftPurchaseOrder.laborOnly,
                  }}
                  sort={{ field: 'shortName', order: 'ASC' }}
                  onChange={event => {
                    if (event.target.value !== board.boardTypeId) {
                      handleBoardTypeChange(event, floor.floor, boardIndex);
                    }
                  }}
                  error={boardTypeIdValitation}
                  helperText={boardTypeIdValitation}
                />
                <TextField
                  label="Quantity"
                  fullWidth
                  className={classes.inputNoMarginRight}
                  disabled={isEdit && !isDraft}
                  value={board.quantityOrdered}
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={event => {
                    handleQuantityChange(event, floor.floor, boardIndex);
                  }}
                  error={quantityOrderedValidation}
                  helperText={quantityOrderedValidation}
                />
                <Show condition={isNew || isDraft}>
                  <DeleteIcon
                    onClick={() => {
                      handleRemoveBoard(floor.floor, boardIndex);
                    }}
                    className={classes.pointer}
                  />
                </Show>
              </Box>
            );
          })}
          <Show condition={isNew || isDraft}>
            <Box display="flex" justifyContent="flex-end">
              <AddCircleIcon
                className={classes.pointer}
                onClick={() => handleAddBoard(floor.floor)}
              />
            </Box>
          </Show>
        </Box>
      ))}
      <Box
        className={classes.floorBoard}
        display="flex"
        width="calc(100% - 20px)"
        mt={1}
        justifyContent="space-between"
      >
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Total Boards:
        </Typography>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          {boardsLabel}
        </Typography>
      </Box>
      <Box className={classes.addFloor}>
        <Show condition={isNew || isDraft}>
          <Typography variant="body1" gutterBottom className={classes.bold}>
            Add New Floor
          </Typography>
          <AddCircleIcon className={classes.pointer} onClick={handleAddFloor} />
        </Show>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles({
  floorBoard: {
    backgroundColor: '#efefef',
    borderRadius: '10px',
    padding: '10px',
  },
  addFloor: {
    display: 'flex',
    width: 'calc(100% - 20px)',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '8px',
    padding: '10px',
  },
  bold: {
    fontWeight: 'bold',
  },
  pointer: {
    cursor: 'pointer',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
});
