import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useContainer } from 'unstated-next';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { useDataProvider, useNotify } from 'react-admin';
import ConfirmDialog from 'components/ConfirmDialog';
import { DefaultModelContainer } from '../../providers/default-model';
import { JobContainer } from '../../providers/job';
import { BoardPurchaseDto } from '@vatos-pas/common';
import { Show } from 'components/Show';
import { updatePurchaseOrder } from 'services/jobs';
import {
  EMPTY_MATERIALS,
  INITIAL_FLOORS,
  INITIAL_MATERIALS,
} from 'modules/jobs/constants';

type Props = {
  canEditInvoice: boolean;
  purchaseOrders: BoardPurchaseDto[] | null;
};

export const PurchaseOrderHeader: FC<Props> = ({
  canEditInvoice,
  purchaseOrders,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const {
    draftPurchaseOrder,
    selectedPhase,
    setIsLoading,
    isLoading,
    isEdit,
    isDraft,
    isCanceled,
    isNew,
    sameSupplier,
    setSameSupplier,
    setDraftPurchaseOrder,
  } = useContainer(PurchaseOrderContainer);
  const { setImportedDefault, setCreateModelDefaults } = useContainer(
    DefaultModelContainer,
  );
  const { job, loadJobData, setIsPurchaseOrderOpen, selectedPurchaseOrder } =
    useContainer(JobContainer);

  const classes = useStyles();

  const [openSameSupplierModal, setOpenSameSupplierModal] = useState(false);
  const [confirmLaborOnly, setConfirmLaborOnly] = useState(false);

  const hasPurchaseOrders = !!purchaseOrders && purchaseOrders.length > 0;
  const isFirstPurchaseOrder =
    purchaseOrders?.[0]?.id === selectedPurchaseOrder?.id;
  const isFirstPOSignedOff =
    hasPurchaseOrders && !!purchaseOrders?.[0].approved;
  const canOverrideSupplier = isNew && hasPurchaseOrders;
  const lastSupplierId =
    purchaseOrders && purchaseOrders[purchaseOrders.length - 1]?.supplierId;

  const showSameSupplier = isNew
    ? hasPurchaseOrders
      ? !isFirstPurchaseOrder
      : false
    : isDraft
    ? hasPurchaseOrders
    : !isFirstPurchaseOrder;

  const handleChangeInvoiceNumber = async () => {
    if (!selectedPurchaseOrder?.id || !job?.id) return;

    setIsLoading(true);

    try {
      if (selectedPurchaseOrder.id) {
        const params = {
          id: selectedPurchaseOrder.id,
          data: { invoiceNumber: draftPurchaseOrder.invoiceNumber },
          previousData: selectedPurchaseOrder,
        };
        await updatePurchaseOrder(dataProvider, params);
        await loadJobData(job.id);
        notify('Invoice Number updated!');
      }
    } catch (error) {
      notify(`Error on update Invoice number`, 'warning');
    } finally {
      setIsPurchaseOrderOpen(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Typography variant="body1" gutterBottom className={classes.bold}>
        Floor Boards - Purchase Order Details
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.bold}>
        Model: {job?.model?.name || ''}
      </Typography>
      <Typography variant="body1" gutterBottom className={classes.bold}>
        Lot: {job?.lot || ''}
      </Typography>
      <Show condition={!!job?.block}>
        <Typography variant="body1" gutterBottom className={classes.bold}>
          Block: {job?.block}
        </Typography>
      </Show>
      {isCanceled && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gridGap={8}
          p={2}
        >
          <Typography className={classes.canceledText}>Canceled</Typography>
          {selectedPurchaseOrder?.dateCanceled && (
            <Typography className={classes.canceledDateText}>
              {new Date(selectedPurchaseOrder.dateCanceled).toLocaleDateString(
                'en-US',
              )}
            </Typography>
          )}
        </Box>
      )}
      <Show condition={isEdit && !isDraft}>
        <Box display="flex">
          {(canEditInvoice || draftPurchaseOrder.invoiceNumber) && (
            <TextField
              label="Invoice #"
              fullWidth
              disabled={isCanceled || !canEditInvoice}
              className={classes.input}
              defaultValue={selectedPurchaseOrder?.invoiceNumber}
              value={draftPurchaseOrder.invoiceNumber}
              onChange={event => {
                setDraftPurchaseOrder(prevState => ({
                  ...prevState,
                  invoiceNumber: event?.currentTarget.value,
                }));
              }}
            />
          )}
          {!isCanceled && canEditInvoice && (
            <Button
              disabled={isLoading || !selectedPhase}
              onClick={handleChangeInvoiceNumber}
              color="primary"
              variant="contained"
              autoFocus
              className={classes.buttonInvoice}
            >
              Submit
            </Button>
          )}
        </Box>
      </Show>

      <Show condition={!isCanceled}>
        <Box display="flex" gridGap={16} py={2}>
          <FormControlLabel
            control={
              <Switch
                checked={
                  isEdit && !isDraft
                    ? selectedPurchaseOrder?.laborOnly
                    : draftPurchaseOrder.laborOnly
                }
                onChange={() => setConfirmLaborOnly(true)}
                name="laborOnly"
                disabled={isEdit && !isDraft}
                color="primary"
              />
            }
            label="Labor Only"
          />
          {hasPurchaseOrders && isNew && isFirstPOSignedOff && (
            <FormControlLabel
              control={
                <Switch
                  checked={
                    !isDraft
                      ? selectedPurchaseOrder?.isReplacement
                      : draftPurchaseOrder.isReplacement
                  }
                  onChange={event => {
                    const { checked } = event.target;

                    setDraftPurchaseOrder(prevState => ({
                      ...prevState,
                      replacement: checked,
                    }));
                  }}
                  name="replacement"
                  disabled={isEdit && !isDraft}
                  color="primary"
                />
              }
              label="Replacement"
            />
          )}
          {showSameSupplier && (
            <FormControlLabel
              control={
                <Switch
                  checked={sameSupplier}
                  onChange={event => {
                    const { checked } = event.target;
                    if (checked) {
                      setSameSupplier(true);
                      setDraftPurchaseOrder(prevState => ({
                        ...prevState,
                        supplierId: lastSupplierId ?? '',
                      }));
                    } else {
                      setOpenSameSupplierModal(true);
                    }
                  }}
                  name="sameSupplier"
                  disabled={!isDraft && !canOverrideSupplier}
                  color="primary"
                />
              }
              label="Same Supplier"
            />
          )}
        </Box>
      </Show>
      <ConfirmDialog
        open={openSameSupplierModal}
        confirmLabel="Confirm"
        showWarning
        title="Are you sure you want change the Supplier? This change won't affect previous purchase orders!"
        handleClose={() => setOpenSameSupplierModal(false)}
        onConfirm={() => {
          setOpenSameSupplierModal(false);
          setSameSupplier(false);
        }}
      />
      <ConfirmDialog
        open={confirmLaborOnly}
        confirmLabel="Confirm"
        showWarning
        title="Are you sure you want change the labor only? This will remove all boards and materials from the PO"
        handleClose={() => setConfirmLaborOnly(false)}
        onConfirm={() => {
          setDraftPurchaseOrder(prevState => ({
            ...prevState,
            boardPurchaseFloors: [...INITIAL_FLOORS],
            materials: hasPurchaseOrders
              ? [...EMPTY_MATERIALS]
              : [...INITIAL_MATERIALS],
            laborOnly: !prevState.laborOnly,
          }));

          setCreateModelDefaults(false);
          setConfirmLaborOnly(false);
          setImportedDefault(false);
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  buttonInvoice: {
    marginLeft: '10px',
    height: '40px',
  },
  bold: {
    fontWeight: 'bold',
  },
  input: {
    margin: '8px 0px',
  },
  canceledText: {
    textTransform: 'uppercase',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#EB5757',
  },
  canceledDateText: {
    fontSize: '16px',
  },
});
