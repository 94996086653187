import React, { cloneElement, FC, ReactElement } from 'react';
import {
  List,
  TextField,
  ShowButton,
  DateField,
  TopToolbar,
  useListContext,
  CreateButton,
  sanitizeListRestProps,
  ExportButton,
  FunctionField,
  FunctionFieldProps,
  ToolbarProps,
  ExportButtonProps,
  ListProps,
} from 'react-admin';
import Datagrid from 'components/Datagrid';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import PendingIcon from '@material-ui/icons/MoreHoriz';

import { exporter } from 'providers/export';
import { JobSheetFilters } from './filters/job-sheet-filters';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';
import { CAN_CHANGE_JOB } from 'providers/permissions';
import { useStyles } from './jobs-styles';
import { JobSheetInterface } from '@vatos-pas/common';

export enum POStatus {
  NoPO = 'NoPO',
  Pending = 'Pending',
  Signed = 'Signed',
}

const StatusField: FC<Omit<FunctionFieldProps<JobSheetInterface>, 'render'>> =
  props => {
    const classes = useStyles();
    return (
      <FunctionField<JobSheetInterface>
        {...props}
        render={record => {
          switch (record?.poStatus) {
            case POStatus.Pending:
              return (
                <Box className={classes.pendingStatusBadge}>
                  <PendingIcon />
                </Box>
              );
            case POStatus.Signed:
              return (
                <Box className={classes.signedStatusBadge}>
                  <CheckIcon />
                </Box>
              );
            default:
              return null;
          }
        }}
      />
    );
  };

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions: FC<ListActionProps> = ({
  className,
  filters,
  maxResults,
  ...rest
}) => {
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasPermission(CAN_CHANGE_JOB) && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const PreRockField = props => {
  const preRockText = props.record.preRock === true ? 'Yes' : 'No';
  return <label>{preRockText}</label>;
};

export const JobsList: FC<ListProps> = props => {
  const { opened } = UIProvider.useContainer();

  const classes = useStyles();

  return (
    <List
      classes={{
        content: opened ? classes.content : classes.contentClosed,
        root: opened ? classes.root : classes.rootClosed,
      }}
      {...props}
      exporter={exporter('jobs')}
      bulkActionButtons={false}
      filterDefaultValues={{ 'voided||$eq': false }}
      filters={<JobSheetFilters>{props.filters}</JobSheetFilters>}
      actions={<ListActions maxResults={20000} />}
    >
      <Datagrid rowClick="show">
        <TextField source="builderName" label="Builder" />
        <TextField source="subdivisionName" label="Subdivision" />
        <TextField source="jobLot" label="Lot" />
        <TextField source="jobBlock" label="Block" />
        <TextField
          source="orderNumber"
          label="PO ID"
          emptyText="-"
          sortable={false}
        />
        <TextField source="modelName" label="Model" />
        <TextField
          source="originalSupervisorFullName"
          label="Original Supervisor"
        />
        <TextField source="supervisorFullName" label="Supervisor" />
        <TextField source="supplierName" label="Current Supplier" />
        <DateField source="createdAt" label="Created At" showTime />
        <StatusField label="Status" />
        <DateField source="poSignedAt" label="Signed At" emptyText="-" />
        <PreRockField source="preRock" label="Pre-Rock" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
