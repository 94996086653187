import { SubdivisionDto } from '@vatos-pas/common';
import { DataProvider } from 'react-admin';
import { axiosInstance } from 'services/http';

interface ISendVerificationCode {
  regionId: string;
}

export interface UserInterface {
  id: string;
  firstName: string;
  lastName: string;
}
export interface UserInterfaceResponse {
  data: {
    data: UserInterface[];
  };
}

export const getCoordinatorsByRegion = ({
  regionId,
}: ISendVerificationCode): Promise<UserInterfaceResponse> => {
  const filter = `?limit=300&sort%5B0%5D=firstName%2CASC&page=1&filter=active%7C%7C$eq%7C%7Ctrue&filter=userRoles.role.name%7C%7C$eq%7C%7CSupervisor&filter=userRegions.regionId%7C%7C$eq%7C%7C${regionId}&join=userRegions&join=userRoles&join=userRoles.role`;
  return axiosInstance.get('/user' + filter);
};

export const findSubdivisionsByRegion = async (
  dataProvider: DataProvider,
  regionId: string,
  filter?: { [key: string]: string },
): Promise<SubdivisionDto[]> => {
  try {
    const subdivisions = await dataProvider.getList<SubdivisionDto>(
      'subdivision',
      {
        filter: {
          regionId,
          ...(filter && filter),
        },
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'name', order: 'ASC' },
      },
    );

    return subdivisions.data;
  } catch (error: any) {
    return error;
  }
};
