import React, { Fragment } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';
import CheckIcon from '@material-ui/icons/Check';
import {
  PhaseDto,
  PhaseInterface,
  PhaseTypeEnum,
  JobPhaseDto,
} from '@vatos-pas/common';

import { useStyles } from './master-sheet-styles';
import { Job } from './master-sheet-edit';
import isMobileHook from 'hooks/isMobile';
import { dateWithoutServerTimezone } from '../utils/dateWithoutServerTimezone';

interface MasterSheetStatusBarProps {
  theJob: Job;
  getPhase: any;
  isInWarranty: any;
  showBump: any;
}

const getCircleColor = (
  theJob: Job,
  phaseType: PhaseTypeEnum,
  classes: any,
  getPhase: any,
) => {
  const phaseJob = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    phaseType,
  );
  if (!phaseJob || !phaseJob.dateStart) return classes.circleGray;
  if (phaseJob.approved) return classes.circleGreen;
  const today = new Date();
  if (
    phaseJob.dateDue &&
    new Date(phaseJob.dateDue) < today &&
    !phaseJob.approved
  ) {
    return classes.circleRed;
  }
  return classes.circle;
};

const isPhaseCompleted = (newJob: Job, phaseType: PhaseTypeEnum): boolean => {
  if (newJob.phases) {
    const phase = newJob.phases.find(
      (phaseItem: PhaseDto) => phaseItem.phaseType === phaseType,
    );
    if (phase) {
      const jobPhase = newJob.jobPhases.find(
        (jobPhase: JobPhaseDto) => jobPhase.phaseId === phase.id,
      );
      if (jobPhase) {
        return jobPhase.approved;
      }
    }
  }

  return false;
};

const TimelineJobPhase = (props: {
  job: Job;
  phaseType: PhaseTypeEnum;
  className: string;
  getPhase: any;
}) => {
  const { job, getPhase } = props;
  const jobPhase = getPhase(
    job.job,
    job.phases,
    job.jobPhases,
    props.phaseType,
  );
  if (!jobPhase.dateStart) {
    return <Fragment />;
  }
  const dateStart = new Date(jobPhase.dateStart);
  return (
    <Typography className={props.className}>
      {dateStart.getMonth() + 1}/{dateStart.getDate()}/{dateStart.getFullYear()}
    </Typography>
  );
};

const getPhaseName = (theJob: Job, phaseId: PhaseInterface['id']) => {
  if (theJob.job.pendingReview) return 'Materials – pending approval';
  const phase = theJob.phases.find((phase: PhaseDto) => phase.id === phaseId);
  return phase ? phase.name : null;
};
export const MasterSheetStatusBar: React.FC<MasterSheetStatusBarProps> = ({
  theJob,
  showBump,
  getPhase,
  isInWarranty,
}) => {
  const PhaseName = getPhaseName(theJob, theJob.jobPhase.phaseId);
  const isMobile = isMobileHook();

  const JobPriority = theJob.jobPhase.priority;
  const showBump4 = showBump(PhaseTypeEnum.Bump4);
  const showBump5 = showBump(PhaseTypeEnum.Bump5);
  const isBump1PhaseCompleted = isPhaseCompleted(theJob, PhaseTypeEnum.Bump1);
  const isBump2PhaseCompleted = isPhaseCompleted(theJob, PhaseTypeEnum.Bump2);
  const isBump3PhaseCompleted = isPhaseCompleted(theJob, PhaseTypeEnum.Bump3);
  const isBump4PhaseCompleted = isPhaseCompleted(theJob, PhaseTypeEnum.Bump4);
  const isBump5PhaseCompleted = isPhaseCompleted(theJob, PhaseTypeEnum.Bump5);
  const displayBump1Text = isMobile ? !isBump1PhaseCompleted : true;
  const displayBump2Text = isMobile
    ? isBump1PhaseCompleted && !isBump2PhaseCompleted
    : true;
  const displayBump3Text = isMobile
    ? isBump2PhaseCompleted && !isBump3PhaseCompleted
    : true;
  const displayBump4Text = isMobile
    ? isBump3PhaseCompleted && !isBump4PhaseCompleted && showBump4
    : true;
  const displayBump5Text = isMobile
    ? isBump4PhaseCompleted && !isBump5PhaseCompleted
    : true;

  const BumpTimeLine = () => {
    const classes = useStyles();

    return (
      <Box className={isMobile ? classes.whiteBoxMobile : classes.whiteBox}>
        <Box className={classes.timeline}>
          <Fragment>
            <Box className={classes.statusItem}>
              <Box className={classes.circleGreen}>
                <CheckIcon className={classes.iconCircle} />
              </Box>
              {!isMobile && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography className={classes.statusText}>
                    Delivery Complete
                  </Typography>
                  <TimelineJobPhase
                    job={theJob}
                    getPhase={getPhase}
                    className={classes.dateText}
                    phaseType={PhaseTypeEnum.Spraying}
                  />
                </Box>
              )}
            </Box>
            <Box
              className={
                isMobile ? classes.transparentSeparator : classes.separator
              }
            />
            <Box className={classes.statusItem}>
              {isBump1PhaseCompleted ? (
                <Box className={classes.circleGreen}>
                  <CheckIcon className={classes.iconCircle} />
                </Box>
              ) : (
                <Box
                  className={getCircleColor(
                    theJob,
                    PhaseTypeEnum.Bump1,
                    classes,
                    getPhase,
                  )}
                >
                  <Typography className={classes.statusNumber}>1</Typography>
                </Box>
              )}
              {displayBump1Text && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography className={classes.statusText}>
                    Bump Out 1
                  </Typography>
                  <TimelineJobPhase
                    job={theJob}
                    getPhase={getPhase}
                    className={classes.dateText}
                    phaseType={PhaseTypeEnum.Bump1}
                  />
                </Box>
              )}
            </Box>
            <Box
              className={
                isMobile ? classes.transparentSeparator : classes.separator
              }
            />
            <Box className={classes.statusItem}>
              {isBump2PhaseCompleted ? (
                <Box className={classes.circleGreen}>
                  <CheckIcon className={classes.iconCircle} />
                </Box>
              ) : (
                <Box
                  className={getCircleColor(
                    theJob,
                    PhaseTypeEnum.Bump2,
                    classes,
                    getPhase,
                  )}
                >
                  <Typography className={classes.statusNumber}>2</Typography>
                </Box>
              )}
              {displayBump2Text && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography className={classes.statusText}>
                    Bump Out 2
                  </Typography>
                  <TimelineJobPhase
                    job={theJob}
                    getPhase={getPhase}
                    className={classes.dateText}
                    phaseType={PhaseTypeEnum.Bump2}
                  />
                </Box>
              )}
            </Box>
            <Box
              className={
                isMobile ? classes.transparentSeparator : classes.separator
              }
            />
            <Box className={classes.statusItem}>
              {isBump3PhaseCompleted ? (
                <Box className={classes.circleGreen}>
                  <CheckIcon className={classes.iconCircle} />
                </Box>
              ) : (
                <Box
                  className={getCircleColor(
                    theJob,
                    PhaseTypeEnum.Bump3,
                    classes,
                    getPhase,
                  )}
                >
                  <Typography className={classes.statusNumber}>3</Typography>
                </Box>
              )}
              {displayBump3Text && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography className={classes.statusText}>
                    Bump Out 3
                  </Typography>
                  <TimelineJobPhase
                    job={theJob}
                    getPhase={getPhase}
                    className={classes.dateText}
                    phaseType={PhaseTypeEnum.Bump3}
                  />
                </Box>
              )}
            </Box>
            {showBump4 && (
              <Fragment>
                <Box
                  className={
                    isMobile ? classes.transparentSeparator : classes.separator
                  }
                />
                <Box className={classes.statusItem}>
                  {isBump4PhaseCompleted ? (
                    <Box className={classes.circleGreen}>
                      <CheckIcon className={classes.iconCircle} />
                    </Box>
                  ) : (
                    <Box
                      className={getCircleColor(
                        theJob,
                        PhaseTypeEnum.Bump4,
                        classes,
                        getPhase,
                      )}
                    >
                      <Typography className={classes.statusNumber}>
                        4
                      </Typography>
                    </Box>
                  )}
                  {displayBump4Text && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.statusText}>
                        Bump Out 4
                      </Typography>
                      <TimelineJobPhase
                        job={theJob}
                        getPhase={getPhase}
                        className={classes.dateText}
                        phaseType={PhaseTypeEnum.Bump4}
                      />
                    </Box>
                  )}
                </Box>
              </Fragment>
            )}
            {showBump5 && (
              <Fragment>
                <Box
                  className={
                    isMobile ? classes.transparentSeparator : classes.separator
                  }
                />
                <Box className={classes.statusItem}>
                  {isBump5PhaseCompleted ? (
                    <Box className={classes.circleGreen}>
                      <CheckIcon className={classes.iconCircle} />
                    </Box>
                  ) : (
                    <Box
                      className={getCircleColor(
                        theJob,
                        PhaseTypeEnum.Bump5,
                        classes,
                        getPhase,
                      )}
                    >
                      <Typography className={classes.statusNumber}>
                        5
                      </Typography>
                    </Box>
                  )}
                  {displayBump5Text && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography className={classes.statusText}>
                        Bump Out 5
                      </Typography>
                      <TimelineJobPhase
                        job={theJob}
                        getPhase={getPhase}
                        className={classes.dateText}
                        phaseType={PhaseTypeEnum.Bump5}
                      />
                    </Box>
                  )}
                </Box>
              </Fragment>
            )}
          </Fragment>
        </Box>
      </Box>
    );
  };

  const getApprovedDate = (
    phaseType: PhaseTypeEnum,
    theJob: Job,
    getPhase: any,
  ) => {
    const jobPhase = getPhase(
      theJob.job,
      theJob.phases,
      theJob?.jobPhases,
      phaseType,
    );
    if (jobPhase.dateApproved) {
      const dateStart = dateWithoutServerTimezone(jobPhase.dateApproved);
      return `${
        dateStart.getMonth() + 1
      }/${dateStart.getDate()}/${dateStart.getFullYear()}`;
    }
    return '';
  };

  const getResourceRequested = (
    phaseType: PhaseTypeEnum,
    theJob: Job,
    getPhase: any,
  ) => {
    const phaseJob = getPhase(
      theJob.job,
      theJob.phases,
      theJob.jobPhases,
      phaseType,
    );
    if (phaseJob) {
      if (phaseJob.dateRequested) {
        const dateRequested = dateWithoutServerTimezone(phaseJob.dateRequested);

        return `${
          dateRequested.getMonth() + 1
        }/${dateRequested.getDate()}/${dateRequested.getFullYear()}`;
      }
    }
  };

  const getDateAssigned = (
    phaseType: PhaseTypeEnum,
    theJob: Job,
    getPhase: any,
  ) => {
    const phaseJob = getPhase(
      theJob.job,
      theJob.phases,
      theJob.jobPhases,
      phaseType,
    );
    if (phaseJob) {
      if (phaseJob.dateAssigned) {
        const dateAssigned = dateWithoutServerTimezone(phaseJob.dateAssigned);

        return `${
          dateAssigned.getMonth() + 1
        }/${dateAssigned.getDate()}/${dateAssigned.getFullYear()}`;
      }
    }
  };

  const TimeLine = () => {
    const classes = useStyles();
    return (
      <Box className={isMobile ? classes.whiteBoxMobile : classes.whiteBox}>
        <Box className={classes.timeline}>
          <Box display="flex" flexDirection="row" alignItems="center">
            {JobPriority > 300 ? (
              <Box className={classes.signedStatusBadge}>
                <CheckIcon />
              </Box>
            ) : (
              <PanoramaFishEye fontSize="large" />
            )}
            <Box
              ml={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography>Hanging Approved</Typography>
              <Typography>
                {getApprovedDate(PhaseTypeEnum['Hanging'], theJob, getPhase)}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ width: '50px' }} variant="middle" />
          <Box display="flex" flexDirection="row" alignItems="center">
            {(theJob.jobPhase.requested && JobPriority === 400) ||
            JobPriority > 400 ? (
              <Box className={classes.signedStatusBadge}>
                <CheckIcon />
              </Box>
            ) : (
              <PanoramaFishEye fontSize="large" />
            )}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              ml={1}
            >
              <Typography>Finisher Requested</Typography>
              <Typography>
                {getResourceRequested(
                  PhaseTypeEnum['Finishing'],
                  theJob,
                  getPhase,
                )}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ width: '50px' }} variant="middle" />
          <Box display="flex" flexDirection="row" alignItems="center">
            {(theJob.jobPhase.requested && JobPriority === 400) ||
            JobPriority >= 500 ? (
              theJob.jobPhase.contractorId || JobPriority > 400 ? (
                <Box className={classes.signedStatusBadge}>
                  <CheckIcon />
                </Box>
              ) : (
                <PanoramaFishEye fontSize="large" />
              )
            ) : (
              <PanoramaFishEye fontSize="large" />
            )}
            <Box
              display="flex"
              ml={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography>Finisher Assigned</Typography>
              <Typography>
                {getDateAssigned(PhaseTypeEnum['Finishing'], theJob, getPhase)}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ width: '50px' }} variant="middle" />
          <Box
            display={JobPriority > 400 ? 'flex' : 'none'}
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display={JobPriority > 400 ? 'flex' : 'none'}
              className={classes.signedStatusBadge}
            >
              <CheckIcon />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box
              display={JobPriority > 400 ? 'none' : 'flex'}
              flexDirection="row"
              alignItems="center"
            >
              <PanoramaFishEye fontSize="large" />
            </Box>
            <Box
              display="flex"
              ml={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography>Finisher Approved</Typography>
              <Typography>
                {getApprovedDate(PhaseTypeEnum['Finishing'], theJob, getPhase)}
              </Typography>
            </Box>
          </Box>
          <Divider style={{ width: '50px' }} variant="middle" />
          <Box display="flex" flexDirection="row" alignItems="center">
            {JobPriority >= 500 && theJob.jobPhase.contractorId ? (
              <Box className={classes.signedStatusBadge}>
                <CheckIcon />
              </Box>
            ) : (
              <PanoramaFishEye fontSize="large" />
            )}
            <Box
              display="flex"
              ml={1}
              flexDirection="column"
              alignItems="center"
            >
              <Typography>Sprayer Assigned</Typography>
              <Typography>
                {getDateAssigned(PhaseTypeEnum['Spraying'], theJob, getPhase)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {PhaseName === 'Materials' ||
      PhaseName === 'Garage' ? null : isInWarranty() ? (
        <BumpTimeLine />
      ) : (
        <TimeLine />
      )}
    </>
  );
};

export default MasterSheetStatusBar;
