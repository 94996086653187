import React, { FC } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  NullableBooleanInput,
  Filter,
  ReferenceInput,
  DateInput,
  SelectInput,
  TextInput,
  FilterProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { UserDto } from '@vatos-pas/common';
import { AppRole } from 'providers/roles';

const lotStyles = makeStyles({
  input: {
    width: '7em',
  },
});

const contractorStyles = makeStyles({
  input: {
    width: '9em',
  },
  filterDate: {
    width: '100%',
    minWidth: '200px',
  },
});

export const RmMasterSheetFilter: FC<FilterProps> = props => {
  const lotClasses = lotStyles();
  const contractorClasses = contractorStyles();

  return (
    <Filter {...props}>
      <ReferenceInput
        label="Supervisor"
        source="supervisorUserId"
        reference="user"
        data-cy="RmMasterSheetFilterSupervisor"
        filterToQuery={value => {
          if (value) {
            return {
              s: {
                $and: [
                  {
                    $or: [
                      { firstName: { $cont: value } },
                      { lastName: { $cont: value } },
                    ],
                  },
                  { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
                ],
              },
            };
          }
          return { s: { 'userRoles.role.name': { $eq: AppRole.Supervisor } } };
        }}
        sort={{ field: 'firstName', order: 'ASC' }}
        allowEmpty={false}
        resettable
        alwaysOn
      >
        <AutocompleteInput
          optionText={(user: UserDto) => `${user.firstName} ${user.lastName}`}
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <TextInput
        className={lotClasses.input}
        label="Lot"
        source="jobLot||$eq"
        alwaysOn
        resettable
        data-cy="RmMasterSheetFilterLot"
      />
      <TextInput
        className={lotClasses.input}
        label="Block"
        source="jobBlock||$eq"
        alwaysOn
        resettable
        data-cy="RmMasterSheetFilterBlock"
      />
      <ReferenceInput
        label="Builder"
        source="builderId"
        reference="builder"
        data-cy="RmMasterSheetFilterBuilder"
        filterToQuery={value => (value ? { name: value } : {})}
        sort={{ field: 'name', order: 'ASC' }}
        allowEmpty={false}
        resettable
      >
        <AutocompleteInput
          allowEmpty={false}
          optionText="name"
          optionValue="id"
          suggestionLimit={25}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Subdivision"
        source="subdivisionId"
        reference="subdivision"
        data-cy="RmMasterSheetFilterSubdivision"
        resettable
        alwaysOn
        allowEmpty={false}
        filterToQuery={name => (name ? { name } : {})}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <ReferenceInput
        label="Phase"
        source="phaseId"
        reference="phase"
        data-cy="RmMasterSheetFilterPhase"
        perPage={Number.MAX_SAFE_INTEGER}
        sort={{ field: 'priority', order: 'ASC' }}
        allowEmpty={false}
        resettable
        alwaysOn
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <BooleanInput
        source="requestIsOnTime"
        label="Request Is On Time"
        data-cy="RmMasterSheetFilterRequestOnTime"
      />
      <NullableBooleanInput
        className={contractorClasses.input}
        source="contractorHasBeenAssigned||$eq"
        label="Contractor"
        data-cy="ProdPayMasterSheetFilterShowAssigned"
        nullLabel="All"
        options={{
          SelectProps: { displayEmpty: true },
          InputLabelProps: { shrink: true },
        }}
        trueLabel="Assigned"
        falseLabel="Unassigned"
        alwaysOn
      />
      <DateInput
        className={contractorClasses.filterDate}
        label="Date Requested"
        source="jobPhaseDateRequested||$eq"
        alwaysOn
      />
      <ReferenceInput
        label="Region"
        source="regionId"
        reference="region"
        data-cy="RmMasterSheetFilterRegion"
        allowEmpty={false}
        perPage={Number.MAX_SAFE_INTEGER}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
    </Filter>
  );
};
