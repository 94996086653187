import React, { useState, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ImageLogo from 'assets/logo.png';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { sendVerificationCode } from 'services/auth';

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '20px',
  },
  logo: {
    width: '100%',
    marginBottom: '30px',
    '@media(max-width: 1300px)': {
      maxWidth: '325px',
    },
  },
  cardLogin: {
    maxWidth: '400px',
    '@media(max-width: 1300px)': {
      width: '70vw',
      padding: '20px 30px',
      maxWidth: '600px',
    },
    width: '20vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
  },
  submitButton: {
    marginLeft: 'auto',
    width: 'fit-content',
    fontWeight: 'bold',
  },
  successText: {
    color: 'green',
    marginBottom: '10px',
    marginTop: '10px',
  },
  errorText: {
    color: 'red',
    marginTop: '10px',
  },
});

export const RecoveryPassword: React.FC = () => {
  const [codeSent, setCodeSent] = useState(false);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();
  const [email, setEmail] = useState('');

  const sendTheCode = () => {
    setHasError(false);
    sendVerificationCode({ email })
      .then(_response => {
        setCodeSent(true);
      })
      .catch(_error => setHasError(true));
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        borderRadius={10}
        boxShadow={3}
        p={4}
        px={6}
        className={classes.cardLogin}
      >
        <img src={ImageLogo} className={classes.logo} />
        {!codeSent ? (
          <Fragment>
            <Typography variant="h4">Recovery Password</Typography>
            <form className={classes.form}>
              <TextField
                className={classes.input}
                id="standard-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={onChangeEmail}
              />
              <Button
                variant="contained"
                color="default"
                className={classes.submitButton}
                startIcon={<SendIcon />}
                onClick={sendTheCode}
              >
                Send the Code
              </Button>
            </form>
            {hasError && (
              <Box>
                <Typography className={classes.errorText}>
                  We have an error, please try again
                </Typography>
              </Box>
            )}
          </Fragment>
        ) : (
          <Box>
            <Typography className={classes.successText}>
              Please check your email and reset your password!
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RecoveryPassword;
