import React, { useEffect, useRef } from 'react';
import { useInput, InputProps } from 'react-admin';

import CustomSelect from 'components/CustomSelect';

type RouteInputProps = {
  regionId: string | undefined;
  classes: { [key: string]: string };
} & InputProps;

export const RouteInput = ({
  regionId,
  classes,
  onChange,
  ...props
}: RouteInputProps) => {
  const regionIdCache = useRef(regionId);

  const {
    input,
    meta: { touched, error },
  } = useInput(props);

  const handleChange = async (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    onChange && onChange(event.target.value);
    input.onChange(event);
  };

  // Reset subdivision state whenever region changes
  useEffect(() => {
    if (regionIdCache.current !== regionId) {
      input.onChange(null);
      regionIdCache.current = regionId;
    }
  }, [regionId]);

  return (
    <CustomSelect
      label="Route"
      className={classes.input}
      resource="supervisorUserId"
      variant="filled"
      options={props.options}
      margin="dense"
      error={!!(touched && error)}
      helperText={error}
      {...input}
      onChange={handleChange}
    />
  );
};
