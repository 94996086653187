import { useFormState } from 'react-final-form';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import CustomSelect, {
  ICustomItem,
  ICustomReference,
} from 'components/CustomSelect';
import { useHistory } from 'react-router-dom';

type BumperFieldProps = {
  id: string | undefined;
  classes: { [key: string]: string };
  bumpers: ICustomItem[] | null;
  selectedBumpers: string[];
  setSelectedBumpers: React.Dispatch<React.SetStateAction<string[]>>;
} & Pick<ICustomReference, 'error' | 'helperText'>;

const BumperField = ({
  id,
  classes,
  bumpers,
  selectedBumpers,
  setSelectedBumpers,
  error,
  helperText,
}: BumperFieldProps) => {
  const history = useHistory();
  const { values } = useFormState();

  if (!values.bumperAvailable) return null;

  return (
    <Box
      className={classes.fullWidth}
      mb={3}
      display="flex"
      alignItems="center"
    >
      <CustomSelect
        margin="dense"
        variant="filled"
        renderValue={(value: unknown) => (
          <div className={classes.chips}>
            {(value as string[])
              .map(item => bumpers?.find(choice => choice.id === item))
              .filter(item => !!item)
              .map(item => (
                <Chip key={item?.id} label={item?.name} />
              ))}
          </div>
        )}
        className={classes.halfWidth}
        multiple
        resource="user"
        label="Bumpers"
        options={bumpers ?? []}
        value={selectedBumpers}
        onChange={event => setSelectedBumpers(event.target.value as string[])}
        error={error}
        helperText={helperText}
      />
      <Box className={classes.addBumperButton}>
        <IconButton
          onClick={() =>
            history.push({
              pathname: `/user/create`,
              search: `contractorId=${id}`,
              state: {
                formState: values,
                selectedBumpers,
              },
            })
          }
        >
          <AddIcon color="primary" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default BumperField;
