import React, { Fragment } from 'react';
import { DeleteButton, SaveButton, Toolbar as ToolBarRA } from 'react-admin';
import { Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_SEE_TOOLBAR } from 'providers/permissions';

const useStyles = makeStyles({
  buttonBack: {
    marginRight: 'auto',
  },
  buttonDelete: {
    marginRight: 16,
  },
});

interface IToolbar {
  removeGoBack?: boolean;
  renderDelete?: boolean;
  deleteContent?: string;
  deleteTitle?: string;
}

export const Tollbar: React.FC<IToolbar> = props => {
  const classes = useStyles();

  const { hasPermission } = PermissionsProvider.useContainer();

  return (
    <ToolBarRA {...props}>
      {!props.removeGoBack && (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.buttonBack}
          startIcon={<ChevronLeftIcon />}
          onClick={() => {
            window.history.back();
          }}
        >
          Go Back
        </Button>
      )}
      {props.renderDelete && (
        <DeleteButton
          className={classes.buttonDelete}
          confirmTitle={props.deleteTitle}
          confirmContent={props.deleteContent}
          mutationMode="pessimistic"
        />
      )}
      {hasPermission(CAN_SEE_TOOLBAR) ? (
        <SaveButton redirect="list" />
      ) : (
        <Fragment />
      )}
    </ToolBarRA>
  );
};

export default Tollbar;
