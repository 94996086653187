/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import { Admin } from '@react-admin/ra-enterprise';
import { Resource } from 'react-admin';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';

import UserModule from 'modules/users';
import RolesModule from 'modules/roles';
import RegionsModule from 'modules/regions';
import BuildersModule from 'modules/builders';
import ModelsModule from 'modules/model';
import ScheduleModule from 'modules/schedule';
import ModelBoardSheetModule from 'modules/model-board-sheet';
import MaterialsDeliveryModule from 'modules/materials-delivery';
import JobRepairsSheetModule from 'modules/job-repair-sheet';
import MasterSheetModule from 'modules/master-sheet';
import PreRockSheet from 'modules/pre-rock-sheet';
import WarrantySheetModule from 'modules/job-warranty-sheet';
import RMMasterSheetModule from 'modules/rm-master-sheet';
import ProdPayMasterSheet from 'modules/prod-pay-master-sheet';
import RepairReceivablesSheet from 'modules/repair-receivables-sheet';
import RepairPayablesSheet from 'modules/repair-payables-sheet';
import RepairsSettings from 'modules/repairs-settings';
import BoardModule from 'modules/board';
import SupplierModule from 'modules/supplier';
import BumpoutTimesheet from 'modules/bumpout-timesheet';
import RoutesModule from 'modules/route';
import JobsModule from 'modules/jobs';
import ContractorsModule from 'modules/contractor';
import SubDivisionsModule from 'modules/sub-divisions';
import CustomLayout from 'customizations/CustomLayout';
import Login from 'modules/login';
import CustomRoutes from 'customizations/CustomRoutes';
import dataProvider from 'providers/dataProvider';
import authProvider from 'providers/authProvider';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import pendingJobs from 'modules/pending-jobs';

const App: FC = () => (
  <UIProvider.Provider>
    <PermissionsProvider.Provider>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Admin
          customRoutes={CustomRoutes}
          layout={CustomLayout}
          dataProvider={dataProvider}
          loginPage={Login}
          authProvider={authProvider}
          disableTelemetry={true}
          theme={{
            breakpoints: {
              values: { xs: 200, sm: 850, md: 1080, lg: 1200, xl: 1536 },
            },
          }}
        >
          <Resource key="master-sheet" {...MasterSheetModule} />
          <Resource key="pre-rock-sheet" {...PreRockSheet} />
          <Resource key="materials-delivery" {...MaterialsDeliveryModule} />
          <Resource key="schedule" {...ScheduleModule} />
          <Resource key="model-board-sheet" {...ModelBoardSheetModule} />
          <Resource key="warranty" {...WarrantySheetModule} />
          <Resource key="supplier" {...SupplierModule} />
          <Resource key="user" {...UserModule} />
          <Resource key="roles" {...RolesModule} />
          <Resource key="regions" {...RegionsModule} />
          <Resource key="builders" {...BuildersModule} />
          <Resource key="sub-divisions" {...SubDivisionsModule} />
          <Resource key="models" {...ModelsModule} />
          <Resource key="board" {...BoardModule} />
          <Resource key="rm-master-sheet" {...RMMasterSheetModule} />
          <Resource key="prod-pay-master-sheet" {...ProdPayMasterSheet} />
          <Resource key="repair-receivables" {...RepairReceivablesSheet} />
          <Resource key="repair-payables" {...RepairPayablesSheet} />
          <Resource key="jobs" {...JobsModule} />
          <Resource key="job-order" {...pendingJobs} />
          <Resource key="contractors" {...ContractorsModule} />
          <Resource key="repairs-settings" {...RepairsSettings} />
          <Resource key="user-role" name="user-role" />
          <Resource key="user-region" name="user-region" />
          <Resource key="supplier-region" name="supplier-region" />
          <Resource key="contractor-region" name="contractor-region" />
          <Resource key="phase" name="phase" />
          <Resource key="repair" name="repair" />
          <Resource key="user-representative" name="user-representative" />
          <Resource
            key="supplier-representative"
            name="supplier-representative"
          />
          <Resource key="job-repair-sheet" {...JobRepairsSheetModule} />
          <Resource key="bump-resource-sheet" {...BumpoutTimesheet} />
          <Resource key="route" {...RoutesModule} />
        </Admin>
      </MuiPickersUtilsProvider>
    </PermissionsProvider.Provider>
  </UIProvider.Provider>
);

export default App;
