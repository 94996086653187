import { useEffect, useState } from 'react';
import flatten from 'lodash/flatten';
import { createContainer, useContainer } from 'unstated-next';
import { JobPhaseDto } from '@vatos-pas/common';
import { PurchaseOrderContainer } from './purchase-order';
import { useDataProvider } from 'react-admin';

import { JobContainer } from './job';
import { ModelBoardPurchaseDtoWithId } from '../types';
import { getMaterialsFromBoards } from '../utils';

const useDefaultModel = () => {
  const dataProvider = useDataProvider();
  const { setDraftPurchaseOrder, resetPurchaseOrder } = useContainer(
    PurchaseOrderContainer,
  );
  const { purchaseOrders } = useContainer(JobContainer);

  const [defaultModel, setDefaultModel] =
    useState<ModelBoardPurchaseDtoWithId | null>(null);
  const [importedDefault, setImportedDefault] = useState(false);
  const [createModelDefaults, setCreateModelDefaults] = useState(true);

  const hasPurchaseOrders = !!purchaseOrders && purchaseOrders.length > 0;

  const clearDefaultModel = () => {
    resetPurchaseOrder(hasPurchaseOrders);
    setImportedDefault(false);
  };

  const importDefaultModel = (jobPhase?: JobPhaseDto) => {
    const filteredBoards = defaultModel?.modelBoardFloors?.filter(
      item => item.phaseId === jobPhase?.phaseId,
    );

    if (!filteredBoards) return;

    const onlyFloors = [...new Set(filteredBoards.map(item => item.floor))];

    const onlyFloorsBoards = onlyFloors.map(item => {
      const boards = filteredBoards.filter(board => board.floor === item);

      return boards;
    });

    const formattedFloors = flatten(
      onlyFloorsBoards.map(boards => {
        return boards.map(board => ({
          floor: board.floor,
          boardTypeId: board.boardTypeId,
          quantityOrdered: board.quantity,
        }));
      }),
    );

    setImportedDefault(true);
    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: formattedFloors,
      ...(purchaseOrders?.length === 0 && {
        materials: getMaterialsFromBoards(formattedFloors, prevState.materials),
      }),
    }));

    setCreateModelDefaults(false);
  };

  const hasDefaultModel = (jobPhase?: JobPhaseDto) => {
    if (!jobPhase || !defaultModel) return false;

    const filteredBoards = defaultModel?.modelBoardFloors?.filter(
      item => item.phaseId === jobPhase.phaseId,
    );

    const hasDefault = !!filteredBoards?.length && filteredBoards.length > 0;

    if (hasDefault && createModelDefaults) setCreateModelDefaults(false);
    return hasDefault;
  };

  const getDefaultModel = async (modelId: string) => {
    try {
      const newDefaultModel =
        await dataProvider.getOne<ModelBoardPurchaseDtoWithId>(
          'model-board-purchase',
          { id: modelId },
        );
      setDefaultModel(newDefaultModel.data);
    } catch (error) {
      console.log('Error loading default models!');
    }
  };

  useEffect(() => {
    if (hasPurchaseOrders && createModelDefaults) {
      setCreateModelDefaults(false);
    }
  }, [JSON.stringify(purchaseOrders)]);

  return {
    defaultModel,
    setDefaultModel,
    importedDefault,
    createModelDefaults,
    setCreateModelDefaults,
    setImportedDefault,
    clearDefaultModel,
    importDefaultModel,
    hasDefaultModel,
    getDefaultModel,
  };
};

export const DefaultModelContainer = createContainer(useDefaultModel);
