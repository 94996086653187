import { FC, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { PermissionsProvider } from 'providers/permissionsProvider';
import { AppRole } from 'providers/roles';
import { PurchaseOrderHeader } from './purchase-order-header';
import { PurchaseOrderPhaseSelection } from './purchase-order-phase-selection';
import { PurchaseOrderFloors } from './purchase-order-floors';
import { PurchaseOrderMaterials } from './purchase-order-materials';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { PurchaseOrderBody } from './purchase-order-body';
import { PurchaseOrderFooter } from './purchase-order-footer';
import { useContainer } from 'unstated-next';
import { useNotify } from 'react-admin';
import { DefaultModelContainer } from '../../providers/default-model';
import { JobContainer } from '../../providers/job';

export const PurchaseOrder: FC = () => {
  const {
    setSameSupplier,
    getJobOrderBoard,
    setPOHasBoardExcelFloors,
    setErrors,
  } = useContainer(PurchaseOrderContainer);
  const { job, purchaseOrders, isPurchaseOrderOpen, setIsPurchaseOrderOpen } =
    useContainer(JobContainer);
  const { setImportedDefault, getDefaultModel, setCreateModelDefaults } =
    useContainer(DefaultModelContainer);
  const notify = useNotify();
  const { hasRole } = PermissionsProvider.useContainer();

  const canEditInvoice = !hasRole(AppRole.AccountingViewOnly);

  const onClose = () => {
    setIsPurchaseOrderOpen(false);
    setPOHasBoardExcelFloors(false);
    setImportedDefault(false);
    setSameSupplier(true);
    setErrors({});

    if (purchaseOrders && purchaseOrders?.length > 0) {
      setCreateModelDefaults(false);
    } else {
      setCreateModelDefaults(true);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        if (!job?.modelId || !job?.id) return;

        await Promise.all([
          getDefaultModel(job.modelId),
          getJobOrderBoard(job.id),
        ]);
      } catch (error) {
        notify(
          'Failed to load purchase order information, please try again.',
          'error',
        );
      }
    };

    initialize();
  }, [job?.modelId, job?.id]);

  return (
    <Dialog
      open={isPurchaseOrderOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <PurchaseOrderHeader
          canEditInvoice={canEditInvoice}
          purchaseOrders={purchaseOrders}
        />
        <PurchaseOrderPhaseSelection />
        <PurchaseOrderBody />
        <PurchaseOrderFloors />
        <PurchaseOrderMaterials />
        <PurchaseOrderFooter />
      </DialogContent>
    </Dialog>
  );
};

export default PurchaseOrder;
