import { useEffect, useState } from 'react';
import { createContainer, useContainer } from 'unstated-next';
import _ from 'lodash';
import { Record, useDataProvider } from 'react-admin';
import { BoardTypeDto, JobOrderDto } from '@vatos-pas/common';
import { JobCreateContainer } from './job-create';
import { SP_FIELD_MAPPING, SP_MATCHING_FIELDS } from '../constants';
import { SpMatchFields } from '../types';
import { findManyBoardTypes, findManyJobOrder } from 'services/jobs';

const useImportedJob = () => {
  const dataProvider = useDataProvider();

  const { getSupervisor, fields, setFields, models, builders, subdivisions } =
    useContainer(JobCreateContainer);

  const [boardTypes, setBoardTypes] = useState<BoardTypeDto[] | null>(null);
  const [importedJob, setImportedJob] = useState<JobOrderDto | null>(null);

  const getSpMappingPayload = () => {
    const spModelName = !importedJob?.modelId && {
      modelId: fields.model,
    };

    const spSubdivisionName = !importedJob?.subdivisionId && {
      subdivisionId: fields.subdivision,
    };

    const spBuilderName = !importedJob?.builderId && {
      builderId: fields.builder,
    };

    return {
      ...(spModelName ? { spModelName } : {}),
      ...(spSubdivisionName ? { spSubdivisionName } : {}),
      ...(spBuilderName ? { spBuilderName } : {}),
    };
  };

  const getImportedJob = async (id: string) => {
    const job = await findManyJobOrder(dataProvider, id);

    if (!job?.length) return null;

    const importedJobResponse = job[0];
    setImportedJob(importedJobResponse);

    const additionalFields = Object.entries(SP_FIELD_MAPPING).reduce(
      (fields, [sourceField, targetField]) => {
        if (importedJobResponse[sourceField]) {
          fields[targetField] =
            sourceField === 'spOrderJobStateCode' &&
            importedJobResponse[sourceField] === 'USFL'
              ? 'Florida'
              : importedJobResponse[sourceField];
        }
        return fields;
      },
      {} as { [key: string]: string },
    );

    const subdivisionItem =
      importedJobResponse?.subdivisionId &&
      subdivisions?.[importedJobResponse.subdivisionId];

    let supervisorName = '';

    if (subdivisionItem) {
      supervisorName =
        (await getSupervisor(
          dataProvider,
          subdivisionItem?.supervisorUserId,
        )) ?? '';
    }

    return {
      supervisor: supervisorName,
      model: importedJobResponse.modelId ?? '',
      subdivision: importedJobResponse.subdivisionId ?? '',
      builder: importedJobResponse.builderId ?? '',
      ...additionalFields,
    };
  };

  const getFieldMatchInactive = <T extends Record>(
    itemId: string,
    fieldDictionary: T[] | null,
    spFieldName: string,
    spFieldNameId: string,
  ) => {
    if (!itemId || !importedJob) return false;

    const currentFieldItem = fieldDictionary?.find(item => item.id === itemId);

    if (!currentFieldItem) return false;

    const matchedImportedField = currentFieldItem?.[spFieldName]?.find(
      builder => builder.spNameId === importedJob?.[spFieldNameId],
    );

    if (!matchedImportedField) return false;

    return !matchedImportedField?.active;
  };

  const getBoardTypes = async () => {
    const boardTypes = await findManyBoardTypes(dataProvider);

    if (boardTypes?.length) {
      setBoardTypes(boardTypes);
    }
  };

  const isBuilderMatchActive = getFieldMatchInactive(
    fields.builder,
    builders,
    SP_MATCHING_FIELDS[SpMatchFields.Builder].name,
    SP_MATCHING_FIELDS[SpMatchFields.Builder].id,
  );

  const isSubdivisionMatchActive = getFieldMatchInactive(
    fields.subdivision,
    subdivisions,
    SP_MATCHING_FIELDS[SpMatchFields.Subdivision].name,
    SP_MATCHING_FIELDS[SpMatchFields.Subdivision].id,
  );

  const isModelMatchInactive = getFieldMatchInactive(
    fields.model,
    models,
    SP_MATCHING_FIELDS[SpMatchFields.Model].name,
    SP_MATCHING_FIELDS[SpMatchFields.Model].id,
  );

  useEffect(() => {
    const getSupervisorName = async () => {
      const subdivisionItem = subdivisions?.[fields.subdivision];

      const supervisorName = await getSupervisor(
        dataProvider,
        subdivisionItem?.supervisorUserId,
      );

      if (!supervisorName) return;

      setFields(prevState => ({
        ...prevState,
        supervisor: supervisorName,
      }));
    };

    getSupervisorName();
  }, [fields.subdivision]);

  return {
    importedJob,
    getImportedJob,
    getSpMappingPayload,
    isSubdivisionMatchActive,
    isModelMatchInactive,
    isBuilderMatchActive,
    getFieldMatchInactive,
    getBoardTypes,
    boardTypes,
  };
};

export const ImportedJobContainer = createContainer(useImportedJob);
