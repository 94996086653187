import React, { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import {
  useDataProvider,
  useNotify,
  useRefresh,
  Record as RARecord,
  ReduxState,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export interface OrderStateParams {
  order: string;
  sort: string;
  filter: Record<string, unknown>;
}

export interface OrderButtonsProps {
  source?: string;
  record?: RARecord;
  resource?: string;
}

export enum OrderDirection {
  Up = 'up',
  Down = 'down',
}

export const OrderButtons: React.FC<OrderButtonsProps> = ({
  record,
  resource,
  source,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const { order, sort, filter }: OrderStateParams = useSelector(
    (state: ReduxState) => state.admin.resources[resource || ''].list.params,
  );

  const filterLength = filter ? Object.keys(filter).length : 0;

  const move = (direction: OrderDirection) => {
    dataProvider
      .move(resource, { id: record?.id, direction })
      .then(() => refresh())
      .catch((error: Error) => notify(error.message, 'warning'));
  };

  const handleUp = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    move(OrderDirection.Up);
  };

  const handleDown = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    move(OrderDirection.Down);
  };

  const isValidSource = source === sort || sort === null;
  const sourceStr = record && source ? !record[source] : '';
  // Default value its sorted
  const disabled =
    !isValidSource || order === 'DESC' || sourceStr || filterLength > 0;

  return (
    <ButtonGroup size="small" disabled={disabled}>
      <Button onClick={handleUp} title="Move up">
        <KeyboardArrowUpIcon />
      </Button>
      <Button onClick={handleDown} title="Move down">
        <KeyboardArrowDownIcon />
      </Button>
    </ButtonGroup>
  );
};
