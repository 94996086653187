import { JobPhaseBumpApprovalStatus } from '@vatos-pas/common';

export const JOB_PHASE_BUMP_APPROVAL_STATUS_LABEL_MAPPING = {
  [JobPhaseBumpApprovalStatus.MISMATCH_APPROVAL]: 'Mismatch Approval',
  [JobPhaseBumpApprovalStatus.APPROVED]: 'Approved',
  [JobPhaseBumpApprovalStatus.NOT_STARTED]: 'Not Started',
  [JobPhaseBumpApprovalStatus.OVERRIDDEN]: 'Overriden',
  [JobPhaseBumpApprovalStatus.PENDING_APPROVAL]: 'Pending Approval',
  [JobPhaseBumpApprovalStatus.PENDING_REVIEW]: 'Pending Review',
  [JobPhaseBumpApprovalStatus.DEPRECATED]: 'Deprecated',
};
