import {
  SubDivisionsList,
  SubDivisionsCreate,
  SubDivisionsEdit,
} from './views';

export default {
  create: SubDivisionsCreate,
  edit: SubDivisionsEdit,
  list: SubDivisionsList,
  name: 'subdivision',
  label: 'Subdivisions',
  to: '/subdivision',
};
