import { axiosInstance } from 'services/http';

interface ISendVerificationCode {
  boardPurchaseId: string;
}

export const getSummary = ({
  boardPurchaseId,
}: ISendVerificationCode): Promise<any> => {
  return axiosInstance.get('/board-purchase/' + boardPurchaseId + '/summary');
};
