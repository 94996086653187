/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { FC } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cancelButton: {
    color: '#e6005a',
  },
  dialog: {
    maxHeight: '70vh',
  },
});

type PartialDelivery = {
  id: string;
  partialDeliveryDate: string;
  notes: string;
};

type PartialDeliveryHistoryModalProps = {
  open: boolean;
  handleClose(): void;
  deliveries: PartialDelivery[];
};

export const PartialDeliveryHistoryModal: FC<PartialDeliveryHistoryModalProps> =
  ({ open, handleClose, deliveries }) => {
    const styles = useStyles();

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent className={styles.dialog}>
          {deliveries.map((delivery, index) => (
            <Box key={delivery.id}>
              {index !== 0 && (
                <Box mt={6} mb={4} bgcolor="black" height="1px" width="100%" />
              )}
              <Box width="50%" minWidth="160px">
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Partial Delivery Date"
                  fullWidth
                  value={delivery.partialDeliveryDate}
                  onChange={date => date}
                  maxDate={new Date()}
                  disabled
                />
              </Box>
              <Box mt={1}>
                <TextField
                  label="Description"
                  variant="outlined"
                  fullWidth
                  value={delivery.notes}
                  multiline
                  rows={5}
                  onChange={e => e}
                  disabled
                />
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            paddingX="20px"
            paddingY="20px"
            paddingTop="10px"
          >
            <Button onClick={handleClose} className={styles.cancelButton}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  };

export default PartialDeliveryHistoryModal;
