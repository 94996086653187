import React, { FC, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';

const useStyles = makeStyles({
  dialog: {
    width: '500px',
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  textItem: {
    fontSize: '14px',
  },
  separator: {
    height: 1,
    backgroundColor: 'lightgray',
    marginBottom: 8,
    marginTop: 8,
  },
});

export const JobNotesModal: FC<any> = props => {
  const classes = useStyles();

  if (!props.selectedJob.jobNotes) {
    return <Fragment />;
  }

  const jobNotes = [...props.selectedJob.jobNotes];
  const sortedSelectedJob = orderBy(jobNotes, ['createdAt'], ['desc']);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-title">
        Lot {props.selectedJob.jobLot}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        {!!sortedSelectedJob?.length && (
          <Box pt={3}>
            <Typography style={{ fontWeight: 'bold' }}>
              Notes History
            </Typography>
            <Box>
              {sortedSelectedJob.map((item, index) => (
                <Box key={item.id} mb={2}>
                  {index > 0 && <Divider />}
                  <Box pt={2}>
                    <Box>
                      {item.user?.firstName && item.user.lastName && (
                        <Box>
                          <Typography>{`${item.user.firstName} ${
                            item.user.lastName
                          } - ${new Date(item.createdAt).toLocaleTimeString(
                            'en-US',
                          )} ${new Date(item.createdAt).toLocaleDateString(
                            'en-US',
                          )}`}</Typography>
                        </Box>
                      )}
                      {!!item.jobNoteOptions?.length && (
                        <Typography>
                          Job Options:{' '}
                          {map(item.jobNoteOptions, 'option.name').join(', ')}
                        </Typography>
                      )}
                    </Box>
                    {item.note && (
                      <Box mt={2}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.note,
                          }}
                          className="preview"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default JobNotesModal;
