import React, { FC, ReactElement } from 'react';
import {
  Create,
  TextInput,
  SelectArrayInput,
  BooleanInput,
  NumberInput,
  CreateProps,
  useRedirect,
  useNotify,
  InjectedFieldProps,
  PublicFieldProps,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {
  ReferenceManyToManyInput,
  ManyToManyReferenceContextProvider,
} from '@react-admin/ra-relationships';
import { validateContractor } from './contractorValidation';
import SimpleForm from 'components/SimpleForm';
import { OptionsInput } from 'components/OptionsInput';
import { bumperPaymentTypeOptions, paymentTypeOptions } from './constants';
import { ContractorInterface } from '@vatos-pas/common';

type BoxWrapperProps = {
  children: ReactElement[];
} & PublicFieldProps &
  InjectedFieldProps<ContractorInterface>;

// This component is necessary because `ReferenceManyToManyInput` needs
// To be a direct child of SimpleForm. By passing down the props of the `Box`
// we replicate this behavior and the form renders as expected.
const BoxWrapper = ({ children, ...rest }: BoxWrapperProps) => {
  const classes = useStyles();

  const renderChildren = () => {
    return React.Children.map(children, child => {
      // We want to clone `ReferenceManyToManyInput` passing down
      // the SimpleForm props
      if (child?.props?.reference === 'region') {
        return React.cloneElement(child, {
          ...rest,
        });
      }

      return child;
    });
  };

  return <Box className={classes.fields}>{renderChildren()}</Box>;
};

export const ContractorsCreate: FC<CreateProps> = props => {
  const redirect = useRedirect();
  const classes = useStyles();
  const notify = useNotify();

  return (
    <Create
      {...props}
      onSuccess={({ data }) => {
        if (data.bumperAvailable) {
          redirect(`/contractor/${data.id}`);
          notify('Element created');
          return;
        }

        redirect('/contractor');
        notify('Element created');
      }}
      className={classes.createBox}
    >
      <ManyToManyReferenceContextProvider>
        <SimpleForm validate={validateContractor}>
          <Box className={classes.fields}>
            <TextInput fullWidth className={classes.input} source="name" />
            <TextInput
              fullWidth
              className={classes.input}
              source="corporation"
            />
          </Box>
          <Box className={classes.fields}>
            <OptionsInput
              fullWidth
              className={classes.input}
              label="Payment Type"
              options={paymentTypeOptions}
              source="paymentType"
            />
            <NumberInput
              fullWidth
              className={classes.input}
              min="1"
              max="100"
              step="0.01"
              source="paymentRate"
            />
            <OptionsInput
              fullWidth
              className={classes.input}
              label="Bumper Payment Type"
              options={bumperPaymentTypeOptions}
              source="bumperPaymentType"
            />
            <NumberInput
              fullWidth
              className={classes.input}
              min="1"
              max="100"
              step="0.01"
              source="bumperPaymentRate"
            />
          </Box>
          <Box className={classes.fields}>
            <BooleanInput
              label="Hanger"
              className={classes.input}
              source="hangerAvailable"
            />
            <BooleanInput
              label="Finisher"
              className={classes.input}
              source="finisherAvailable"
            />
            <BooleanInput
              label="Sprayer"
              className={classes.input}
              source="sprayerAvailable"
            />
            <BooleanInput
              label="Bumper"
              className={classes.input}
              source="bumperAvailable"
            />
            <BooleanInput
              label="Specialist"
              className={classes.input}
              source="specialistAvailable"
            />
          </Box>
          <BoxWrapper>
            <ReferenceManyToManyInput
              source="id"
              reference="region"
              through="contractor-region"
              using="contractorId,regionId"
              label="Regions"
              initialValue={[]}
            >
              <SelectArrayInput
                optionText="name"
                className={classes.halfWidth}
              />
            </ReferenceManyToManyInput>
            <TextInput
              className={classes.halfWidth}
              source="notes"
              multiline
              rows={2}
            />
          </BoxWrapper>
          <Box>
            <BooleanInput
              defaultValue={false}
              source="suspended"
              className={classes.input}
            />
          </Box>
        </SimpleForm>
      </ManyToManyReferenceContextProvider>
    </Create>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
});
