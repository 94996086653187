import { dataProvider } from 'providers/dataProvider';

interface IErrors {
  name?: string[];
  code?: string[];
}

export const validateBuilder = async (values: {
  code: number;
  name: string;
  id: string;
}): Promise<IErrors> => {
  const errors: IErrors = {};

  if (!values.name) {
    errors.name = ['The name is required'];
  }
  if (!values.code) {
    errors.code = ['The Code is required'];
  }
  if (values.code) {
    const builder = await dataProvider.getList('builder', {
      filter: { 'code||$eq||': values.code },
      pagination: { page: 1, perPage: 200 },
      sort: { field: 'name', order: 'ASC' },
    });
    if (builder.data.length) {
      if (builder.data.length > 1 || builder.data[0].id !== values.id) {
        errors.code = ['Already have a builder with this code'];
      }
    }
  }
  return errors;
};
