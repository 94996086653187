import React, { ChangeEvent, ReactElement, useState } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  SpMatchesDefaultTableContainer,
  SpMatchesDefaultRow,
  SpMatchesDefaultTableRow,
  SpMatchesDefaultDraftRowPayload,
  SpMatchesDefaultDraftRow,
} from 'components/SpMatchesDefaultTable';

const MAX_NAME_LENGTH = 250;

export interface SpMatchesError {
  message: string;
}

interface SpMatchesDefaultTableEditProps<T extends unknown> {
  rows: SpMatchesDefaultTableRow[] | null;
  error: SpMatchesError | null;
  columns: string[];
  onAddNewItem: (name: string, active: boolean) => Promise<T>;
  onUpdateActiveFlag: (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => Promise<void>;
}

export const SpMatchesDefaultTableEdit = <T extends unknown>({
  rows,
  error,
  columns,
  onAddNewItem,
  onUpdateActiveFlag,
}: SpMatchesDefaultTableEditProps<T>): ReactElement => {
  const classes = useStyles();

  const [draftRow, setDraftRow] =
    useState<SpMatchesDefaultDraftRowPayload | null>(null);
  const [loading, setLoading] = useState(false);

  const handleDraftRowChange = (payload: SpMatchesDefaultDraftRowPayload) => {
    setDraftRow(prevState => {
      if (!prevState) return { name: '', active: false };

      return {
        ...prevState,
        ...payload,
      };
    });
  };

  const handleRowFlagChange = async (
    event: ChangeEvent<HTMLInputElement>,
    id: string,
  ) => onUpdateActiveFlag(event, id);

  const handleAddNewItem = async (name: string, active: boolean) => {
    if (name.length > MAX_NAME_LENGTH) return;

    setLoading(true);

    const response: T = await onAddNewItem(name, active);

    if (response) {
      setDraftRow(null);
    }

    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <SpMatchesDefaultTableContainer columns={columns}>
        {rows?.length
          ? rows.map(row => (
              <TableRow key={row.name}>
                <SpMatchesDefaultRow
                  row={row}
                  handleRowFlagChange={handleRowFlagChange}
                  readOnly={false}
                />
              </TableRow>
            ))
          : !draftRow && (
              <TableRow>
                <TableCell
                  colSpan={columns.length + 1}
                  className={classes.tableCellCenter}
                >
                  No potential matches found
                </TableCell>
              </TableRow>
            )}
        {!!draftRow && loading && (
          <TableRow>
            <TableCell
              colSpan={columns.length + 1}
              className={classes.tableCellCenter}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                py={1}
              >
                <CircularProgress size={26} />
              </Box>
            </TableCell>
          </TableRow>
        )}
        {!!draftRow && !loading && (
          <SpMatchesDefaultDraftRow
            row={draftRow}
            error={error}
            handleDraftRowChange={handleDraftRowChange}
          />
        )}
      </SpMatchesDefaultTableContainer>
      <Box className={classes.boxActions}>
        {!draftRow && (
          <IconButton
            onClick={() =>
              setDraftRow({
                name: '',
                active: false,
              })
            }
            disabled={loading}
          >
            <AddIcon color="primary" />
          </IconButton>
        )}
        {!!draftRow?.name && (
          <IconButton
            onClick={() => {
              if (!draftRow?.name) return;

              handleAddNewItem(draftRow.name, !!draftRow?.active);
            }}
            disabled={loading}
          >
            <SaveIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
  },
  boxActions: {
    display: 'flex',
    columnGap: 8,
    paddingLeft: 8,
    paddingBottom: 16,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
});
