/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, Fragment, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Dashboard, useUppy } from '@uppy/react';
import { dataProvider } from 'providers/dataProvider';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/file-input/dist/style.css';
import { makeStyles } from '@material-ui/core/styles';
import ImageViewer from './master-sheet-image-viewer';

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
  cancelButton: {
    color: '#e6005a',
  },
  photoDialog: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px 24px 18px 24px',
  },
});

const getJobPhasePhotos = async (dataProvider, jobPhaseId, setPhasePhotos) => {
  const jobPhasePhotos = [];
  const jobPhasePhotosResult = await dataProvider.getList('job-phase-photo', {
    filter: {
      'jobPhaseId||$eq||': jobPhaseId,
    },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
  });
  setPhasePhotos(jobPhasePhotosResult.data);
};

const createJobPhasePhoto = async (
  dataProvider,
  fileName,
  jobPhaseId,
  setPhasePhotos,
) => {
  const jobPhasePhotoPayload = {
    fileName,
    // "metaData": {},
    jobPhaseId,
  };
  const createdJobPhasePhoto = dataProvider.create('/job-phase-photo', {
    data: jobPhasePhotoPayload,
  });

  setTimeout(() => {
    getJobPhasePhotos(dataProvider, jobPhaseId, setPhasePhotos);
  }, 200);
};

export const ConfirmDialog = (props: any) => {
  const classes = useStyles();
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [phasePhotos, setPhasePhotos] = useState<any>([]);
  const [imageSelected, setImageSelected] = useState<any>();

  const onBeforeUpload = files => {
    Object.keys(files).map((key: any) => {
      files[key].fileName = `job-phase-id-${
        props.selectedPhase.id
      }-${new Date().getTime()}-${files[key].name}`;
    });
    return files;
  };

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      onBeforeUpload,
      restrictions: {
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
      },
    }).use(AwsS3, {
      getUploadParameters(file: any) {
        return dataProvider
          .create('/uploads/sign', {
            data: {
              contentType: file.type,
              objectName: `${file.fileName}`,
            },
          })
          .then(({ data }) => {
            return {
              method: 'PUT',
              url: data.signedUrl,
              fields: data.fields,
              headers: {
                'Content-Type': file.type,
              },
            };
          });
      },
    });
  });

  useEffect(() => {
    if (props.selectedPhase && props.selectedPhase.id) {
      getJobPhasePhotos(dataProvider, props.selectedPhase.id, setPhasePhotos);
    }
  }, [props.selectedPhase]);

  useEffect(() => {
    uppy.on('complete', _response => {
      _response.successful.map((itemResponse: any) => {
        createJobPhasePhoto(
          dataProvider,
          itemResponse.fileName,
          props.selectedPhase.id,
          setPhasePhotos,
        );
      });
      uppy.reset();
    });
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">
        {props.isViewPhotos ? 'View Job Phase Photos' : 'Photos'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          {!props.isViewPhotos && (
            <Dashboard
              disableThumbnailGenerator
              showLinkToFileUploadResult={false}
              uppy={uppy}
              height={100}
            />
          )}
        </DialogContentText>
        <Box>
          <Box>
            {phasePhotos.map((phasePhoto: any) => {
              return (
                <img
                  className={classes.img}
                  onClick={() => {
                    setImageSelected(phasePhoto);
                    setImageViewerOpen(true);
                  }}
                  src={phasePhoto.fileUrl}
                  key={phasePhoto.id}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.photoDialog}>
        <Button
          onClick={props.handleClose}
          color="primary"
          className={classes.cancelButton}
        >
          Cancel
        </Button>
        <Box display="grid" gridAutoFlow="column" gridGap={30}>
          {!props.isViewPhotos && (
            <Button
              onClick={() => {
                props.approveJob();
              }}
              color="primary"
              autoFocus
              variant="contained"
            >
              Approve Phase
            </Button>
          )}
        </Box>
      </DialogActions>
      <ImageViewer
        imageSelected={imageSelected}
        open={imageViewerOpen}
        isViewPhotos={props.isViewPhotos}
        handleClose={() => setImageViewerOpen(false)}
        getJobPhasePhotos={() =>
          getJobPhasePhotos(
            dataProvider,
            props.selectedPhase.id,
            setPhasePhotos,
          )
        }
      />
    </Dialog>
  );
};

export default ConfirmDialog;
