import { SuppliersList, SuppliersCreate, SuppliersEdit } from './views';

export default {
  create: SuppliersCreate,
  edit: SuppliersEdit,
  list: SuppliersList,
  name: 'supplier',
  label: 'Suppliers',
  to: '/supplier',
};
