import React from 'react';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select, { SelectProps } from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles({
  box: {
    marginBottom: '15px',
    width: '100%',
    marginTop: '8px',
  },
});

export interface ICustomItem {
  name: string;
  id: string;
}

export interface ICustomReference {
  options: ICustomItem[] | undefined;
  renderValue?: SelectProps['renderValue'];
  className?: SelectProps['className'];
  margin?: InputLabelProps['margin'];
  variant?: SelectProps['variant'];
  label?: string;
  resource?: string;
  onChange?: SelectInputProps['onChange'];
  disabled?: boolean;
  value?: string | string[];
  required?: boolean;
  error?: boolean;
  helperText?: string;
  multiple?: boolean;
}

export const CustomReference: React.FC<ICustomReference> = ({
  options,
  renderValue,
  className,
  margin,
  variant,
  label,
  resource,
  onChange,
  disabled,
  value,
  error,
  helperText,
  multiple = false,
}: ICustomReference) => {
  const classes = useStyles();

  return (
    <FormControl
      variant={variant}
      error={error}
      className={`${classes.box} ${className}`}
    >
      {resource && label && (
        <InputLabel
          margin={margin}
          variant={variant}
          id={`custom-reference-${resource}`}
        >
          {label}
        </InputLabel>
      )}
      <Select
        autoWidth
        disabled={disabled}
        onChange={onChange}
        labelId={resource ? `custom-reference-${resource}` : undefined}
        id={resource ? `reference-${resource}` : 'reference'}
        renderValue={renderValue}
        fullWidth
        value={value}
        multiple={multiple}
        margin={margin}
      >
        {!options?.length ? (
          <MenuItem disabled>No options available</MenuItem>
        ) : (
          options.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))
        )}
      </Select>
      {error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CustomReference;
